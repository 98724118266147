import _ from 'lodash'
import peachApiCall from './peachApiCall'

/* 
peachApi.getAll() is a special case of a GET operation.
it will recursively get all results and return them as if limit=infinity.  
this is a dangerous operation in the case of unbounded collections (i.e. it could indefinitely load)
and should only be used as a stop-gap measure until a better solution can be found 
(usually better filter parameters in an API so that we only query for what we need when we need it)

note:  the `limit` parameter takes on the meaning of 'chunk size' in this context

*/

// guarding against some live-lock behavior where we fetch forever
const MAX_PAGES = 10

const check = (str, condition) => {
  if (!condition) throw new Error(`peachApi.getAll() ${str}`)
}

const append = (prevResult, newResp) => {
  const { data: prevData, count: prevCount, ...restPrev } = prevResult || {}
  const { data: newData, count: newCount, ...restNew } = newResp || {}
  return {
    ...restPrev,
    ...restNew,
    count: (prevCount || 0) + newCount,
    data: (prevData || []).concat(newData)
  }
}

const peachGetAll = async options => {
  const { apiOptions, fullUrl, result, pageCounter = 0 } = options

  const resp = await peachApiCall({ ...apiOptions, fullUrl, method: 'GET' })

  check('must return a paginated list', _.isArray(resp?.data))

  const newResult = append(result, resp)

  if (resp.nextUrl) {
    check(`must be less than ${MAX_PAGES} pages`, pageCounter < MAX_PAGES)

    return peachGetAll({
      apiOptions,
      fullUrl: resp.nextUrl,
      result: newResult,
      pageCounter: pageCounter + 1
    })
  } else {
    return newResult
  }
}

export default peachGetAll
