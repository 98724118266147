import _ from 'lodash'
import { CREATE_NOTE, GET_NOTES, UPDATE_NOTE } from 'core/actions/constants'

const initialState = {
  byId: {},
  allIds: []
}

const notes = function(state = initialState, action) {
  switch (action.type) {
    case GET_NOTES:
      const newNotes = _.reduce(
        action.payload,
        (acc, { id, ...rest }) => ({
          ...acc,
          [id]: { id, personId: action.personId, ...rest }
        }),
        {}
      )
      return {
        ...state,
        byId: {
          ...state.byId,
          ...newNotes
        }
      }
    case CREATE_NOTE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
            personId: action.personId
          }
        }
      }
    case UPDATE_NOTE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...action.payload,
            personId: action.personId
          }
        }
      }
    default:
      return state
  }
}

export default notes
