import _ from 'lodash'

const addRePrefix = str => {
  // If flasy then return as is
  if (!str) return str

  // Converting to lowercase to capture all case-sensitive scenarios
  const lowerCaseStr = _.toLower(str)
  if (_.startsWith(lowerCaseStr, 're:')) return str

  // Add prefix if param is truthy and does not start with prefix
  return `Re: ${str}`
}

export default addRePrefix
