import { useSelector } from 'react-redux'
import { _useCompanyId as useCompanyId } from 'core/SystemProvider/CompanyIdProvider'
// importing directly to avoid a circular dependency issue with 'core/hooks/index.js'

const defaultValue = {}

const usePermissions = () => {
  const companyId = useCompanyId()

  return useSelector(state => {
    return state.permissions.hashByCompanyId[companyId] || defaultValue
  })
}

export default usePermissions
