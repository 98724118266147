import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import variables from 'core/styles/variables'

const Row = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid ${variables.colorBlack30};
  color: ${variables.colorBlack60};
  font-size: 14px;
  /**
   * Using 4px top/bottom to split the 8px padding
   * that was originally on this component with
   * the InlineDropdown component, which now has 4px
   * top/bottom padding.
   */
  padding: 4px 16px;
  margin: ${p => (p.maximized ? '0 16px' : '0')};
`

Row.propTypes = {
  /** If the containing resource window is maximized */
  maximized: PropTypes.bool
}

export default Row
