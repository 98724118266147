import { useParams } from 'react-router-dom'

import useCase from 'core/hooks/useCaseEntity'

/** Simple hook to grab the case of the current context in the
 * navigation url, assuming that both borrowerId and caseId are
 * parameters in the React route. In other words, only use this
 * hook on appropriate pages that have that url context.
 */
const useCurrentCase = () => {
  const { caseId, borrowerId } = useParams()
  return useCase(caseId, { personId: borrowerId })
}

export default useCurrentCase
