import PropTypes from 'prop-types'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useBorrowerUrl } from 'core/hooks'

import styled from 'styled-components/macro'

const Wrapper = styled.div``

const Message = styled.div`
  padding: 24px;
`

const ActionArea = styled.div`
  text-align: right;
  padding: 16px;
`

const BackLink = styled(Link)`
  padding: 4px 8px;
`

const ActionDisabledMessage = ({ backUrl, message }) => {
  const { loanId } = useParams()

  const url = backUrl || (loanId ? `/loans/${loanId}/activity` : '/loans')

  const borowerUrl = useBorrowerUrl(url)

  return (
    <Wrapper>
      <Message>{message}</Message>

      <ActionArea>
        <BackLink to={borowerUrl}>Back</BackLink>
      </ActionArea>
    </Wrapper>
  )
}

ActionDisabledMessage.propTypes = {
  backUrl: PropTypes.string,
  message: PropTypes.string
}

export default ActionDisabledMessage
