import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

const MastercardIcon = () => (
  <SvgIcon fontSize='inherit' viewBox='0 0 40 40'>
    <path
      d='M40 31.4283C40 32.7342 38.9414 33.7929 37.6355 33.7929H2.36445C1.05859 33.7929 0 32.734 0 31.4283V8.57162C0 7.26576 1.05859 6.20709 2.36445 6.20709H37.6355C38.9414 6.20709 39.9999 7.26576 39.9999 8.57162L40 31.4283Z'
      fill='#0099DF'
    />
    <path
      d='M17.7248 20.1379C17.7248 18.2119 18.6119 16.4934 19.9998 15.3681C18.9452 14.5129 17.601 14 16.1373 14C12.7475 14 10 16.7479 10 20.1377C10 23.5275 12.7475 26.2752 16.1373 26.2752C17.6012 26.2752 18.9452 25.7626 19.9998 24.9071C18.6119 23.7821 17.7248 22.0637 17.7248 20.1379Z'
      fill='#EA001B'
    />
    <path
      d='M23.8624 14.0002C22.3986 14.0002 21.055 14.5131 19.9999 15.3682C21.3881 16.4936 22.2752 18.2121 22.2752 20.138C22.2752 22.0638 21.3881 23.7823 19.9999 24.9074C21.055 25.7628 22.3988 26.2755 23.8624 26.2755C27.2525 26.2755 30 23.5278 30 20.138C30 16.748 27.2522 14.0002 23.8624 14.0002Z'
      fill='#F79F1A'
    />
    <path
      d='M22.275 20.1379C22.275 18.212 21.3879 16.4934 19.9998 15.3681C18.6119 16.4934 17.7247 18.212 17.7247 20.1379C17.7247 22.0637 18.6118 23.7822 19.9998 24.9073C21.3878 23.7822 22.275 22.0637 22.275 20.1379Z'
      fill='#FF5F01'
    />
  </SvgIcon>
)

export default MastercardIcon
