import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { AnimatedInput } from 'core/components'
import { useWrite } from 'core/hooks'
import { ExpectedError } from 'core/system/ErrorLogging'

import LoginSubmitButton from './LoginSubmitButton'
import { minDelay } from './loginHelpers'
import { requestOneTimeCode } from './loginApi'
import OneTimeCodeModal from './OneTimeCode/OneTimeCodeModal'
import LoginFormCard from './LoginFormCard'
import { formatOtcError } from './OneTimeCode/otcHelpers'
import { useLoginInfo } from './LoginWrapper'

const CodeLogin = ({ onLogin }) => {
  const { companyId, userType, authType } = useLoginInfo()

  const authValueLabel = authType === 'oneTimeCodeEmail' ? 'Email' : 'Username'

  /* 
    oneTimeCodeEmail => 'email' is the default OTC channel
    oneTimeCodeText => 'text' is the default channel
  */

  const initialChannel = authType === 'oneTimeCodeText' ? 'text' : 'email'

  const [username, setUsername] = useState('')

  const onRequestCodeToChannel = async channel => {
    return requestOneTimeCode({ username, companyId, userType, channel })
  }

  const handleOneTimeCode = code => {
    return onLogin({ username, password: code })
  }

  const [requestCode, requesting, resp, error, clear] = useWrite(async () => {
    if (!username) throw new ExpectedError(`${authValueLabel} is required`)

    const promise = onRequestCodeToChannel(initialChannel)
    return minDelay(promise, 750)
  })

  useEffect(() => {
    clear()
  }, [username, clear])

  const errorMessage = formatOtcError(error)

  const handleChange = event => {
    // BE should be case insensitive,
    // but also setting to lowercase for aesthetic reasons for the user
    setUsername(event.target.value.toLowerCase())
  }

  const handleEnter = () => {
    if (username && !requesting) requestCode()
  }

  return (
    <LoginFormCard>
      <AnimatedInput
        label={authValueLabel}
        name={authType}
        onChange={handleChange}
        placeholder=' '
        showError={error && !username}
        type='text'
        value={username}
        disabled={requesting}
        onEnter={handleEnter}
      />

      <LoginSubmitButton
        onSubmit={requestCode}
        sending={requesting}
        errorMessage={errorMessage}
        subtitle="We'll send you a verification code"
        disabled={!!resp}
      />

      {resp && (
        <OneTimeCodeModal
          onOneTimeCode={handleOneTimeCode}
          userType={userType}
          onRequestCodeToChannel={onRequestCodeToChannel}
          onClose={clear}
          initialChannel={initialChannel}
        />
      )}
    </LoginFormCard>
  )
}

CodeLogin.propTypes = {
  onLogin: PropTypes.func
}

export default CodeLogin
