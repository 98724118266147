/**
 * Returns the editable content portion of a template and
 * the start and end html context if it exists. Otherwise
 * it returns the input param as is.
 *
 * @param {String} html
 */
const extractEditableContentHtml = html => {
  const start = '<!-- START_EDITABLE_CONTENT -->'
  const end = '<!-- END_EDITABLE_CONTENT -->'
  const startIndex = html.indexOf(start)
  const endIndex = html.indexOf(end)

  const editableContent = html.substring(startIndex + start.length, endIndex)

  return {
    editableContent: editableContent
  }
}

export default extractEditableContentHtml
