// Third Party ------------------------
import PropTypes from 'prop-types'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components/macro'
import { MobileTitleProvider } from 'core/components/Layout/MobileLayout/MobileTitleManager'
import BorrowerQueryProvider from 'loan/queries/internal/BorrowerQueryProvider'
import { useSetGlobalValue } from 'core/badGlobalDoNotUse'

const BorrowerIdContext = createContext('')
const BorrowerBasePathContext = createContext('') // this is a route path with the :wildcard, for use in routes
const BorrowerBaseUrlContext = createContext('') // this is a route url with the actual ID for use in links, etc
const BorrowerIsMobileContext = createContext(false)

const BorrowerProviders = props => {
  const {
    basePath = '',
    baseUrl = '',
    borrowerId,
    isMobile,
    children,
    companyTheme
  } = props

  const [personId, setPersonId] = useState(undefined)
  const switching = personId !== borrowerId

  useSetGlobalValue('borrowerPersonId', borrowerId)

  // this forces remounting and a small delay so that the
  // bad global value will definitely be up to date when the cDM
  // and other lifecycle methods run to start the fetching.
  //
  // TODO:  modify cDM, etc lifecycles to take personId as an argument
  // rather than pulling it from the global

  useEffect(() => {
    const onTimeout = () => setPersonId(borrowerId)
    const id = setTimeout(onTimeout, 100)
    return () => clearTimeout(id)
  }, [borrowerId])

  if (!personId || switching) return null

  return (
    <BorrowerQueryProvider>
      <MobileTitleProvider>
        <BorrowerIdContext.Provider value={personId}>
          <BorrowerBasePathContext.Provider value={basePath}>
            <BorrowerBaseUrlContext.Provider value={baseUrl}>
              <BorrowerIsMobileContext.Provider value={!!isMobile}>
                <StyledThemeProvider theme={companyTheme}>
                  {personId && !switching ? children : null}
                </StyledThemeProvider>
              </BorrowerIsMobileContext.Provider>
            </BorrowerBaseUrlContext.Provider>
          </BorrowerBasePathContext.Provider>
        </BorrowerIdContext.Provider>
      </MobileTitleProvider>
    </BorrowerQueryProvider>
  )
}

BorrowerProviders.propTypes = {
  basePath: PropTypes.string,
  borrowerId: PropTypes.string,
  baseUrl: PropTypes.string,
  isMobile: PropTypes.bool,
  children: PropTypes.node,
  companyTheme: PropTypes.object
}

const _useBorrowerId = () => useContext(BorrowerIdContext)

const _useBorrowerBasePath = () => useContext(BorrowerBasePathContext)

const _useBorrowerBaseUrl = () => useContext(BorrowerBaseUrlContext)

const _useBorrowerIsMobile = () => useContext(BorrowerIsMobileContext)

export {
  BorrowerProviders,
  _useBorrowerId,
  _useBorrowerBasePath,
  _useBorrowerBaseUrl,
  _useBorrowerIsMobile
}
