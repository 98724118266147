import { useBorrower, useContactsSelectOptions } from 'core/hooks'
import {
  KEY_AFFILIATION,
  STATUS_ADDITIONAL,
  STATUS_ACTIVE,
  STATUS_PRIMARY,
  STATUS_SECONDARY
} from 'core/hooks/useContactsSelectOptions'

const useTelephonyContacts = ({ borrowerId }) => {
  const borrower = useBorrower(borrowerId)
  const { borrowerType } = borrower || {}
  const isBusinessBorrower = borrowerType === 'business'

  const groupedContactList = useContactsSelectOptions({
    borrowerId,
    filterBy: {
      authorizedThirdParty: true,
      contactType: 'phone',
      /**
       * 'active' is not part of the contacts status enum, but it is
       * part of the enum value for legal representation. We're combining
       * contacts and legal rep in the same list, which is why we are
       * adding 'active' as part of the enum for status
       */
      status: [
        STATUS_ACTIVE,
        STATUS_PRIMARY,
        STATUS_SECONDARY,
        STATUS_ADDITIONAL
      ],
      valid: true
    },
    groupedBy: KEY_AFFILIATION,
    groupWithAdditionalContacts: true,
    isBusinessBorrower,
    useIcons: true,
    useSublabels: true
  })
  return groupedContactList
}

export default useTelephonyContacts
