import {
  APIendpoint,
  DELETE_ASSOCIATION,
  LOADING,
  getStoredToken
} from 'core/actions/constants'
import { DELETE } from 'core/api/legacyApi'
import { handleErrors } from 'core/helpers/handleErrors'

const deleteAssociation = ({ key, personId, caseId, query, objectId }) => {
  if (!personId || !caseId) {
    throw new Error('personId or caseId is missing')
  }

  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    const endpoint = `${APIendpoint}/people/${personId}/cases/${caseId}?${query}`
    const response = await fetch(endpoint, DELETE(getStoredToken()))
    await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    if (isSuccess) {
      dispatch({
        type: DELETE_ASSOCIATION,
        id: caseId,
        objectId
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error'
      })
    }
  }
}

export default deleteAssociation
