import styled from 'styled-components/macro'

import variables from 'core/styles/variables'

/** Use this in between instances of CardSection as desired */
const Divider = styled.div`
  border-bottom: 1px solid ${variables.colorBlack20};
  margin-left: ${p => p.marginLeft || '24px'};
`
export default Divider
