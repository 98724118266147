// Third party ------------------------
import 'react-datepicker/dist/react-datepicker.css'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import _ from 'lodash'
import { getYear, getMonth, isToday } from 'date-fns'
import { useWindowSize } from '@reach/window-size'

// Proprietary ------------------------
import variables from 'core/styles/variables'

// Components
import { Button } from 'core/components'
import Icon from 'core/components/Icon'

import {
  Container,
  CustomHeader,
  IconWrapper,
  InputYear,
  SelectMonth,
  SelectionGroup,
  TodayText
} from './DatepickerStyles'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
].map((m, i) => ({
  value: i,
  label: m
}))

const replacedComponentsForSelect = {
  DropdownIndicator: () => <Icon name='arrow_drop_down' />,
  IndicatorSeparator: () => null
}

const Datepicker = ({
  dateFormat,
  disabled,
  filterDate,
  getSelectedDate,
  isClearable,
  isUncontrolled,
  isSelectedToday,
  maxDate,
  minDate,
  onChange,
  placeholderText,
  popperModifiers: passedPopperModifiers,
  popperPlacement,
  selectedDate,
  width
}) => {
  // Local state
  const [startDate, setStartDate] = useState(selectedDate)

  const { width: windowWidth } = useWindowSize()

  const showAsDialog = windowWidth < 520

  const calendarRef = useRef()

  const showTodayText = isUncontrolled
    ? isToday(startDate)
    : isSelectedToday
    ? isSelectedToday()
    : isToday(selectedDate)

  const renderCustomHeader = customHeaderProps => {
    const {
      changeMonth,
      changeYear,
      date,
      decreaseMonth,
      increaseMonth,
      nextMonthButtonDisabled,
      prevMonthButtonDisabled
    } = customHeaderProps

    return (
      <CustomHeader>
        <Button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          <Icon name='play_arrow' color={variables.colorBlack60} />
        </Button>

        <SelectionGroup className='selection-group'>
          <SelectMonth
            components={replacedComponentsForSelect}
            onChange={({ value }) => changeMonth(value)}
            options={months}
            value={months.find(m => m.value === getMonth(date))}
          />

          <InputYear
            maxLength='4'
            onChange={e => changeYear(e.target.value)}
            type='text'
            value={getYear(date) || ''}
          />
        </SelectionGroup>

        <Button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          <Icon name='play_arrow' color={variables.colorBlack60} />
        </Button>
      </CustomHeader>
    )
  }

  const handleClick = () => {
    if (!disabled) calendarRef.current.setOpen(true)
  }

  const handleSelect = date => {
    if (isUncontrolled) {
      setStartDate(date)
      getSelectedDate && getSelectedDate(date)
    } else {
      onChange(date)
      _.delay(() => {
        if (calendarRef.current) calendarRef.current.setOpen(false)
      }, 300)
    }
  }

  const defaultPopperModifiers = {
    flip: { enabled: false },
    offset: { enabled: true, offset: '29px, 10px' },
    preventOverflow: { enabled: false }
  }
  const popperModifiers = {
    ...defaultPopperModifiers,
    ...passedPopperModifiers
  }

  return (
    <Container width={width} className='datepicker' onClick={handleClick}>
      <IconWrapper>
        <Icon name='event' fontSize='24px' />
      </IconWrapper>

      {showTodayText && <TodayText>{'Today, '}</TodayText>}

      <ReactDatePicker
        renderCustomHeader={renderCustomHeader}
        dateFormat={dateFormat}
        disabled={disabled}
        isClearable={isClearable}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleSelect}
        placeholderText={placeholderText}
        ref={calendarRef}
        selected={isUncontrolled ? startDate : selectedDate}
        showPopperArrow={false}
        useWeekdaysShort
        withPortal={showAsDialog}
        filterDate={filterDate}
        disabledKeyboardNavigation
        popperPlacement={popperPlacement || 'bottom'}
        popperModifiers={popperModifiers}
        // keyboard navigation shows a confusing focus ring, even when not using the keyboard.
        // for accessibility, we do want to turn this back on eventually
      />
    </Container>
  )
}

Datepicker.propTypes = {
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  filterDate: PropTypes.func,
  getSelectedDate: PropTypes.func,
  isClearable: PropTypes.bool,
  isUncontrolled: PropTypes.bool,
  /** This prop is just so that TzDatepicker can override
   * how we determine whether to show the string "Today"
   * next to the selected date string.
   */
  isSelectedToday: PropTypes.func,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  placeholderText: PropTypes.string,
  popperModifiers: PropTypes.object,
  popperPlacement: PropTypes.string,
  selectedDate: PropTypes.instanceOf(Date),
  width: PropTypes.string
}

Datepicker.defaultProps = {
  width: '200px'
}

export default Datepicker
