// Third party --------------
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components/macro'

// Components ---------------
import Divider from 'loan/components/actionCards/shared/Divider'
import MessageBubble from '../MessageBubble'

// Rest ---------------------
import { variables } from 'core/styles'

// Styled components --------
const Container = styled.div`
  padding: 8px 8px 0;
`
const Timestamp = styled.div`
  padding: 16px 0;
  color: ${variables.colorBlack60};
  font-size: 14px;
  text-align: center;
`
const SensitiveText = styled.div`
  padding: 16px 16px 24px;
  color: ${variables.colorBlack60};
  text-align: center;
`

// Sensitive content components
const sensitiveBorrowerOnlyContent = (
  <SensitiveText data-testid='sensitive-borrower-only-content'>
    "Account Credential" interactions are never displayed in the CRM because
    they contain information used to verify borrower details.
  </SensitiveText>
)
const sensitiveContent = (
  <SensitiveText data-testid='sensitive-content'>
    Content is locked. Only those with access, like a manager, can unlock this
    content.
  </SensitiveText>
)

const Conversation = ({
  conversation = [],
  startedAt,
  endedAt,
  isSensitive,
  isSensitiveBorrowerOnly
}) => {
  return (
    <>
      {startedAt && (
        <>
          <Timestamp data-testid='started-at-text'>
            Started {startedAt}
          </Timestamp>
          <Divider marginLeft='0' />
        </>
      )}
      <Container data-testid='container'>
        {isSensitiveBorrowerOnly ? (
          sensitiveBorrowerOnlyContent
        ) : isSensitive ? (
          sensitiveContent
        ) : (
          <>
            {_.map(conversation, (message, index) => {
              const { author, content, direction, timestamp } = message
              return (
                <MessageBubble
                  author={author}
                  content={content}
                  direction={direction}
                  key={`${index}-${timestamp}`}
                  timestamp={timestamp}
                />
              )
            })}
          </>
        )}
      </Container>
      {startedAt && endedAt ? (
        <>
          <Divider marginLeft='0' />
          <Timestamp data-testid='ended-at-text'>Ended {endedAt}</Timestamp>
        </>
      ) : startedAt && !endedAt ? (
        <>
          <Divider marginLeft='0' />
          <Timestamp data-testid='ended-at-text'>
            Conversation is in progress...
          </Timestamp>
        </>
      ) : null}
    </>
  )
}

Conversation.propTypes = {
  conversation: PropTypes.array,
  startedAt: PropTypes.string,
  endedAt: PropTypes.string,
  isSensitive: PropTypes.bool,
  isSensitiveBorrowerOnly: PropTypes.bool
}

export default Conversation
