import {
  GET_INTERACTIONS,
  GET_MORE_INTERACTIONS,
  LOADING
} from 'core/actions/constants'
import { peachApi } from 'core/api'

const getInteractions = ({ key, filters, nextUrl }) => {
  const { personId } = filters

  return async dispatch => {
    if (key && !nextUrl) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    const endpoint = `/people/${personId}/interactions?sortBy=-id&limit=100`

    try {
      const response = await peachApi.get({
        url: !nextUrl ? endpoint : null,
        fullUrl: nextUrl
      })
      dispatch({
        type: nextUrl ? GET_MORE_INTERACTIONS : GET_INTERACTIONS,
        payload: response.data
      })
      if (response.nextUrl) {
        dispatch(
          getInteractions({
            filters,
            key,
            nextUrl: response.nextUrl
          })
        )
      } else {
        if (key) {
          dispatch({ type: LOADING, key, status: 'success' })
        }
      }
    } catch {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
    }
  }
}

export default getInteractions
