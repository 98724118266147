import styled from 'styled-components/macro'
import itemStyles from './itemStyles'

const BadgeButton = styled.button`
  ${itemStyles};
  background-color: #ddd;
  transition: background-color 0.2s;
  color: #222;
  &:not(:disabled) {
    cursor: pointer;
    &:hover {
      background-color: #ccc;
    }
  }

  &:disabled {
    color: #888;
  }
`

export default BadgeButton
