import {
  GET_CASE,
  GET_CASES,
  UPDATE_CASE,
  UPDATE_SNOOZE,
  UPDATE_ESCALATION,
  CANCEL_ESCALATION,
  ADD_ASSOCIATION,
  DELETE_ASSOCIATION
} from 'core/actions/constants'

const initialState = {
  byId: {},
  allIds: []
}

const cases = function(state = initialState, action) {
  switch (action.type) {
    case GET_CASE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...action.payload,
            personId: action.personId
          }
        }
      }
    case GET_CASES:
      return {
        ...state,
        byId: {
          // keep all existing cases for other personIds
          ...Object.values(state.byId).reduce((acc, curr) => {
            if (curr.personId !== action.personId) {
              acc[curr.id] = curr
            }
            return acc
          }, {}),
          // insert the fresh cases for this personId
          ...action.payload.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.id]: {
                ...curr,
                personId: action.personId
              }
            }),
            {}
          )
        }
      }
    case UPDATE_CASE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            ...action.payload
          }
        }
      }
    case UPDATE_SNOOZE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.caseId]: {
            ...state.byId[action.caseId],
            snoozedAt: action.payload.snoozedAt,
            snoozedUntil: action.payload.snoozedUntil
          }
        }
      }
    case UPDATE_ESCALATION:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.caseId]: {
            ...state.byId[action.caseId],
            ...action.payload
          }
        }
      }
    case CANCEL_ESCALATION:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.caseId]: {
            ...state.byId[action.caseId],
            ...action.payload
          }
        }
      }
    case ADD_ASSOCIATION:
      const associations = state.byId[action.id].associations || []
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            associations: [
              ...associations,
              {
                objectId: action.body.objectId,
                objectType: action.body.objectType
              }
            ]
          }
        }
      }
    case DELETE_ASSOCIATION:
      let remainingAssociation = state.byId[action.id].associations || []
      if (remainingAssociation.length > 0) {
        remainingAssociation = remainingAssociation.filter(
          association => association.objectId !== action.objectId
        )
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            associations: remainingAssociation
          }
        }
      }
    default:
      return state
  }
}

export default cases
