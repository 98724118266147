import useCollection from './useCollection'

const useEmployees = companyId => {
  const { useCollection } = useEmployees.dependencies
  return useCollection('employees', { companyId })
}
useEmployees.dependencies = {
  useCollection
}

export default useEmployees
