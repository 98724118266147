// Third party ------------------------
import 'react-quill/dist/quill.snow.css'
import PropTypes from 'prop-types'
import React, { useMemo, useRef } from 'react'
import ReactQuill from 'react-quill'

const defaultFormats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color'
]

const PeachQuill = props => {
  const { formats, id, placeholder, quillRef: passedQuillRef, ...rest } = props

  const localQuillRef = useRef()
  const quillRef = passedQuillRef || localQuillRef

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: `#${id}`,
        handlers: {
          redo: () => quillRef.current.editor.history.redo(),
          undo: () => quillRef.current.editor.history.undo()
        }
      },
      clipboard: {
        matchVisual: false
      }
    }
  }, [id, quillRef])

  return (
    <ReactQuill
      formats={formats || defaultFormats}
      placeholder={placeholder || ''}
      ref={quillRef}
      theme='snow'
      modules={modules}
      {...rest}
    />
  )
}

PeachQuill.propTypes = {
  formats: PropTypes.array,
  id: PropTypes.string,
  modules: PropTypes.object,
  placeholder: PropTypes.string,
  quillRef: PropTypes.object
}

export default PeachQuill
