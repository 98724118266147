import _ from 'lodash'

const wait = wait => new Promise(resolve => setTimeout(resolve, wait))

// this delay the resolve of a quick promise to wait at least the delay time,
// which is used for very fast endpoints to show that some kind  of work is being done.

const minDelay = async (promiseOrFn, delay = 500) => {
  const realPromise =
    typeof promiseOrFn === 'function' ? promiseOrFn() : promiseOrFn
  const delayPromise = wait(delay)
  return Promise.all([realPromise, delayPromise]).then(([result]) => result)
}

const parseError = error => {
  if (!error) return
  if (_.isString(error)) return error
  if (_.isString(error.message)) return error.message
  return 'Unknown Error'
}

export { wait, minDelay, parseError }
