import { useState, useEffect } from 'react'
import ErrorLogging from 'core/system/ErrorLogging'

import { getStoredToken, decodeToken } from 'core/actions/helpers/tokenHelpers'

const useErrorLoggingTag = (tag, value) => {
  useEffect(() => {
    ErrorLogging.setTag(tag, value)
    return () => ErrorLogging.setTag(tag, undefined)
  }, [tag, value])
}

const useErrorLoggingScope = options => {
  const { companyId, company, appDomainType, userType } = options

  // putting this in state so it doesn't re-parse on each render
  const [token] = useState(() => decodeToken(getStoredToken()))

  const { sub: userId, personId, employeeId } = token || {}

  const { brandName, legalName } = company || {}

  const companyName = legalName || brandName

  useEffect(() => {
    ErrorLogging.setUser({ id: userId })
  }, [userId])

  useErrorLoggingTag('appDomainType', appDomainType)

  useErrorLoggingTag('company.id', companyId)

  useErrorLoggingTag('company.name', companyName)

  useErrorLoggingTag('person.id', personId)

  useErrorLoggingTag('employee.id', employeeId)

  useErrorLoggingTag('user.type', userType)
}

export { useErrorLoggingScope, useErrorLoggingTag }
