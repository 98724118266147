import { GET_CASE, LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

const getCase = ({ id, key, personId }) => {
  return async dispatch => {
    if (key) {
      dispatch({ type: LOADING, key, status: 'loading' })
    }

    try {
      const response = await peachApi.get({
        url: `/people/${personId}/cases/${id}?associations=all&include=dispute,purchase`
      })
      dispatch({ type: GET_CASE, payload: response.data, personId, id })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
    } catch {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
    }
  }
}

export default getCase
