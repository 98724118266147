const editDraftPreview = draftPreview => {
  const { contentHtml } = draftPreview || {}
  if (!contentHtml) return ''
  const index = contentHtml.indexOf('</head>')
  const beforeNewStyles = contentHtml.slice(0, index)
  const afterNewStyles = contentHtml.slice(index)
  const style = '<style>.page-content { padding: 24px; }</style>'
  return beforeNewStyles + style + afterNewStyles
}

export default editDraftPreview
