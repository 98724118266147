import useLoan from './useLoan'
import { useTime } from 'core/time'

const useLoanStartDate = loanId => {
  const loan = useLoan(loanId)
  const { timestringToDatestring } = useTime()
  return loan?.timestamps.startedAt
    ? timestringToDatestring(loan.timestamps.startedAt)
    : undefined
}

export default useLoanStartDate
