import { GET_NOTES, LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

const getNotes = ({ key, filters }) => {
  const { personId, caseId } = filters

  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    try {
      const response = await peachApi.get({
        url: `/people/${personId}/cases/${caseId}/notes?includeArchived=true`
      })
      dispatch({ type: GET_NOTES, payload: response.data, personId })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
      return response.data
    } catch {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
    }
  }
}

export default getNotes
