import _ from 'lodash'
import {
  CREATE_SUPERCASE,
  GET_SUPERCASE,
  GET_SUPERCASES,
  GET_MORE_SUPERCASES,
  UPDATE_SUPERCASE
} from 'core/actions/constants'

const initialState = {
  byId: {},
  allIds: []
}

const supercases = function(state = initialState, action) {
  switch (action.type) {
    case GET_SUPERCASE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        }
      }
    case GET_SUPERCASES:
      const newSupercases = _.reduce(
        action.payload,
        (acc, { id, ...rest }) => ({ ...acc, [id]: { id, ...rest } }),
        {}
      )
      return {
        ...state,
        byId: newSupercases
      }
    case GET_MORE_SUPERCASES:
      // GET_MORE_SUPERCASES differs from GET_SUPERCASES in that it
      // doesn't delete anything already in the supercases redux store.
      // This is useful for the call to getSupercases to do multiple
      // api calls to get all the paginated results.
      const additionalSupercases = _.reduce(
        action.payload,
        (acc, { id, ...rest }) => ({ ...acc, [id]: { id, ...rest } }),
        {}
      )
      return {
        ...state,
        byId: {
          ...state.byId,
          ...additionalSupercases
        }
      }
    case UPDATE_SUPERCASE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...action.payload
          }
        }
      }
    case CREATE_SUPERCASE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        }
      }
    default:
      return state
  }
}

export default supercases
