import _ from 'lodash'
import { POST } from 'core/api/legacyApi'

import {
  APIendpoint,
  getStoredToken,
  decodeToken
} from 'core/actions/helpers/tokenHelpers'

const parseError = error => {
  if (!error) return
  if (_.isString(error)) return error
  if (_.isString(error.message)) return error.message
  return 'Unknown Error'
}

const keys = ['username', 'password', 'userType', 'companyId']

const loginWithPassword = async options => {
  for (const key of keys) {
    if (!options[key]) throw new Error(`'${key}' is required`)
  }

  const { username, password, userType, companyId } = options

  const endpoint = `${APIendpoint}/auth/token?userType=${userType}&companyId=${companyId}`

  const body = { username, password }

  const resp = await fetch(endpoint, POST(body))

  const json = await resp.json()

  if (resp.ok) {
    return json
  } else {
    throw json
  }
}

const requestOneTimeCode = async options => {
  const { username, companyId, userType, channel } = options

  const endpoint = `${APIendpoint}/companies/${companyId}/auth/send-one-time-code`

  const resp = await fetch(endpoint, POST({ username, channel, userType }))

  const json = await resp.json()

  if (resp.ok) {
    return json
  } else {
    throw json
  }
}

const requestOneTimeCodeViaEmail = async options => {
  const { username, companyId, userType } = options

  return requestOneTimeCode({ username, companyId, userType, channel: 'email' })
}

const requestOneTimeCodeViaPhone = async options => {
  const { username, companyId, userType } = options

  return requestOneTimeCode({ username, companyId, userType, channel: 'phone' })
}

export {
  getStoredToken as getToken,
  decodeToken,
  loginWithPassword,
  requestOneTimeCode,
  requestOneTimeCodeViaEmail,
  requestOneTimeCodeViaPhone,
  parseError
}
