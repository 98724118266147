import _ from 'lodash'
import { useState, useEffect } from 'react'

const overridesEnabled = !!localStorage.getItem('ENABLE_PEACH_OVERRIDES')

const KEY = 'PEACH_OVERRIDES'

const exists = val => val !== null && val !== undefined

const load = () => {
  try {
    const val = JSON.parse(localStorage.getItem(KEY))
    return _.isPlainObject(val) ? val : {}
  } catch {
    return {}
  }
}

const overrides = {
  values: load(),
  callbacks: {},
  globalCbs: []
}

const save = () => {
  try {
    if (_.isEmpty(overrides.values)) {
      localStorage.removeItem(KEY)
    } else {
      localStorage.setItem(KEY, JSON.stringify(overrides.values))
    }
  } catch {}
}

const getOverrides = () => overrides.values

const getOverride = key => overrides.values?.[key]

const trigger = key => {
  const val = getOverride(key)
  _.each(overrides.callbacks[key], cb => cb(val))
}

const triggerAll = () => _.each(_.keys(overrides.callbacks), trigger)

const setValue = (key, value) => {
  if (exists(value)) {
    overrides.values[key] = value
  } else {
    delete overrides.values[key]
  }
}

const setOverride = (key, value) => {
  setValue(key, value)
  trigger(key)
  save()
}

const setOverrides = obj => {
  _.each(obj, (value, key) => {
    setValue(key, value)
    trigger(key)
  })
  save()
}

const clearOverrides = () => {
  overrides.values = {}
  triggerAll()
  save()
}

const bind = (key, fn) => {
  if (!overrides.callbacks[key]) overrides.callbacks[key] = []
  overrides.callbacks[key] = overrides.callbacks[key].concat(fn)
}
const unbind = (key, fn) => {
  overrides.callbacks[key] = _.without(overrides.callbacks[key], fn)
}

const useOverrides = () => {
  const [val, setVal] = useState(getOverrides)

  useEffect(() => {
    const onChange = () => setVal(getOverrides())
    overrides.globalCbs = overrides.globalCbs.concat(onChange)
    return () => {
      overrides.globalCbs = _.without(overrides.globalCbs, onChange)
    }
  }, [])

  return val || {}
}

const useOverride = (key, fallback) => {
  const [val, setVal] = useState(() => getOverride(key))

  useEffect(() => {
    const onChange = val => setVal(val)
    bind(key, onChange)
    return () => unbind(key, onChange)
  }, [key])

  return overridesEnabled && exists(val) ? val : fallback
}

export {
  overridesEnabled,
  setOverride,
  setOverrides,
  getOverride,
  getOverrides,
  clearOverrides,
  useOverrides,
  useOverride
}
