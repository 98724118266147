import _ from 'lodash'
import {
  CREATE_LOCAL_NOTE,
  GET_LOCAL_NOTE,
  GET_LOCAL_NOTES,
  UPDATE_LOCAL_NOTE
} from 'core/actions/constants'

const initialState = {
  byId: {}
}

const localNotes = function(state = initialState, action) {
  switch (action.type) {
    case GET_LOCAL_NOTE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        }
      }
    case GET_LOCAL_NOTES:
      const newLocalNotes = _.keyBy(action.payload, 'id')
      return {
        ...state,
        byId: {
          ...state.byId,
          ...newLocalNotes
        }
      }
    case UPDATE_LOCAL_NOTE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...action.payload
          }
        }
      }
    case CREATE_LOCAL_NOTE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        }
      }
    default:
      return state
  }
}

export default localNotes
