// Third party --------------
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

// Components ---------------
import TemplateStyles from './TemplateStyles'

// Rest ---------------------
import getFullName from 'core/helpers/getFullName'
import extractStylesFromHtml from 'core/helpers/extractStylesFromHtml'
import parseInteractionInfo from 'core/helpers/parseInteractionInfo'
import sanitizeContentHtml from 'core/helpers/sanitizeContentHtml'
import variables from 'core/styles/variables'
import { useTime } from 'core/time'
import { useBorrower } from 'core/hooks'

// Styled components --------
const Header = styled.div`
  border-top: 1px solid ${variables.colorBlack40};
  padding: 16px;
  color: ${variables.colorBlack60};
  font-size: 14px;
`

const PreviousInteraction = ({ interaction, setHeightAuto }) => {
  const { formatRelative } = useTime()

  const { channel, createdAt, direction } = interaction

  const { name, body } = parseInteractionInfo(interaction)

  const date = formatRelative(createdAt)
  const dateIsBeforeYesterday =
    !date.includes('Today') && !date.includes('Yesterday')

  const borrower = useBorrower(interaction.personId)

  const borrowerName = getFullName(borrower) || 'Unidentified borrower'

  const fromName = interaction.direction === 'inbound' ? borrowerName : name

  const emailAddress = interaction.content.from.email

  const templateStyles = extractStylesFromHtml(body)

  const isEmail = channel === 'email'

  const addPaddingToContent = direction === 'inbound' && isEmail

  const sanitizedHtml = sanitizeContentHtml(body)

  return (
    <>
      <Header>
        {dateIsBeforeYesterday ? 'On ' : ''}
        {`${date} ${fromName} <${emailAddress}> wrote:`}
      </Header>
      {isEmail && (
        <TemplateStyles
          setHeightAuto={setHeightAuto}
          templateStyles={templateStyles}
          addPadding={addPaddingToContent}
          dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        />
      )}
    </>
  )
}

PreviousInteraction.propTypes = {
  interaction: PropTypes.object
}

export default PreviousInteraction
