import _ from 'lodash'
import { LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

/**
 * Like sendCommunication, but returns a preview. Currently does
 * not dispatch an action object or put anything in the redux
 * store (other than loading status).
 */
const getFreeFormEmailPreview = ({
  key,
  subjectLine,
  contentHtml,
  contentPlain
}) => {
  return async dispatch => {
    if (key) {
      dispatch({ type: LOADING, key, status: 'loading' })
    }

    const body = _.pickBy({ subjectLine, contentHtml, contentPlain })

    try {
      const response = await peachApi.post({
        url: `/communicator/preview-free-form-email`,
        body
      })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
      return response
    } catch (e) {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
      return e
    }
  }
}

export default getFreeFormEmailPreview
