// Third party --------------
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import _ from 'lodash'

// Components ---------------
import { Radio } from 'core/components'

const RadioGroup = props => {
  const {
    customInputRef,
    data,
    disabled,
    getCustomAmount,
    onChange,
    value: passedValue
  } = props

  const [value, setValue] = useState('')
  const currentValue = useMemo(() => {
    return typeof onChange === 'function' ? passedValue : value
  }, [onChange, passedValue, value])

  const filteredData = _.filter(data, d => d.show)

  const _onChange = evt => {
    if (typeof onChange === 'function') {
      onChange(evt)
    } else {
      setValue(evt.target.value)
    }
  }

  return (
    <div className='radio-group'>
      {_.map(filteredData, (d, i) => {
        return (
          <Radio
            key={i}
            label={d.label}
            amount={d.amount}
            subLabel={d.subLabel}
            customField={d.customField}
            customFieldPlaceholder={d.customFieldPlaceholder}
            value={d.value}
            checked={currentValue === d.value}
            onChange={_onChange}
            getCustomAmount={getCustomAmount}
            customInputRef={customInputRef}
            disabled={disabled}
          />
        )
      })}
    </div>
  )
}

RadioGroup.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  /** If a controlled component, use the supplied value as the current value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** If you specify onChange, this becomes a controlled component.
   * Takes a synthetic event, e.g. `e => this.setState({ val: e.target.value })`
   */
  onChange: PropTypes.func,
  getCustomAmount: PropTypes.func,
  customInputRef: PropTypes.shape({
    current: PropTypes.object
  }),
  disabled: PropTypes.bool
}

export default RadioGroup
