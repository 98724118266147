import { REQUEST_PROGRESS } from 'core/actions/communicator'

const requestProgress = ({ file, loaded, total }) => {
  return async dispatch => {
    dispatch({
      type: REQUEST_PROGRESS,
      payload: {
        file,
        loaded,
        total
      }
    })
  }
}

export default requestProgress
