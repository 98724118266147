import {
  GET_CONTACT_V2,
  GET_CONTACTS_V2,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACTS,
  CLONE_CONTACT
} from 'core/actions/constants'
import keyBy from 'lodash/keyBy'
import pickBy from 'lodash/pickBy'

const initialState = {
  byId: {},
  allIds: []
}

const contacts = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS_V2:
      return {
        ...state,
        byId: {
          ...pickBy(state.byId, item => item.personId !== action.personId),
          ...keyBy(action.payload, 'id')
        }
      }
    case GET_CONTACT_V2:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...action.payload,
            personId: action.personId
          }
        }
      }
    case CLONE_CONTACT:
      const cloneById = {
        ...state.byId,
        [action.payload.data.id]: action.payload.data
      }
      delete cloneById[action.payload.contactId]
      return {
        ...state,
        byId: {
          ...cloneById
        }
      }
    case CREATE_CONTACT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        }
      }
    case UPDATE_CONTACT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...action.payload
          }
        }
      }
    case DELETE_CONTACTS:
      const newById = JSON.parse(JSON.stringify(state.byId))
      delete newById[action.payload]
      return {
        ...state,
        byId: {
          ...state.byId,
          ...newById
        }
      }
    default:
      return state
  }
}

export default contacts
