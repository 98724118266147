import {
  APIendpoint,
  ADD_ASSOCIATION,
  LOADING,
  getStoredToken
} from 'core/actions/constants'
import { POSTwithToken } from 'core/api/legacyApi'
import { handleErrors } from 'core/helpers/handleErrors'

const addAssociation = ({ key, body, filters = {} } = { filters: {} }) => {
  const { personId, caseId } = filters
  if (!personId || !caseId) {
    throw new Error('personId or caseId is missing')
  }

  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    const endpoint = `${APIendpoint}/people/${personId}/cases/${caseId}`
    const response = await fetch(
      endpoint,
      POSTwithToken(getStoredToken(), body)
    )
    await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    if (isSuccess) {
      dispatch({
        type: ADD_ASSOCIATION,
        body,
        id: caseId
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error'
      })
    }
  }
}

export default addAssociation
