export default function deCamelize(camelString) {
  if (!camelString) return ''

  return (
    camelString
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function(str) {
        return str.toUpperCase()
      })
  )
}
