// Third party --------------
import PropTypes from 'prop-types'
import React, { createContext, useCallback, useMemo, useState } from 'react'

const TwilioConversationContext = createContext({})

const TwilioConversationContextProvider = ({ children }) => {
  const [conversations, setConversations] = useState({})

  const addConversation = useCallback(
    ({ conversationId, interactionId, status } = {}) => {
      if (!conversationId || !interactionId) return
      const updatedConversations = {
        ...conversations,
        [conversationId]: { conversationId, interactionId, status }
      }
      setConversations(updatedConversations)
    },
    [conversations, setConversations]
  )

  const updateConversation = useCallback(
    ({ conversationId, ...rest }) => {
      const updatedConversations = {
        ...conversations,
        [conversationId]: { ...rest }
      }
      setConversations(updatedConversations)
    },
    [conversations, setConversations]
  )

  const value = useMemo(() => {
    return {
      addConversation,
      conversations,
      updateConversation
    }
  }, [conversations, addConversation, updateConversation])

  return (
    <TwilioConversationContext.Provider value={value}>
      {children}
    </TwilioConversationContext.Provider>
  )
}

TwilioConversationContextProvider.propTypes = {
  children: PropTypes.node
}

export { TwilioConversationContext }
export default TwilioConversationContextProvider
