import { useEffect } from 'react'
import logout from 'core/api/logout'
import { signOut } from 'app/loginRedirect/loginRedirectHelpers'

const signOutAndRedirect = async () => {
  try {
    await logout()
  } catch (e) {}

  signOut()
}

const SignOutRoute = () => {
  useEffect(() => {
    signOutAndRedirect()
  }, [])

  return null
}

export default SignOutRoute
