import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import useEntity from 'core/hooks/useEntity'
import { useTime } from 'core/time'

/** Retrieve some calculated info about the current snooze set
 * on the current case (which is retrieved with useCurrentCase).
 * Alternatively, you can specify which case by passing in the caseId
 * as the arg.
 *
 * If there is no snooze, the `isSnoozed` field is set to false and
 * other fields are null. If the current case technically has a
 * snoozedUntil but it is today or a previous day, this hook still
 * returns as if there is no snooze set on the case.
 *
 * Returns:
 * - isSnoozed: boolean
 * - snoozedFor: integer, number of days
 * - snoozedUntil: datestring
 * - snoozedAt: timestring
 * - today: datestring for today, for convenience of calculating a new snoozeFor
 * - caseId: The id of the current case, for convenience of updating the snooze
 * - personId: The id of the person on the current case, for convenience of updating the snooze
 */
const useSnoozeInfo = caseId => {
  const params = useParams()
  const thisCaseId = caseId ?? params.caseId
  const thisCase = useEntity('cases', thisCaseId, {
    personId: params.borrowerId
  })
  const { differenceInCalendarDays, getToday } = useTime()

  const today = useMemo(() => getToday(), [getToday])

  const snoozedFor = useMemo(() => {
    if (thisCase.snoozedUntil) {
      const days = differenceInCalendarDays(thisCase.snoozedUntil, today)
      return days > 0 ? days : null
    } else {
      return null
    }
  }, [thisCase.snoozedUntil, today, differenceInCalendarDays])

  return {
    snoozedFor,
    isSnoozed: !!snoozedFor,
    snoozedUntil: thisCase.snoozedUntil,
    today,
    snoozedAt: thisCase.snoozedAt,
    caseId: thisCaseId,
    personId: params.borrowerId
  }
}

export default useSnoozeInfo
