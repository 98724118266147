import humanizeKey from './humanizeKey'
import combineStrings from './combineStrings'
import _ from 'lodash'

const lookForName = json => {
  if (!json) return ''
  const { name, description, nickname, object, id } = json
  const guess = _.filter(
    [name, description || nickname],
    str => _.isString(str) && str
  ).join(' / ')
  if (guess) return guess

  return `${object ? humanizeKey(object) : ''} ${id || ''}`.trim()
}

const combine = (...list) => _.compact(list).join(' ').trim()

const formatAmount = amount => {
  const sign = amount < 0 ? '-' : ''
  const abs = Math.abs(amount)
  return `${sign}$${Number.isInteger(abs) ? abs : abs.toFixed(2)}`
}

const getTransactionName = txn => {
  const { actualAmount, timestamps, transactionType, paymentDetails } = txn
  const { effectiveDate } = timestamps
  const { reason } = paymentDetails

  const date = effectiveDate ? `for ${timestamps.effectiveDate}` : ''

  const amount = formatAmount(actualAmount)

  const displayReason =
    reason === 'oneTimePayment'
      ? 'One-Time'
      : _.str.titleize(_.str.humanize(reason))
  const type = _.str.titleize(_.str.humanize(transactionType))

  return `${amount} ${displayReason} ${type} ${date}`
}

const getLoanName = loan => {
  const { id, type, nickname, current, assetDisplayName } = loan

  const displayName = nickname || `${assetDisplayName || 'Loan'} ${id}`

  const autopay = current?.autopayEnabled ? ' (Autopay)' : ''

  const suffix =
    type === 'lineOfCredit' ? '💳' : type === 'installment' ? '💰' : '💲'

  return combineStrings([displayName, autopay, `(${suffix})`])
}

const getDrawName = draw => {
  const { assetDisplayName, nickname } = draw

  const displayName = nickname || `${assetDisplayName} Draw`
  return displayName
}

const getContactName = contact => {
  const { status, label, contactType } = contact
  return combineStrings(_.map([status, label, contactType], humanizeKey))
}

const getCaseName = $case => {
  const name =
    $case.name || combineStrings([humanizeKey($case.caseType), 'Case'])
  return combineStrings([name, $case.id])
}

const getDescriptorName = desc => {
  return combineStrings([humanizeKey(desc.subject), `(${desc.channel})`])
}

const getEmployeeName = employee => {
  const { id, firstName, lastName } = employee
  return combine(firstName, lastName) || id
}

const getPersonName = name => {
  const { prefix, firstName, preferredFirstName } = name || {}
  const { lastName, maidenLastName, suffix } = name || {}
  const maiden = maidenLastName && `(né ${maidenLastName})`
  const nick = preferredFirstName && `"${preferredFirstName}"`
  const names = [prefix, firstName, nick, lastName, maiden, suffix]
  return combine(...names)
}

const getBusinessName = details => {
  return details?.businessName || details?.businessLegalName
}

const getBorrowerName = ({ id, borrowerType, name, businessDetails }) => {
  return borrowerType === 'business'
    ? `${getBusinessName(businessDetails) || 'Borrower ' + id} (🏢)`
    : `${getPersonName(name) || 'Borrower ' + id} (👤)`
}

const getUserName = user => {
  const { id, status, auths, type, employee, person } = user

  const authValue = _.find(auths, (auth = {}) => auth.value)?.value

  const prefix = _.str.titleize(_.str.humanize(type))

  const name = employee
    ? getEmployeeName(employee)
    : person
    ? getBorrowerName(person)
    : authValue
    ? authValue
    : id

  const suffix = status !== 'active' ? ` (${status})` : ''

  return combine(prefix, 'User', `'${name}'`, suffix)
}

const getLoanTypeName = loanType => {
  const { id, name, managedByPeach, paymentWaterfall } = loanType
  const { single, multi } = paymentWaterfall || {}
  const invalid = _.isEmpty(single) || _.isEmpty(multi)
  const note = managedByPeach ? '(🍑)' : ''
  const warning = invalid ? '(Empty Payment Waterfall)' : ''
  return combine(name || id, note, warning)
}

const getRepaymentEngineNotificationName = notification => {
  const trigger = _.str.titleize(_.str.humanize(notification.trigger))
  const subject = _.str.titleize(_.str.humanize(notification.subject))
  return `${trigger} ⟶ ${subject}`
}

const getPurchaseName = purchase => {
  const { amount, type, purchaseDate } = purchase
  const displayAmount = formatAmount(amount)
  return `${displayAmount} ${type} purchase on ${purchaseDate}`
}

const getPeriodName = period => {
  const { startDate = '', endDate = '' } = period

  const [startYear, startMonth, startDay] = startDate.split('-')
  const [endYear, endMonth, endDay] = endDate.split('-')

  if (startYear === endYear) {
    return `Period ${startMonth}/${startDay} - ${endMonth}/${endDay}, ${startYear}`
  }
  return `Period ${startDate} - ${endDate}`
}

const byCode = {
  BO: borrower => getBorrowerName(borrower),
  CE: caseType => `${humanizeKey(caseType.caseType) || caseType.id}`,
  CN: consent => humanizeKey(consent.name),
  CP: company => company.legalName || company.name || company.id,
  CS: $case => getCaseName($case),
  CT: contact => getContactName(contact),
  EM: ({ id, firstName, lastName }) => combine(firstName, lastName) || id,
  IR: investor => investor.legalName,
  LN: loan => getLoanName(loan),
  LT: loanType => getLoanTypeName(loanType),
  PM: perm => perm.action,
  RL: role => `${role.alias} (${role.roleType})`,
  ST: step => step.descriptionShort,
  TD: descriptor => getDescriptorName(descriptor),
  TV: tmp => tmp.subjectLineTemplate || `Template ${tmp.id}`,
  TX: txn => getTransactionName(txn),
  UR: user => getUserName(user),
  WT: wt => `${wt.name} (${_.size(wt.steps)} Steps)`,
  FT: feeType => `${feeType.name.displayName} (${feeType.name.apiName})`,
  DD: doc => doc.fileName,
  RE: notification => getRepaymentEngineNotificationName(notification),
  DP: purchase => getPurchaseName(purchase),
  PE: period => getPeriodName(period)
}

const getFeeName = (fee = {}) => {
  const { type, amount, dynamicFeeDetails } = fee
  const displayAmount = formatAmount(amount)

  if (dynamicFeeDetails) {
    const { loanFeeId, displayName } = dynamicFeeDetails
    const name = displayName || `Dynamic Fee ${loanFeeId}`
    return `${displayAmount} ${name}`
  }
  return `${displayAmount} ${humanizeKey(type)}`
}

const feeTypeKeys = ['originationFee', 'drawFee', 'dynamicFee']

const getName = (json = {}) => {
  if (_.isEmpty(json)) return ''

  if (!json.id && _.includes(feeTypeKeys, json.type)) {
    return getFeeName(json)
  }

  if (json.object === 'draw') {
    return getDrawName(json)
  }

  if (_.isString(json.id)) {
    const code = json.id.slice(0, 2)

    if (byCode[code]) {
      return byCode[code](json)
    }
    return lookForName(json)
  }
}

export default getName
