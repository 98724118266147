import { checkTimeordate } from '../types'
import mtz from '../utils/mtz'

const makeIsToday = (context = {}) => {
  const { timezone } = context

  const isToday = timeordate => {
    checkTimeordate(timeordate)

    // We don't want a nully value to get interpreted as "now" and accidentally
    // report that it is today.
    if (!timeordate) {
      return false
    }

    return mtz(null, timezone).isSame(mtz(timeordate, timezone), 'day')
  }

  return isToday
}

export default makeIsToday
