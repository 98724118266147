import _ from 'lodash'

const capitalize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const getError = error => {
  if (_.isString(error)) return error

  if (_.isString(error.message) && error.message !== 'Unknown Error') {
    return error.message
  }

  if (_.isString(error.detail)) return error.detail

  if (_.isString(error.message)) return error.message

  return 'Unknown Error'
}

const parseError = error => {
  if (!error) return

  return capitalize(getError(error))
}

export default parseError
