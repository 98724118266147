import React, { lazy, Suspense } from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { Spinner } from 'core/components'

const LazyAgentRoutes = lazy(() => import('app/routes/AgentRoutes'))
const LazyBorrowerRoutes = lazy(() => import('app/routes/BorrowerRoutes'))

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

const defaultFallback = (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
)

const AgentRoutes = ({ fallback, ...rest }) => {
  return (
    <Suspense fallback={fallback || defaultFallback}>
      <LazyAgentRoutes {...rest} />
    </Suspense>
  )
}

AgentRoutes.propTypes = {
  fallback: PropTypes.node
}

const BorrowerRoutes = ({ fallback, ...rest }) => {
  return (
    <Suspense fallback={fallback || defaultFallback}>
      <LazyBorrowerRoutes {...rest} />
    </Suspense>
  )
}

BorrowerRoutes.propTypes = {
  fallback: PropTypes.node
}

export { AgentRoutes, BorrowerRoutes }
