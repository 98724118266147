import { useState } from 'react'

import { getStoredToken, decodeToken } from 'core/actions/helpers/tokenHelpers'

const useTokenInfo = () => {
  // putting this in state so it doesn't re-parse on each render
  const [token] = useState(() => decodeToken(getStoredToken()))

  const { sub: userId, personId, employeeId } = token || {}

  return {
    userId,
    personId,
    employeeId
  }
}

export default useTokenInfo
