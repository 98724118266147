import { _useCompanyId as useCompanyId } from 'core/SystemProvider/CompanyIdProvider'

import useCollection from './useCollection'

/** Only returns the caseTypes for the current company
 * and ones that are active.
 */
const useCaseTypes = () => {
  const { useCompanyId, useCollection } = useCaseTypes.dependencies

  const companyId = useCompanyId()
  return useCollection('caseTypes', { companyId, status: 'active' })
}

useCaseTypes.dependencies = {
  useCompanyId,
  useCollection
}

export default useCaseTypes
