// Third party --------------
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

// Components ---------------
import Icon from 'core/components/Icon'
import { Button, InfoCard, Spinner } from 'core/components'

// Rest ---------------------
import variables from 'core/styles/variables'
import {
  ELAPSED_TIME_TO_RETRY,
  UPLOAD_FAIL,
  UPLOAD_SUCCESS,
  UPLOAD_TAKING_LONG
} from 'contexts/FileUploadContext'
import { useFileUploadContext, useLoadingStatus, useUserType } from 'core/hooks'

const Description = styled.div`
  padding: 0 24px 24px;
  color: ${variables.colorBlack90};
`

const ButtonContainer = styled.div`
  position: relative;
  margin: 8px 0 0 0;
`

const UploadIconContainer = styled.span`
  display: inherit;
  position: relative;
  top: 8px;
  font-size: 24px;
`

const RetryAndCancel = styled.div`
  display: flex;
  position: absolute;
  top: 16px;
  right: 16px;
`

const NoticeBarContainer = styled.div`
  position: relative;
  margin-top: -12px;
  overflow: hidden;
`

const NoticeBar = styled.div`
  position: relative;
  left: -2px;
  bottom: -2px;
  box-sizing: border-box;
  overflow: hidden;
  width: calc(100% + 4px);
  height: 32px;
  border-radius: 0 0 8px 8px;
  padding: 4px 24px;
  background: ${p => p.background};
`

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${p => p.progress}%;
  background: ${p => p.background};
`

const Message = styled.div`
  position: relative;
  color: ${p => p.color};
`

const SpinnerContainer = styled.span`
  display: inherit;
  position: relative;
  top: 4px;
  width: 24px;
  height: 19px;
`

const helpText =
  'Please note that we only accept the following uploaded file types: .jpg,.jpeg, .png, or .pdf, that the file size may not exceed 10 megabytes, and that the file name cannot be greater than 50 characters.'

const FileUpload = () => {
  // ----------------------------------
  // Local state
  const [message, setMessage] = useState('')
  const [progressTextColor, setProgressTextColor] = useState(null)
  const [progressBackgroundColor, setProgressBackgroundColor] = useState(null)

  // ----------------------------------
  // Loading status: Document descriptor & file upload
  const documentDescriptorsLoadingStatus = useLoadingStatus(
    'CreateDocumentDescriptors'
  )
  const uploadFileLoadingStatus = useLoadingStatus('UploadFiles')
  const isUploading =
    documentDescriptorsLoadingStatus === 'loading' ||
    uploadFileLoadingStatus === 'loading'
  const hasUploadError =
    documentDescriptorsLoadingStatus === 'error' ||
    uploadFileLoadingStatus === 'error'

  // ----------------------------------
  // Local variables
  const userType = useUserType()

  const buttonColor =
    userType === 'agent' || isUploading
      ? variables.colorBlack50
      : variables.colorBluePrimary

  // ----------------------------------
  // File upload context values
  const {
    elapsedTime,
    error: requirementError,
    onCancelUpload,
    onOpenFileSelector,
    onRetryUpload,
    progress
  } = useFileUploadContext()

  // Loading state
  useEffect(() => {
    if (uploadFileLoadingStatus === 'loading') {
      setMessage('')
      if (elapsedTime > ELAPSED_TIME_TO_RETRY) {
        setMessage(UPLOAD_TAKING_LONG)
        setProgressTextColor(variables.colorOrange)
        setProgressBackgroundColor(variables.colorOrangeLighten)
      } else {
        setProgressTextColor(null)
        setProgressBackgroundColor(variables.colorGreenLighten)
      }
    }
  }, [uploadFileLoadingStatus, elapsedTime])

  // Success state
  useEffect(() => {
    if (uploadFileLoadingStatus === 'success') {
      setMessage(UPLOAD_SUCCESS)
      setProgressTextColor(variables.colorGreen)
      setProgressBackgroundColor(variables.colorGreenLighten)
    }
  }, [uploadFileLoadingStatus])

  // Error state
  useEffect(() => {
    if (requirementError) {
      setMessage(requirementError)
      setProgressTextColor(variables.colorRed)
      setProgressBackgroundColor(variables.colorRedLighten)
    } else if (hasUploadError && uploadFileLoadingStatus !== undefined) {
      setMessage(UPLOAD_FAIL)
      setProgressTextColor(variables.colorRed)
      setProgressBackgroundColor(variables.colorRedLighten)
    }
  }, [
    hasUploadError,
    uploadFileLoadingStatus,
    documentDescriptorsLoadingStatus,
    requirementError
  ])
  // ----------------------------------

  const onFileUploadClick = () => {
    onOpenFileSelector({
      acceptedFileTypes: '.jpg, .jpeg, .png, .pdf',
      autoUpload: true,
      allowMultiFileUpload: false
    })
  }

  return (
    <>
      {userType !== 'agent' && (
        <InfoCard
          title='Upload documents'
          helpText={helpText}
          helpTextMargin='0 24px 24px'
          iconMargin='0 56px 0 0'
          removeHelpTextTitle
        >
          <Description>
            To keep your personal information safe, documents will not be
            accessible after you successfully upload them. Our customer care
            team will review them internally. Upload one .jpg, .jpeg, .pdf or
            .png document at a time with a maximum size of 10MB.
          </Description>
          <NoticeBarContainer>
            {(requirementError || uploadFileLoadingStatus !== undefined) && (
              <NoticeBar>
                <ProgressBar
                  progress={
                    requirementError || hasUploadError
                      ? 100
                      : Math.floor((progress.loaded / progress.total) * 100)
                  }
                  background={progressBackgroundColor}
                />
                <Message color={progressTextColor}>{message}</Message>
              </NoticeBar>
            )}
          </NoticeBarContainer>
        </InfoCard>
      )}

      <ButtonContainer>
        <Button
          data-testid='button-upload'
          primaryLight
          fullWidth
          borderType='dashed-light'
          readOnly={userType === 'agent'}
          color={buttonColor}
          onClick={() => onFileUploadClick()}
        >
          {isUploading ? (
            <>
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>{' '}
              Uploading
            </>
          ) : (
            <>
              <UploadIconContainer>
                <Icon fontSize='inherit' name='upload' />
              </UploadIconContainer>{' '}
              Upload document(s)
            </>
          )}
        </Button>
        {(isUploading || hasUploadError) && (
          <RetryAndCancel>
            {(elapsedTime > ELAPSED_TIME_TO_RETRY || hasUploadError) && (
              <Button secondary margin='0 40px 0 0' onClick={onRetryUpload}>
                Retry
              </Button>
            )}
            <Button secondary onClick={onCancelUpload}>
              Cancel upload
            </Button>
          </RetryAndCancel>
        )}
      </ButtonContainer>
    </>
  )
}

export { Message, NoticeBar, ProgressBar }
export default FileUpload
