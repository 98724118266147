import { useState, useEffect } from 'react'

const useAudio = ({ url, loop = false, isPlaying, canPlay = true }) => {
  const [audio, setAudio] = useState(() => new Audio(url))

  useEffect(() => {
    setAudio(() => new Audio(url))
  }, [url])

  useEffect(() => {
    audio.loop = loop
  }, [audio, loop])

  useEffect(() => {
    const onPlay = () => {
      audio.currentTime = 0
      audio.play()
    }
    isPlaying && canPlay ? onPlay() : audio.pause()
  }, [audio, canPlay, isPlaying])

  useEffect(() => {
    const onEnd = () => {
      audio.pause()
    }
    audio.addEventListener('ended', onEnd)
    return () => {
      audio.removeEventListener('ended', onEnd)
    }
  }, [audio])
}

export default useAudio
