/**
 * This method will move the cursor position of an input or textarea
 * to the end of the value of the input or textarea if it has any value.
 * It's based on an input or textarea event, such as click or focus,
 * and takes the entire event object as its parameter.
 *
 * @param {Object} e – Input/Textarea event
 */
export const moveCursorPositionToEnd = e => {
  const tempValue = e.target.value
  e.target.value = ''
  e.target.value = tempValue
}

/**
 * This method wil adjust the height of a textarea based on its
 * scroll height. You can attach it to things like the onKeyUp or
 * onFocus events.
 *
 * @param {Object} e  - Textarea event
 */
export const setTextAreaHeight = (e, heightPadding = 5) => {
  e.style.height = '1px'
  e.style.height = `${heightPadding + e.scrollHeight}px`
}
