import React from 'react'
import styled, { css } from 'styled-components/macro'
import variables from 'core/styles/variables'
import { Link as RouterLink } from 'react-router-dom'
import { useBorrowerBaseUrl, useCompanyLinks } from 'core/hooks'
import { isLoggedIn } from 'core/actions/helpers/tokenHelpers'

const Links = styled.div`
  display: flex;
  flex-direction: column;
`

const linkStyles = css`
  margin-bottom: 16px;
  font-size: 16px;
  color: ${variables.colorBluePrimary};
  &:hover {
    text-decoration: underline;
  }
`

const Link = styled(RouterLink)`
  ${linkStyles};
`

const ExternalLink = styled.a`
  ${linkStyles};
`

const FooterLinks = () => {
  const baseUrl = useBorrowerBaseUrl()

  const { helpUrl } = useCompanyLinks()

  const isloggedIn = isLoggedIn()

  return (
    <Links>
      {!isloggedIn && <Link to={`${baseUrl}/login`}>Login</Link>}

      {isloggedIn && <Link to={`${baseUrl}/loans`}>Accounts</Link>}

      {isloggedIn && <Link to={`${baseUrl}/account-settings`}>Settings</Link>}

      {helpUrl && (
        <ExternalLink href={helpUrl} target='_blank' rel='noopener noreferrer'>
          Help
        </ExternalLink>
      )}

      {isloggedIn && <Link to={`${baseUrl}/sign-out`}>Sign out</Link>}
    </Links>
  )
}

export default FooterLinks
