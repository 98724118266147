import { GET_COMPANY } from 'core/actions/constants'
import insertSorted from 'core/helpers/insertSorted'

const initialState = {
  byId: {},
  allIds: []
}

const companies = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: action.payload
        },
        allIds: insertSorted({ item: action.id, list: state.allIds })
      }
    default:
      return state
  }
}

export default companies
