import mtz from '../utils/mtz'

const makeGetToday = (context = {}) => {
  const { timezone } = context

  const getToday = () =>
    mtz(null, timezone)
      .startOf('day')
      .format('Y-MM-DD')

  return getToday
}

export default makeGetToday
