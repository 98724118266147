import variables from 'core/styles/variables'

const borderAndMarginStyles = position => ({
  [`margin${position}`]: '-4px',
  [`border${position}`]: '0',
  [`border${position}LeftRadius`]: '0',
  [`border${position}RightRadius`]: '0'
})

const baseContainerStyles = (provided, state) => {
  const { selectProps } = state || {}
  const { classNamePrefix } = selectProps || {}
  return {
    ...provided,
    fontSize: classNamePrefix === 'inline' ? '14px' : '16px'
  }
}

const baseControlStyles = (provided, state) => {
  const { isFocused, menuIsOpen, selectProps } = state || {}
  const { classNamePrefix } = selectProps || {}

  const stylesForInlineDropdown =
    classNamePrefix === 'inline'
      ? {
          '&:hover': { borderColor: 'transparent' },
          borderColor: 'transparent',
          minHeight: '0'
        }
      : {}

  const isFocusedOrMenuOpenStyles =
    isFocused || menuIsOpen
      ? {
          /**
           * Using !important to override the hover styles
           * for inline drop downs, since those styles are
           * slightly more specific than this.
           */
          borderColor: `${variables.colorBluePrimary} !important`,
          boxShadow: 'none'
        }
      : {}

  return {
    ...provided,
    alignItems: 'normal',
    cursor: 'pointer',
    ...stylesForInlineDropdown,
    ...isFocusedOrMenuOpenStyles
  }
}

const baseDropdownIndicator = (provided, state) => {
  const { selectProps } = state || {}
  const { classNamePrefix } = selectProps || {}

  const stylesForInlineDropdown =
    classNamePrefix === 'inline' ? { padding: '0' } : {}

  return {
    ...provided,
    ...stylesForInlineDropdown
  }
}

const baseIndicatorSeparatorStyles = provided => ({
  ...provided,
  display: 'none',
  paddingLeft: '0'
})

const baseMenuStyles = (provided, state) => {
  const { selectProps } = state || {}
  const { menuPlacement, classNamePrefix } = selectProps || {}

  const stylesByPlacement =
    menuPlacement === 'bottom'
      ? { ...borderAndMarginStyles('Top') }
      : { ...borderAndMarginStyles('Bottom') }

  const stylesForInlineDropdown =
    classNamePrefix === 'inline' ? { borderWidth: '1px' } : {}

  return {
    ...provided,
    boxShadow: 'none',
    border: `1px solid ${variables.colorBluePrimary}`,
    ...stylesByPlacement,
    ...stylesForInlineDropdown
  }
}

const baseMenuListStyles = provided => {
  return {
    ...provided,
    '::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: '12px'
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      backgroundColor: variables.colorBlack30,
      border: '3px solid white'
    }
  }
}

const baseOptionStyles = (provided, state) => {
  const { isDisabled, isFocused, isSelected, selectProps } = state || {}
  const { classNamePrefix } = selectProps || {}

  const stylesForInlineDropdown =
    classNamePrefix === 'inline'
      ? {
          padding: '12px',
          fontSize: 'inherit',
          ':hover': {
            backgroundColor: variables.colorBlack10
          }
        }
      : {}

  return {
    ...provided,
    cursor: 'pointer',
    backgroundColor: isFocused ? variables.colorBlack10 : null,
    color: isDisabled ? variables.colorBlack50 : variables.colorBlack70,
    fontWeight: isSelected ? 500 : 400,
    ':hover': {
      backgroundColor: isDisabled && 'transparent'
    },
    svg: {
      fill: variables.colorBlack90
    },
    padding: '8px',
    ...stylesForInlineDropdown
  }
}

const basePlaceholderStyles = (provided, state) => {
  const { selectProps } = state || {}
  const { classNamePrefix } = selectProps || {}

  const stylesForInlineDropdown =
    classNamePrefix === 'inline' ? { fontSize: 'inherit' } : {}

  return {
    ...provided,
    // react-select default overrides
    position: 'relative',
    top: '0',
    transform: 'none',
    margin: '0',
    // end react-select overrides ---
    color: variables.colorBlack50,
    fontSize: '16px',
    whiteSpace: 'nowrap',
    ...stylesForInlineDropdown
  }
}

const baseSingleValueStyles = (provided, state) => {
  const { selectProps } = state || {}
  const { classNamePrefix } = selectProps || {}

  const stylesForInlineDropdown =
    classNamePrefix === 'inline'
      ? {
          maxWidth: 'none',
          color: 'inherit'
        }
      : {}

  return {
    ...provided,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0',
    marginRight: '0',
    maxWidth: 'none',
    ...stylesForInlineDropdown
  }
}

const baseValueContainerStyles = (provided, state) => {
  const { selectProps } = state || {}
  const { classNamePrefix } = selectProps || {}

  const stylesForInlineDropdown =
    classNamePrefix === 'inline'
      ? {
          fontSize: 'inherit',
          /**
           * Using 4px top/bottom padding to split the 8px padding
           * that was originally on the Row component, which now
           * has 4px top/bottom padding and is used to wrap this
           * component.
           */
          padding: '4px 10px'
        }
      : {}

  return {
    ...provided,
    ...stylesForInlineDropdown,
    flexWrap: 'nowrap'
  }
}

const customStyles = {
  container: (provided, state) => baseContainerStyles(provided, state),
  control: (provided, state) => baseControlStyles(provided, state),
  dropdownIndicator: (provided, state) =>
    baseDropdownIndicator(provided, state),
  indicatorSeparator: provided => baseIndicatorSeparatorStyles(provided),
  menu: (provided, state) => baseMenuStyles(provided, state),
  menuList: provided => baseMenuListStyles(provided),
  option: (provided, state) => baseOptionStyles(provided, state),
  placeholder: (provided, state) => basePlaceholderStyles(provided, state),
  singleValue: (provided, state) => baseSingleValueStyles(provided, state),
  valueContainer: (provided, state) => baseValueContainerStyles(provided, state)
}

export default customStyles
