import _ from 'lodash'
import { LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

/**
 * Like sendCommunication, but returns a preview. Currently does
 * not dispatch an action object or put anything in the redux
 * store (other than loading status).
 */
const getTemplatePreview = ({
  key,
  subject,
  personId,
  channel,
  contactId,
  caseId,
  context,
  companyId
}) => {
  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    const body = _.pickBy(
      {
        subject,
        personId,
        channel,
        contactId,
        caseId,
        context
      },
      _.identity
    )

    try {
      const response = await peachApi.post({
        url: `/communicator/preview?companyId=${companyId}`,
        body
      })
      if (key) {
        dispatch({
          type: LOADING,
          key,
          status: 'success'
        })
      }
      return response
    } catch (e) {
      if (key) {
        dispatch({
          type: LOADING,
          key,
          status: 'error'
        })
      }
      return e
    }
  }
}

export default getTemplatePreview
