import React from 'react'
import PropTypes from 'prop-types'
import { Dialog } from 'core/components'

export const DIALOG_LOCK = 'DIALOG_LOCK'
export const DIALOG_UNAUTHORIZED = 'DIALOG_UNAUNTHORIZED'

const InteractionModalContent = ({
  dialogToShow,
  onLockInteraction,
  onCancel
}) => {
  return (
    <>
      {dialogToShow === DIALOG_LOCK && (
        <Dialog
          title='Lock interaction'
          confirmLabel='Yes'
          onConfirm={onLockInteraction}
          cancelLabel='No'
          onCancel={onCancel}
        >
          <div id='lock-interaction-message'>
            Are you sure you want to lock this interaction? Only CRM users with
            access will be able to view or listen to it.
          </div>
        </Dialog>
      )}
      {dialogToShow === DIALOG_UNAUTHORIZED && (
        <Dialog
          title='Cannot unlock interaction'
          confirmLabel='Ok'
          onConfirm={onCancel}
        >
          <div id='unauthroized-unlock-interaction-message'>
            You do not have permission to unlock this interaction.
          </div>
        </Dialog>
      )}
    </>
  )
}

InteractionModalContent.propTypes = {
  dialogToShow: PropTypes.string,
  onLockInteraction: PropTypes.func,
  onCancel: PropTypes.func
}

export default InteractionModalContent
