import useEntity from './useEntity'

const useDraftPopulation = ({ supercaseId }) => {
  const { useEntity } = useDraftPopulation.dependencies
  return useEntity('draftPopulations', supercaseId, {}, 'supercaseId')
}

useDraftPopulation.dependencies = {
  useEntity
}

export default useDraftPopulation
