import { GET_CONTACT_V2, LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

const getContact = ({ key, id, personId }) => {
  return async dispatch => {
    // Short circuit and do nothing if id or personId is nullish.
    // This can happen if we call useEntity early before the params
    // are all ready.
    if (!id || !personId) {
      return
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    try {
      const response = await peachApi.get({
        url: `/people/${personId}/contacts/${id}`
      })
      dispatch({
        type: GET_CONTACT_V2,
        payload: response.data,
        personId,
        id
      })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
      return response.data
    } catch {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
    }
  }
}

export default getContact
