import { SET_CAN_INTERACT_STATUS } from 'core/actions/constants'

const initialState = {
  complianceGuardErrorMessage: null
}

const canInteract = function(state = initialState, action) {
  switch (action.type) {
    case SET_CAN_INTERACT_STATUS:
      const message = action.complianceGuardErrorMessage
        ? `Compliance Guard has blocked this interaction because: ${action.complianceGuardErrorMessage} Please review the account settings and make the appropriate changes before attempting again.`
        : null

      return {
        ...state,
        complianceGuardErrorMessage: message
      }
    default:
      return state
  }
}

export default canInteract
