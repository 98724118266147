import _ from 'lodash'
import React from 'react'
import styled from 'styled-components/macro'
import itemStyles from './itemStyles'
import withLabel from './withLabel'

const capitalizeFirstLetter = (str = '') => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const SelectElement = styled.select`
  ${itemStyles};
  cursor: pointer;
`

const Select = props => {
  const { value, onChange, options, children, prompt, label, ...rest } = props

  const $prompt = prompt || (label ? `${label}...` : null)

  const hasNone = _.some(options, val => !val)

  const displayOptions = _.map(options, option => {
    if (!option) {
      return (
        <option key='none' value=''>
          None
        </option>
      )
    }
    return (
      <option key={`option-${option}`} value={option}>
        {capitalizeFirstLetter(option)}
      </option>
    )
  })

  return (
    <SelectElement
      value={value || ''}
      onChange={e => onChange(e.target.value || null)}
      {...rest}
    >
      <option
        key='placeholder'
        value={hasNone ? '__placeholder__' : ''}
        disabled
        hidden
      >
        {$prompt}
      </option>

      {$prompt && (
        <option key='prompt' disabled>
          {$prompt}
        </option>
      )}
      {displayOptions}
      {children}
    </SelectElement>
  )
}

const BooleanSelect = ({ value, onChange, nullable, ...rest }) => {
  const options = nullable ? ['true', 'false', ''] : ['true', 'false']

  const $value = value === true ? 'true' : value === false ? 'false' : ''

  const $onChange = val => {
    const value = val === 'true' ? true : val === 'false' ? false : null
    onChange(value)
  }
  return (
    <Select value={$value} onChange={$onChange} options={options} {...rest} />
  )
}

const LabeledSelect = withLabel(Select)
const LabeledBooleanSelect = withLabel(BooleanSelect)

export { LabeledSelect as Select, LabeledBooleanSelect as BooleanSelect }
