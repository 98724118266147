import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const Button = styled.button`
  padding: 6px 12px;
  margin: 12px;
`

const Warning = styled.div`
  border: 3px solid red;
  padding: 30px;
  background-color: #fcc;
  overflow-y: auto;
`
const Code = styled.div`
  font-size: 12px;
  white-space: pre;
  font-family: monospace;
`
const Stack = ({ title, text }) => {
  return (
    <div>
      <h3>{title}</h3>
      <Code>{text}</Code>
    </div>
  )
}

Stack.propTypes = { title: PropTypes.string, text: PropTypes.string }

const DevelopmentErrorMessage = ({ error, reset, componentStack }) => {
  return (
    <Warning>
      <Button onClick={() => window.location.reload()}>Reload Page</Button>
      <Button onClick={reset}>Reload Component</Button>
      <h1>Error: {error?.message || 'Unknown Error'}</h1>
      {error?.stack && <Stack title='Call Stack' text={error.stack} />}
      {componentStack && (
        <Stack title='Component Stack' text={componentStack} />
      )}
    </Warning>
  )
}

DevelopmentErrorMessage.propTypes = {
  reset: PropTypes.func,
  error: PropTypes.any,
  componentStack: PropTypes.string
}

export default DevelopmentErrorMessage
