// Third party ------------------------
import TippyBase from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away.css'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

// Proprietary ------------------------
import variables from 'core/styles/variables'

// Components -------------------------
import Icon from 'core/components/Icon'

/**
 * If you attempt to pass props into Tippy that is not part of their
 * documentation, it will output a console warning in dev mode. This
 * helps us remove the prop that Tippy doesn't allow which will consequently
 * remove the console warnings in the browser. For now Tippy doesn't
 * allow the props 'backgroundColor', 'color', and 'fontSize'. If you
 * want to use additional props on the Tippy styled component that is
 * not part of their doc, simply add those in the object destructring
 * of the props of PropsOmittedTippyBase.
 */
const PropsOmittedTippyBase = props => {
  const {
    backgroundColor,
    color,
    'data-testid': dataTestid,
    fontSize,
    ...rest
  } = props
  return <TippyBase {...rest} />
}
PropsOmittedTippyBase.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string
}

const Tippy = styled(PropsOmittedTippyBase)`
  font-size: ${p => p.fontSize};
  padding: 8px;
  box-shadow: ${variables.peachyShadow};
  border-radius: 8px;
  background-color: ${p => p.backgroundColor} !important;

  /* Top/Bottom Tippy Arrow placement styling */
  &[data-theme~='light'][data-placement^='bottom'],
  &[data-theme~='light'][data-placement^='top'] {
    > .tippy-arrow:before {
      border-bottom-color: ${p => p.backgroundColor};
      border-top-color: ${p => p.backgroundColor};
    }
  }
  /* Left/Right Tippy Arrow placement styling */
  &[data-theme~='light'][data-placement^='left'],
  &[data-theme~='light'][data-placement^='right'] {
    > .tippy-arrow:before {
      border-left-color: ${p => p.backgroundColor};
      border-right-color: ${p => p.backgroundColor};
    }
  }
  ${p => (p.color ? `color: ${p.color};` : '')}
`
const IconWrapper = styled.span.attrs(() => ({ role: 'button', 'data-cy': 'tippy-tooltip' }))`
  display: inline-flex;
  align-items: baseline;
  cursor: pointer;
  margin: ${p => p.margin};
`

const Tooltip = ({
  animation,
  arrow,
  backgroundColor,
  children,
  color,
  content,
  fontSize,
  hideOnClick,
  iconColor,
  iconMargin,
  iconName,
  iconSize,
  placement,
  width,
  inline
}) => {
  const offset =
    placement === 'bottom-end'
      ? [32, 8]
      : placement === 'bottom-start'
      ? [-32, 8]
      : [0, 0]

  return (
    <Tippy
      data-testid='tippy-tooltip'
      arrow={arrow}
      animation={animation}
      backgroundColor={backgroundColor}
      color={color}
      content={content}
      fontSize={fontSize}
      hideOnClick={hideOnClick}
      interactive
      maxWidth={width}
      offset={offset}
      placement={placement}
      theme='light'
      trigger='mouseenter click'
    >
      <IconWrapper margin={iconMargin}>
        {children || (
          <Icon
            name={iconName}
            fontSize={iconSize}
            color={iconColor}
            inline={inline}
          />
        )}
      </IconWrapper>
    </Tippy>
  )
}

Tooltip.propTypes = {
  animation: PropTypes.string,
  arrow: PropTypes.bool,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  /** content can be a string or a tree of react elements */
  content: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
    PropTypes.node.isRequired
  ]),
  fontSize: PropTypes.string,
  hideOnClick: PropTypes.bool,
  iconColor: PropTypes.string,
  iconMargin: PropTypes.string,
  iconName: PropTypes.string,
  iconSize: PropTypes.string,
  placement: PropTypes.string,
  width: PropTypes.number,
  inline: PropTypes.bool
}

Tooltip.defaultProps = {
  arrow: true,
  backgroundColor: 'white',
  fontSize: '16px',
  hideOnClick: true,
  iconColor: variables.colorBlack50,
  iconMargin: '0',
  iconName: 'help_outline',
  iconSize: '24px',
  placement: 'bottom-end',
  width: 648
}

export { IconWrapper, PropsOmittedTippyBase, Tippy }
export default Tooltip
