import React from 'react'
import PropTypes from 'prop-types'
import styled, {
  ThemeProvider as StyledThemeProvider
} from 'styled-components/macro'
import { CompanyLogo } from 'core/components'
import Footer from 'core/components/Layout/Footer/Footer'

const LoginContentsArea = styled.div`
  padding: 16px;
  padding-bottom: 256px;
`

const LogoContainer = styled.div`
  text-align: center;
  padding-bottom: 16px;
`

const LoginLayout = ({ companyTheme, children }) => {
  return (
    <StyledThemeProvider theme={companyTheme}>
      <LoginContentsArea>
        <LogoContainer>
          <CompanyLogo type='appLargeCentered' />
        </LogoContainer>
        {children}
      </LoginContentsArea>
      <Footer />
    </StyledThemeProvider>
  )
}

LoginLayout.propTypes = {
  children: PropTypes.node,
  companyTheme: PropTypes.object
}

export default LoginLayout
