import { GET_BOOTSTRAP_DATA } from 'core/actions/constants'

const initialState = {
  company: null,
  userType: null
}

const bootstrap = function(state = initialState, action) {
  switch (action.type) {
    case GET_BOOTSTRAP_DATA:
      return {
        ...state,
        company: action.payload.company,
        userType: action.payload.userType
      }
    default:
      return state
  }
}

export default bootstrap
