import { useContext } from 'react'
import { FileUploadContext } from 'contexts/FileUploadContext'

const useFileUploadContext = () => {
  const {
    elapsedTime,
    error: requirementError,
    onCancelUpload,
    onOpenFileSelector,
    onRetryUpload,
    progress,
    setShowUploadStatusModal
  } = useContext(FileUploadContext)

  return {
    elapsedTime,
    error: requirementError,
    onCancelUpload,
    onOpenFileSelector,
    onRetryUpload,
    progress,
    setShowUploadStatusModal
  }
}

export default useFileUploadContext
