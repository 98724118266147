const loanAppLayout = {
  maxWidthMobile: '1023'
}

const crmAppLayout = {
  maxWidthMain: '1920px',
  minWidthMain: '1376px',
  maxWidthBorrowerContent: '1448px',
  minWidthBorrowercontent: '1280px'
}

export { crmAppLayout, loanAppLayout }
