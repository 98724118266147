// Third party ------------------------
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'

// Proprietary ------------------------
import variables from 'core/styles/variables'

// Components -------------------------
import ContactsOptionLabel from 'crm/components/ContactsOptionLabel'
import Icon from 'core/components/Icon'
import Select from 'components/Select'
import { Tooltip } from 'core/components'

// Styles -----------------------------
const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(2, auto) 1fr;
  justify-content: center;
  align-items: center;
`
const Call = styled.span`
  grid-column-start: 2;
  margin-right: 8px;
  color: ${variables.colorBlack60};
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`
const SelectWrapper = styled.div`
  width: 360px;
`
const TippyWrapper = styled.div`
  margin-left: auto;
  cursor: pointer;
`

const _formatCreateLabel = userInput => {
  if (!userInput) return ''
  if (_.startsWith(userInput, '+1')) return userInput
  return `+1${userInput}`
}

// Local variables --------------------
const TOOLTIP_CONTENT =
  'The selectable phone numbers are associated with the borrower you opened the telephony widget with. If you’d like to change the associated borrower on the telephony widget, navigate to that borrower, and select the phone icon next to the borrower’s name.'

const TopSection = ({
  groupedContactList,
  getSelectedOption,
  selectedContact
}) => {
  // Local state ----------------------
  const [showHelpText, setShowHelpText] = useState(false)

  // Methods --------------------------
  const _toggleTooltip = () => setShowHelpText(!showHelpText)

  const _formatOptionLabel = props => <ContactsOptionLabel {...props} />

  return (
    <Section>
      <Call>Call</Call>
      <SelectWrapper>
        <Select
          controlledValue={selectedContact}
          creatable
          formatCreateLabel={_formatCreateLabel}
          formatOptionLabel={_formatOptionLabel}
          getSelectionOption={getSelectedOption}
          isClearable
          isControlled
          placeholder='Enter number or select contact'
          options={groupedContactList}
        />
      </SelectWrapper>
      <TippyWrapper>
        <Tooltip
          data-testid='tooltip'
          animation='shift-away'
          content={TOOLTIP_CONTENT}
          hideOnClick
          maxWidth={480}
          placement='bottom-end'
          theme='light'
          trigger='click'
        >
          <div id='telephony-predial-tooltip-toggle' onClick={_toggleTooltip}>
            <Icon
              color={variables.colorBlack60}
              fontSize='22px'
              name='help_outline'
            />
          </div>
        </Tooltip>
      </TippyWrapper>
    </Section>
  )
}

TopSection.propTypes = {
  getSelectedOption: PropTypes.func,
  groupedContactList: PropTypes.any,
  selectedContact: PropTypes.object
}

export { _formatCreateLabel, TOOLTIP_CONTENT }
export default TopSection
