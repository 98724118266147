import _ from 'lodash'
import { useEffect } from 'react'

const useOnMountEffect = fn => {
  useEffect(() => {
    const result = fn()
    if (_.isFunction(result)) {
      return result
    }
  }, []) // eslint-disable-line
}

export default useOnMountEffect
