import _ from 'lodash'
import usePermissions from './usePermissions'

const useHasPermission = val => {
  const permissionsHash = usePermissions()

  // use a predicate function to check complex permissions.
  // intended use-case here is checking OR conditions on permissions
  if (_.isFunction(val)) {
    return !!val(permissionsHash)
  }

  // if not nothing is guarded, then use has permission
  // also, functions are 'empty'
  if (_.isEmpty(val)) return true

  if (_.isEmpty(permissionsHash)) return undefined

  // check if the user has a single permission
  if (_.isString(val)) {
    return !!permissionsHash[val]
  }

  // check if a user has all of a list of permissions
  if (_.isArray(val)) {
    return _.every(val, key => permissionsHash[key])
  }

  // something strange has happened
  return undefined
}

export default useHasPermission
