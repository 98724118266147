import React from 'react'
import styled from 'styled-components/macro'

import { CompanyLogo } from 'core/components'

const Wrapper = styled.div``

const LogoBox = styled.span`
  display: inline-block;
  margin: 12px;
  border: 1px solid #eaa;
  line-height: 0;
`

const Logos = () => {
  return (
    <Wrapper>
      <h3>appLargeCentered</h3>

      <LogoBox>
        <CompanyLogo type='appLargeCentered' />
      </LogoBox>

      <h3>appRegularNavigation</h3>

      <LogoBox>
        <CompanyLogo type='appRegularNavigation' />
      </LogoBox>

      <h3>appCompactNavigation</h3>

      <LogoBox>
        <CompanyLogo type='appCompactNavigation' />
      </LogoBox>
    </Wrapper>
  )
}

export default Logos
