import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useOverride } from 'core/components/Badge/overrides'

const CustomizationsContext = createContext({})

const CustomizationsProvider = ({ company, children }) => {
  return (
    <CustomizationsContext.Provider value={company}>
      {children}
    </CustomizationsContext.Provider>
  )
}

CustomizationsProvider.propTypes = {
  company: PropTypes.object,
  children: PropTypes.node
}

const _useCompanyCopies = () => {
  const company = useContext(CustomizationsContext)
  return useMemo(() => company?.brandAssets?.copies || {}, [company])
}

const _useCompanyEvidence = () => {
  const company = useContext(CustomizationsContext)
  return useMemo(() => company?.evidence || {}, [company])
}

const _useCompanyLogos = () => {
  const company = useContext(CustomizationsContext)
  return useMemo(() => company?.brandAssets?.logos || {}, [company])
}

const _useCompanyInfo = () => {
  const company = useContext(CustomizationsContext)

  return useMemo(() => {
    const { brandAssets, ...rest } = company || {}
    return rest || {}
  }, [company])
}

const _useCompanyLinks = () => {
  const company = useContext(CustomizationsContext)
  return useMemo(() => company?.links || {}, [company])
}

const _useCompanyOpenHours = () => {
  const company = useContext(CustomizationsContext)
  return useMemo(() => company?.openHours || {}, [company])
}

const _useCompanyPhoneHours = () => {
  const company = useContext(CustomizationsContext)
  return useMemo(() => {
    const { phoneSupport } = company?.openHours || {}
    const { holidays = [], normal = [], ...restPhoneSupport } =
      phoneSupport || {}
    return { holidays, normal, ...restPhoneSupport }
  }, [company])
}

const _useCompanyPaymentTypes = () => {
  const company = useContext(CustomizationsContext)

  const plaidBalanceCheck = useOverride(
    'plaidBalanceCheck',
    company?.paymentTypes?.balanceCheck?.plaid
  )

  const plaidVerification = useOverride(
    'plaidVerification',
    company?.paymentTypes?.achVerification?.plaid
  )

  const microdepositVerification = useOverride(
    'microdepositVerification',
    company?.paymentTypes?.achVerification?.microdeposit
  )

  return useMemo(() => {
    const { paymentTypes } = company || {}
    const { achVerification = {}, balanceCheck = {} } = paymentTypes || {}
    return {
      ...paymentTypes,
      balanceCheck: {
        ...balanceCheck,
        plaid: plaidBalanceCheck
      },
      achVerification: {
        ...achVerification,
        plaid: plaidVerification,
        microdeposit: microdepositVerification
      }
    }
  }, [company, plaidBalanceCheck, plaidVerification, microdepositVerification])
}

const _useCompanyUI = () => {
  const company = useContext(CustomizationsContext)
  return useMemo(() => {
    const { ui } = company || {}
    const { loanOptions = {}, accountSettingsPage = {} } = ui || {}
    return { ...ui, loanOptions, accountSettingsPage }
  }, [company])
}

const _useCompanyStatementsInfo = () => {
  /* 
  company.statements.useCompanyStatementsInfo
  is the proposed location for statements configuration data 
  but is subject to change as of 2021-04-06
  */

  const company = useContext(CustomizationsContext)
  return useMemo(() => {
    const { statements } = company || {}
    return statements || {}
  }, [company])
}

// if you need this hook, use import { useCompanyCopies, useCompanyLogos } from 'core/hooks'

export {
  CustomizationsProvider,
  _useCompanyCopies,
  _useCompanyEvidence,
  _useCompanyLogos,
  _useCompanyInfo,
  _useCompanyLinks,
  _useCompanyOpenHours,
  _useCompanyPhoneHours,
  _useCompanyPaymentTypes,
  _useCompanyUI,
  _useCompanyStatementsInfo
}
