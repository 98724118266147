import styled from 'styled-components/macro'
import variables from 'core/styles/variables'

const StyledAttachment = styled.div`
  display: ${p => (p.noFlex ? 'block' : 'flex')};
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin: 0 8px 8px;
  border-radius: 8px;
  background: ${variables.colorBlack20};
  padding: 0 8px;
  color: ${variables.colorBluePrimary};
  font-weight: 500;
  line-height: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: ${p => (p.isClickable ? 'pointer' : 'default')};
`

export default StyledAttachment
