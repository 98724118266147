import { useContext } from 'react'
import { TwilioDeviceContext } from 'contexts/TwilioDeviceContext'

const useTwilioDeviceContext = () => {
  const { device, onSetupTwilioDevice } = useContext(TwilioDeviceContext)

  return { device, onSetupTwilioDevice }
}

export default useTwilioDeviceContext
