import { useEffect, useRef } from 'react'

/**
 *
 * We're only supporting `options.capture` for the third
 * param of the event listener API, for now.
 */
const useEventListener = (key, fn, options) => {
  const { capture } = options || {}
  const funcRef = useRef(fn)

  useEffect(() => {
    funcRef.current = fn
  }, [fn])

  useEffect(() => {
    const $options = capture !== undefined ? { capture } : {}
    const onEvent = evt => funcRef.current(evt)
    document.addEventListener(key, onEvent, $options)
    return () => {
      document.removeEventListener(key, onEvent, $options)
    }
  }, [key, capture])
}
export default useEventListener
