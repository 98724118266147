import React from 'react'
import styled from 'styled-components/macro'
import variables from 'core/styles/variables'

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  text-align: center;
`

const Title = styled.div`
  padding: 6px 12px;
  margin: 12px;
  color: ${variables.colorBlack80};
  font-weight: bold;
  font-size: 20px;
`

const MessageText = styled.span`
  display: inline-block;
`

const Email = styled.a`
  color: ${variables.colorBluePrimary};

  &:hover {
    text-decoration: underline;
  }
`

const ImageWrapper = styled.div`
  padding-top: 32px;
`

const Image = styled.img`
  display: inline-block;
  width: 150px;
  height: 150px;
`

const SUPPORT_EMAIL = 'support@peachfinance.com'

const getSubject = () => {
  const { origin = '', pathname = '' } = window.location || {}
  return `Unexpected error on page ${origin}${pathname}`
}

const ContactSupportErrorMessage = () => {
  return (
    <Message>
      <Title>An unexpected error has occurred</Title>
      <MessageText>
        Please contact{' '}
        <Email
          href={`mailto:${SUPPORT_EMAIL}?subject=${getSubject()}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          customer support
        </Email>
        <ImageWrapper>
          <Image src={process.env.PUBLIC_URL + '/images/FancyElmo300.jpg'} />
        </ImageWrapper>
      </MessageText>
    </Message>
  )
}

export default ContactSupportErrorMessage
