import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as SmartyStreetsSDK from 'smartystreets-javascript-sdk'
import * as sdkUtils from 'smartystreets-javascript-sdk-utils'
import styled from 'styled-components/macro'

import MaskedInput from 'core/components/MaskedInput'
import formatAddress from 'core/helpers/formatAddress'
import smartyStreetToApiAddress from 'core/helpers/smartyStreetToApiAddress'
import variables from 'core/styles/variables'

const SmartyStreetsCore = SmartyStreetsSDK.core
const Lookup = SmartyStreetsSDK.usAutocompletePro.Lookup
const LookupStreet = SmartyStreetsSDK.usStreet.Lookup
const websiteKey = process.env.REACT_APP_SMARTYSTREETS_WEBSITE_KEY
const credentials = new SmartyStreetsCore.SharedCredentials(websiteKey)
const client = SmartyStreetsCore.buildClient.usAutocompletePro(credentials)
const clientStreet = SmartyStreetsCore.buildClient.usStreet(credentials)

const SuggestionMenu = styled.div`
  z-index: 1;
  position: absolute;
  top: 24px;
  left: 24px;
  width: 320px;
  height: auto;
  max-height: 200px;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: var(--peachyShadow);
`

const SuggestionMenuOption = styled.p`
  padding: 16px 8px;
  margin: 0;
  cursor: pointer;

  &:hover {
    background-color: ${variables.colorBlack10};
  }
`

const Container = styled.div`
  position: relative;
`

const AddressInput = ({ addressData, onChange, placeholder }) => {
  const addressDataString = useMemo(() => {
    return formatAddress(addressData, { singleLine: true })
  }, [addressData])

  const [raw, setRaw] = useState(addressDataString)
  const [suggestedAddresses, setSuggestedAddresses] = useState([])
  // If showMenu is true _and_ suggestedAddresses is not empty, we'll show
  // the menu. This is so we can close it if the input loses focus and open
  // it if it gains focus.
  const [showMenu, setShowMenu] = useState(true)

  // If the prop addressData changes (and results in a different string),
  // then the parent wants to also update the raw string that is shown.
  useEffect(() => {
    setRaw(addressDataString)
    setSuggestedAddresses([])
  }, [addressDataString, setRaw])

  const _onChange = useCallback(
    async newRaw => {
      setRaw(newRaw)

      const lookup = new LookupStreet()
      lookup.street = newRaw

      const validatedResults = await clientStreet.send(lookup)

      const firstMatch = validatedResults.lookups[0]
      const isValid = sdkUtils.isValid(firstMatch)

      const ret = {}

      if (firstMatch && isValid) {
        const smartyStreet = firstMatch.result[0].components
        ret.addressData = smartyStreetToApiAddress(smartyStreet)
        ret.isValid = true
      } else {
        ret.addressData = {}
        ret.isValid = false
      }

      onChange(ret)

      if (newRaw.length > 0) {
        const suggestedResults = await client.send(new Lookup(newRaw))
        setSuggestedAddresses([...suggestedResults.result])
      } else {
        setSuggestedAddresses([])
      }
    },
    [setRaw, setSuggestedAddresses, onChange]
  )

  const onAddressSelection = useCallback(async address => {
    if (address.entries > 1) {
      const lookup = new Lookup(address)
      lookup.selected = `${address.streetLine} ${address.secondary} (${address.entries}) ${address.city}, ${address.state} ${address.zipcode}`
      const suggestedResults = await client.send(lookup)
      setSuggestedAddresses([...suggestedResults.result])
    } else {
      setRaw(formatAddress(smartyStreetToApiAddress(address)))
      setSuggestedAddresses([])
    }
  }, [])

  return (
    <Container>
      <MaskedInput
        inputType='text'
        value={raw}
        onChange={e => _onChange(e.target.value)}
        placeholder={placeholder}
        withEllipsis
        disableUnderline
        onFocus={() => setShowMenu(true)}
        onBlur={() => setShowMenu(false)}
      />

      {suggestedAddresses.length > 0 && showMenu && (
        <SuggestionMenu>
          {suggestedAddresses.map((address, idx) => {
            const possibleEntries =
              address.entries > 1 ? `(${address.entries} more entries)` : ''
            const addrStr = formatAddress(smartyStreetToApiAddress(address), {
              singleLine: true
            })
            return (
              <SuggestionMenuOption
                key={`${address.streetLine}-${idx}`}
                onClick={() => onAddressSelection(address)}
              >
                {`${addrStr} ${possibleEntries}`}
              </SuggestionMenuOption>
            )
          })}
        </SuggestionMenu>
      )}
    </Container>
  )
}

AddressInput.propTypes = {
  addressData: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
}

AddressInput.defaultProps = {
  onChange: () => {}
}

export default AddressInput
