// Third party ------------------------
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

// Proprietary ------------------------
import outcomeOptions from 'core/helpers/getOutcomeOptions'
import variables from 'core/styles/variables'

// Components -------------------------
import Select from 'components/Select'

// Styles -----------------------------
const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${variables.colorBlack10};
  padding: 24px 0;
`
const SelectWrapper = styled.div`
  width: 360px;
`
const CallOutcome = styled.div`
  margin-right: 16px;
  color: ${variables.colorBlack60};
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`

const SelectOutcome = ({
  maxMenuHeight,
  selectedOutcome,
  setSelectedOutcome
}) => {
  return (
    <Section>
      <CallOutcome>call outcome</CallOutcome>
      <SelectWrapper>
        <Select
          controlledValue={selectedOutcome}
          getSelectionOption={setSelectedOutcome}
          isClearable
          isControlled
          maxMenuHeight={maxMenuHeight}
          placeholder='Select call outcome'
          options={outcomeOptions}
          menuPlacement='top'
        />
      </SelectWrapper>
    </Section>
  )
}

SelectOutcome.propTypes = {
  maxMenuHeight: PropTypes.number,
  selectedOutcome: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  setSelectedOutcome: PropTypes.func.isRequired
}

export default SelectOutcome
