// Third party --------------
import PropTypes from 'prop-types'
import React, { createContext, useMemo, useState } from 'react'
import _ from 'lodash'
import { Device } from 'twilio-client'
import { useLocation } from 'react-router-dom'

// Rest ---------------------
import { isLoggedIn } from 'core/actions/helpers/tokenHelpers'
import { useInterval, useNow, useOnMount } from 'core/hooks'
import { useTime } from 'core/time'
import {
  initDevice,
  setupTwilioDevice,
  setupTwilioDeviceEventHandlers
} from 'crm/helpers/twilioClient'

const isValidPath = (pathname = '') => {
  return pathname.includes('/crm')
}

const TwilioDeviceContext = createContext({})

const TwilioDeviceContextProvider = ({ children }) => {
  // Hooks and helpers
  const { difference } = useTime()
  const now = useNow()

  // Local variables
  const { pathname } = useLocation()
  const canInteractWithApi = isLoggedIn() && isValidPath(pathname)

  // Local state
  const [device] = useState(() => new Device())
  const [tokenLastUpdated, setTokenLastUpdated] = useState(null)

  // Run once on mount
  useOnMount(() => {
    initDevice(device)
    setupTwilioDeviceEventHandlers(device)
  })

  useInterval(
    () => {
      const isCallStatusActive = localStorage.getItem('callStatus') === 'active'

      // Don't do anything if currently on an active call
      if (isCallStatusActive) return

      if (!canInteractWithApi) return

      /**
       * If tokenLastUpdated is null it means that the app
       * most likely just initialized for the first time and
       * the tokens have not been fetched yet. If this is the
       * case and the above checks also pass then we'll want
       * to fetch the Twilio access tokens and setup the
       * Twilio device.
       */
      if (tokenLastUpdated === null) {
        setupTwilioDevice(device)
        setTokenLastUpdated(now)
        return
      }

      /**
       * The TTL for the Twilio access tokens for phone calls is
       * 1 hour. Below we're checking to see if the elapsed time is
       * greater than 50 minutes (3000 seconds) so we have some buffer
       * time to refetch tokens in case the application is in a state
       * that is not able to refetch tokens.
       */
      const diff = difference(now, tokenLastUpdated)
      const elapsedTimeInSec = _.floor(diff / 1000)
      const shouldRefetchTokens = elapsedTimeInSec > 3000

      if (shouldRefetchTokens) {
        setupTwilioDevice(device)
        setTokenLastUpdated(now)
      }
    },
    // Do this check every second
    { delay: 1000 }
  )

  const value = useMemo(() => ({ device }), [device])

  return (
    <TwilioDeviceContext.Provider value={value}>
      {children}
    </TwilioDeviceContext.Provider>
  )
}

TwilioDeviceContextProvider.propTypes = {
  children: PropTypes.node
}

export { TwilioDeviceContext }
export default TwilioDeviceContextProvider
