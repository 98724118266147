import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import pickBy from 'lodash/pickBy'

import {
  CREATE_INTERACTION,
  DELETE_INTERACTION,
  GET_INTERACTION,
  GET_INTERACTIONS,
  GET_MORE_INTERACTIONS,
  UPDATE_INTERACTION,
  UPDATE_UNBOUND_INTERACTION
} from 'core/actions/constants'

const initialState = {
  byId: {}
}

const interactions = function(state = initialState, action) {
  switch (action.type) {
    case GET_INTERACTIONS:
      return {
        ...state,
        byId: {
          // keep all existing cases for other personIds
          ...Object.values(state.byId).reduce((acc, curr) => {
            if (curr.personId !== action.personId) {
              acc[curr.id] = curr
            }
            return acc
          }, {}),
          // insert the fresh cases for this personId
          ...action.payload.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.id]: {
                ...curr
              }
            }),
            {}
          )
        }
      }
    case GET_INTERACTION:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...action.payload,
            id: action.id,
            companyId: action.companyId
          }
        }
      }
    case GET_MORE_INTERACTIONS:
      // GET_MORE_INTERACTIONS differs from GET_INTERACTIONS in that it
      // doesn't delete anything already in the interactions redux store.
      // This is useful for the call to getInteractions to do multiple
      // api calls to get all the paginated results.
      return {
        ...state,
        byId: {
          ...state.byId,
          ...mapValues(keyBy(action.payload, 'id'), item => ({
            ...item,
            personId: action.personId
          }))
        }
      }
    case CREATE_INTERACTION:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload,
            personId: action.personId
          }
        }
      }
    case UPDATE_INTERACTION:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            ...action.payload,
            personId: action.personId
          }
        }
      }
    case UPDATE_UNBOUND_INTERACTION:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            ...action.payload
          }
        }
      }
    case DELETE_INTERACTION:
      return {
        ...state,
        byId: pickBy(state.byId, item => item.id !== action.id)
      }
    default:
      return state
  }
}

export default interactions
