import { GET_CASE_TYPES } from 'core/actions/constants'

const initialState = {
  byId: {},
  allIds: []
}

const caseTypes = (state = initialState, action) => {
  switch (action.type) {
    case GET_CASE_TYPES:
      return {
        ...state,
        allIds: action.payload.map(caseType => caseType.id),
        byId: action.payload.reduce(
          (newById, caseType) => ({
            ...newById,
            [caseType.id]: caseType
          }),
          {}
        )
      }
    default:
      return state
  }
}

export default caseTypes
