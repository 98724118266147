// Third party ------------------------
import Creatable from 'react-select/creatable'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactSelect, { components } from 'react-select'
import classNames from 'classnames'
import styled from 'styled-components/macro'

// Proprietary ------------------------
import customStyles from './customStyles'
import useOnMount from 'core/hooks/useOnMount'
import variables from 'core/styles/variables'

// Components -------------------------
import Icon from 'core/components/Icon'

const PlaceholderSpan = styled.span`
  color: ${variables.colorBlack50};
`
const GroupLabel = styled.div`
  color: ${variables.colorBlack50};
  font-size: 12px;
  text-transform: uppercase;
`

// Formatting
const _formattedPlaceholder = placeholder =>
  typeof placeholder === 'string' ? (
    <PlaceholderSpan>{placeholder}</PlaceholderSpan>
  ) : (
    placeholder
  )

const _formattedGroupLabel = data => <GroupLabel>{data.label}</GroupLabel>

const Select = ({
  controlledValue,
  creatable,
  defaultValue,
  formatCreateLabel,
  formatOptionLabel,
  getPaymentMethodId,
  getSelectionOption,
  getSelectionValue,
  isClearable,
  isControlled,
  isDisabled,
  isOptionDisabled,
  isMulti,
  maxMenuHeight,
  menuPlacement,
  options,
  placeholder,
  renderNoOption,
  setDayDateSelection,
  small,
  isSearchable
}) => {
  const [selectedOption, setSelectedOption] = useState(null)

  useOnMount(() => {
    const op = options && options.find(o => o.value === defaultValue)
    if (defaultValue) {
      setSelectedOption({
        label: op ? op.label : defaultValue,
        value: defaultValue
      })
    }
  })

  // TODO: Move these class styles directly in the Select/index.scss file
  // instead of having them floating somewhere else.
  const selectClassnames = classNames({
    select: true,
    small: small
  })

  // Methods and helpers --------------
  const _handleSelectChange = selectedOption => {
    if (!selectedOption) {
      const op =
        options &&
        options.find(o => o.value === defaultValue && o.value !== undefined)
      setSelectedOption({
        label: op ? op.label : defaultValue,
        value: defaultValue
      })
    } else {
      setSelectedOption(selectedOption)
    }

    getSelectionOption && getSelectionOption(selectedOption)
    // REFACTOR: the two methods below seem to be doing the same thing
    getSelectionValue && getSelectionValue(selectedOption?.value)
    setDayDateSelection && setDayDateSelection(selectedOption?.value)
    getPaymentMethodId && getPaymentMethodId(selectedOption?.id)
  }

  const _handleNoOptions = () => {
    return renderNoOption?.() ?? 'No options'
  }

  return creatable ? (
    <Creatable
      styles={customStyles}
      classNamePrefix='creatable'
      components={{ DropdownIndicator }}
      formatCreateLabel={formatCreateLabel}
      formatGroupLabel={_formattedGroupLabel}
      formatOptionLabel={formatOptionLabel}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isOptionDisabled={isOptionDisabled}
      maxMenuHeight={maxMenuHeight}
      menuPlacement={menuPlacement}
      noOptionsMessage={() => _handleNoOptions()}
      onChange={_handleSelectChange}
      options={options}
      placeholder={_formattedPlaceholder(placeholder)}
      value={isControlled ? controlledValue : selectedOption}
    />
  ) : (
    <ReactSelect
      styles={customStyles}
      className={selectClassnames}
      classNamePrefix='select'
      components={{ DropdownIndicator }}
      formatGroupLabel={_formattedGroupLabel}
      formatOptionLabel={formatOptionLabel}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isOptionDisabled={isOptionDisabled}
      isMulti={isMulti}
      isSearchable={!!isSearchable}
      maxMenuHeight={maxMenuHeight}
      menuPlacement={menuPlacement}
      noOptionsMessage={() => _handleNoOptions()}
      onChange={_handleSelectChange}
      options={options}
      placeholder={_formattedPlaceholder(placeholder)}
      value={isControlled ? controlledValue : selectedOption}
    />
  )
}

Select.propTypes = {
  controlledValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  creatable: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  formatCreateLabel: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  getPaymentMethodId: PropTypes.func,
  getSelectionOption: PropTypes.func,
  getSelectionValue: PropTypes.func,
  isClearable: PropTypes.bool,
  isControlled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  isMulti: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  menuPlacement: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object)
  ]),
  placeholder: PropTypes.node,
  renderNoOption: PropTypes.func,
  setDayDateSelection: PropTypes.func,
  small: PropTypes.bool,
  isSearchable: PropTypes.bool
}

Select.defaultProps = {
  controlledValue: null
}

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon
          name='arrow_drop_down'
          color={variables.colorBlack90}
          fontSize='24px'
        />
      </components.DropdownIndicator>
    )
  )
}

export default Select
