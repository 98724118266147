import _ from 'lodash'
import { getName } from 'country-list'

/** Takes an address object just like it appears,
 * for example, in the contacts api and returns a
 * string version of it. 

 options: 
 - singleLine
 - twoLines
 - includeCountry

 default behavior is multi-line, up to 5 total
*/

const combine = (list, delimiter = ' ') => _.compact(list).join(delimiter)

const formatAddress = (address, options) => {
  const { singleLine, twoLines, includeCountry } = options || {}

  if (_.isEmpty(address)) return ''

  const {
    addressLine1,
    addressLine2,
    city,
    countyOrRegion,
    state,
    postalCode,
    country,
    POBox
  } = address

  const $POBox = POBox && `PO Box ${POBox}`

  const cityState = combine([city, countyOrRegion, state], ', ')

  const addressLine3 = combine([cityState, postalCode])

  const $country = includeCountry && country && getName(country)

  if (twoLines) {
    const line1Part1 = combine([$POBox, addressLine1])
    const line1 = combine([line1Part1, addressLine2], ', ')
    const line2 = combine([addressLine3, $country], ' ')
    return combine([line1, line2], '\n')
  }

  const parts = [$POBox, addressLine1, addressLine2, addressLine3, $country]

  if (singleLine) {
    return combine(parts, ', ')
  }

  return combine(parts, '\n')
}

export const formatAddressSdn = (address, options) => {
  const massagedAddress = {
    addressLine1: address.address,
    postalCode: address.cityStateProvincePostalCode,
    country: address.country
  }
  return formatAddress(massagedAddress, options)
}

export default formatAddress
