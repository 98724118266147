import {
  APIendpoint,
  UPDATE_DOCUMENT_DESCRIPTOR,
  LOADING,
  getStoredToken
} from 'core/actions/constants'
import { PUTwithToken } from 'core/api/legacyApi'
import { handleErrors } from 'core/helpers/handleErrors'

const updateDocumentDescriptors = ({ key, filters = {} } = { filters: {} }) => {
  const { personId, documentDescriptorId, body } = filters

  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    const endpoint = `${APIendpoint}/people/${personId}/documents/${documentDescriptorId}`
    const response = await fetch(endpoint, PUTwithToken(getStoredToken(), body))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    if (isSuccess) {
      dispatch({
        type: UPDATE_DOCUMENT_DESCRIPTOR,
        payload: responseBody,
        personId
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error'
      })
    }
  }
}

export default updateDocumentDescriptors
