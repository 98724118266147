import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'

const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  background-color: var(--colorBlack50);
  z-index: 3;
`

const RefWrapper = styled.div`
  position: absolute;
  ${p =>
    p.isDraftTextInteraction
      ? `
        top: 50%;
        transform: translateY(-50%);`
      : `
        top: 96px;
        bottom: 96px;`}
  right: 96px;
  left: 96px;
`

const ResourceModal = ({
  children,
  isDraftTextInteraction,
  onClickOutside
}) => {
  const ref = useRef(null)

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClickOutside])

  return (
    <Container>
      <RefWrapper ref={ref} isDraftTextInteraction={isDraftTextInteraction}>
        {children}
      </RefWrapper>
    </Container>
  )
}

ResourceModal.propTypes = {
  children: PropTypes.node,
  isDraftTextInteraction: PropTypes.bool,
  onClickOutside: PropTypes.func
}

ResourceModal.defaultProps = {
  onClickOutside: () => {}
}

export default ResourceModal
