import {
  GET_PEOPLE,
  GET_PERSON,
  UPDATE_PERSON,
  GET_PAYMENT_INSTRUMENTS
} from 'core/actions/constants'
import {
  GET_CONTACTS,
  SET_PLAID_SAVING,
  SET_PLAID_INSTRUMENT,
  SET_PLAID_FAILED,
  SET_PLAID_INTERNAL_ERROR,
  RESET_PLAID_FLAG,
  SET_BANK_INSTRUMENT,
  SET_BANK_INSTRUMENT_ERROR,
  SET_DEBIT_INSTRUMENT_ERROR,
  UPDATE_BANK_INSTRUMENT,
  DELETE_BANK_INSTRUMENT,
  SET_DEBIT_INSTRUMENT,
  GET_SPECIFIC_BANK_INSTRUMENT,
  CANCEL_EDIT_MODE,
  UPDATE_CARD_INSTRUMENT,
  CLEAR_PLAID_DATA,
  CLEAR_BANK_DATA,
  CLEAR_CARD_DATA,
  CLEAR_BANK_ERROR,
  CLEAR_CARD_ERROR,
  VERIFY_ROUTING_NUMBER_SUCCESS,
  VERIFY_ROUTING_NUMBER_FAILED,
  SET_INITIAL_DOCUMENT_DESCRIPTOR,
  FINALIZE_DOCUMENT_DESCRIPTOR,
  DELETE_DOCUMENT_DESCRIPTOR,
  CONVERT_FINAL_DOCUMENT_PDF,
  GET_ALL_RELATED_DOCUMENTS,
  CREATE_CONTACTS_SUCCESS,
  CREATE_CONTACTS_FAILED,
  CLONE_CONTACTS_FAILED,
  CREATE_CONTACTS_CLEAR_ERROR,
  CLEAR_CONTACT_DATA,
  UPDATE_CONTACTS_FAILED,
  CREATE_CONTACTS_TIMEOUT,
  UPDATE_CONTACTS_TIMEOUT,
  GET_LEGAL_REP_SUCCESS,
  CREATE_LEGAL_REP_SUCCESS,
  UPDATE_LEGAL_REP_SUCCESS
} from 'core/actions/people'

import { GENERATE_TOKEN_SUCCESS, GENERATE_TOKEN_ERROR } from 'core/actions/auth'

import insertSorted from 'core/helpers/insertSorted'

const initialState = {
  initialDocumentDescriptor: {},
  documentDescriptors: {},
  loginError: {},
  newData: false,
  isEditMode: false,
  plaidSaving: false,
  currentPlaidData: {},
  draftMode: false,
  finalDocumentPDF: {},
  relatedDocuments: [],
  currentBankData: {},
  currentCardData: {},
  setBankError: '',
  setCardError: '',
  byId: {},
  allIds: [],
  contactsError: '',
  contactData: {}
}

const people = function(state = initialState, action) {
  switch (action.type) {
    case GENERATE_TOKEN_SUCCESS:
      return {
        ...state
      }
    case GENERATE_TOKEN_ERROR:
      return {
        ...state,
        loginError: action.payload
      }
    case GET_PERSON:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: action.payload
        },
        allIds: insertSorted({ item: action.id, list: state.allIds })
      }
    case UPDATE_PERSON:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            ...action.payload
          }
        }
      }
    case GET_PEOPLE:
      return {
        ...state,
        byId: {
          // keep all existing people for other personIds
          ...Object.values(state.byId).reduce((acc, curr) => {
            if (curr.personId !== action.personId) {
              acc[curr.id] = curr
            }
            return acc
          }, {}),
          // insert the fresh people for this personId
          ...action.payload.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.id]: {
                ...curr,
                personId: action.personId,
                companyId: action.companyId
              }
            }),
            {}
          )
        }
      }
    case GET_PAYMENT_INSTRUMENTS:
      return {
        ...state,
        paymentInstruments: action.payload,
        hasNewData: false
      }
    case GET_CONTACTS:
      return {
        ...state,
        contacts: action.payload
      }
    case SET_PLAID_SAVING:
      return {
        ...state,
        plaidSaving: true
      }
    case SET_PLAID_INSTRUMENT:
      return {
        ...state,
        plaidSaving: false,
        currentPlaidData: action.payload,
        failedToAddPlaid: false,
        plaidErrorMessage: ''
      }
    case SET_PLAID_FAILED:
      return {
        ...state,
        plaidSaving: false,
        failedToAddPlaid: true,
        plaidErrorMessage: action.payload
      }
    case SET_PLAID_INTERNAL_ERROR:
      return {
        ...state,
        plaidSaving: false,
        failedToAddPlaid: true,
        plaidErrorMessage: action.payload
      }
    case RESET_PLAID_FLAG:
      return {
        ...state,
        failedToAddPlaid: false,
        plaidErrorMessage: ''
      }
    case SET_BANK_INSTRUMENT:
      return {
        ...state,
        currentBankData: action.payload,
        hasNewData: true,
        setBankError: ''
      }
    case SET_BANK_INSTRUMENT_ERROR:
      return {
        ...state,
        setBankError: action.payload
      }
    case SET_DEBIT_INSTRUMENT_ERROR:
      return {
        ...state,
        setCardError: action.payload
      }
    case UPDATE_BANK_INSTRUMENT:
      return {
        ...state,
        hasNewData: true,
        isEditMode: false
      }
    case UPDATE_CARD_INSTRUMENT:
      return {
        ...state,
        hasNewData: true,
        isEditMode: false
      }
    case DELETE_BANK_INSTRUMENT:
      return {
        ...state,
        hasNewData: true
      }
    case SET_DEBIT_INSTRUMENT:
      return {
        ...state,
        hasNewData: true,
        currentCardData: action.payload
      }
    case GET_SPECIFIC_BANK_INSTRUMENT:
      return {
        ...state,
        bankDataToEdit: action.payload,
        isEditMode: true
      }
    case CANCEL_EDIT_MODE:
      return {
        ...state,
        isEditMode: false
      }
    case CLEAR_PLAID_DATA:
      return {
        ...state,
        plaidSaving: false,
        currentPlaidData: {}
      }
    case CLEAR_BANK_DATA:
      return {
        ...state,
        currentBankData: {}
      }
    case CLEAR_BANK_ERROR:
      return {
        ...state,
        setBankError: ''
      }
    case CLEAR_CARD_DATA:
      return {
        ...state,
        currentCardData: {}
      }
    case CLEAR_CARD_ERROR:
      return {
        ...state,
        setCardError: ''
      }

    case VERIFY_ROUTING_NUMBER_SUCCESS:
      return { ...state, routingNumberData: action.payload }

    case VERIFY_ROUTING_NUMBER_FAILED:
      return { ...state, routingNumberData: action.payload }

    case SET_INITIAL_DOCUMENT_DESCRIPTOR:
      return {
        ...state,
        initialDocumentDescriptor: action.payload,
        draftMode: true
      }
    case FINALIZE_DOCUMENT_DESCRIPTOR:
      return {
        ...state,
        draftMode: false,
        initialDocumentDescriptor: {}
      }
    case DELETE_DOCUMENT_DESCRIPTOR:
      return {
        ...state,
        draftMode: false,
        initialDocumentDescriptor: {}
      }
    case CONVERT_FINAL_DOCUMENT_PDF:
      return {
        ...state,
        finalDocumentPDF: action.payload
      }
    case GET_ALL_RELATED_DOCUMENTS:
      return {
        ...state,
        relatedDocuments: action.payload
      }
    case CREATE_CONTACTS_SUCCESS:
      return {
        ...state,
        contactData: action.payload
      }
    case CREATE_CONTACTS_FAILED:
      return {
        ...state,
        contactsError: action.payload
      }
    case CLONE_CONTACTS_FAILED:
      return {
        ...state,
        contactsError: action.payload
      }
    case CREATE_CONTACTS_CLEAR_ERROR:
      return {
        ...state,
        contactsError: ''
      }
    case CLEAR_CONTACT_DATA:
      return {
        ...state,
        contactData: {}
      }
    case UPDATE_CONTACTS_FAILED:
      return {
        ...state,
        contactsError: action.payload
      }
    case CREATE_CONTACTS_TIMEOUT:
      return {
        ...state,
        contactsError: action.payload
      }
    case UPDATE_CONTACTS_TIMEOUT:
      return {
        ...state,
        contactsError: action.payload
      }
    case GET_LEGAL_REP_SUCCESS:
      return {
        ...state,
        legalReps: action.payload
      }
    case CREATE_LEGAL_REP_SUCCESS:
      return {
        ...state,
        legalRepData: action.payload
      }
    case UPDATE_LEGAL_REP_SUCCESS:
      return {
        ...state,
        legalRepData: action.payload
      }
    default: {
      return state
    }
  }
}

// TODO: cache state values using redux selectors for scalability/performance boosts

export default people
