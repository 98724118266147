/**
 * Returns the innerText of the first style tag in an html
 * string.
 *
 * @param {String} html
 */
const extractStylesFromHtml = html => {
  const dom = document.createElement('div')
  dom.innerHTML = html
  const styleTags = [...dom.getElementsByTagName('style')]
  return styleTags[0]?.innerText?.toString() || ''
}

export default extractStylesFromHtml
