import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { QueryClient, QueryClientProvider } from 'react-query'

import { ReactQueryDevtools } from 'react-query/devtools'

const BorrowerQueryProvider = ({ children }) => {
  const [queryClient] = useState(() => new QueryClient())

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

BorrowerQueryProvider.propTypes = {
  children: PropTypes.node
}

export default BorrowerQueryProvider
