import _ from 'lodash'

const formatType = (str = '') => str.toLowerCase()

const combine = (...items) =>
  _.compact(items)
    .join(' ')
    .trim()

const getFullName = record => {
  if (!record) return
  const type = formatType(record.object)

  switch (type) {
    case 'person': {
      const { borrowerType, name, businessDetails } = record
      if (borrowerType === 'business') {
        const { businessName, businessLegalName } = businessDetails || {}
        return businessName || businessLegalName
      } else {
        const { preferredFirstName, firstName, lastName } = name || {}
        return combine(preferredFirstName || firstName, lastName)
      }
    }

    case 'employee': {
      const { firstName, lastName } = record
      return combine(firstName, lastName)
    }

    default: {
      const { firstName, lastName } = record
      return firstName || lastName ? combine(firstName, lastName) : undefined
    }
  }
}

export default getFullName
