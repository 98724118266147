import { parseError } from '../loginHelpers'

const errorAliases = {
  'too frequent attempts': `Looks like there is a problem with your login information. For security reasons we've frozen your account for an hour. Please try again later.`,
  'Not authorized': `The verification code you've entered is incorrect.`
}

const formatOtcError = error => {
  const string = parseError(error)
  return errorAliases[string] || string
}

export { formatOtcError }
