import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { AnimatedInput } from 'core/components'
import { useWrite } from 'core/hooks'
import { ExpectedError } from 'core/system/ErrorLogging'

import LoginSubmitButton from '../LoginSubmitButton'
import { parseError } from '../loginApi'
import LoginFormCard from '../LoginFormCard'
import { useLoginInfo } from '../LoginWrapper'

const BasicPasswordLogin = ({ onLogin }) => {
  const { authValueType } = useLoginInfo()

  const authValueLabel =
    authValueType === 'email'
      ? 'Email'
      : authValueType === 'phone'
      ? 'Phone Number'
      : authValueType === 'username'
      ? 'Username'
      : 'Unknown Authentication Type'

  const [authValue, setAuthValue] = useState('')
  const [password, setPassword] = useState('')

  const [onSubmit, sending, resp, error, clear] = useWrite(async () => {
    if (!authValue) throw new ExpectedError(`${authValueLabel} is required`)

    if (!password) throw new ExpectedError(`Password is required`)

    return onLogin({ username: authValue, password })
  })

  const errorMessage = parseError(error)

  useEffect(() => {
    clear()
  }, [authValue, password, clear])

  const hasEmptyInputs = !authValue || !password

  const handleEnter = () => {
    if (authValue && password && !sending) onSubmit()
  }

  const handleChange = event => {
    // BE should be case insensitive,
    // but also setting to lowercase for aesthetic reasons for the user
    setAuthValue(event.target.value.toLowerCase())
  }

  return (
    <LoginFormCard>
      <AnimatedInput
        label={authValueLabel}
        name={authValueType}
        onChange={handleChange}
        placeholder=' '
        showError={error && !authValue}
        type='text'
        value={authValue}
        disabled={sending || !!resp}
        onEnter={handleEnter}
      />
      <AnimatedInput
        hasEmptyInputs={hasEmptyInputs}
        label='Password'
        name='password'
        onChange={e => setPassword(e.target.value)}
        placeholder=' '
        showError={error && !password}
        type='password'
        value={password}
        disabled={sending || !!resp}
        onEnter={handleEnter}
      />

      <LoginSubmitButton
        onSubmit={onSubmit}
        errorMessage={errorMessage}
        sending={sending}
        disabled={!!resp}
      />
    </LoginFormCard>
  )
}

BasicPasswordLogin.propTypes = {
  onLogin: PropTypes.func
}

export default BasicPasswordLogin
