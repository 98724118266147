// Third party --------------
import _ from 'lodash'
import { useEffect, useState } from 'react'

// Rest ---------------------
import { formatSelectOption } from '../helpers'

const useCasesOptions = ({ cases }) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    const hiddenCases = ['completed', 'canceled']
    const filteredCases = _.filter(cases, c => !hiddenCases.includes(c.status))
    const mappedCases = _.map(filteredCases, c => formatSelectOption(c))
    setOptions(prev => {
      if (_.isEqual(prev, mappedCases)) return prev
      return mappedCases
    })
  }, [cases])

  return options
}

export default useCasesOptions
