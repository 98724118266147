import { UPDATE_TASK_AVAILABILITY, LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

const updateTaskAvailability = ({ key, body }) => {
  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    try {
      const response = await peachApi.put({
        url: `/twilio/tasktype-availability`,
        body
      })
      dispatch({ type: UPDATE_TASK_AVAILABILITY, payload: response.data })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
      return response.data
    } catch {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
    }
  }
}

export default updateTaskAvailability
