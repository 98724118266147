import React from 'react'
import styled from 'styled-components/macro'
import itemStyles from './itemStyles'
import withLabel from './withLabel'

const InputElement = styled.input`
  ${itemStyles};
`

const Input = ({ value, onChange, ...rest }) => {
  return (
    <InputElement
      value={value || ''}
      onChange={e => onChange(e.target.value)}
      {...rest}
    />
  )
}

export default withLabel(Input)
