import { GET } from 'core/api/legacyApi'
import { handleErrors } from 'core/helpers/handleErrors'
import {
  APIendpoint,
  GET_TEAMS,
  LOADING,
  getStoredToken
} from 'core/actions/constants'

const getTeams = ({ filters, key }) => {
  const { companyId } = filters

  return async dispatch => {
    if (key) {
      dispatch({ type: LOADING, key, status: 'loading' })
    }
    const endpoint = `${APIendpoint}/companies/${companyId}/teams?limit=100`
    const response = await fetch(endpoint, GET(getStoredToken()))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    if (isSuccess) {
      dispatch({
        type: GET_TEAMS,
        payload: responseBody.data,
        companyId
      })
    }

    if (key) {
      dispatch({ type: LOADING, key, status: isSuccess ? 'success' : 'error' })
    }
  }
}

export default getTeams
