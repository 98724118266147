import { useSelector } from 'react-redux'

const useLoadingStatus = key => {
  return useSelector(state => state.loading[key])
}

const useIsLoading = key => {
  return !!(useLoadingStatus(key) === 'loading')
}

export { useLoadingStatus, useIsLoading }
