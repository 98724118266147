import { useEffect } from 'react'
import { resetLoading } from 'core/actions/loading'
import { useDispatch } from 'react-redux'

const useResetLoading = key => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetLoading(key))
  }, [dispatch, key])
}

export default useResetLoading
