import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import styled from 'styled-components/macro'

import Resource from './Resource'
import { OpenResourceContext } from 'contexts/OpenResourceContext'

const ResourceListContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100vw;
  box-sizing: border-box;
  z-index: 2;
`

const ResourceOverlay = ({ children }) => {
  const { resourceList, setMode, onClose } = useContext(OpenResourceContext)

  return (
    <>
      {children}
      <ResourceListContainer>
        {resourceList.map(resource => (
          <Resource
            borrowerId={resource.borrowerId}
            id={resource.id}
            key={`${resource.id}-${resource.type}`}
            mode={resource.mode}
            onClose={onClose}
            options={resource.options}
            setMode={setMode}
            type={resource.type}
          />
        ))}
      </ResourceListContainer>
    </>
  )
}

ResourceOverlay.propTypes = {
  children: PropTypes.node
}

export default ResourceOverlay
