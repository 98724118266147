import _ from 'lodash'
import {
  init,
  captureException,
  captureMessage,
  setTag,
  setUser,
  addBreadcrumb
} from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const dsn = process.env.REACT_APP_SENTRY_DSN
const environment = process.env.REACT_APP_ENV_LABEL
const release = process.env.REACT_APP_SENTRY_RELEASE

let initialized = false

const logWarning = (...args) => {
  if (process.env.NODE_ENV !== 'test') {
    console.log(...args) // eslint-disable-line no-console
  }
}

class ExpectedError extends Error {
  constructor(message) {
    super(message)
    this.name = 'ExpectedError'
    this.isExpectedError = true
  }
}

const beforeSend = (event, hint) => {
  const { message, status, isExpectedError } = hint?.originalException || {}

  // do not log expected errors
  if (isExpectedError) return null

  // do not log 400 level network responses
  if (status >= 400 && status < 500) return null

  // Guards against twilio/task-router.js websocket unhandled error events,
  // causing us to hit rate limit: https://sentry.io/organizations/peach-finance/issues/2040108205/?project=5465764
  if (message === 'Uncaught, unspecified "error" event.') {
    return null
  }
  return event
}

if (!dsn) {
  logWarning(
    `ErrorLogging.init(), REACT_APP_SENTRY_DSN was not provided. Error logging will not be initialized`
  )
} else if (!environment) {
  logWarning(
    `ErrorLogging.init(), REACT_APP_ENV_LABEL was not provided.  Error logging will not be initialized`
  )
} else {
  if (!release) {
    logWarning(
      `ErrorLogging.init(), REACT_APP_SENTRY_RELEASE was not provided. Error logging will initialize without a specified release, which means source maps will not work.`
    )
  }

  const integrations = [new Integrations.BrowserTracing()]

  init({
    dsn,
    environment,
    integrations,
    release,
    traceSampleRate: 0.5,
    beforeSend
  })

  initialized = true
}

const ErrorLogging = {
  captureException,
  captureMessage,
  setTag,
  setUser,
  addBreadcrumb
}

const wrappedErrorLogging = _.mapValues(ErrorLogging, (sentryFn, key) => {
  return (...args) => {
    if (initialized) {
      sentryFn(...args)
    } else {
      logWarning(`ErrorLogging.${key}()`, args)
    }
  }
})

export { ExpectedError }

export default wrappedErrorLogging
