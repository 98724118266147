import { useRef } from 'react'
import { useRect as useReachRect } from '@reach/rect'
import { useWindowSize } from '@reach/window-size'

const useRect = (passedRef, passedDefaults) => {
  const localRef = useRef()

  const windowDefaults = useWindowSize()

  const defaults = passedDefaults || windowDefaults

  const ref = passedRef || localRef

  const reachRect = useReachRect(ref)

  const rect = {
    top: reachRect?.top,
    left: reachRect?.left,
    right: reachRect?.right,
    bottom: reachRect?.bottom,
    width: reachRect?.width ?? defaults.width,
    height: reachRect?.height ?? defaults.height
  }
  return [rect, ref]
}
export default useRect
