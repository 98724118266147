import { GET_CASES, LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

const getCases = ({ key, filters = {} } = { filters: {} }) => {
  const { personId } = filters

  return async dispatch => {
    if (!personId) return
    if (key) {
      dispatch({ type: LOADING, key, status: 'loading' })
    }

    try {
      const response = await peachApi.get({
        url: `/people/${personId}/cases?associations=all&include=dispute,purchase`
      })
      dispatch({ type: GET_CASES, payload: response.data, personId })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
    } catch {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
    }
  }
}

export default getCases
