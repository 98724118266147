import { PUTwithToken } from 'core/api/legacyApi'
import jwt from 'jsonwebtoken'
import APIendpoint from './APIendpoint'
import ErrorLogging from 'core/system/ErrorLogging'

const TOKEN = 'secretToken'
const SESSION_EXPIRED = 'sessionExpired'

const getStoredToken = () => {
  try {
    const val = localStorage.getItem(TOKEN)
    if (val) {
      return JSON.parse(val) || undefined
    }
    return undefined
  } catch (e) {
    return undefined
  }
}

const setStoredToken = token => {
  if (token) {
    try {
      localStorage.setItem(TOKEN, JSON.stringify(token))
    } catch (e) {
      localStorage.removeItem(TOKEN)
    }
  } else {
    localStorage.removeItem(TOKEN)
  }
}

const getSessionExpired = () => {
  try {
    const val = localStorage.getItem(SESSION_EXPIRED)
    if (val) {
      return val === 'true'
    }
    return false
  } catch (e) {
    return false
  }
}

const setSessionExpired = sessionExpired => {
  if (sessionExpired) {
    try {
      localStorage.setItem(SESSION_EXPIRED, 'true')
    } catch (e) {
      localStorage.removeItem(SESSION_EXPIRED)
    }
  } else {
    localStorage.removeItem(SESSION_EXPIRED)
  }
}

const setSessionExpiredIfRecent = () => {
  const token = getStoredToken()
  const { exp } = decodeToken(token)
  if (exp > Math.round(Date.now() / 1000) - 12 * 3600) {
    // token expired within the past 12 hours; show session expired message
    setSessionExpired(true)
  }
}

const decodeToken = token => {
  try {
    return jwt.decode(token) || {}
  } catch {
    return {}
  }
}

const formatTimestamp = ts => {
  try {
    return ts ? new Date(ts).toLocaleString('en-US') : '--'
  } catch {}
}

const logBreadcrumb = msg => {
  ErrorLogging.addBreadcrumb({ category: 'auth', message: msg })
}

const renewSession = async () => {
  const token = getStoredToken()
  const { exp } = decodeToken(token)
  const endpoint = `${APIendpoint}/auth/token`

  logBreadcrumb(`renewing session, expires ${formatTimestamp(exp * 1000)}`)

  try {
    const resp = await fetch(endpoint, PUTwithToken(token, {}))

    if (resp.ok) {
      const { token: newToken } = await resp.json()
      setStoredToken(newToken)
    } else {
      setSessionExpiredIfRecent()
      setStoredToken('')
      logBreadcrumb('non-200 response from renewal request')
    }
  } catch {
    setSessionExpiredIfRecent()
    setStoredToken('')
    logBreadcrumb('error while renewing')
  }
}

const isTokenExpired = token => {
  return token && decodeToken(token).exp * 1000 <= Date.now()
}

const isTokenValid = token => {
  const expiresAt = decodeToken(token).exp
  return !!(expiresAt && expiresAt * 1000 >= Date.now())
}

const isLoggedIn = () => isTokenValid(getStoredToken())

export {
  getStoredToken,
  setStoredToken,
  getSessionExpired,
  setSessionExpiredIfRecent,
  setSessionExpired,
  renewSession,
  decodeToken,
  isTokenExpired,
  isTokenValid,
  APIendpoint,
  isLoggedIn
}
