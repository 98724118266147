import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import getCase from 'core/actions/cases/getCase'
import getCompany from 'core/actions/companies/getCompany'
import getContact from 'core/actions/contacts/getContact'
import getDocumentWithContent from 'core/actions/documents/getDocumentWithContent'
import getEmployee from 'core/actions/employees/getEmployee'
import getInteraction from 'core/actions/interactions/getInteraction'
import getPerson from 'core/actions/people/getPerson'
import getDraftPopulation from 'core/actions/populations/getDraftPopulation'
import getTeam from 'core/actions/teams/getTeam'
import getWorkflow from 'core/actions/workflows/getWorkflow'
import { useCompanyId } from 'core/hooks'
import useDispatch from 'core/hooks/useDispatch'
import { makeGetLoadingStatus } from 'core/selectors/loading'

const actions = {
  cases: getCase,
  companies: getCompany,
  contacts: getContact,
  documents: getDocumentWithContent, // pass personId into context
  draftPopulations: getDraftPopulation,
  employees: getEmployee,
  interactions: getInteraction,
  people: getPerson,
  teams: getTeam,
  workflows: getWorkflow // pass personId into context
}

/** We store loading keys as keys in this object (value will be true always)
 * in order to synchronously track which fetches we have already begun and
 * avoid redundant fetches. The `loading` redux state doesn't get updated
 * immediately once dispatch is called in useEffect or a redux thunk. And we
 * aren't supposed to call dispatch synchronously in a component because it's
 * kind of the same as setting state, which shouldn't be done in the middle
 * of a render chain. All state updates and redux updates should be done at the
 * end of a render chain, which is why we always put them in useEffect or call
 * the provided setState or put them in an event handler.
 *
 * So when the first call to fetch data happens, and we're still in the middle
 * of a render chain while several others call it (and the actual fetching
 * hasn't begun yet and redux hasn't been updated yet), the several other components
 * calling to fetch the same data will queue up an api fetch as well, and we end up
 * with like 10 redundant api fetches before `loading` is set.
 *
 * This cache can be set immediately to signal to other call instances of the
 * function that they don't need to trigger an api fetch as well.
 */
const loadingCache = {}

const useEntity = (collection, entityId, context = {}, entityIdType = 'id') => {
  // Include any of these in the context arg if needed for the corresponding
  // action.
  const { personId } = context

  const dispatch = useDispatch()
  const companyId = useCompanyId()
  // e.g. byId, byCaseId, bySupercaseId
  const byString =
    'by' + entityIdType.charAt(0).toUpperCase() + entityIdType.slice(1)
  const loadingKey = `${collection}.${byString}.${entityId}`
  const getLoadingStatus = useMemo(
    () => makeGetLoadingStatus(loadingKey),
    [loadingKey]
  )
  const loadingStatus = useSelector(getLoadingStatus)
  const entity =
    useSelector(state => state[collection][byString][entityId]) || {}
  entity.loadingStatus = loadingStatus

  const isLoadingNow = !!loadingCache[loadingKey]
  if (entityId && !loadingStatus && !isLoadingNow) {
    loadingCache[loadingKey] = true
  }

  // Populate the entity in redux if entityId is truthy but it hasn't started
  // trying to load yet.
  useEffect(() => {
    const asyncFunc = async () => {
      if (entityId && !loadingStatus && !isLoadingNow) {
        try {
          await dispatch(
            actions[collection]({
              [entityIdType]: entityId,
              key: loadingKey,
              companyId,
              personId
            })
          )
        } finally {
          delete loadingCache[loadingKey]
        }
      }
    }
    asyncFunc()
  }, [
    dispatch,
    entityId,
    loadingKey,
    collection,
    companyId,
    personId,
    loadingStatus,
    isLoadingNow,
    entityIdType
  ])

  return entity
}

export default useEntity
