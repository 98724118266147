import _ from 'lodash'
import fetchTwilioAccessTokens from 'crm/helpers/fetchTwilioAccessTokens'
import { peachApi } from 'core/api'

const fetchOutboundVoiceParams = async params => {
  // Sanitize body
  const sanitizedParams = _.pickBy(params)
  const { contactId, phone } = sanitizedParams

  // The outbound call API requires either just the contactId
  // or the phone value. If both exists, just use contactId.
  if (contactId && phone) delete sanitizedParams.phone

  const response = await peachApi.post({
    url: '/twilio/make-outbound-voice-params',
    body: sanitizedParams
  })
  return response
}

const answerQueuedVoiceCall = async reservation => {
  try {
    const response = await peachApi.post({
      url: '/twilio/answer-queued-call',
      body: { reservation }
    })
    return response
  } catch {
    throw Error('Could not answer queued calls')
  }
}

const initDevice = device => {
  // Docs: https://www.twilio.com/docs/voice/client/javascript/device
  device.on('cancel', (...args) => {
    console.log('Twilio device, cancel event:', args) // eslint-disable-line no-console
  })
  device.on('error', err => {
    if (err?.code === 31208) {
      // "User denied access to microphone, or the web browser did not allow microphone access at this address."
      alert(
        'Your web browser is not configured to allow access to the microphone. Please allow microphone access.'
      )
    }

    if (err?.twilioError?.code === 53405) {
      // Media connection failed
      // This someone generic encounter was encountered by a peach dev (wilcox) and the only
      // way to fix it was to reset microphone permissions for this domain. Whether or not this would
      // ever happen for a real user is unknown.

      // Perhaps we should link to a documen like this??
      // https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=en

      alert(
        'Media connection failed or media permissions not given. Please try removing microphone permissions and adding it again.'
      )

      alert(`${err.twilioError.description}--${err.twilioError.explanation}`)
    }
    console.log('Twilio device, error event:', err) // eslint-disable-line no-console
  })

  device.on('incoming', conn => {
    console.log('Twilio device, incoming voice call:', conn) // eslint-disable-line no-console
    conn.accept()
    console.log('Twilio device, accepted inbound connection') // eslint-disable-line no-console
  })

  device.on('offline', (...args) => {
    console.log('Twilio device, offline event:', args) // eslint-disable-line no-console
  })

  device.on('ready', (...args) => {
    console.log('Twilio device, ready event:', args) // eslint-disable-line no-console
  })
}

const setupTwilioDevice = async device => {
  try {
    const response = await fetchTwilioAccessTokens()
    const { accessToken } = response
    device.setup(accessToken, {
      allowIncomingWhileBusy: false,
      closeProtection: true,
      debug: process.env.REACT_APP_DEBUG_TWILIO_DEVICE,
      enableIceRestart: false // If this is set to true, it death loops forever on restarting
    })
  } catch (e) {
    throw Error(e)
  }
}

const connectWhenReady = ({ device, twilioConnect } = {}) => {
  if (device?.status() === 'ready') {
    device.connect(twilioConnect)
  } else {
    setTimeout(connectWhenReady, 200)
  }
}

const setupTwilioDeviceEventHandlers = device => {
  const makeCall = async () => {
    device.on('disconnect', () => {
      localStorage.setItem('callStatus', 'notActive')
      localStorage.setItem('callIsMuted', 'null')
    })
    device.on('connect', conn => {
      const disableRecordingTimeout = setTimeout(
        () => sessionStorage.setItem('isVoiceRecordingDisabled', 'false'),
        2000
      )
      const endCallOnHangUp = () => {
        /**
         * State variables won't work here, like `callMode`. Instead
         * use localStorage to access the current call status.
         */
        if (localStorage.getItem('callStatus') === 'notActive') {
          conn.disconnect()
          clearTimeout(disableRecordingTimeout)
        } else {
          setTimeout(endCallOnHangUp, 200)
        }
      }
      const muteAndUnmuteCall = () => {
        if (localStorage.getItem('callIsMuted') !== 'null') {
          if (localStorage.getItem('callIsMuted') === 'true') {
            conn.mute(true)
            setTimeout(muteAndUnmuteCall, 200)
          } else {
            conn.mute(false)
            setTimeout(muteAndUnmuteCall, 200)
          }
        }
      }
      endCallOnHangUp()
      muteAndUnmuteCall()
    })
  }
  makeCall()
}

export {
  answerQueuedVoiceCall,
  connectWhenReady,
  fetchOutboundVoiceParams,
  initDevice,
  setupTwilioDevice,
  setupTwilioDeviceEventHandlers
}
