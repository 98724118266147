import { useState, useLayoutEffect, useMemo } from 'react'

const useDebounceValue = (value, wait = 300) => {
  const [val, setVal] = useState(value)

  useLayoutEffect(() => {
    const onTimeout = () => setVal(value)
    const id = setTimeout(onTimeout, wait)
    return () => clearTimeout(id)
  }, [value]) // eslint-disable-line

  return useMemo(() => [val, val !== value], [val, value])
}

export default useDebounceValue
