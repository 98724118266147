import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { ErrorBoundary } from 'core/components'
import ErrorLogging from 'core/system/ErrorLogging'

const SingleError = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 24px;
  padding: 16px;
`
const Label = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
`
const Button = styled.button`
  padding: 4px 8px;
`

const Bomb = ({ name }) => {
  throw new Error(name)
}

const Errors = () => {
  const [showErrorOne, setShowErrorOne] = useState(false)
  const [showErrorTwo, setShowErrorTwo] = useState(false)
  return (
    <>
      <SingleError>
        <Label>Error wrapped in error boundary</Label>
        <ErrorBoundary onReset={() => setShowErrorOne(false)}>
          <Button onClick={() => setShowErrorOne(true)}>
            Mount error component
          </Button>
          {showErrorOne ? <Bomb name='Bomb one' /> : null}
        </ErrorBoundary>
      </SingleError>

      <SingleError>
        <Label>Error not wrapped in error boundary</Label>
        <Button onClick={() => setShowErrorTwo(true)}>
          Mount error component
        </Button>
        {showErrorTwo ? <Bomb name='Bomb two' /> : null}
      </SingleError>

      <SingleError>
        <Label>Click handler error</Label>
        <Button
          onClick={() => {
            throw new Error('Invoked error')
          }}
        >
          Click for error
        </Button>
      </SingleError>

      <SingleError>
        <Label>Manually captured error</Label>
        <Button
          onClick={() => {
            const error = new Error('Manual error')
            ErrorLogging.captureException(error)
          }}
        >
          Click to capture exception
        </Button>
      </SingleError>

      <SingleError>
        <Label>Manually captured message</Label>
        <Button
          onClick={() => {
            ErrorLogging.captureMessage('Manually captured message')
          }}
        >
          Click to capture message
        </Button>
      </SingleError>
    </>
  )
}

export default Errors
