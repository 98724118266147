import cloneDeep from 'lodash/cloneDeep'

import {
  GET_WORKFLOW,
  UPDATE_STEP,
  UPDATE_WORK_ITEM
} from 'core/actions/constants'

const initialState = {
  byId: {},
  byCaseId: {},
  allIds: []
}

const workflows = (state = initialState, action) => {
  let newWorkflow

  switch (action.type) {
    case GET_WORKFLOW:
      const byId = {
        ...state.byId,
        [action.id]: {
          ...action.payload,
          caseId: action.caseId,
          personId: action.personId,
          companyId: action.companyId
        }
      }

      return {
        ...state,
        byId,
        byCaseId: {
          ...state.byCaseId,
          [action.caseId]: {
            ...action.payload,
            caseId: action.caseId,
            personId: action.personId,
            companyId: action.companyId
          }
        },
        allIds: Object.keys(byId)
      }
    case UPDATE_STEP:
      newWorkflow = cloneDeep(state.byCaseId[action.caseId])
      newWorkflow.steps = newWorkflow.steps.map(s => {
        if (s.id !== action.stepId) {
          return s
        }
        return {
          ...s,
          ...action.payload
        }
      })

      return {
        ...state,
        byId: {
          ...state.byId,
          [newWorkflow.id]: newWorkflow
        },
        byCaseId: {
          ...state.byCaseId,
          [action.caseId]: newWorkflow
        }
      }
    case UPDATE_WORK_ITEM:
      newWorkflow = cloneDeep(state.byCaseId[action.caseId])
      const step = newWorkflow.steps.find(s => s.id === action.stepId)
      step.workItems = step.workItems.map(wi => {
        if (wi.id !== action.workItemId) {
          return wi
        }
        return {
          ...wi,
          ...action.payload
        }
      })

      return {
        ...state,
        byId: {
          ...state.byId,
          [newWorkflow.id]: newWorkflow
        },
        byCaseId: {
          ...state.byCaseId,
          [action.caseId]: newWorkflow
        }
      }
    default:
      return state
  }
}

export default workflows
