import _ from 'lodash'
/** After making a change to the list of interaction details, like opening a new one
 * or changing the mode of an existing one, we should always run the new list through
 * this function, which makes sure the horizontal space is filled correctly by minimizing,
 * hiding, or opening other interactions. Returns the reconciled list.
 *
 * list: The list after the direct change but before being reconciled.
 *
 * changedIndex: Optional. Index of the interaction that was directly changed. This
 * interaction will be ignored when making reconciling changes.
 */
const reconcileResourceList = (list, changedIndex) => {
  const fixedList = [...list]
  let hiddenCount = fixedList.filter(item => item.mode === 'hidden').length
  let openCount = fixedList.filter(item => item.mode === 'open').length
  // minimizedCount here actually would change like the other values as the list is
  // modified, but it turns out we don't need to use it to check anything, so I don't
  // update it. I just use it to calculate slotsTaken, which is checked and tracked.
  const minimizedCount = fixedList.filter(item => item.mode === 'minimized')
    .length
  // The basic idea is that minimized and open interactions take up width. We have
  // designated in our design to have up to 5 slots filled across the screen, where
  // a minimized interaction takes up one slot and an open interaction takes up two.
  // If we have too many slots taken up, we need to minimize or hide some
  // interactions. If we have too few, we need to unhide any hidden ones until the
  // slots are filled up or we run out of hidden ones.
  let slotsTaken = minimizedCount + 2 * openCount

  while (slotsTaken > 5) {
    if (openCount > 2) {
      const indexToMinimize = fixedList.findIndex(
        (item, i) => item.mode === 'open' && i !== changedIndex
      )
      fixedList[indexToMinimize].mode = 'minimized'
      slotsTaken -= 1
      openCount -= 1
    } else {
      const indexToHide = fixedList.findIndex(
        (item, i) => item.mode === 'minimized' && i !== changedIndex
      )
      fixedList[indexToHide].mode = 'hidden'
      slotsTaken -= 1
      hiddenCount += 1
    }
  }

  while (slotsTaken < 5 && hiddenCount > 0) {
    const indexToShow = _.findLastIndex(
      fixedList,
      (item, i) => item.mode === 'hidden' && i !== changedIndex
    )
    fixedList[indexToShow].mode = 'minimized'
    slotsTaken += 1
    hiddenCount -= 1
  }

  return fixedList
}

const _setMode = ({ id, mode, type, prevResourceList }) => {
  const index = prevResourceList.findIndex(
    item => item.id === id && item.type === type
  )
  const resource = prevResourceList[index]
  if (resource.mode === mode) {
    return prevResourceList
  }
  const newResourceList = [...prevResourceList]
  const newItem = {
    ...resource,
    mode
  }
  newResourceList[index] = newItem
  return reconcileResourceList(newResourceList, index)
}

const existingDraftInteractions = reconcileResourceList(
  /**
   * Check if user is logged in before adding resource.
   * Otherwise the page will infinitely route back to login
   * due to the draft resource automatically opening and the
   * application recognizing that the user is not logged in.
   */
  localStorage.getItem('secretToken') !== null
    ? _.map(
        _.keys(JSON.parse(localStorage.getItem('draftInteractionsById'))),
        k => ({
          id: k,
          type: 'draftInteraction',
          mode: 'open',
          onClose: () => localStorage.removeItem(k)
        })
      )
    : []
)

export { existingDraftInteractions, reconcileResourceList, _setMode }
