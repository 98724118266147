import { GET_LOAN_TYPES } from 'core/actions/constants'

const initialState = {
  byId: {},
  allIds: []
}

const loanTypes = function(state = initialState, action) {
  switch (action.type) {
    case GET_LOAN_TYPES:
      return {
        ...state,
        byId: {
          // keep all existing loanTypes for other personIds
          ...Object.values(state.byId).reduce((acc, curr) => {
            if (curr.personId !== action.personId) {
              acc[curr.id] = curr
            }
            return acc
          }, {}),
          // insert the fresh loanTypes for this personId
          ...action.payload.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.id]: {
                ...curr
              }
            }),
            {}
          )
        }
      }
    default:
      return state
  }
}

export default loanTypes
