// Third party --------------
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// Rest ---------------------
import { variables } from 'core/styles'

// Styled components --------
const Container = styled.div`
  display: flex;
  justify-content: ${p => (p.isInbound ? 'flex-start' : 'flex-end')};
  margin-bottom: 16px;
`
const InnerContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  max-width: 80%;
`
const Bubble = styled.div`
  box-sizing: border-box;
  border-color: ${p =>
    p.isInbound ? variables.colorViolet : variables.colorBlack90};
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 8px;
  padding: 16px;
  width: auto;
  color: ${variables.colorBlack60};
`
const SentBy = styled.div`
  display: flex;
  justify-content: ${p => (p.isInbound ? 'flex-start' : 'flex-end')};
  box-sizing: border-box;
  margin-left: 8px;
  padding-right: 16px;
  width: 100%;
  color: ${variables.colorBlack50};
  font-size: 10px;
  line-height: 24px;
`

const MessageBubble = ({ author, direction, content, timestamp }) => {
  const isInbound = direction === 'inbound'
  const isOutbound = direction === 'outbound'
  return (
    <Container isInbound={isInbound}>
      <InnerContainer>
        <Bubble isInbound={isInbound}>{content}</Bubble>
        <SentBy data-testid='sent-by-text' isInbound={isInbound}>
          {isOutbound ? (author ? `${author} ` : '') : ''}
          {timestamp}
        </SentBy>
      </InnerContainer>
    </Container>
  )
}

MessageBubble.propTypes = {
  author: PropTypes.string,
  direction: PropTypes.string,
  content: PropTypes.string,
  timestamp: PropTypes.string
}

export { Bubble, Container, SentBy }
export default MessageBubble
