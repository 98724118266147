import forEach from 'lodash/forEach'

const sanitizeContentHtml = contentHtml => {
  const parser = new DOMParser()
  const dom = parser.parseFromString(contentHtml, 'text/html')
  forEach(dom.getElementsByClassName('preheader'), el => el.remove())
  return dom.body.outerHTML
}

export default sanitizeContentHtml
