// Third party --------------
import PropTypes from 'prop-types'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'

// Rest ---------------------
import getTimeFromSeconds from 'core/helpers/getTimeFromSeconds'
import { CallModeContext, ACTIVE } from 'contexts/CallModeContext'
import { DraftContext } from 'contexts/DraftContext'
import { OpenResourceContext } from 'contexts/OpenResourceContext'
import { useNow, useInterval } from 'core/hooks'
import { useStoredState } from 'admin/hooks'
import { useTime } from 'core/time'

const TelephonyContext = createContext({})

const TelephonyContextProvider = ({ children }) => {
  const { onClose, openResource, resourceList } =
    useContext(OpenResourceContext)

  const {
    callMode,
    isActiveCall,
    setCallModeActive,
    setCallModePostDial,
    setCallModePredial
  } = useContext(CallModeContext)

  // Draft context to prevent users from accidentally
  // refreshing the page during an active call
  const { addDraft, removeDraft } = useContext(DraftContext)

  const { difference, getNow } = useTime()
  const now = useNow(500)
  const { pathname } = useLocation()
  const isInCRMPath = pathname.includes('/crm')
  // This value is used to determine whether the Telephony
  // widget was opened if a draftVoiceInteraction exists.
  // We only want to open the widget once on app load.
  const hasSetCallModePostDial = useRef(false)

  const [draftVoiceInteraction, setDraftVoiceInteraction] = useStoredState(
    'draftVoiceInteraction'
  )

  const {
    callDuration: callDurationFromDraftVoiceInteraction,
    hasBeenRecorded: haBeenRecordedFromDraftVoiceInteraction
  } = draftVoiceInteraction || {}

  const [callDuration, setCallDuration] = useState(
    callDurationFromDraftVoiceInteraction || '00:00:00'
  )
  const [callStartTime, setCallStartTime] = useState(null)
  const [contactId, setContactId] = useState(null)
  const [delay, setDelay] = useState(null) // Used to track setInterval of call time
  const [hasBeenRecorded, setHasBeenRecorded] = useState(
    !!haBeenRecordedFromDraftVoiceInteraction || false
  )
  const [hasSavedCallOutcome, setHasSavedCallOutcome] = useState(false)
  const [micOn, setMicOn] = useState(true)
  const [personId, setPersonId] = useState(undefined)
  const [recording, setRecording] = useState(false)

  const callDurationInSeconds = difference(now, callStartTime, 'seconds')
  const { hours, minutes, seconds } = getTimeFromSeconds(
    callDurationInSeconds,
    { addPadding: true }
  )

  const isTelephonyOpen = useMemo(() => {
    return !!_.filter(resourceList, resource => resource.type === 'telephony')
      .length
  }, [resourceList])

  // TODO: (Satoru) consider making these changes
  // https://github.com/peachfinance/peach-front/pull/1163#discussion_r608077041
  useEffect(() => {
    if (callMode === ACTIVE) {
      setCallDuration(`${hours}:${minutes}:${seconds}`)
    }
  }, [callDuration, callMode, hours, minutes, seconds])

  // Mute/Unmute microphone
  useEffect(() => {
    localStorage.setItem('callIsMuted', `${!micOn}`)
  }, [micOn])

  // Checks if call was ended by non-agent side
  useInterval(() => {
    if (
      callMode === ACTIVE &&
      localStorage.getItem('callStatus') === 'notActive'
    ) {
      setCallModePostDial()
    }
  }, {})

  const resetTelephonyContext = useCallback(
    ({ keepContactValue = false } = {}) => {
      if (!keepContactValue) setContactId(null)
      setCallDuration('00:00:00')
      setHasBeenRecorded(false)
      setMicOn(true)
      setRecording(false)
      setHasSavedCallOutcome(false)
      setCallModePredial()
      localStorage.setItem('isVoiceRecordingDisabled', 'true')
    },
    [setCallModePredial]
  )

  const onEndCall = useCallback(() => {
    /**
     * State variables won't work here, like `callMode`. Instead
     * use localStorage to set the current call status.
     */
    localStorage.setItem('callStatus', 'notActive')
    localStorage.setItem('callIsMuted', 'null')

    setDelay(null)
    setCallModePostDial()
  }, [setCallModePostDial])

  const setupActiveCallState = useCallback(() => {
    // Add new draft state
    addDraft('OnActiveCall', {
      ignoreAppRouteChange: true
    })

    /**
     * State variables won't work here, like `callMode`. Instead
     * use localStorage to set the current call status.
     */
    localStorage.setItem('callStatus', 'active')

    // Set all other states
    setCallDuration('00:00:00')
    setCallModeActive()
    setCallStartTime(getNow())
    setDelay(1000)
  }, [addDraft, getNow, setCallModeActive])

  const onOpenTelephony = useCallback(
    ({ personId: passedPersonId, options }) => {
      openResource({
        /**
         * Use the borrowerId as the unique id for the telephony resource.
         * This way, when an agent switches users and clicks on the phone
         * icon to call another borrower, this resource is actually switched
         * to a new one and that the widget will in fact re-render.
         */
        id: `telephony-${passedPersonId || personId}`,
        type: 'telephony',
        borrowerId: passedPersonId || personId,
        options
      })
    },
    [openResource, personId]
  )

  /**
   * New calls and redials are essentially the same thing.
   * In both cases we want to close Telephony, reset the
   * call context, and pass in the options we need to get
   * the new call/redial state to initialize correctly.
   * The `options` object param is the key to setting up
   * the correct state for the new Telephony instance.
   */
  const _onNewCallOrRedial = useCallback(
    ({ options }) => {
      onClose(`telephony-${personId}`, 'telephony')
      resetTelephonyContext()
      /**
       * This setTimeout() ensures that Telephony is opened
       * only after it has been fully closed. Otherwise
       * the local states in the Telephony component will
       * remain as it is reopened.
       */
      setTimeout(() => onOpenTelephony({ options }), 100)
    },
    [onClose, onOpenTelephony, personId, resetTelephonyContext]
  )

  const onRedial = useCallback(
    ({ options }) => {
      _onNewCallOrRedial({ personId, options })
    },
    [_onNewCallOrRedial, personId]
  )

  const onNewCall = useCallback(
    ({ options } = {}) => {
      _onNewCallOrRedial({ personId, options })
    },
    [_onNewCallOrRedial, personId]
  )

  const onCloseTelephony = useCallback(() => {
    onClose(`telephony-${personId}`, 'telephony')
  }, [onClose, personId])

  const removeActiveCallDraft = useCallback(() => {
    removeDraft('OnActiveCall')
  }, [removeDraft])

  const shouldSetCallModePostDial =
    !isActiveCall &&
    draftVoiceInteraction &&
    !hasSetCallModePostDial.current &&
    isInCRMPath

  useEffect(() => {
    /**
     * This will ensure that the Telephony component is
     * set to call mode of post dial if there is a
     * draftVoiceInteraction stored in localStorage
     */
    if (shouldSetCallModePostDial) {
      hasSetCallModePostDial.current = true
      setCallModePostDial()
    }
  }, [shouldSetCallModePostDial, hasSetCallModePostDial, setCallModePostDial])

  const value = useMemo(() => {
    return {
      callDuration,
      callStartTime,
      setCallStartTime,
      contactId,
      setContactId,
      delay,
      setDelay,
      hasBeenRecorded,
      setHasBeenRecorded,
      hasSavedCallOutcome,
      setHasSavedCallOutcome,
      micOn,
      setMicOn,
      personId,
      setPersonId,
      recording,
      setRecording,
      resetTelephonyContext,
      onEndCall,
      setupActiveCallState,
      removeActiveCallDraft,
      draftVoiceInteraction,
      setDraftVoiceInteraction,
      onRedial,
      onNewCall,
      isTelephonyOpen,
      onOpenTelephony,
      onCloseTelephony
    }
  }, [
    callDuration,
    callStartTime,
    setCallStartTime,
    contactId,
    setContactId,
    delay,
    setDelay,
    hasBeenRecorded,
    setHasBeenRecorded,
    hasSavedCallOutcome,
    setHasSavedCallOutcome,
    micOn,
    setMicOn,
    personId,
    setPersonId,
    recording,
    setRecording,
    resetTelephonyContext,
    onEndCall,
    setupActiveCallState,
    removeActiveCallDraft,
    draftVoiceInteraction,
    setDraftVoiceInteraction,
    onRedial,
    onNewCall,
    isTelephonyOpen,
    onOpenTelephony,
    onCloseTelephony
  ])

  return (
    <TelephonyContext.Provider value={value}>
      {children}
    </TelephonyContext.Provider>
  )
}

TelephonyContextProvider.propTypes = {
  children: PropTypes.node
}

export { TelephonyContext }
export default TelephonyContextProvider
