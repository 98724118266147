import React, { lazy, Suspense } from 'react'
import styled from 'styled-components/macro'
import { Spinner } from 'core/components'

const LazyAdminApp = lazy(() => import('admin/AdminApp'))

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

const fallback = (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
)

const AdminRoutes = () => {
  return (
    <Suspense fallback={fallback}>
      <LazyAdminApp />
    </Suspense>
  )
}

export default AdminRoutes
