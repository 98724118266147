// Third party --------------
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { useDispatch } from 'react-redux'
import { useMemo, useState } from 'react'

// Components ---------------
import Case from '../Case'
import Conversation from '../Conversation'
import Reason from '../Reason'
import To from '../To'
import TopBar from '../TopBar'
import InteractionModalContent, {
  DIALOG_LOCK,
  DIALOG_UNAUTHORIZED
} from '../InteractionModalContent'
import { Dialog, Modal, Spinner } from 'core/components'

// Rest ---------------------
import addAssociation from 'core/actions/cases/addAssociation'
import deleteAssociation from 'core/actions/cases/deleteAssociation'
import getFullName from 'core/helpers/getFullName'
import updateInteraction from 'core/actions/interactions/updateInteraction'
import { INTERACTION, RELATED } from 'core/actions/constants'
import { formatSelectOption } from '../helpers'
import {
  useCases,
  useContact,
  useHasPermission,
  useInteraction,
  useWrite
} from 'core/hooks'
import {
  useCaseAssociationsByInteractionId,
  useCasesOptions,
  useFormattedTextConversation,
  useFormattedToValue
} from '../hooks'
import { useTime } from 'core/time'
import { variables } from 'core/styles'

// Styled components --------
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  /**
   * We're subtracting the height of the <TopBar /> component,
   * which is 50px, from the total height of the <Resource />
   * component.
   */
  height: calc(100% - 50px);
`
const ScrollableArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  background-color: white;
`
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${variables.overlay};
`

const PastTextInteraction = ({
  borrower,
  id: interactionId,
  maximized,
  mode,
  onClose,
  setMode
}) => {
  const { useDispatch, useTime } = PastTextInteraction.dependencies
  const dispatch = useDispatch()
  const { formatRelative } = useTime()

  // Permissions
  const canReadSensitive = useHasPermission('interaction:read.sensitive')

  const [
    showCannotUpdateInteractionModal,
    setShowCannotUpdateInteractionModal
  ] = useState(false)
  const [showLockModal, setShowLockModal] = useState(false)
  const [dialogToShow, setDialogToShow] = useState(null)

  // Borrower
  const borrowerName = getFullName(borrower)
  const { id: borrowerId } = borrower

  // Interaction
  const interaction = useInteraction(interactionId)
  const {
    contactId,
    content,
    createdAt,
    endedAt,
    direction,
    sensitive,
    sensitiveBorrowerOnly,
    status,
    theme,
    updatedAt
  } = interaction || {}
  const { body, messages } = content || {}
  const isOutbound = direction === 'outbound'
  const isSensitiveBorrowerOnly =
    sensitiveBorrowerOnly || theme === 'opsAccountCredentials'
  const isSensitive = sensitive && !canReadSensitive
  const isInProgress = status === 'inProgress'
  const isConversationActive = isInProgress && !endedAt

  // Contact
  const contact = useContact(contactId)
  const isContactLoading = !contact?.id
  const to = useFormattedToValue({ contact })

  // Conversation
  const isTransactionalInteraction = useMemo(() => {
    const hasSingleStringBody = typeof body === 'string'
    return hasSingleStringBody && isOutbound
  }, [body, isOutbound])

  const createdAtRelativeTime = isTransactionalInteraction
    ? null
    : formatRelative(createdAt)
  const updatedAtRelativeTime =
    isTransactionalInteraction || isConversationActive
      ? null
      : formatRelative(updatedAt)

  const formattedTextConversation = useFormattedTextConversation({
    interaction,
    isTransactionalInteraction,
    messages
  })

  const showReason = !!theme && isTransactionalInteraction

  // TopBar
  const topBarLeftText = formattedTextConversation[0]?.content || ''
  const gridTemplateColumns =
    mode === 'minimized'
      ? '32px 152px 104px'
      : mode === 'maximized'
      ? '320px auto 320px'
      : '200px auto 200px'

  // Case stuff
  const casesCollection = useCases({
    personId: interaction?.personId
  }).byId
  const cases = useMemo(() => {
    return _.orderBy(_.values(casesCollection), ['createdAt'], ['desc'])
  }, [casesCollection])

  // Cases options
  const casesOptions = useCasesOptions({ cases })

  // Associated cases
  const associations = useCaseAssociationsByInteractionId({
    cases,
    interactionId
  })
  const associatedCases = _.map(associations, c => formatSelectOption(c))

  // Methods
  const [onCaseSelect, isUpdatingCaseSelect] = useWrite(async option => {
    if (option?.length > associatedCases.length) {
      const caseId = option[option.length - 1].value
      return dispatch(
        addAssociation({
          key: `AddAssociation-${caseId}`,
          filters: {
            personId: borrowerId,
            caseId
          },
          body: {
            objectType: INTERACTION,
            objectId: interactionId,
            relation: RELATED
          }
        })
      )
    } else {
      let caseId
      const oldIds = associatedCases.map(_case => _case.value)
      const newIds = option?.map(o => o.value) || []
      oldIds.forEach(id => {
        if (!newIds.includes(id)) {
          caseId = id
        }
      })
      return dispatch(
        deleteAssociation({
          key: `DeleteAssociation-${caseId}`,
          personId: borrowerId,
          caseId,
          query: `objectType=${INTERACTION}&objectId=${interactionId}`,
          objectId: interactionId
        })
      )
    }
  })

  const [onUpdateInteraction, isUpdatingInteraction] = useWrite(async body => {
    return dispatch(
      updateInteraction({
        body,
        id: interactionId,
        key: `UpdateInteraction-${interactionId}`,
        personId: borrowerId
      })
    )
  })

  const onClickLock = () => {
    if (isConversationActive) {
      setShowCannotUpdateInteractionModal(true)
    } else if (sensitiveBorrowerOnly) {
      setDialogToShow(DIALOG_UNAUTHORIZED)
      setShowLockModal(true)
    } else if (sensitive && canReadSensitive) {
      onUpdateInteraction({ sensitive: false })
    } else if (sensitive) {
      setDialogToShow(DIALOG_UNAUTHORIZED)
      setShowLockModal(true)
    } else {
      setDialogToShow(DIALOG_LOCK)
      setShowLockModal(true)
    }
  }

  const onClickConfirmLock = () => {
    onUpdateInteraction({ sensitive: true })
    setShowLockModal(false)
  }

  const onCaseClick = () => {
    isConversationActive && setShowCannotUpdateInteractionModal(true)
  }

  const showSpinner =
    isContactLoading || isUpdatingCaseSelect || isUpdatingInteraction

  return (
    <>
      <TopBar
        centerText={borrowerName}
        gridTemplateColumns={gridTemplateColumns}
        iconName='mobile_screen_share'
        id={interactionId}
        leftText={topBarLeftText}
        mode={mode}
        onClose={onClose}
        setMode={setMode}
        type='pastTextInteraction'
      />
      <MainContainer>
        <To
          borrowerId={borrowerId}
          contactInfo={to}
          interaction={interaction}
          maximized={maximized}
          onClickLock={onClickLock}
          readOnly
          showLockIcon
        />
        <Case
          isControlled
          isDisabled={isConversationActive}
          maxMenuHeight={120}
          maximized={maximized}
          multiSelect
          onChange={onCaseSelect}
          onClick={onCaseClick}
          options={casesOptions}
          value={associatedCases}
        />
        {showReason && <Reason maximized={maximized} theme={theme} readOnly />}
        <ScrollableArea>
          <Conversation
            conversation={formattedTextConversation}
            startedAt={createdAtRelativeTime}
            endedAt={updatedAtRelativeTime}
            isSensitive={isSensitive}
            isSensitiveBorrowerOnly={isSensitiveBorrowerOnly}
          />
        </ScrollableArea>
        {showSpinner && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
      </MainContainer>

      {showLockModal && (
        <Modal onClose={() => setShowLockModal(false)} width={476}>
          <InteractionModalContent
            dialogToShow={dialogToShow}
            onLockInteraction={onClickConfirmLock}
            onCancel={() => setShowLockModal(false)}
          />
        </Modal>
      )}

      {showCannotUpdateInteractionModal && (
        <Modal
          onClose={() => setShowCannotUpdateInteractionModal(false)}
          width={476}
        >
          <Dialog
            title='Cannot update interaction'
            confirmLabel='Ok'
            onConfirm={() => setShowCannotUpdateInteractionModal(false)}
          >
            Details on a conversation interaction cannot be modified while it is
            in progress.
          </Dialog>
        </Modal>
      )}
    </>
  )
}

PastTextInteraction.propTypes = {
  borrower: PropTypes.object,
  id: PropTypes.string,
  maximized: PropTypes.bool,
  mode: PropTypes.string,
  onClose: PropTypes.func,
  setMode: PropTypes.func
}

PastTextInteraction.dependencies = {
  useDispatch,
  useTime
}

export default PastTextInteraction
