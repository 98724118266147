import bootstrap from './bootstrap'
import borrowerLoading from './borrowerLoading'
import canInteract from './canInteract'
import caseTypes from './caseTypes'
import cases from './cases'
import communicator from './communicator'
import companies from './companies'
import consents from './consents'
import contacts from './contacts'
import doNotInteracts from './doNotInteracts'
import documents from './documents'
import draftPopulations from './draftPopulations'
import employee from './employee'
import employees from './employees'
import globalNotes from './globalNotes'
import interactions from './interactions'
import legalRepresentatives from './legalRepresentatives'
import loading from './loading'
import loanTypes from './loanTypes'
import loans from './loans'
import localNotes from './localNotes'
import notes from './notes'
import people from './people'
import permissions from './permissions'
import populations from './populations'
import supercases from './supercases'
import teams from './teams'
import verifications from './verifications'
import workflows from './workflows'
import { combineReducers } from 'redux'

export default combineReducers({
  bootstrap,
  borrowerLoading,
  canInteract,
  caseTypes,
  cases,
  communicator,
  companies,
  consents,
  contacts,
  doNotInteracts,
  documents,
  draftPopulations,
  employee,
  employees,
  globalNotes,
  interactions,
  legalRepresentatives,
  loading,
  loanTypes,
  loans,
  localNotes,
  notes,
  people,
  permissions,
  populations,
  supercases,
  teams,
  verifications,
  workflows
})
