// Third party --------------
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'

// Components ---------------
import Attachment from 'components/ResourceOverlay/InteractionBottom/Attachment'
import { Button, Dialog, Modal } from 'core/components'

// Styled components --------
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 16px 0 8px;
  margin-top: auto;
`
const Attachments = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  flex: 1 0 auto;
`
const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 128px;
`

const InteractionBottom = ({
  attachments,
  channel,
  hideResendButton,
  openAttachment,
  onReply,
  onResend
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const onResendConfirm = () => {
    onResend()
    setShowConfirmModal(false)
  }

  return (
    <>
      <Container>
        <Attachments>
          {attachments.map(attachment => {
            return (
              <Attachment
                attachment={attachment}
                key={_.uniqueId('attachment-')}
                openAttachment={openAttachment}
              />
            )
          })}
        </Attachments>
        <ActionButtons>
          {channel === 'email' && (
            <>
              {!hideResendButton && (
                <Button
                  margin='8px 0 16px 0'
                  secondary
                  onClick={() => setShowConfirmModal(true)}
                >
                  Resend
                </Button>
              )}
              <Button
                margin='8px 0 16px 36px'
                secondary
                onClick={() => onReply(channel)}
              >
                Reply
              </Button>
            </>
          )}
        </ActionButtons>
      </Container>
      {showConfirmModal && (
        <Modal onClose={() => setShowConfirmModal(false)} width={476}>
          <Dialog
            title='Are you sure you want to resend?'
            confirmLabel='Confirm'
            onConfirm={onResendConfirm}
            cancelLabel='Back'
            onCancel={() => setShowConfirmModal(false)}
          />
        </Modal>
      )}
    </>
  )
}

InteractionBottom.propTypes = {
  attachments: PropTypes.array,
  channel: PropTypes.string,
  hideResendButton: PropTypes.bool,
  openAttachment: PropTypes.func,
  onReply: PropTypes.func,
  onResend: PropTypes.func
}

export default InteractionBottom
