import {
  APIendpoint,
  GET_PEOPLE,
  LOADING,
  getStoredToken
} from 'core/actions/constants'
import { GET } from 'core/api/legacyApi'
import { handleErrors } from 'core/helpers/handleErrors'

const getPeople = ({ key, filters = {} } = { filters: {} }) => {
  const { companyId, status } = filters
  if (!companyId)
    throw new Error('company id must be provided in getPeople action')

  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    let query = `?companyId=${companyId}`
    if (status) {
      query = query.concat(`&status=${status}`)
    }

    const endpoint = `${APIendpoint}/people${query}`
    const response = await fetch(endpoint, GET(getStoredToken()))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    if (isSuccess) {
      dispatch({
        type: GET_PEOPLE,
        payload: responseBody.data,
        companyId
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error'
      })
    }
  }
}

export default getPeople
