import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import BasicPasswordLogin from './Basic/BasicPasswordLogin'
import CodeLogin from './CodeLogin'
import SamlLogin from './SAML/SamlLogin'
import { loginWithPassword } from './loginApi'
import { useOnMount } from 'core/hooks'
import {
  getSessionExpired,
  getStoredToken,
  decodeToken,
  setSessionExpired,
  setStoredToken,
  isTokenExpired,
  isTokenValid,
  setSessionExpiredIfRecent
} from 'core/actions/helpers/tokenHelpers'

import { useLoginInfo } from './LoginWrapper'
import LoginErrorMessage from './LoginErrorMessage'
import { redirectToApp } from 'app/loginRedirect/loginRedirectHelpers'
import { Toast } from 'core/components'
import variables from 'core/styles/variables'
import styled from 'styled-components/macro'

const ToastWrapper = styled.div`
  max-width: 560px;
  margin: 0 auto 18px;
`

const storeValue = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const storeAuthData = (token, username) => {
  const { personId, userType } = decodeToken(token)
  setStoredToken(token)
  storeValue('personIdFromToken', personId)
  localStorage.setItem('userTypeFromToken', userType)
  if (username) {
    localStorage.setItem('username', username)
  } else {
    localStorage.removeItem('username')
  }
}

const LoginManager = () => {
  const { userType, companyId, authType } = useLoginInfo()
  const [showSessionExpired, setShowSessionExpired] = useState(false)

  const dispatch = useDispatch()

  const onSuccess = ({ token, username }) => {
    storeAuthData(token, username)
    dispatch({ type: 'GENERATE_TOKEN_SUCCESS' })
    redirectToApp()
  }

  const onLogin = async ({ username, password }) => {
    const loginOptions = { username, password, companyId, userType }
    const resp = await loginWithPassword(loginOptions)
    const { token } = resp
    onSuccess({ token, username })
    return resp
  }

  const isBasic = authType === 'basic'
  const isCode =
    authType === 'oneTimeCodeText' || authType === 'oneTimeCodeEmail'

  const isSaml = authType === 'SAML'

  const isValidAuthType = isBasic || isCode || isSaml

  useOnMount(() => {
    // Token. Remove token from localStorage if expired
    if (isValidAuthType) {
      const token = getStoredToken()
      if (token && isTokenExpired(token)) {
        setSessionExpiredIfRecent()
        setStoredToken('')
      } else if (isTokenValid(token)) {
        onSuccess({ token })
      }
    }
    // show session expiry message only once and remove flag from storage
    if (getSessionExpired()) {
      setSessionExpired(false)
      setShowSessionExpired(true)
    }
  })

  if (isValidAuthType) {
    return (
      <>
        {showSessionExpired && (
          <ToastWrapper>
            <Toast
              icon='hourglass_empty'
              iconFill={variables.colorBlack80}
              header='Session Timed Out'
              description='Your session timed out please log in again to access your account.'
              bgColor={variables.colorYellowLighten}
              margin='0'
            />
          </ToastWrapper>
        )}
        {isBasic && <BasicPasswordLogin onLogin={onLogin} />}
        {isCode && <CodeLogin onLogin={onLogin} />}
        {isSaml && <SamlLogin onLogin={onLogin} />}
      </>
    )
  }

  return <LoginErrorMessage>Unsupported Auth Type</LoginErrorMessage>
}

export default LoginManager
