import React, { Component, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import ErrorLogging from 'core/system/ErrorLogging'

import DevelopmentErrorMessage from './DevelopmentErrorMessage'
import ContactSupportErrorMessage from './ContactSupportErrorMessage'

const ErrorResetContext = createContext(() => {
  console.warn('No Error boundary found above!') // eslint-disable-line no-console
})

const isTrue = value => value === true || value === 'true'

const showContactSupport = isTrue(
  process.env.REACT_APP_SHOW_CONTACT_SUPPORT_ON_RENDER_ERROR
)

const DefaultErrorMessage = showContactSupport
  ? ContactSupportErrorMessage
  : DevelopmentErrorMessage

class ErrorBoundary extends Component {
  state = { hasError: false, error: undefined, componentStack: undefined }

  componentDidCatch(error, info) {
    const { componentStack } = info || {}

    if (process.env.NODE_ENV !== 'development') {
      ErrorLogging.captureException(error, {
        contexts: { react: { componentStack } }
      })
    }

    this.setState({ hasError: true, error, componentStack })
  }

  reset = () => {
    this.setState({ hasError: false, error: undefined })
  }

  render() {
    const { hasError, error, componentStack } = this.state
    const {
      fallback,
      renderFallback,
      FallbackComponent,
      onReset,
      children
    } = this.props
    const { reset } = this

    if (!hasError) {
      return (
        <ErrorResetContext.Provider value={reset}>
          {children}
        </ErrorResetContext.Provider>
      )
    }

    const onHandleReset = () => {
      if (onReset) onReset()
      reset()
    }

    const renderProps = {
      error,
      reset: onHandleReset,
      componentStack
    }

    if (fallback) {
      return fallback
    } else if (renderFallback) {
      return renderFallback(renderProps)
    } else if (FallbackComponent) {
      return <FallbackComponent {...renderProps} />
    }

    return <DefaultErrorMessage {...renderProps} />
  }
}

ErrorBoundary.propTypes = {
  fallback: PropTypes.node,
  renderFallback: PropTypes.func,
  FallbackComponent: PropTypes.any,
  children: PropTypes.node,
  onReset: PropTypes.func
}

const useErrorReset = () => useContext(ErrorResetContext)

export { useErrorReset }

export default ErrorBoundary
