import { useParams } from 'react-router-dom'

import { useSupercases } from 'core/hooks'

const useSupercase = id => {
  const { useParams } = useSupercase.dependencies

  const { caseId: paramCaseId } = useParams()
  const caseId = id ?? paramCaseId ?? undefined
  const allSupercases = useSupercases()
  const thisSupercase = {
    ...allSupercases.byId[caseId],
    loadingStatus: caseId in allSupercases.byId ? 'success' : 'error'
  }
  return thisSupercase
}

useSupercase.dependencies = {
  useParams
}

export default useSupercase
