/**
 * TODO: IMPORT THE <TOPBAR /> COMPONENT TO HERE AND REPLACE
 * IT WITH THE <TITLEBAR /> STYLED COMPONENT IN THE <RESOURCE />
 * COMPONENT.
 */

// Third party --------------
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { useDispatch } from 'react-redux'

// Rest ---------------------
import addAssociation from 'core/actions/cases/addAssociation'
import deCamelize from 'core/helpers/deCamelize'
import deleteAssociation from 'core/actions/cases/deleteAssociation'
import getFullName from 'core/helpers/getFullName'
import getRecordingContent from 'core/actions/interactions/getRecordingContent'
import getTimeFromSeconds from 'core/helpers/getTimeFromSeconds'
import outcomeOptions from 'core/helpers/getOutcomeOptions'
import updateInteraction from 'core/actions/interactions/updateInteraction'
import updateUnboundInteraction from 'core/actions/interactions/updateUnboundInteraction'
import variables from 'core/styles/variables'
import { INTERACTION, RELATED } from 'core/actions/constants'
import { OpenResourceContext } from 'contexts/OpenResourceContext'
import { SearchedBorrowerContext } from 'contexts/SearchedBorrowerContext'
import { useTime } from 'core/time'
import {
  useBorrower,
  useBorrowerIdFromPathname,
  useCases,
  useCompanyId,
  useContact,
  useHasPermission,
  useInteraction,
  useIsLoading,
  useOnMount
} from 'core/hooks'

// Components ---------------
import Case from '../Case'
import Icon from 'core/components/Icon'
import Outcome from '../Outcome'
import Reason from '../Reason'
import TopPostDialSection from '../Telephony/TopPostDialSection'
import InteractionModalContent, {
  DIALOG_LOCK,
  DIALOG_UNAUTHORIZED
} from '../InteractionModalContent'
import { Button, Dialog, Modal, Spinner } from 'core/components'

// Styles -------------------
const Container = styled.div`
  position: relative;
`
const TopSection = styled.div`
  padding: 24px 16px;
  border-bottom: 1px solid ${variables.colorBlack20};
`
const BottomSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 48px 16px 8px;
`
const StyledButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const LaunchRecordingButton = props => {
  const { blockSensitive, url, children } = props
  return blockSensitive ? (
    <> {children} </>
  ) : (
    <a href={url} target='_blank' rel='noopener noreferrer'>
      {children}
    </a>
  )
}
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${variables.overlay};
`

const CANNOT_ASSOCIATE_BORROWER = 'CANNOT_ASSOCIATE_BORROWER'
const DISASSOCIATE_BORROWER_WARNING = 'DISASSOCIATE_BORROWER_WARNING'

const VoiceInteraction = ({ id, setBorrowerName }) => {
  const dispatch = useDispatch()
  const { openResource } = useContext(OpenResourceContext)
  const { difference } = useTime()
  const companyId = useCompanyId()
  const borrowerIdFromPathname = useBorrowerIdFromPathname()

  // Searched borrower context stuff
  const { searchedBorrower, setSearchedBorrower } = useContext(
    SearchedBorrowerContext
  )

  // Collections ----------------------
  const interaction = useInteraction(id)
  const { contactId, content, personId } = interaction || {}
  const { from } = content || {}

  const borrower = useBorrower(personId)
  const cases = useCases({ personId })

  const contact = useContact(contactId, { personId })
  const { loadingStatus: contactLoadingStatus } = contact || {}

  const isContactLoading = contactLoadingStatus === 'loading'

  // Local state ----------------------
  const [callAudioUrl, setCallAudioUrl] = useState(null)
  const [selectedOutcome, setSelectedOutcome] = useState(null)
  const [selectedTheme, setSelectedTheme] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [
    showChangeBorrowerAssociationModal,
    setShowChangeBorrowerAssociationModal
  ] = useState(false)
  const [dialogToShow, setDialogToShow] = useState(null)

  // ----------------------------------
  // Local variables ------------------
  const isInteractionLoading = useIsLoading(
    `Interactions.byId(id=${interaction?.id})`
  )
  const isLoading = isInteractionLoading

  const { direction } = interaction || {}
  const isInboundCall = direction === 'inbound'

  // Cases options
  const casesOptions = useMemo(() => {
    const filteredCases = _.pickBy(cases.byId, item =>
      ['initiated', 'processing', 'reopened'].includes(item.status)
    )
    const sortedCases = _.orderBy(filteredCases, ['createdAt'], ['desc'])
    return _.map(sortedCases, _case => ({
      label: `${_case.id} ${deCamelize(_case.caseType)}`,
      value: _case.id
    }))
  }, [cases])

  // Time calculations
  // TODO: Make sure we have the correct elapsed time calculation
  const { startedAt, endedAt } = interaction || {}
  const callDurationInSeconds = difference(endedAt, startedAt, 'seconds')
  const timesFromSeconds = getTimeFromSeconds(callDurationInSeconds, {
    addPadding: true
  })
  const { hours, minutes, seconds } = timesFromSeconds
  const elapsedTime = !endedAt ? null : `${hours}:${minutes}:${seconds}`
  const hasVoiceRecording = interaction.recordingStartedAt !== null

  // Permissions
  const canReadSensitive = useHasPermission('interaction:read.sensitive')

  // Interaction Variables
  const isInteractionSensitive = interaction.sensitive
  const blockSensitive = isInteractionSensitive && !canReadSensitive

  // Associated cases
  const _formatSelectedCases = cases => {
    return Object.values(cases).map(_case => ({
      label: `${_case.id} ${deCamelize(_case.caseType)}`,
      value: _case.id
    }))
  }
  const associatedCases = useMemo(() => {
    const allCases = Object.values(cases.byId)
    if (allCases.length === 0) return
    const _cases = _.filter(allCases, ({ associations }) => {
      return _.some(associations, {
        objectId: interaction.id,
        objectType: 'interaction'
      })
    })
    return _formatSelectedCases(_cases)
  }, [cases, interaction])

  // On mount -------------------------
  useEffect(() => {
    const name = getFullName(borrower) || 'unidentified caller'
    const isSelf = _.includes(
      ['self', undefined, 'unknown'],
      contact?.affiliation
    )
    const title =
      personId && isSelf
        ? `Call outcome for ${name}`
        : personId && !isSelf
        ? `Call outcome on behalf of ${name}`
        : 'Unidentified caller'
    setBorrowerName(title)
  }, [borrower, contact, personId, setBorrowerName])

  // TODO: (Satoru) consider using `useRead` here
  useEffect(() => {
    if (hasVoiceRecording && !callAudioUrl) {
      ;(async () => {
        const url = await dispatch(
          getRecordingContent({
            personId,
            interactionId: interaction.id
          })
        )
        setCallAudioUrl(url)
      })()
    }
  }, [callAudioUrl, dispatch, hasVoiceRecording, interaction, personId])

  useOnMount(() => {
    setSelectedOutcome(
      outcomeOptions.find(o => o.value === interaction.statusDetails)
    )
    setSelectedTheme(interaction.theme)
  })

  // Methods --------------------------
  const _onUpdateInteraction = async body => {
    if (!personId) {
      await dispatch(
        updateUnboundInteraction({
          body,
          companyId,
          id: interaction.id,
          key: `Interactions.byId(id=${interaction.id})`
        })
      )
    } else {
      const { personId: personIdFromParam } = body || {}
      const personIdIsNull = personIdFromParam === null
      await dispatch(
        updateInteraction({
          body,
          personId: personId,
          id: interaction.id,
          personIdIsNull,
          key: `Interactions.byId(id=${interaction.id})`
        })
      )
    }
  }

  const onCaseSelect = option => {
    const _option = option ?? []
    if (_option.length > associatedCases.length) {
      const caseId = _option[_option.length - 1].value
      dispatch(
        addAssociation({
          key: `AddAssociation-${caseId}`,
          filters: {
            personId,
            caseId
          },
          body: {
            objectType: INTERACTION,
            objectId: interaction.id,
            relation: RELATED
          }
        })
      )
    } else {
      let caseId
      const oldIds = associatedCases.map(_case => _case.value)
      const newIds = _option.map(o => o.value)
      oldIds.forEach(id => {
        if (!newIds.includes(id)) {
          caseId = id
        }
      })
      dispatch(
        deleteAssociation({
          key: `DeleteAssociation-${caseId}`,
          personId,
          caseId,
          query: `objectType=${INTERACTION}&objectId=${interaction.id}`,
          objectId: interaction.id
        })
      )
    }
  }

  const onReasonSelect = option => {
    setSelectedTheme(option)
    _onUpdateInteraction({ theme: option.value })
  }

  const onOutcomeSelect = option => {
    setSelectedOutcome(option)
    _onUpdateInteraction({ statusDetails: option.value, status: option.status })
  }

  // Open telephony widget
  const onOpenTelephony = () => {
    const options = {
      _case: associatedCases[0]?.value ?? undefined,
      contact: contact.value || from,
      theme: selectedTheme.value || selectedTheme
    }
    openResource({ id: '1', type: 'telephony', borrowerId: personId, options })
  }

  const onLockInteraction = () => {
    _onUpdateInteraction({ sensitive: true })
    setShowModal(false)
  }

  const onLockClick = () => {
    if (isInteractionSensitive && canReadSensitive) {
      _onUpdateInteraction({ sensitive: false })
    } else if (isInteractionSensitive) {
      setDialogToShow(DIALOG_UNAUTHORIZED)
      setShowModal(true)
    } else {
      setDialogToShow(DIALOG_LOCK)
      setShowModal(true)
    }
  }

  const _associateBorrower = async borrowerId => {
    await _onUpdateInteraction({ personId: borrowerId })
  }

  const disassociateBorrower = async () => {
    await _onUpdateInteraction({ personId: null })
    setShowChangeBorrowerAssociationModal(null)
    setSearchedBorrower(null)
  }

  const onChangeBorrowerAssociation = () => {
    if (interaction.personId) {
      setShowChangeBorrowerAssociationModal(DISASSOCIATE_BORROWER_WARNING)
    } else {
      if (searchedBorrower || borrowerIdFromPathname) {
        _associateBorrower(searchedBorrower?.id || borrowerIdFromPathname)
      } else {
        setShowChangeBorrowerAssociationModal(CANNOT_ASSOCIATE_BORROWER)
      }
    }
  }

  if (!interaction?.id || isContactLoading) {
    return <Spinner height='80px' />
  }

  return (
    <>
      <Container>
        <TopSection>
          <TopPostDialSection
            borrowerId={personId}
            callDuration={elapsedTime || '00:00:00'}
            callerPhoneNumber={from}
            canChangeBorrowerAssociation
            contact={contact}
            isInbound={isInboundCall}
            onLockClick={onLockClick}
            onChangeBorrowerAssociation={onChangeBorrowerAssociation}
            recorded={!!hasVoiceRecording}
            sensitiveData={isInteractionSensitive}
          />
        </TopSection>
        <Reason
          isControlled
          isInboundInteraction={isInboundCall}
          onChange={onReasonSelect}
          // Reason only editable for inbound calls
          readOnly={interaction.direction === 'outbound'}
          theme={selectedTheme}
        />
        {personId && (
          <Case
            isControlled
            maxMenuHeight={120}
            multiSelect
            onChange={onCaseSelect}
            options={casesOptions}
            value={associatedCases}
          />
        )}
        <Outcome
          maxMenuHeight={304}
          menuPlacement='top'
          onChange={onOutcomeSelect}
          outcome={selectedOutcome}
        />
        <BottomSection>
          {/* TODO: Add music player here */}
          {hasVoiceRecording && (
            <LaunchRecordingButton
              blockSensitive={blockSensitive}
              url={callAudioUrl}
            >
              <StyledButton
                margin='0 0 0 40px'
                secondary
                readOnly={blockSensitive}
              >
                <Icon fontSize='24px' name='voicemail' />
                Launch recording
              </StyledButton>
            </LaunchRecordingButton>
          )}
          <StyledButton
            margin='0 0 0 40px'
            onClick={onOpenTelephony}
            readOnly={!personId}
            secondary
          >
            <Icon fontSize='24px' name='phone' />
            Call
          </StyledButton>
        </BottomSection>
        {isLoading && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
      </Container>
      {showModal && (
        <Modal onClose={() => setShowModal(false)} width={476}>
          <InteractionModalContent
            dialogToShow={dialogToShow}
            onCancel={() => setShowModal(false)}
            onLockInteraction={onLockInteraction}
          />
        </Modal>
      )}

      {showChangeBorrowerAssociationModal && (
        <Modal
          width={476}
          onClose={() => setShowChangeBorrowerAssociationModal(null)}
        >
          {showChangeBorrowerAssociationModal ===
          DISASSOCIATE_BORROWER_WARNING ? (
            <Dialog
              title='Disassociate borrower'
              confirmLabel='Yes'
              onConfirm={disassociateBorrower}
              cancelLabel='No'
              onCancel={() => setShowChangeBorrowerAssociationModal(null)}
            >
              <div>
                Are you sure you want to remove borrower association which will
                leave the interaction without borrower ID.
              </div>
            </Dialog>
          ) : showChangeBorrowerAssociationModal ===
            CANNOT_ASSOCIATE_BORROWER ? (
            <Dialog
              title='Associate with borrower'
              confirmLabel='Ok'
              onConfirm={() => setShowChangeBorrowerAssociationModal(null)}
            >
              <div>
                To associate with a borrower please search for the borrower in
                “Find a borrower”. Select the borrower to confirm their contact
                details, then you can "Associate with borrower" in the
                interaction.
              </div>
            </Dialog>
          ) : null}
        </Modal>
      )}
    </>
  )
}

VoiceInteraction.propTypes = {
  id: PropTypes.string,
  setBorrowerName: PropTypes.func
}

LaunchRecordingButton.propTypes = {
  blockSensitive: PropTypes.bool,
  url: PropTypes.string,
  children: PropTypes.any
}

export default VoiceInteraction
