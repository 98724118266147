import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useUserType } from 'core/hooks'
import { _useCompanyInfo as useCompanyInfo } from 'core/SystemProvider/CustomizationsProvider'
import { useOverride } from 'core/components/Badge/overrides'

const noop = () => {}

const useCaseId = () => {
  const userType = useUserType()
  const loanTypesById = useSelector(state => state.loanTypes.byId) || {}
  const { forceCaseIdOnAgentActions } = useCompanyInfo()

  const [storedCaseId, setStoredCaseId] = useState(undefined)

  // TODO:  adjust to match where this property ends up in the bootstrap call
  const requiredByCompany = forceCaseIdOnAgentActions

  // TODO: deprecate checking on loanType once bootstrap has value in all environments
  const requriedByAnyLoanType = _.some(
    loanTypesById,
    'forceCaseIdOnAgentActions'
  )

  const realRequiresCaseId =
    userType === 'agent' && (requiredByCompany || requriedByAnyLoanType)

  const requiresCaseId = useOverride('showCaseId', realRequiresCaseId)

  const caseId = requiresCaseId ? storedCaseId : undefined
  const setCaseId = requiresCaseId ? setStoredCaseId : noop

  // bad caseId will always be false (and therefore not disable submit)
  // if caseId is not required

  const badCaseId = !!(requiresCaseId && !caseId)

  return { requiresCaseId, caseId, setCaseId, badCaseId }
}

export default useCaseId
