import { LOADING } from 'core/actions/loading'

const initialState = {
  loading: {}
}

const loading = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        [action.key]: action.status
      }
    default:
      return state
  }
}

export default loading
