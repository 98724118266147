import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import Icon from 'core/components/Icon'
import variables from 'core/styles/variables'

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${p => p.margin};
`

export const TitleText = styled.h2`
  font-size: 2.2rem;
  color: ${variables.colorBlack90};
`

const HelpTextTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid ${variables.colorBlueLighten};
  margin: -16px 28px 0 0;
`

const TriangleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const HelpText = styled.div`
  background-color: ${variables.colorBlueLighten};
  margin: ${p => p.helpTextMargin || '0 8px'};
  padding: 16px;
  border-radius: 8px;
`

const HelpTitle = styled.p`
  font-weight: 500;
  margin-top: 0;
`

const HelpParagraph = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  white-space: pre-wrap;
`

export const TooltipButton = styled.button`
  border: none;
  background-color: transparent;
  margin: ${p => p.margin};

  cursor: pointer;
`

export const Subtitle = styled.span``

const TitleWithEducationTip = props => {
  const {
    subTitle,
    helpText,
    removeHelpTextTitle,
    helpTextMargin,
    iconMargin,
    statusIcon,
    helpTitle,
    title,
    titleMargin
  } = props

  const [helpTextOpen, setHelpTextOpen] = useState(false)

  const toggleHelpText = () => setHelpTextOpen(isOpen => !isOpen)

  return (
    <>
      {title && (
        <TitleRow margin={titleMargin}>
          <TitleText>
            {title} {statusIcon}
          </TitleText>
          {helpText && (
            <TooltipButton
              onClick={toggleHelpText}
              margin={iconMargin || '0 24px 0 0'}
            >
              <Icon
                name={helpTextOpen ? 'highlight_off' : 'help_outline'}
                color={variables.colorBlack60}
                fontSize='24px'
              />
            </TooltipButton>
          )}
        </TitleRow>
      )}

      {helpTextOpen && (
        <>
          <TriangleWrapper>
            <HelpTextTriangle />
          </TriangleWrapper>
          <HelpText helpTextMargin={helpTextMargin}>
            {!removeHelpTextTitle && <HelpTitle>{helpTitle}</HelpTitle>}
            <HelpParagraph>{helpText}</HelpParagraph>
          </HelpText>
        </>
      )}

      {subTitle && <Subtitle>{subTitle}</Subtitle>}
    </>
  )
}

TitleWithEducationTip.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  helpText: PropTypes.node,
  helpTextMargin: PropTypes.string,
  removeHelpTextTitle: PropTypes.bool,
  iconMargin: PropTypes.string,
  statusIcon: PropTypes.node,
  helpTitle: PropTypes.string,
  titleMargin: PropTypes.string
}

TitleWithEducationTip.defaultProps = {
  titleMargin: '0 0 0 24px'
}

export default TitleWithEducationTip
