import styled from 'styled-components/macro'
import Select from 'react-select'

import variables from 'core/styles/variables'

export const InputYear = styled.input`
  display: inline;
  border: none;
  border-bottom: 1px solid ${variables.colorBlack30};
  margin-left: 16px;
  background-color: transparent;
  padding: 0;
  width: 64px;
  font-family: inherit;
  font-size: 1.6rem;
  outline: none;
`

export const SelectMonth = styled(Select)`
  width: 120px;
  margin-right: 4px;

  [class$='-control'] {
    border: 1px solid ${variables.colorBlack20};

    &:hover {
      border-color: ${variables.colorBlack20};
    }

    [class$='-singleValue'] {
      font-size: 16px;
      font-weight: 500;
    }
  }
`

export const Container = styled.div`
  border: 1px solid ${variables.colorBlack20};
  padding: 8px 12px;
  border-radius: 4px;
  align-items: center;
  display: flex;
  font-size: 12px;
  position: relative;
  width: ${p => p.width};
  box-sizing: border-box;

  .react-datepicker {
    width: 320px;
    font-size: 12px;
    box-shadow: ${variables.peachyShadow};
    border-radius: 8px;
    border: none;
  }

  .react-datepicker__input-container {
    input {
      display: inline;
      border: none;
      background-color: transparent;
      padding: 0;
      font-family: inherit;
      font-size: 1.6rem;
      outline: none;
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${variables.colorBlack90};
  }

  .react-datepicker__navigation--next {
    border-left-color: ${variables.colorBlack90};
  }

  .react-datepicker-popper {
    transform: none !important;
    top: 42px !important;
  }

  .react-datepicker__month {
    margin: 0 0 24px 0;
  }

  .react-datepicker__month-container {
    width: inherit;
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;

    .react-datepicker__current-month {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .react-datepicker__day-names {
    text-transform: uppercase;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 8px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 16px;
    padding: 4px;
  }

  .react-datepicker__day {
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid transparent;
    color: ${variables.colorBlack90};
  }

  .react-datepicker__day--disabled {
    color: ${variables.colorBlack50};
  }

  .react-datepicker__day--today {
    font-weight: 500;
    background-color: transparent;
    border-color: ${variables.colorBlack90};
  }

  .react-datepicker__day--selected {
    font-weight: 500;
    background-color: ${variables.colorBlueLighten};
    border-color: transparent;
    color: ${variables.colorBluePrimary};
  }

  .react-datepicker__close-icon::after {
    background-color: transparent;
    color: ${variables.colorBlack30};
    font-size: 24px;
  }

  .react-datepicker-wrapper {
    flex: 1 1 auto;
    overflow: hidden;
  }
`

export const CustomHeader = styled.div`
  margin: 10px;
  display: flex;
  justify-content: space-between;
  margin: 24px 12px;

  button:first-child {
    transform: rotateY(180deg);
  }
`

export const SelectionGroup = styled.div`
  display: flex;
  align-items: center;

  .select {
    &:first-child {
      margin-right: 4px;
      min-width: 120px;
    }

    &:last-child {
      margin-right: 0;
      min-width: 72px;
    }

    .select__value-container {
      padding: 4px;
      width: auto;
    }

    .select__dropdown-indicator {
      padding 4px 4px 4px 0;
    }

    .select__single-value {
      font-weight: 500;
    }
  }
`

// This will ensure the icon width doesn't shrink to zero
// from flexbox.
export const IconWrapper = styled.div`
  flex: 0 0 auto;
  margin-right: 8px;
  vertical-align: middle;
`

export const TodayText = styled.span`
  font-size: 1.6rem;
  white-space: pre;
  cursor: pointer;
`
