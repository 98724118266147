const interactionThemes = {
  opsAccountCredentials: 'Account credentials',
  opsCollContactEmployerNotice: 'Intention to contact employer notice',
  opsCollDebt: 'Collections',
  opsCollLocateBorrower: 'Locate borrower for collections',
  opsCollVerifyEmployment: 'Verify borrower employment for collections',
  opsLoanTimeBarredNotice: 'Time-barred debt notice',
  opsLoanValidationOfDebtNotice: 'Validation of debt notice',
  opsServicing: 'Servicing',
  opsServicingDebtValidation: 'Debt validation notice',
  opsServicingNegativeCreditReportNotice: 'Negative credit report notice',
  opsServicingTimeBarredNotice: 'Time barred notice',
  opsServicingVerificationOfDebt: 'Verification of debt'
}

export { interactionThemes }
