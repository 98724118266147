import _ from 'lodash'
import { useState, useMemo } from 'react'
import useOnChangeEffect from '../useOnChangeEffect'

import { store, retrieve } from './storage'
import { combine } from './storedStateHelpers'
import { useStoredStateNamespace } from './StoredStateNamespace'

// this hook can be used a useState() call, but will be localStorage-backed

const useStoredState = passedKey => {
  const parentKey = useStoredStateNamespace()

  const fullKey = combine(parentKey, passedKey)

  const [value, setValue] = useState(() => retrieve(fullKey))

  const onChange = useMemo(() => {
    return newValue => {
      if (!passedKey) return
      setValue(value => {
        const result = _.isFunction(newValue) ? newValue(value) : newValue
        store(fullKey, result)
        return result
      })
    }
  }, [passedKey, fullKey])

  // if the key changes during use, reload the data
  useOnChangeEffect(() => setValue(retrieve(fullKey)), [fullKey])

  return [value, onChange]
}

export default useStoredState
