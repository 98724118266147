import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
const CompanyIdContext = createContext('')

const CompanyIdProvider = ({ companyId, children }) => {
  return (
    <CompanyIdContext.Provider value={companyId}>
      {children}
    </CompanyIdContext.Provider>
  )
}

CompanyIdProvider.propTypes = {
  companyId: PropTypes.string,
  children: PropTypes.node
}

const _useCompanyId = () => useContext(CompanyIdContext)

// if you need this hook, use import { useCompanyId } from 'core/hooks'
export { CompanyIdProvider, _useCompanyId }
