import { checkTimeordate } from '../types'
import mtz from '../utils/mtz'

const makeIsInTimeRange = (context = {}) => {
  const { timezone } = context

  const isInTimeRange = (timeToCheck, from, to) => {
    checkTimeordate(timeToCheck)
    checkTimeordate(from)
    checkTimeordate(to)

    // If timeToCheck is falsy or from and to are both falsy, return false
    if (!timeToCheck || (!from && !to)) {
      return false
    }

    let isBetween = true

    if (from) {
      isBetween =
        isBetween &&
        mtz(timeToCheck, timezone).isSameOrAfter(mtz(from, timezone))
    }

    if (to) {
      isBetween =
        isBetween &&
        mtz(timeToCheck, timezone).isSameOrBefore(mtz(to, timezone))
    }

    return isBetween
  }

  return isInTimeRange
}

export default makeIsInTimeRange
