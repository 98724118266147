import { POST, POSTwithToken } from 'core/api/legacyApi'

import { LOADING, getStoredToken } from 'core/actions/constants'

import {
  APIendpoint,
  decodeToken,
  setStoredToken
} from 'core/actions/helpers/tokenHelpers'

export const GENERATE_TOKEN_SUCCESS = 'GENERATE_TOKEN_SUCCESS'
export const GENERATE_TOKEN_ERROR = 'GENERATE_TOKEN_ERROR'

export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'

export const generateToken = (username, password, userType, companyId) => {
  if (!userType || !companyId)
    throw new Error('userType and companyId must be provided')

  return async dispatch => {
    const endpoint = `${APIendpoint}/auth/token?userType=${userType}&companyId=${companyId}`

    const body = { username, password }
    const response = await fetch(endpoint, POST(body))
    const responseBody = await response.json()
    const isSuccess = response.status >= 200 && response.status <= 299

    if (!isSuccess) {
      dispatch({
        type: GENERATE_TOKEN_ERROR,
        payload: {
          message: response.message,
          status: response.status
        }
      })
    } else {
      dispatch({ type: GENERATE_TOKEN_SUCCESS })

      const storeValue = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value))
      }
      const token = responseBody.token
      const { personId, userType } = decodeToken(token)
      setStoredToken(token)
      storeValue('personIdFromToken', personId)
      localStorage.setItem('userTypeFromToken', userType)
      localStorage.setItem('username', username)
    }

    return { ...responseBody, isSuccess }
  }
}

export const changePassword = ({ key, password }) => {
  return async dispatch => {
    if (key) {
      dispatch({ type: LOADING, key, status: 'loading' })
    }
    const username = localStorage.getItem('username')

    const body = { username, password }

    const endpoint = `${APIendpoint}/auth/password`
    const response = await fetch(
      endpoint,
      POSTwithToken(getStoredToken(), body)
    )
    const isSuccess = response.status >= 200 && response.status <= 299
    const badRequest = response.status === 400

    if (isSuccess) {
      dispatch({ type: UPDATE_PASSWORD_SUCCESS })
    }

    if (badRequest) {
      dispatch({ type: UPDATE_PASSWORD_ERROR })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error'
      })
    }
  }
}
