// Third party ------------------------
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'

// Proprietary ------------------------
import variables from 'core/styles/variables'
import {
  KEY_AFFILIATION,
  STATUS_ADDITIONAL,
  STATUS_ACTIVE,
  STATUS_PRIMARY,
  STATUS_SECONDARY,
  CONTACT_TYPE_PHONE,
  CONTACT_TYPE_ADDRESS,
  getPrimarySelf,
  getContactById
} from 'core/hooks/useContactsSelectOptions'
import { useBorrower, useContactsSelectOptions } from 'core/hooks'

// Components -------------------------
import ContactsOptionLabel from 'crm/components/ContactsOptionLabel'
import Icon from 'core/components/Icon'
import InlineDropdownBase from 'core/components/InlineDropdown'
import RowBase from './Row'
import RowLabel from './RowLabel'
import { Button } from 'core/components'

// Styles -----------------------------
const InlineDropdown = styled(InlineDropdownBase)`
  width: calc(100% - 64px);
  max-width: 400px;
`
const Row = styled(RowBase)`
  justify-content: space-between;
`
const RowLeft = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
const Span = styled.span`
  padding: 0 0 0 8px;
  /** The 30px height/line-height are according to design */
  height: 30px;
  line-height: 30px;
`
const IconGroup = styled.div`
  display: flex;
  align-items: center;
  color: ${variables.colorBlack50};
  font-size: 24px;
`

const To = ({
  borrowerId,
  channel,
  contact,
  contactInfo,
  interaction = {},
  isLockDisabled,
  maximized,
  onChangeContact,
  onClickLock,
  readOnly,
  showLockIcon
}) => {
  const { useContactsSelectOptions } = To.dependencies

  const borrower = useBorrower(borrowerId)
  const { borrowerType } = borrower || {}
  const isBusinessBorrower = borrowerType === 'business'

  const options = useContactsSelectOptions({
    borrowerId,
    filterBy: {
      authorizedThirdParty: true,
      contactType:
        channel === 'text'
          ? CONTACT_TYPE_PHONE
          : channel === 'mail'
          ? CONTACT_TYPE_ADDRESS
          : channel,
      receiveTextMessages: channel === 'text',
      /**
       * 'active' is not part of the contacts status enums, but it is
       * part of the enum value for legal representation. We're combining
       * contacts and legal rep in the same list, which is why we are
       * adding 'active' as part of the enum for status
       */
      status: [
        STATUS_ACTIVE,
        STATUS_PRIMARY,
        STATUS_SECONDARY,
        STATUS_ADDITIONAL
      ],
      valid: true
    },
    groupedBy: KEY_AFFILIATION,
    groupWithAdditionalContacts: true,
    isBusinessBorrower,
    useSublabels: true
  })

  const { sensitive, sensitiveBorrowerOnly } = interaction || {}

  const lockIconColor = sensitive
    ? variables.colorBlack90
    : variables.colorBlack40

  const lockIconName = sensitiveBorrowerOnly || sensitive ? 'lock' : 'lock_open'

  // Set primary self contact if avilable and contact is null
  useEffect(() => {
    const isGrouped = true
    const primaryContact = getPrimarySelf(options, isGrouped)
    if (typeof contact === 'string') {
      const tempContact = getContactById(options, contact)
      if (tempContact) {
        onChangeContact(tempContact)
      }
    } else if (options !== null && !contact && primaryContact) {
      onChangeContact(primaryContact)
    }
  }, [contact, options, onChangeContact])

  const _formatOptionLabel = props => <ContactsOptionLabel {...props} />
  const _formatValue = contact => {
    if (!contact) return undefined

    const newContact = _.pick(contact, [
      'affiliation',
      'contactType',
      'displayLabel',
      'group',
      'id',
      'label',
      'name',
      'value'
    ])
    return newContact
  }

  /**
   * Whenever there are values for both contact and contactInfo
   * it means that this is a reply to what used to be an unknown
   * person but was associated to a borrower prior to sending
   * the reply. In this scenario we want to prioritize the
   * value from the contact object to be set as the displayed
   * value for the readOnly field. readOnly becomes true during
   * the preview mode, just prior to actually sending an email
   * and we want to make sure that the selected contact's value
   * is what get's displayed during this mode.
   */
  const displayedContactInfo =
    !!contact?.value && !!contactInfo ? contact.value : contactInfo

  if (!options && !readOnly) return null

  return (
    <Row maximized={maximized}>
      <RowLeft>
        <RowLabel>To</RowLabel>
        {readOnly ? (
          <Span>{displayedContactInfo}</Span>
        ) : (
          <InlineDropdown
            isControlled
            formatOptionLabel={_formatOptionLabel}
            onChange={onChangeContact}
            options={options}
            placeholder='Select a contact method'
            value={_formatValue(contact)}
          />
        )}
      </RowLeft>
      <IconGroup>
        {showLockIcon && (
          <Button onClick={onClickLock} readOnly={isLockDisabled}>
            <Icon color={lockIconColor} fontSize='24px' name={lockIconName} />
          </Button>
        )}
      </IconGroup>
    </Row>
  )
}

To.propTypes = {
  /** For readOnly=true, contactInfo is the plain string
   * label displayed, and contact is ignored.
   */
  contactInfo: PropTypes.string,
  /** For readOnly=false, contact is the selected
   * value in the dropdown, and contactInfo is ignored.
   */
  contact: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  readOnly: PropTypes.bool,
  /** If the containing resource window is maximized */
  maximized: PropTypes.bool,
  channel: PropTypes.oneOf(['email', 'mail', 'text']),
  onChangeContact: PropTypes.func,
  borrowerId: PropTypes.string
}

To.dependencies = {
  useContactsSelectOptions
}

export default To
