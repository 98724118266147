// Third party --------------
import _ from 'lodash'
import { formatPhone } from 'core/helpers/phoneNumberFormatter'
import { useEffect, useState } from 'react'

const useFormattedToValue = ({ contact }) => {
  const [to, setTo] = useState('')

  useEffect(() => {
    const { affiliation, contactType, label, name, value } = contact || {}
    const isSelf = affiliation === 'self'
    const isPhone = contactType === 'phone'
    const isMobile = isPhone && label === 'personal'
    const formattedPhoneValue = formatPhone(value)
    const toPrefix =
      isSelf && isMobile
        ? `Mobile, `
        : isSelf
        ? `${_.capitalize(label)}, `
        : name
        ? `${name}, `
        : ''
    const to = `${toPrefix}${formattedPhoneValue}`

    setTo(to)
  }, [contact])

  return to
}

export default useFormattedToValue
