// Third party --------------
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { createContext } from 'react'
import { useDispatch } from 'react-redux'

// Reset --------------------
import getTaskAvailability from 'core/actions/employee/getTaskAvailability'
import parseError from 'core/helpers/parseError'
import updateTaskAvailability from 'core/actions/employee/updateTaskAvailability'
import { useLocation } from 'react-router-dom'
import { useRead, useWrite } from 'core/hooks'
import { isLoggedIn } from 'core/actions/helpers/tokenHelpers'

const TaskAvailabilityContext = createContext({})

const INVALID_PATHS = ['/login', '/document-uploader']

const isValidPath = (pathname = '') => {
  return _.every(INVALID_PATHS, invalidPath => !pathname.includes(invalidPath))
}

const TaskAvailabilityContextProvider = ({ children }) => {
  const dispatch = useDispatch()

  const { pathname } = useLocation()

  const canInteractWithApi = isLoggedIn() && isValidPath(pathname)

  const [
    availability,
    isFetchTaskAvailability,
    getAvailabilityError,
    refresh
  ] = useRead(() => {
    if (!canInteractWithApi) return {}
    return dispatch(
      getTaskAvailability({
        key: 'GetTaskTypeAvailability'
      })
    )
  }, [canInteractWithApi])

  // Update hook ------------
  const [
    updateAvailability,
    isUpdatingAvailability,
    ,
    updateAvailabilityError
  ] = useWrite(async body => {
    if (!canInteractWithApi) return {}
    await dispatch(
      updateTaskAvailability({
        body,
        key: 'UpdateTaskAvailability'
      })
    )
    refresh()
  })

  const isLoading = isFetchTaskAvailability || isUpdatingAvailability

  const hasGetTaskAvailabilityError = !!getAvailabilityError
  const getAvailabilityErrorText = parseError(getAvailabilityError)
  const hasUpdateTaskAvailabilityError = !!updateAvailabilityError
  const updateAvailabilityErrorText = parseError(updateAvailabilityError)

  const value = {
    availability,
    hasGetTaskAvailabilityError,
    updateAvailability,
    hasUpdateTaskAvailabilityError,
    getAvailabilityErrorText,
    updateAvailabilityErrorText,
    isLoading,
    isUpdatingAvailability
  }

  return (
    <TaskAvailabilityContext.Provider value={value}>
      {children}
    </TaskAvailabilityContext.Provider>
  )
}

TaskAvailabilityContextProvider.propTypes = {
  children: PropTypes.node
}

export { TaskAvailabilityContext }
export default TaskAvailabilityContextProvider
