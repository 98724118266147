// Third party --------------
import PropTypes from 'prop-types'
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

// Rest ---------------------
import { RESERVATION_ACCEPTED } from 'crm/components/Tasks/const'
import { answerQueuedVoiceCall } from 'crm/helpers/twilioClient'
import { useStoredState } from 'admin/hooks'
import { useAudio } from 'core/hooks'

const inboundRingtone = `${process.env.PUBLIC_URL}/audio/incoming_call_ringtone.mp3`

const VoiceTaskContext = createContext({})

const VoiceTaskContextProvider = ({ children }) => {
  const [draftVoiceInteraction] = useStoredState('draftVoiceInteraction')
  const [hasAnsweredCall, setHasAnsweredCall] = useState(false)
  const [hasSavedCallOutcome, setHasSavedCallOutcome] = useState(
    !draftVoiceInteraction
  )
  const [html] = useState(() => document.querySelector('html'))
  const [userHasClickedPage, setUserHasClickedPage] = useState(false)
  const [voiceTask, setVoiceTask] = useState()

  const { reservationStatus } = voiceTask || {}

  const isTaskAccepted = useMemo(() => {
    const { reservationStatus } = voiceTask || {}
    return reservationStatus === RESERVATION_ACCEPTED
  }, [voiceTask])

  useEffect(() => {
    const setHasClickedPage = () => {
      if (!userHasClickedPage) setUserHasClickedPage(true)
    }

    html.addEventListener('click', setHasClickedPage)
    return () => {
      html.removeEventListener('click', setHasClickedPage)
    }
  }, [html, userHasClickedPage])

  useAudio({
    url: inboundRingtone,
    loop: true,
    isPlaying: !hasAnsweredCall && reservationStatus === 'pending',
    canPlay: userHasClickedPage
  })

  useEffect(() => {
    setHasAnsweredCall(isTaskAccepted)
  }, [isTaskAccepted])

  useEffect(() => {
    // We want the check for null here since undefined should
    // only be on app load and we don't want to change the
    // initial states for these hooks during that time of the
    // component life cycle
    if (voiceTask === null) {
      setHasAnsweredCall(false)
      setHasSavedCallOutcome(false)
    }
  }, [voiceTask])

  const answerCall = useCallback(() => {
    answerQueuedVoiceCall(voiceTask)
  }, [voiceTask])

  const value = useMemo(() => {
    return {
      answerCall,
      hasAnsweredCall,
      setHasAnsweredCall,
      hasSavedCallOutcome,
      setHasSavedCallOutcome,
      voiceTask,
      setVoiceTask
    }
  }, [
    answerCall,
    hasAnsweredCall,
    setHasAnsweredCall,
    hasSavedCallOutcome,
    setHasSavedCallOutcome,
    voiceTask,
    setVoiceTask
  ])

  return (
    <VoiceTaskContext.Provider value={value}>
      {children}
    </VoiceTaskContext.Provider>
  )
}

VoiceTaskContextProvider.propTypes = {
  children: PropTypes.node
}

export { VoiceTaskContext }
export default VoiceTaskContextProvider
