// Third party --------------
import React from 'react'
import styled from 'styled-components/macro'

// Components ---------------
import { Tooltip } from 'core/components'

// Rest ---------------------
import variables from 'core/styles/variables'
import { useCompanyInfo } from 'core/hooks'

const Wrapper = styled.span`
  color: ${variables.colorBlack70};
`

const Inner = styled.span`
  display: inline-flex;
  align-items: center;
  white-space: pre-wrap;
`

const Content = styled.div`
  font-size: 16px;
  color: ${variables.colorBlack70};
  text-align: left;
`

const PoweredByPeach = () => {
  const { brandName } = useCompanyInfo()

  const starter = brandName ? `${brandName} is using` : 'Using'

  const content = (
    <Content>{`${starter} Peach technology to provide customers with online tools to manage their loans.`}</Content>
  )

  return (
    <Wrapper>
      {'Powered by '}
      <Inner>
        {'Peach '}
        <Tooltip
          style={{ display: 'inline-block' }}
          content={content}
          iconName='help_outline'
          iconColor={variables.colorBlack50}
          fontSize='16px'
          width={400}
          inline
        />
      </Inner>
    </Wrapper>
  )
}

export default PoweredByPeach
