// Third party --------------
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

// Components ---------------
import CardSection from 'loan/components/actionCards/shared/CardSection'
import Icon from 'core/components/Icon'
import { Checkbox, Button } from 'core/components'

// Rest ---------------------
import { useTime } from 'core/time'
import variables from 'core/styles/variables'

const LoanDocSection = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
`
const CheckboxList = styled.div`
  padding-left: 24px;
`
export const HelpText = styled.div`
  padding: 8px 0 0 32px;
  font-size: 14px;
  font-weight: 300;
  color: ${variables.colorBlack60};
`

const CheckboxListSection = ({
  checked,
  disabledDocuments = [],
  documents,
  loanId,
  loans,
  onChange,
  showSectionByDefault
}) => {
  const { useTime } = CheckboxListSection.dependencies
  const { formatRelative } = useTime()
  const [showLoanDocSection, setShowLoanDocSection] = useState(
    showSectionByDefault || false
  )

  const formatTime = date => {
    return formatRelative(date, {
      isCapitalized: true,
      showTime: false
    })
  }
  return (
    <>
      <CardSection label={loans[loanId]?.displayId} labelWidth='136px'>
        <LoanDocSection>
          <Button
            className={`button-toggle-loan-doc-${loanId}`}
            fontSize='24px'
            onClick={() => setShowLoanDocSection(!showLoanDocSection)}
          >
            {showLoanDocSection ? (
              <Icon name='keyboard_arrow_up' />
            ) : (
              <Icon name='keyboard_arrow_down' />
            )}
          </Button>
          {showLoanDocSection && (
            <CheckboxList className={`checkbox-list-${loanId}`}>
              {documents.map((document, index) => {
                const disabled = disabledDocuments.includes(document.id)
                return (
                  <Checkbox
                    key={document.id}
                    label={`${formatTime(document.createdAt)}, ${
                      document.description || document.fileName
                    }`}
                    value={document.id}
                    onChange={e => onChange(document.id, e.target.checked)}
                    checked={checked[document.id].checked}
                    margin={index === 0 ? '' : '16px 0 0'}
                    className={`checkbox-${document.id}`}
                    disabled={disabled}
                  >
                    {disabled && (
                      <HelpText>
                        Remove this as a document case-detail field if you would
                        like to dissociate.
                      </HelpText>
                    )}
                  </Checkbox>
                )
              })}
            </CheckboxList>
          )}
        </LoanDocSection>
      </CardSection>
    </>
  )
}

CheckboxListSection.propTypes = {
  checked: PropTypes.object,
  disabledDocuments: PropTypes.arrayOf(PropTypes.string),
  documents: PropTypes.array,
  loanId: PropTypes.string,
  loans: PropTypes.object,
  onChange: PropTypes.func,
  showSectionByDefault: PropTypes.bool
}

CheckboxListSection.dependencies = {
  useTime
}

export default CheckboxListSection
