import { useEffect, useRef } from 'react'

const EVENTS = ['mousedown', 'touchstart']

const bind = fn => EVENTS.forEach(event => document.addEventListener(event, fn))

const unbind = fn =>
  EVENTS.forEach(event => document.removeEventListener(event, fn))

const useOnClickOutside = func => {
  const ref = useRef()

  useEffect(() => {
    const onDocumentClick = event => {
      if (ref?.current && !ref.current.contains(event.target)) {
        func(event)
      }
    }

    bind(onDocumentClick)
    return () => unbind(onDocumentClick)
  }, []) // eslint-disable-line

  return ref
}

export default useOnClickOutside
