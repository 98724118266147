import _ from 'lodash'

import { APIendpoint } from 'core/actions/constants'
import { getStoredToken } from 'core/actions/helpers/tokenHelpers'

import { formatUrl } from './formatUrl'
import onUnauthorized from './onUnauthorized'
import parseErrorResponse from './parseErrorResponse'

const peachApiDownload = async options => {
  const { document, onUnauthorized } = peachApiDownload.dependencies
  const { url, params, ...restOptions } = options

  if (!_.isString(url)) {
    throw new Error(
      'url is a required as a named arg for a peachApi.download() call'
    )
  }

  const requestUrl = formatUrl(APIendpoint + url, params)

  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getStoredToken()}`
    },
    ...restOptions
  }

  const response = await fetch(requestUrl, fetchOptions)

  if (response.ok) {
    // Figure out the default filename
    const partsOfPath = requestUrl.split('/')
    const urlFilename = partsOfPath[partsOfPath.length - 1]
    const contentDisposition = (
      response.headers.get('Content-Disposition') || ''
    ).split(';')
    const filenameEntry = _.find(contentDisposition, item =>
      item.trim().startsWith('filename=')
    )
    const dispFilename = filenameEntry ? filenameEntry.trim().split('=')[1] : ''
    const filename = dispFilename || urlFilename

    // Create a temporary a-tag with href set to a fancy url that actually
    // has all the data embedded into it, then programmatically click it.
    // This triggers the browser's file download behavior to kick in. The
    // "download" attribute is set so that the browser knows what the
    // default filename is.
    const a = document.createElement('a')
    const responseText = await response.text()
    a.setAttribute('href', `data:application/octet-stream,${responseText}`)
    a.setAttribute('download', filename)
    a.click()
    a.remove()
  } else {
    if (response.status === 401) {
      onUnauthorized()
    } else {
      const error = await parseErrorResponse(response)
      throw error
    }
  }
}

peachApiDownload.dependencies = {
  document: global.document,
  onUnauthorized
}

export default peachApiDownload
