import { useTime } from 'core/time'

const useLongTimezone = () => {
  const { format, getNow } = useTime()
  const timezone = format(getNow(), 'z')

  switch (timezone) {
    case 'PST':
    case 'PDT':
      return 'Pacific Time'
    case 'MST':
    case 'MDT':
      return 'Mountain Time'
    case 'CST':
    case 'CDT':
      return 'Central time'
    case 'EST':
    case 'EDT':
      return 'Eastern Time'
    default:
      return timezone
  }
}

export default useLongTimezone
