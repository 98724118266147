// Third party --------------
import { useEffect, useState } from 'react'
import { useWindowSize } from '@reach/window-size'

// Rest ---------------------
import { loanAppLayout } from 'core/styles'

/**
 *
 * @param {String} appType - Refers to the app type (either 'loan' or 'crm' for now)
 * @returns
 */
const useIsMobileWidth = (appType = 'loan') => {
  const [isMobileWidth, setIsMobileWidth] = useState(false)
  const { width } = useWindowSize()
  const { maxWidthMobile } = loanAppLayout

  /**
   * We currently only support mobile screen sizes for the
   * loan application. If appType is not `loan` then we can
   * assume that the mobile screen size is not needed and
   * we can simply return false.
   */
  useEffect(() => {
    setIsMobileWidth(appType === 'loan' ? width <= maxWidthMobile : false)
  }, [appType, maxWidthMobile, width])

  return isMobileWidth
}

export default useIsMobileWidth
