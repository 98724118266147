const formatUploadLink = (expiresAt, url, index) => {
  const now = new Date()
  const later = new Date(expiresAt)
  const diffInDaysFloat = (later - now) / (1000 * 3600 * 24)
  const diffInDays = Math.round(diffInDaysFloat * 10) / 10 // Displays as integer or with max specificty of tenths place.
  const text = {
    start: 'Please upload documents with this secure document link: ',
    url,
    end: `. This link can be accessed within ${diffInDays} days. `
  }
  return {
    ops: [
      { retain: index },
      { insert: text.start },
      {
        insert: text.url,
        index: index + text.start.length,
        attributes: { link: url }
      },
      {
        insert: text.end,
        index: index + text.start.length + text.url.length
      }
    ]
  }
}

export default formatUploadLink
