import _ from 'lodash'
import { useRef, useEffect } from 'react'

// this hook is called whenever a value in the deps list changes.
// it does not get called on initial mount

const useOnChangeEffect = (onChange, list) => {
  const prevListRef = useRef(list)

  useEffect(() => {
    prevListRef.current = list
  }, [list])

  const prevList = prevListRef.current

  useEffect(() => {
    if (!prevList || !list) return

    // if deps value previous existed (i.e. not initial render)
    // and any of the values have changed,
    const hasChanged = _.some(list, (item, index) => item !== prevList[index])

    if (hasChanged) {
      onChange(prevList)
    }
  }, [list, prevList, onChange])
}

export default useOnChangeEffect
