import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const useBorrowerIdFromPathname = () => {
  const [borrowerId, setBorrowerId] = useState()

  const { pathname } = useLocation()

  useEffect(() => {
    const isBorrowerPage = _.includes(pathname, 'borrowers/BO-')
    const index = pathname.indexOf('/BO-')
    const id = isBorrowerPage ? pathname.substring(index + 1, index + 13) : null
    setBorrowerId(id)
  }, [pathname])

  return borrowerId
}

export default useBorrowerIdFromPathname
