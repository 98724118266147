import PropTypes from 'prop-types'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const SearchedBorrowerContext = createContext({})

const SearchedBorrowerContextProvider = ({ children }) => {
  const [searchedBorrower, setSearchedBorrower] = useState(null)

  const { pathname } = useLocation()
  useEffect(() => {
    if (pathname !== '/crm') setSearchedBorrower(null)
  }, [pathname, setSearchedBorrower])

  const value = useMemo(
    () => ({
      searchedBorrower,
      setSearchedBorrower
    }),
    [searchedBorrower, setSearchedBorrower]
  )

  return (
    <SearchedBorrowerContext.Provider value={value}>
      {children}
    </SearchedBorrowerContext.Provider>
  )
}

SearchedBorrowerContextProvider.propTypes = {
  children: PropTypes.node
}

export default SearchedBorrowerContextProvider
