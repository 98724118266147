import {
  APIendpoint,
  GET_EMPLOYEES,
  LOADING,
  getStoredToken
} from 'core/actions/constants'
import { GET } from 'core/api/legacyApi'
import { handleErrors } from 'core/helpers/handleErrors'

const getEmployees = ({ filters, key }) => {
  const { companyId } = filters

  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    // We are assuming for now there are no more than 100 employees in a company.
    // Later we need to maybe increase the possible value of limit to something
    // higher than 100, and eventually we need to be smarter dealing with pagination.
    const endpoint = `${APIendpoint}/companies/${companyId}/employees?limit=100`
    const response = await fetch(endpoint, GET(getStoredToken()))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    if (isSuccess) {
      dispatch({
        type: GET_EMPLOYEES,
        payload: responseBody.data
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error'
      })
    }
  }
}

export default getEmployees
