import { useMemo } from 'react'

import useCollection from 'core/hooks/useCollection'
import { useTime } from 'core/time'

/** Returns the soonest upcoming scheduled call for the given case and borrower.
 * Note that the returned object (if any) is an interaction object.
 */
const useNextScheduledCall = ({ caseId, borrowerId }) => {
  const { getNow, isAfter } = useTime()
  const thisCase = useCollection('cases', { personId: borrowerId }).byId[caseId]
  const allInteractions = useCollection('interactions', {
    personId: borrowerId
  }).byId
  const associations = useMemo(() => thisCase?.associations || [], [thisCase])
  const interactionAssociationIds = useMemo(
    () =>
      associations
        .filter(a => a.objectType === 'interaction')
        .map(a => a.objectId),
    [associations]
  )
  const sorted = useMemo(() => {
    const interactionsList = Object.values(allInteractions)
    const now = getNow()
    const filtered = interactionsList.filter(i => {
      return (
        i.channel === 'voice' &&
        i.status === 'scheduled' &&
        interactionAssociationIds.includes(i.id) &&
        i.scheduledAtFrom &&
        isAfter(i.scheduledAtFrom, now)
      )
    })
    return filtered.sort((a, b) =>
      isAfter(b.scheduledAtFrom, a.scheduledAtFrom) ? 1 : -1
    )
  }, [getNow, allInteractions, interactionAssociationIds, isAfter])
  return sorted[0]
}

export default useNextScheduledCall
