import getFullName from 'core/helpers/getFullName'
import { ACTIVE, PREDIAL } from 'contexts/CallModeContext'
import { useBorrower } from 'core/hooks'

const useTelephonyTitle = props => {
  const { personId, callMode, callDuration, selectedContact } = props
  const { affiliation } = selectedContact || {}

  const borrower = useBorrower(personId)
  const { loadingStatus } = borrower || {}
  const isLoadingSuccess = loadingStatus === 'success'

  const name =
    borrower && isLoadingSuccess ? getFullName(borrower) : 'unidentified caller'
  const isSelf = affiliation === 'self' || affiliation === undefined
  const elapsedTime = callDuration || '00:00:00'
  const toOrOnBehalfOf = isSelf ? 'to' : 'on behalf of'
  const forOrOnBehalfOf = isSelf ? 'for' : 'on behalf of'

  if (callMode === ACTIVE) {
    return `Current call ${toOrOnBehalfOf} ${name} ${elapsedTime}`
  } else if (callMode !== PREDIAL) {
    return `Call outcome ${forOrOnBehalfOf} ${name}`
  }
  return name
}

export default useTelephonyTitle
