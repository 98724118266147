import { useEffect, useState } from 'react'

const useIsContentEmpty = (subject, body) => {
  const [hasMadeMinimumEdits, setHasMadeMinimumEdits] = useState(false)

  useEffect(() => {
    const value =
      !subject?.previewOnly ||
      (subject?.previewOnly &&
        !body?.includes('[AGENT:') &&
        !body?.includes('[<u>AGENT:'))
    setHasMadeMinimumEdits(value)
  }, [subject, body])

  return hasMadeMinimumEdits
}

export default useIsContentEmpty
