// Third party ------------------------
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

// Components -------------------------
import { Button } from 'core/components'

// Styles -----------------------------
const Section = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const BottomPredialSection = ({ disabled, onClickClose, onClickDial }) => {
  return (
    <Section>
      <Button
        data-testid='button-cancel'
        margin='0 0 0 40px'
        onClick={onClickClose}
      >
        Cancel
      </Button>
      <Button
        data-testid='button-dial'
        margin='0 0 0 40px'
        onClick={onClickDial}
        readOnly={disabled}
        secondary
      >
        Dial
      </Button>
    </Section>
  )
}

BottomPredialSection.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClickClose: PropTypes.func.isRequired,
  onClickDial: PropTypes.func.isRequired
}

export default BottomPredialSection
