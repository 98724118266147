import _ from 'lodash'
import React, { useState } from 'react'
import {
  Switch,
  Route,
  Redirect,
  NavLink,
  useRouteMatch
} from 'react-router-dom'
import styled from 'styled-components/macro'
import AddressInput from 'components/AddressInput'
import CreateLoan from './components/CreateLoan'
import GenericApiCall from './components/GenericApiCall'
import Permissions from './Permissions'
import Environment from './components/Environment'
import Errors from './components/Errors'
import Auth from './components/Auth'
import Logos from './components/Logos'
import { _useUserType as useUserType } from 'core/SystemProvider/UserTypeProvider'

const Divider = styled.div`
  border-top: 1px solid gray;
  height: 32px;
  margin-top: 16px;
`

const Container = styled.div`
  margin: 16px;
`

const Link = styled(NavLink)`
  padding: 4px 8px;
  margin-right: 8px;
  border-radius: 4px;
  display: inline-block;
  &.active {
    background-color: #eee;
  }
`

const SysAdmin = () => {
  const userType = useUserType()
  const { path, url } = useRouteMatch()

  const [addressData, setAddressData] = useState(null)

  const onChangeAddressData = value => {
    if (value.isValid) {
      setAddressData(value.addressData)
    }
  }

  if (process.env.REACT_APP_ENV_LABEL === 'prod') {
    return <Redirect to='/' />
  }

  const tabs = [
    'create-loan',
    'api-call',
    'address',
    'permissions',
    'logos',
    'env',
    'errors',
    'auth'
  ]

  const displayTabs = _.map(tabs, tab => {
    return (
      <Link key={tab} to={`${url}/${tab}`}>
        {tab}
      </Link>
    )
  })

  return (
    <Container>
      {userType === 'agent' && <Link to='/crm'>{`← CRM`}</Link>}
      {userType === 'borrower' && <Link to='/loans'>{`← Loans`}</Link>}
      {displayTabs}
      <Divider />
      <Switch>
        <Route path={`${path}/create-loan`}>
          <CreateLoan />
        </Route>

        <Route path={`${path}/api-call`}>
          <GenericApiCall />
        </Route>

        <Route path={`${path}/address`}>
          <AddressInput
            addressData={addressData}
            onChange={onChangeAddressData}
            placeholder='Enter your address'
          />
        </Route>

        <Route path={`${path}/permissions`}>
          <Permissions />
        </Route>

        <Route path={`${path}/env`}>
          <Environment />
        </Route>

        <Route path={`${path}/errors`}>
          <Errors />
        </Route>

        <Route path={`${path}/logos`}>
          <Logos />
        </Route>

        <Route path={`${path}/auth`}>
          <Auth />
        </Route>

        <Redirect to={`${url}/create-loan`} />
      </Switch>
    </Container>
  )
}

export default SysAdmin
