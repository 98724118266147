const variables = {
  colorWhite: '#ffffff',
  colorBlack05: '#fafafa',
  colorBlack10: '#f5f8fc',
  colorBlack20: '#e1e4e8',
  colorBlack30: '#c9cdd1',
  colorBlack40: '#b1b5ba',
  colorBlack50: '#999da2',
  colorBlack60: '#6b7075',
  colorBlack70: '#53585d',
  colorBlack80: '#3b4147',
  colorBlack90: '#23292f',
  colorBlack100: '#171c21',
  colorBluePrimary: '#0f72e5',
  colorBlueSecondary: '#0a93eb',
  colorBlueTertiary: '#1db4ff',
  colorBlueLighten: '#d8eefc',
  colorGreen: '#2bcb96',
  colorGreenLighten: '#d5f0e9',
  colorRed: '#f23548',
  colorRedLighten: '#fddfe2',
  colorOrange: '#fe843e',
  colorOrangeLighten: '#fbebe2',
  colorYellow: '#fed74c',
  colorYellowLighten: '#fbf5e3',
  colorMagenta: '#f52a79',
  colorPurple: '#691fdd',
  colorViolet: '#7357ff',
  peachyShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
  overlay: 'rgba(0, 0, 0, 0.16)',
  overlayDark: 'rgba(0, 0, 0, 0.5)'
}

export default variables
