import format from 'date-fns/format'
import parse from 'date-fns/parse'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import styled from 'styled-components/macro'

import Datepicker from './Datepicker'
import { datestring } from '../../types'
import { useTime } from '../../TimeProvider'
import variables from 'core/styles/variables'

export const Container = styled.div`
  width: 100%;

  & .react-datepicker__day--today.react-datepicker__day--disabled {
    border-color: ${variables.colorBlack50};
  }

  & [aria-label="${p => p.ariaToday}"] {
    font-weight: 500;
    border: 1px solid #23292f !important; // literal color hex to match react-datepicker styling
    border-radius: 4px !important;
  }
`

/** Normally we don't want to use date-fns, but the underlying Datepicker
 * has to use date objects (with timestamps) in the local time. So we trick
 * it by taking out timezoned date strings, pretend like they are in local
 * time, and parse them into a date object with date-fns's parse function.
 * Then when converting back out, we take the date object that thinks it's
 * representing local time, and we just take the date string from it, which
 * is actually according to the context timezone. Note that datestrings
 * (without timestamps) passed from the backend are assumed to be in the
 * company timezone. If you take that string you can directly pass it into
 * the props of TzDatepicker.
 *
 * NOTE: parse now takes 3 arguments due to the update
 * https://date-fns.org/v2.22.1/docs/parse#v2.0.0-breaking-changes
 */
const convertIn = tzDateStr => (tzDateStr ? parse(tzDateStr, 'yyyy-MM-dd', new Date()) : null)
const convertOut = naiveDate =>
  naiveDate ? format(naiveDate, 'yyyy-MM-dd') : null

const TzDatepicker = ({
  dateFormat,
  disabled,
  filterDate,
  getSelectedDate,
  isClearable,
  isUncontrolled,
  maxDate,
  minDate,
  onChange,
  placeholderText,
  popperModifiers,
  popperPlacement,
  selectedDate,
  width
}) => {
  const { format, getNow, isToday } = useTime()
  const ariaToday = useMemo(
    () => `Choose ${format(getNow(), 'dddd, MMMM Do, Y')}`,
    [format, getNow]
  )

  return (
    <Container ariaToday={ariaToday}>
      <Datepicker
        dateFormat={dateFormat}
        disabled={disabled}
        filterDate={naiveDate => filterDate(convertOut(naiveDate))}
        getSelectedDate={naiveDate => getSelectedDate(convertOut(naiveDate))}
        isClearable={isClearable}
        isSelectedToday={() => isToday(selectedDate)}
        isUncontrolled={isUncontrolled}
        maxDate={convertIn(maxDate)}
        minDate={convertIn(minDate)}
        onChange={naiveDate => onChange(convertOut(naiveDate))}
        placeholderText={placeholderText}
        popperModifiers={popperModifiers}
        popperPlacement={popperPlacement}
        selectedDate={convertIn(selectedDate)}
        width={width}
      />
    </Container>
  )
}

TzDatepicker.propTypes = {
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  filterDate: PropTypes.func,
  getSelectedDate: PropTypes.func,
  isClearable: PropTypes.bool,
  isUncontrolled: PropTypes.bool,
  maxDate: datestring,
  minDate: datestring,
  onChange: PropTypes.func,
  placeholderText: PropTypes.string,
  popperModifiers: PropTypes.object,
  popperPlacement: PropTypes.string,
  selectedDate: datestring,
  width: PropTypes.string
}

TzDatepicker.defaultProps = {
  filterDate: () => true
}

export default TzDatepicker
