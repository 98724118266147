// Third party --------------
import _ from 'lodash'
import { Client as TwilioConversation } from '@twilio/conversations'

// Rest ---------------------
import fetchTwilioAccessTokens from 'crm/helpers/fetchTwilioAccessTokens'
import { peachApi } from 'core/api'

const createTwilioClient = async () => {
  try {
    const tokens = await fetchTwilioAccessTokens()
    const { accessToken } = tokens
    const initializedClient = await TwilioConversation.create(accessToken)
    return initializedClient
  } catch (e) {
    throw e
  }
}

const closeConversation = async ({ interactionId }) => {
  try {
    await peachApi.post({
      url: '/twilio/close-convo',
      body: { interactionId }
    })
  } catch (e) {
    throw e
  }
}

const setupTwilioClient = async ({
  client,
  setConnectionState,
  setConversation,
  setFocusTextarea,
  setIsLoadingMessages,
  setMessages,
  setNoConversationFound,
  setShowNoConversationModal,
  twilioConvoSid
}) => {
  /**
   * This setTimeout is a crude way to check whether the
   * conversations within the client object is available
   * and matches the given twilioConvoSid of the interaction.
   * The conversations object map does not register all the
   * available conversations immediately and we need to wait
   * a bit to be able to accurately check if the object map
   * contains conversations, and that there is a matching
   * conversation with the give twilioConvoSid.
   */
  setTimeout(() => {
    const {
      connectionState,
      conversations: { conversations }
    } = client
    const size = _.size(conversations)
    const thisConvo =
      (conversations && conversations.get(twilioConvoSid)) || null
    if (connectionState === 'connected') {
      if (size === 0 || !thisConvo) {
        setNoConversationFound(true)
        setShowNoConversationModal(true)
      } else {
        setNoConversationFound(false)
        setShowNoConversationModal(false)
      }
    }
  }, [500])
  client.on('connectionStateChanged', state => {
    setConnectionState(state)
  })
  client.on('conversationJoined', async thisConversation => {
    if (thisConversation?.sid !== twilioConvoSid) return

    setIsLoadingMessages(true)
    setConversation(thisConversation)
    const messagePaginator = await thisConversation.getMessages()

    const getPrev = async (paginator, items) => {
      const { items: pageItems, hasPrevPage } = paginator || {}
      items = [...pageItems, ...items]
      if (hasPrevPage) {
        const prevPage = await paginator.prevPage()
        return getPrev(prevPage, items)
      }
      return items
    }
    const items = await getPrev(messagePaginator, [])
    setFocusTextarea(true)
    setIsLoadingMessages(false)
    setMessages(items)
    setNoConversationFound(false)
    setShowNoConversationModal(false)
  })
}

const createAndSetupTwilioClient = async ({
  setConnectionState,
  setConversation,
  setFocusTextarea,
  setIsLoadingMessages,
  setMessages,
  setNoConversationFound,
  setShowNoConversationModal,
  twilioConvoSid
}) => {
  const client = await createTwilioClient()
  setupTwilioClient({
    client,
    setConnectionState,
    setConversation,
    setFocusTextarea,
    setIsLoadingMessages,
    setMessages,
    setNoConversationFound,
    setShowNoConversationModal,
    twilioConvoSid
  })
  return
}

const setupConversation = ({ conversation, setBlinkTopBar, setMessages }) => {
  conversation.on('messageAdded', message => {
    const { state } = message || {}
    const { author } = state || {}
    const isSentBySystem = author === 'system'
    const isSentByEmployee = _.startsWith(author, 'uid')
    setMessages(prevMessages => {
      return [...prevMessages, message]
    })
    if (!isSentByEmployee && !isSentBySystem) {
      setBlinkTopBar(true)
    }
  })
}

export {
  closeConversation,
  createAndSetupTwilioClient,
  createTwilioClient,
  setupConversation,
  setupTwilioClient
}
