/**
 * useContact should mainly be used to return a list of contacts
 * that is available for contact. Most relevant params include
 * whether the status is archived or not, or if authorizedThirdParty
 * is set to true or not. You can further narrow down the list by
 * specifying the contact contactType, affiliation, receiveTextMessages
 * and label.
 */
// Third party ------------------------
import _ from 'lodash'

// Proprietary ------------------------
import capitalizeFirstLetter from 'core/helpers/capitalizeFirstLetter'
import formatAddress from 'core/helpers/formatAddress'
import isCollectionLoading from 'core/helpers/isCollectionLoading'
import useCollection from 'core/hooks/useCollection'
import { formatPhone } from 'core/helpers/phoneNumberFormatter'

// const exports ----------------------

// Contact object keys
export const KEY_AFFILIATION = 'affiliation'
export const KEY_AUTHORIZED_THIRD_PARTY = 'authorizedThirdParty'
export const KEY_CONTACT_TYPE = 'contactType'
export const KEY_ID = 'id'
export const KEY_LABEL = 'label'
export const KEY_NAME = 'name'
export const KEY_RECEIVE_TEXT_MESSAGES = 'receiveTextMessages'
export const KEY_STATUS = 'status'
export const KEY_VALID = 'valid'
export const KEY_VERIFIED = 'verified'

// Affiliation enums
export const AFFILIATION_ADMINISTRATOR = 'administrator'
export const AFFILIATION_EXECUTOR = 'executor'
export const AFFILIATION_GUARDIAN = 'guardian'
export const AFFILIATION_LEGAL = 'legal'
export const AFFILIATION_OTHER_RELATIVE = 'otherRelative'
export const AFFILIATION_PARENT = 'parent'
export const AFFILIATION_SELF = 'self'
export const AFFILIATION_SPOUSE = 'spouse'
export const AFFILIATION_ADDITIONAL = 'additional'
export const AFFILIATION_BUSINESS_OWNER = 'businessOwner'
export const AFFILIATION_BUSINESS_EXECUTIVE = 'businessExecutive'
export const AFFILIATION_BUSINESS_EMPLOYEE = 'businessEmployee'

// Contact type enums
export const CONTACT_TYPE_ADDRESS = 'address'
export const CONTACT_TYPE_EMAIL = 'email'
export const CONTACT_TYPE_PHONE = 'phone'

// Label enumbs
export const LABEL_PERSONAL = 'personal'
export const LABEL_HOME = 'home'
export const LABEL_WORK = 'work'
export const LABEL_MOBILE = 'mobile'

// Status enums
/**
 * 'active' is a status specific to legal, but in this hook
 * legal and contact are treated the same.
 * */
export const STATUS_ACTIVE = 'active'
export const STATUS_ADDITIONAL = 'additional'
export const STATUS_PRIMARY = 'primary'
export const STATUS_SECONDARY = 'secondary'
// ------------------------------------

// This is used to sort the order of the select group
const sortOrder = [
  AFFILIATION_SELF,
  AFFILIATION_LEGAL,
  AFFILIATION_BUSINESS_OWNER,
  AFFILIATION_BUSINESS_EXECUTIVE,
  AFFILIATION_BUSINESS_EMPLOYEE,
  AFFILIATION_SPOUSE,
  AFFILIATION_EXECUTOR,
  AFFILIATION_ADMINISTRATOR,
  AFFILIATION_OTHER_RELATIVE,
  AFFILIATION_GUARDIAN,
  AFFILIATION_PARENT
]

const filterableKeys = [
  KEY_AFFILIATION,
  KEY_AUTHORIZED_THIRD_PARTY,
  KEY_CONTACT_TYPE,
  KEY_LABEL,
  KEY_RECEIVE_TEXT_MESSAGES,
  KEY_STATUS,
  KEY_VALID,
  KEY_VERIFIED
]

/**
 * Filtering through the list of contacts has some special cases
 * depending on the affiliation type, and perhaps some other
 * criteria in the future. For now we're adding exceptions to
 * legal and self affiliations.
 */
// Keys to skip for legal and self affiliations
const skipKeyForLegal = [
  KEY_AUTHORIZED_THIRD_PARTY,
  KEY_LABEL,
  KEY_VALID,
  KEY_VERIFIED
]
const skipKeyForSelf = [KEY_AUTHORIZED_THIRD_PARTY]
// Custom filtering method
const includeInFilter = (key, value, contact) => {
  return (
    (skipKeyForLegal.includes(key) &&
      contact.affiliation === AFFILIATION_LEGAL) ||
    (skipKeyForSelf.includes(key) &&
      contact.affiliation === AFFILIATION_SELF) ||
    (Array.isArray(value) && value.includes(contact[key])) ||
    contact[key] === value
  )
}

const useContactsSelectOptions = ({
  borrowerId,
  /**
   * The `filterBy` param takes an object where each key is a key
   * that exists on the contact model and the value is either a string,
   * boolean, or an array of values that are valid for each key,
   * according to our contacts api docs.
   *
   * ex: filterBy = { authorizedThirdParty: true, contactType: ['phone', 'email'] }
   */
  filterBy,
  groupedBy,
  groupWithAdditionalContacts,
  isBusinessBorrower = false,
  label, // Use this to replace the contact.label value with another field's value
  removeDuplicateBy, // Removes duplicates by any options values
  useSublabels,
  value // Use this to replace the contact.value value with another field's value
}) => {
  const allContacts = useCollection('contacts', { personId: borrowerId })
  if (isCollectionLoading(allContacts)) return null
  if (!borrowerId) return null

  // Fist create a list of contacts that have valid data and without legal affiliations
  let contacts = Object.values(allContacts.byId)

  // Sort contacts by affiliation using `sortOrder`
  contacts = _.sortBy(contacts, c => _.indexOf(sortOrder, c.affiliation))

  // Run filter
  filterBy &&
    _.forEach(Object.entries(filterBy), ([key, value]) => {
      if (filterableKeys.includes(key)) {
        contacts = _.filter(contacts, c => includeInFilter(key, value, c))
      }
    })

  const baseOptions = _.map(contacts, c => {
    const isLabelPersonal = c.label === LABEL_PERSONAL
    const isLabelHome = c.label === LABEL_HOME
    const isLabelWork = c.label === LABEL_WORK
    const isAddressContactType = c.contactType === CONTACT_TYPE_ADDRESS
    const isPhoneContactType = c.contactType === CONTACT_TYPE_PHONE
    const isAffiliationSelf = c.affiliation === AFFILIATION_SELF
    const isAffiliationLegal = c.affiliation === AFFILIATION_LEGAL
    const isPrimary = c.status === STATUS_PRIMARY
    const isSecondary = c.status === STATUS_SECONDARY

    const newLabel = isLabelPersonal ? LABEL_MOBILE : c.label
    const option = {
      affiliation: isAffiliationSelf ? 'self' : c.affiliation,
      contactType: c.contactType,
      label:
        isAffiliationSelf && isPhoneContactType
          ? capitalizeFirstLetter(newLabel)
          : c.label,
      /**
       * This helps us to further group certain contacts
       * into a specific category without altering the
       * value of its affiliation
       */
      group:
        groupWithAdditionalContacts && !isAffiliationSelf && !isAffiliationLegal
          ? AFFILIATION_ADDITIONAL
          : isAffiliationSelf
          ? 'borrower'
          : c.affiliation,
      id: c.id,
      name: c.name,
      status: c.status,
      value: value ? c[value] : c.value
    }
    if (useSublabels) {
      if (!isAffiliationLegal) {
        option.sublabel = capitalizeFirstLetter(c.status)
      } else {
        option.sublabel = `Legal Representation - Primary ${c.contactType}`
      }
    }
    /**
     * For the label we want to check if the actual contact's type is
     * phone or email or not, and the the contactType prop passed into
     * this hook. This way, even if we don't filter by contactType, each
     * option will output the correct label according to its individual
     * contactType. For legal reps, the label is already created so just
     * use that.
     */
    const displayLabelValue = isPhoneContactType
      ? formatPhone(c.value)
      : c.value

    const phoneDisplayLabelPrefix = isAffiliationSelf
      ? isLabelPersonal
        ? `${capitalizeFirstLetter(LABEL_MOBILE)} `
        : isBusinessBorrower
        ? (isPrimary || isSecondary) && (isLabelHome || isLabelWork)
          ? 'Company '
          : c.name
          ? `${c.name} `
          : ''
        : `${capitalizeFirstLetter(c.label)} `
      : c.name
      ? `${c.name} `
      : ''

    /**
     * This display label should be used for any contact associated
     * with a human borrower and some cases for business borrowers.
     */
    const displayLabel = label
      ? c[label]
      : isAddressContactType
      ? `${c.name ? `${c.name}, ` : ''}${formatAddress(c.address, {
          singleLine: true
        })}`
      : isAffiliationLegal
      ? `${c.name !== null ? `${c.name} ` : ''}${displayLabelValue}`
      : isPhoneContactType
      ? `${phoneDisplayLabelPrefix}${displayLabelValue}`
      : displayLabelValue

    option.displayLabel = displayLabel

    return option
  })

  let options = []

  if (removeDuplicateBy) {
    _.forEach(baseOptions, baseOption => {
      let hasDuplicate = false
      _.forEach(options, o => {
        if (o[removeDuplicateBy] === baseOption[removeDuplicateBy]) {
          hasDuplicate = true
        }
      })
      if (!hasDuplicate) options.push(baseOption)
    })
  } else {
    options = baseOptions
  }

  const groupedOptions = []

  // Grouped by
  if (groupedBy) {
    const groupings = {}
    _.forEach(options, o => {
      /**
       * The below logic is used for a very specific use case. If the
       * `groupWithAdditionalContacts` is set to true and the `groupBy`
       * value is specifically 'affiliation' then we want to group each
       * contact using the contact.group field. Otherwise, proceed with
       * grouping by whatever the value of `groupBy` is.
       */
      if (groupWithAdditionalContacts && groupedBy === KEY_AFFILIATION) {
        if (groupings[o.group]) {
          groupings[o.group].push(o)
        } else {
          groupings[o.group] = [o]
        }
      } else {
        if (groupings[o[groupedBy]]) {
          groupings[o[groupedBy]].push(o)
        } else {
          groupings[o[groupedBy]] = [o]
        }
      }
    })
    _.forEach(Object.entries(groupings), ([key, value]) => {
      groupedOptions.push({
        label: key.toUpperCase(),
        options: value
      })
    })
  }

  /**
   * Place the borrower group to the top of the grouped options list.
   */
  _.forEach(groupedOptions, (option, index) => {
    const { label } = option
    if (label === 'BORROWER' && index > 0) {
      groupedOptions.splice(index, 1)
      groupedOptions.unshift(option)
    }
  })

  return groupedBy ? groupedOptions : options
}

const findPrimarySelf = options =>
  _.find(options, { affiliation: AFFILIATION_SELF, status: STATUS_PRIMARY })

const getPrimarySelf = (options, isGrouped) => {
  const $options = isGrouped
    ? _.find(options, { label: 'BORROWER' })?.options
    : options
  return findPrimarySelf($options) || null
}

const findContactById = (_options, _id) => _.find(_options, { id: _id })

const getContactById = (options, id) => {
  if (!options) return
  let contact = null

  if (options[0].options) {
    _.each(options, option => {
      const tempContact = findContactById(option.options, id)
      if (tempContact) contact = tempContact
    })
  } else {
    contact = findContactById(options, id)
  }

  return contact
}

export { getPrimarySelf, getContactById }

export default useContactsSelectOptions
