import { LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

const getPreviousInteractions = ({
  companyId,
  id,
  key,
  previousInteractions = []
}) => {
  if (!id) return

  return async dispatch => {
    if (key && previousInteractions.length === 0) {
      dispatch({ type: LOADING, key, status: 'loading' })
    }

    try {
      const response = await peachApi.get({
        url: `/companies/${companyId}/interactions/${id}`
      })
      const { data: interaction } = response
      const { previousInteractionId } = interaction
      const newPreviousInteractions = [...previousInteractions, interaction]
      if (previousInteractionId) {
        return dispatch(
          getPreviousInteractions({
            companyId,
            id: previousInteractionId,
            key,
            previousInteractions: newPreviousInteractions
          })
        )
      } else {
        if (key) {
          dispatch({ type: LOADING, key, status: 'success' })
        }
        return newPreviousInteractions
      }
    } catch (e) {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
    }
  }
}

export default getPreviousInteractions
