// Third party --------------
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components/macro'

// Components ---------------
import { Textarea } from 'core/components'

// Rest ---------------------
import { useCompanyInfo, useOnMount } from 'core/hooks'
import { variables } from 'core/styles'

// Styled components --------
const Container = styled.div`
  border-top: ${p =>
    p.noBorderTop ? 'none' : `1px solid ${variables.colorBlack20}`};
`
const Counter = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: ${p => p.padding};
  padding-top: 0;
  padding-bottom: 0;
`

const MessageTextarea = ({
  disabled,
  noBorderTop,
  onChange,
  onSendMessage,
  padding,
  setFocusTextarea,
  focusTextarea,
  value
}) => {
  const MAX_CHARACTER_COUNT = 140
  const { brandName } = useCompanyInfo()

  /**
   * This is to account for the ': ' punctuation to come
   * after the brand name prefix.
   */
  const brandPrefix = `${brandName}: `
  const prefixCount = brandPrefix.length
  const valueCount = value.length

  /** Displayed count values */
  const perMessageValueCount =
    MAX_CHARACTER_COUNT - (valueCount % MAX_CHARACTER_COUNT)
  const messageCount = _.floor(valueCount / MAX_CHARACTER_COUNT) + 1
  /** ---------------------- */

  useOnMount(() => {
    onChange(brandPrefix)
  })

  /**
   *
   * This method will only update the textarea content if
   * the brand prefix is unchanged in the new passed value.
   * @param {String} newValue
   *
   */
  const _onChange = newValue => {
    const newPrefix = newValue.substring(0, prefixCount)
    if (newPrefix === brandPrefix) {
      onChange(newValue)
    }
  }

  const onKeyPress = evt => {
    if (evt.keyCode === 13 && evt.metaKey) {
      onSendMessage && onSendMessage()
    }
  }

  return (
    <Container noBorderTop={noBorderTop}>
      <Textarea
        disabled={disabled}
        height='90px'
        noBorder
        onChange={_onChange}
        onKeyDown={onKeyPress}
        padding={padding || '8px 16px'}
        setFocusTextarea={setFocusTextarea}
        focusTextarea={focusTextarea}
        value={value}
      />
      <Counter data-testid='counter' padding={padding || '0 16px 0 0'}>
        {perMessageValueCount}/{messageCount}
      </Counter>
    </Container>
  )
}

MessageTextarea.propTypes = {
  disabled: PropTypes.bool,
  noBorderTop: PropTypes.bool,
  onChange: PropTypes.func,
  onSendMessage: PropTypes.func,
  padding: PropTypes.string,
  value: PropTypes.string
}

export { Container, Counter }
export default MessageTextarea
