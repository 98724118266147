import { UPDATE_UNBOUND_INTERACTION, LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

const updateUnboundInteraction = ({ key, companyId, id, body }) => {
  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    try {
      const response = await peachApi.put({
        url: `/companies/${companyId}/interactions/${id}`,
        body
      })
      dispatch({
        type: UPDATE_UNBOUND_INTERACTION,
        payload: response.data,
        companyId,
        id
      })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
      return response
    } catch {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
    }
  }
}

export default updateUnboundInteraction
