// Third party ------------------------
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'

import SysAdmin from 'sysAdmin/pages/SysAdmin'

// userTypes specific routes
import { AgentRoutes, BorrowerRoutes } from 'app/routes'
import AdminWrapper from 'app/routes/AdminWrapper'
import SignOutRoute from 'app/routes/SignOutRoute'
import LoginRedirect from './loginRedirect/LoginRedirect'

const AppRoutes = props => {
  const { appDomainType, sessionPersonId, companyTheme, authType } = props

  return (
    <Switch>
      <Route path='/sign-out'>
        <SignOutRoute />
      </Route>

      <Route path='/sys'>
        <SysAdmin />
      </Route>

      <Route path='/sys-admin'>
        <Redirect to='/sys' />
      </Route>

      <Route
        render={() => {
          switch (appDomainType) {
            case 'agent':
              return (
                <AgentRoutes companyTheme={companyTheme} authType={authType} />
              )

            case 'borrower':
              return (
                <BorrowerRoutes
                  key={sessionPersonId}
                  basePath=''
                  baseUrl=''
                  borrowerId={sessionPersonId} // the user is the borrower
                  userType='borrower'
                  companyTheme={companyTheme}
                  authType={authType}
                />
              )

            case 'admin':
              return <AdminWrapper />

            default:
              return <LoginRedirect />
          }
        }}
      />
    </Switch>
  )
}

AppRoutes.propTypes = {
  appDomainType: PropTypes.oneOf(['borrower', 'agent', 'admin']),
  sessionPersonId: PropTypes.string,
  companyTheme: PropTypes.object,
  authType: PropTypes.string
}

export default AppRoutes
