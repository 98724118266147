/** Returns an error object that can be thrown, after doing
 * its best to glean information from the response text to
 * add to the error object.
 * @param response - The response object. Make sure its stream
 * hasn't already been read yet by .text() or .json().
 * */
const parseErrorResponse = async response => {
  // start with at least default error
  const error = {
    status: response?.status || 0,
    message: 'Unknown Error'
  }

  try {
    const { status: statusFromJson, message, ...rest } = await response.json()
    if (statusFromJson) error.status = statusFromJson
    if (message) error.message = message
    Object.assign(error, rest)
  } catch (e) {}

  return error
}

export default parseErrorResponse
