import { peachApi } from 'core/api'

/**
 * @returns {Object|null} - For failures or if data is undefined, return null
 */
export const createDocumentUploadLink = async (
  borrowerId,
  contactId,
  caseId
) => {
  try {
    const resp = await peachApi.post({
      url: `/people/${borrowerId}/document-upload-links`,
      body: {
        contactId,
        caseId
      }
    })
    return resp.data || null
  } catch {
    return null
  }
}
