// Third party ------------------------
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

// Proprietary ------------------------
import variables from 'core/styles/variables'
import { moveCursorPositionToEnd } from 'core/helpers/input'

// Components -------------------------
import { Button } from 'core/components'

// Styles -----------------------------
const NoteSection = styled.div`
  display: flex;
  align-items: flex-start;
`
const NoteLabel = styled.div`
  flex: 0 1 104px;
  color: ${variables.colorBlack60};
  font-size: 14px;
  font-weight: 300;
`
const Textarea = styled.textarea`
  flex: 1 0 auto;
  box-sizing: border-box;
  margin-bottom: 16px;
  border: 1px solid ${variables.colorBlack20};
  border-radius: 8px;
  padding: 4px 8px;
  height: 88px;
  color: ${variables.colorBlack70};
  font-size: 14px;
`
const ButtomSection = styled.div`
  display: flex;
  justify-content: flex-end;
`
const BottomPostDialSection = ({
  caseNote,
  isSaveDisabled,
  onClickSave,
  selectedCase,
  setCaseNote
}) => {
  return (
    <>
      {selectedCase !== null && (
        <NoteSection data-testid='note-section'>
          <NoteLabel>
            <div>Case note</div>
            <div>(Optional)</div>
          </NoteLabel>
          <Textarea
            data-testid='textarea'
            onChange={e => setCaseNote(e.target.value)}
            onFocus={e => moveCursorPositionToEnd(e)}
            value={caseNote}
          />
        </NoteSection>
      )}
      <ButtomSection>
        <Button
          data-testid='button-save'
          margin='0 0 0 40px'
          onClick={onClickSave}
          readOnly={isSaveDisabled}
          secondary
        >
          Save
        </Button>
      </ButtomSection>
    </>
  )
}

BottomPostDialSection.propTypes = {
  caseNote: PropTypes.string,
  isSaveDisabled: PropTypes.bool,
  onClickSave: PropTypes.func,
  selectedCase: PropTypes.object,
  setCaseNote: PropTypes.func
}

export default BottomPostDialSection
