// Third party ------------------------
import React, { useMemo } from 'react'

// Proprietary ------------------------
import fetchBootstrapData from './fetchBootstrapData'
import useRead from 'core/hooks/useRead'
import { parseBoostrapInfo, formatTheme } from './customizationsHelpers'

// Components -------------------------
import App from 'app/App'
import EnvProviders from './env/EnvProviders'
import SystemProviders from './SystemProviders'
import { ErrorBoundary, Spinner } from 'core/components'

// Styles -----------------------------
import { Error } from './SystemProviderStyles'

const BootstrapManager = () => {
  const [boostrap, loading, error] = useRead(fetchBootstrapData)

  const {
    appDomainType,
    userType,
    companyId,
    timezone,
    colors,
    authType,
    authValueType,
    ssoUrl,
    company
  } = parseBoostrapInfo(boostrap)

  const companyTheme = useMemo(() => formatTheme(colors || {}), [colors])

  const isError = error || !appDomainType || !company
  const is503Error = error?.status === 503

  const content = loading ? (
    <Spinner height='100vh' />
  ) : is503Error ? (
    <Error>
      This site is briefly undergoing maintenance. We appreciate your patience.
    </Error>
  ) : isError ? (
    <Error>Something went wrong. Please try refreshing the page.</Error>
  ) : (
    <SystemProviders
      appDomainType={appDomainType}
      userType={userType}
      companyId={companyId}
      company={company}
      timezone={timezone}
    >
      <App
        appDomainType={appDomainType}
        userType={userType}
        companyId={companyId}
        authType={authType}
        authValueType={authValueType}
        companyTheme={companyTheme}
        ssoUrl={ssoUrl}
      />
    </SystemProviders>
  )

  // Success state
  return (
    <ErrorBoundary>
      <EnvProviders>{content}</EnvProviders>
    </ErrorBoundary>
  )
}

export default BootstrapManager
