import _ from 'lodash'

const getContactInfo = ({
  channel,
  contact: passedContact,
  content,
  isReply
} = {}) => {
  const isEmail = channel === 'email'
  const isMail = channel === 'mail'

  if (isReply) {
    if (!content || !isEmail) return ''
    /**
     * Every channel has a different content object structure.
     * This is currently used only for emails, but can be
     * refactored to handle any other channel. For now, we'll
     * default to empty strings if current channel is not email
     * when replying to an interaction.
     */
    const name = content.from?.name || ''
    const contact = content.from?.email || ''

    return _.trim(`${name} <${contact}>`)
  } else {
    if (_.isEmpty(passedContact) || !passedContact?.id) return

    const { name, value, displayLabel } = passedContact

    return isMail
      ? _.trim(`${name || ''} ${displayLabel}`)
      : _.trim(`${name || ''} <${value}>`)
  }
}

export default getContactInfo
