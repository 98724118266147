// Third party --------------
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'

// Components ---------------
import CheckboxListSection from 'crm/components/CheckboxListSection'
import Divider from 'loan/components/actionCards/shared/Divider'
import EditorToolbar from '../EditorToolbar'
import Icon from 'core/components/Icon'
import StyledAttachment from 'components/ResourceOverlay/StyledAttachment'
import {
  ActionButtons,
  Button,
  InfoCard,
  Modal,
  Tooltip
} from 'core/components'

// Rest ---------------------
import getLoanDocsByLoanIds from 'crm/helpers/getLoanDocsByLoanIds'
import variables from 'core/styles/variables'
import {
  DRAFT_MODE,
  PREVIEW_MODE
} from 'components/ResourceOverlay/DraftInteraction'
import { DOCUMENT, LOAN } from 'core/actions/constants'
import { useCase, useCollection } from 'core/hooks'

const Container = styled.div`
  display: flex;
  flex: 0 0 56px;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 0;
  margin-top: auto;
  height: 48px;
`
const ButtonGroup = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${variables.colorBlack40};
  border-radius: 4px;
  margin-right: 8px;
  height: 24px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 100%;
  }

  button.disabled {
    // Adding !important to keep Quill styles from
    // overriding our default button disabled styles
    // on hover.
    cursor: default !important;
    color: ${variables.colorBlack40} !important;
  }
`
const ButtonDivider = styled.span`
  background: ${variables.colorBlack40};
  width: 1px;
  height: 20px;
`
const NoLoanDocs = styled.div`
  padding: 0 24px 40px;
  color: var(--colorBlack50);
`
const Attachments = styled.div`
  position: relative;
  top: ${p => `-${p.length * 48}px`};
  height: 0;
`
const ToolbarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-left: 16px;

  ${p =>
    p.showToolbar
      ? 'opacity: 1; visibility: visible;'
      : 'opacity: 0; visibility: hidden;'}
`

const SendRow = ({
  attachments,
  borrowerId,
  caseId,
  channel,
  documents,
  formatTime,
  id,
  insertUploadLink,
  isAttachmentsDisabled,
  isSubmitDisabled,
  isUploadLinkDisabled,
  mode,
  onCancel,
  onPreview,
  onSubmit,
  previewOnly,
  setMode,
  updateAttachments
}) => {
  const [showModal, setShowModal] = useState(false)
  const [showToolbar, setShowToolbar] = useState(false)
  const currentCase = useCase(caseId, { personId: borrowerId })
  const loansById = useCollection('loans', { personId: borrowerId }).byId

  const associatedDocs = _.filter(currentCase.associations, {
    objectType: DOCUMENT
  })
  const associatedLoanIds = _.filter(currentCase.associations, [
    'objectType',
    LOAN
  ]).map(loan => loan.objectId)
  const loanDocsByLoanId = getLoanDocsByLoanIds(associatedLoanIds, documents)
  const loanDocs = _.flatten(_.values(loanDocsByLoanId))

  const isText = channel === 'text'

  const canShowToolbar = previewOnly !== false && !isText

  const isDraftMode = mode === DRAFT_MODE
  const isPreviewMode = mode === PREVIEW_MODE

  const showTextFormatButton = previewOnly && !isText && isDraftMode

  // ----------------------------------
  // Set checked values
  const docsHash = _.keyBy(loanDocs, 'id')
  const initialCheckedState = _.mapValues(docsHash, doc => {
    return {
      checked:
        _.some(associatedDocs, { objectId: doc.id }) ||
        _.includes(attachments, doc.id)
    }
  })
  const [checked, setChecked] = useState(initialCheckedState)
  const [previousChecked, setPreviousChecked] = useState({})
  const previousCaseId = useRef()
  useEffect(() => {
    if (caseId !== previousCaseId.current) {
      setChecked(initialCheckedState)
      previousCaseId.current = caseId
    }
  }, [caseId, previousCaseId, initialCheckedState, setChecked])

  const previousAttachments = useRef()
  useEffect(() => {
    if (attachments !== previousAttachments.current) {
      const newCheckedState = _.mapValues(docsHash, doc => {
        return {
          checked: _.includes(attachments, doc.id)
        }
      })
      setChecked(newCheckedState)
      previousAttachments.current = attachments
    }
  }, [attachments, previousAttachments, docsHash])

  const onChange = (id, value) => {
    const newState = {
      ...checked,
      [id]: {
        checked: value
      }
    }
    setChecked(newState)
  }

  const onAddLoanDoc = () => {
    const docIds = _.keys(_.pickBy(checked, 'checked'))
    updateAttachments(docIds)
    setShowModal(false)
  }

  const onBack = () => {
    const docIds = _.keys(_.pickBy(previousChecked, 'checked'))
    updateAttachments(docIds)
    setShowModal(false)
  }

  const onOpenModal = () => {
    setPreviousChecked(checked)
    setShowModal(true)
  }

  const getAttachmentLabel = id => {
    const doc = _.find(documents, { id })
    const name = doc.description || doc.fileName
    const date = formatTime(doc.createdAt)
    return `${date}, ${name}`
  }

  const removeAttachment = id => {
    const newAttachments = _.filter(attachments, item => item !== id)
    updateAttachments(newAttachments)
  }

  const onClickPreview = () => {
    setMode(PREVIEW_MODE)
    onPreview()
  }

  return (
    <>
      {attachments?.length > 0 && (
        <Attachments length={attachments.length}>
          {attachments.map(id => (
            <StyledAttachment key={id} className={`attachment-${id}`}>
              {getAttachmentLabel(id)}
              <Button
                className={`button-remove-attachment-${id}`}
                color={variables.colorBlack70}
                fontSize='24px'
                onClick={() => removeAttachment(id)}
                readOnly={isPreviewMode}
              >
                {isDraftMode && <Icon name='clear' />}
              </Button>
            </StyledAttachment>
          ))}
        </Attachments>
      )}
      <Container>
        {canShowToolbar && (
          <ToolbarContainer showToolbar={showToolbar}>
            <EditorToolbar id={id} />
          </ToolbarContainer>
        )}
        {showTextFormatButton && (
          <ButtonGroup>
            <Button
              data-testid='button-text-format'
              readOnly={!canShowToolbar}
              onClick={() => setShowToolbar(!showToolbar)}
            >
              <Icon name='text_format' />
            </Button>
          </ButtonGroup>
        )}
        <ButtonGroup>
          {!isAttachmentsDisabled ? (
            <Button
              className='ql-addAttachment'
              onClick={onOpenModal}
              readOnly={isText}
            >
              <Icon name='attach_file' />
            </Button>
          ) : (
            <Tooltip
              data-testid='tooltip'
              animation='shift-away'
              arrow
              content='Case must be selected in order to attach a loan document'
              trigger='mouseenter'
              width={240}
              placement='top'
              theme='light'
              color={variables.colorBlack80}
            >
              <Button className='ql-addAttachment' readOnly>
                <Icon name='attach_file' />
              </Button>
            </Tooltip>
          )}
          <ButtonDivider />
          <Tooltip
            animation='shift-away'
            arrow
            content='Generate link to securely upload documents associated to this borrower'
            trigger='mouseenter'
            width={320}
            placement='top'
            theme='light'
            color={variables.colorBlack80}
          >
            <Button
              onClick={insertUploadLink}
              className='ql-insertUploadLink'
              readOnly={isUploadLinkDisabled}
            >
              <Icon name='link' />
            </Button>
          </Tooltip>
        </ButtonGroup>
        {(isDraftMode || isText) && (
          <Button
            secondary
            onClick={onCancel}
            margin='0 32px 0 16px'
            color={variables.colorBlack90}
          >
            Cancel
          </Button>
        )}
        {isPreviewMode && !isText && (
          <Button
            data-testid='button-edit'
            secondary
            onClick={() => setMode(DRAFT_MODE)}
            margin='0 32px 0 16px'
            color={variables.colorBlack90}
          >
            Edit
          </Button>
        )}
        {isDraftMode && !isText && (
          <Button
            data-testid='button-preview'
            secondary
            readOnly={isSubmitDisabled}
            onClick={onClickPreview}
            margin='0 32px 0 0'
          >
            Preview
          </Button>
        )}
        {(isPreviewMode || isText) && (
          <Button
            secondary
            readOnly={isSubmitDisabled}
            onClick={onSubmit}
            margin='0 32px 0 0'
          >
            Send
          </Button>
        )}
      </Container>
      {showModal && (
        <Modal onClose={onBack} width={680}>
          <InfoCard key='add-loan-document' title='Add loan document'>
            {_.isEmpty(loanDocsByLoanId) ? (
              <NoLoanDocs>
                To attach a loan-document, a case with associated loans and loan
                documents has to be indicated.
              </NoLoanDocs>
            ) : (
              <>
                {_.map(loanDocsByLoanId, (documents, loanId) => (
                  <Fragment key={loanId}>
                    <Divider />
                    <CheckboxListSection
                      checked={checked}
                      documents={documents}
                      loanId={loanId}
                      loans={loansById}
                      onChange={onChange}
                      showSectionByDefault
                    />
                  </Fragment>
                ))}
              </>
            )}
            <ActionButtons
              mainActionLabel='Add loan document'
              secondaryActionLabel='Back'
              onMainAction={onAddLoanDoc}
              onSecondaryAction={onBack}
              disableMainAction={
                _.isEmpty(loanDocsByLoanId) ||
                _.isEqual(checked, previousChecked)
              }
            />
          </InfoCard>
        </Modal>
      )}
    </>
  )
}

SendRow.propTypes = {
  attachments: PropTypes.array,
  borrowerId: PropTypes.string,
  caseId: PropTypes.string,
  channel: PropTypes.string,
  documents: PropTypes.array,
  formatTime: PropTypes.func,
  id: PropTypes.string,
  insertUploadLink: PropTypes.func,
  isAttachmentsDisabled: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  isUploadLinkDisabled: PropTypes.bool,
  maximized: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  previewOnly: PropTypes.bool,
  updateAttachments: PropTypes.func
}

export { Attachments, ToolbarContainer }
export default SendRow
