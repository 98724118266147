import React from 'react'
import styled from 'styled-components/macro'
import variables from 'core/styles/variables'
import { useCompanyCopies } from 'core/hooks'

const Consents = styled.div`
  color: ${variables.colorBlack70};
`

const Content = styled.div`
  width: 100%;
  white-space: pre-wrap;
  color: ${variables.colorBlack70};
  a {
    text-decoration: none;
    color: ${variables.colorBluePrimary};

    &:hover {
      text-decoration: underline;
    }
  }
`

const FooterConsents = () => {
  const { consent } = useCompanyCopies()

  const consentHtml = { __html: consent }

  return (
    <Consents>
      {consent && <Content dangerouslySetInnerHTML={consentHtml} />}
    </Consents>
  )
}

export default FooterConsents
