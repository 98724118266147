import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

import { FormLabel } from 'core/components'

import { useRect, useBorrowerIsMobile } from 'core/hooks'

export const Container = styled.div`
  margin: ${p => p.margin};
  display: flex;
  align-items: flex-start;
`

export const Label = styled(FormLabel)`
  flex: 0 0 ${p => p.labelWidth || (p.shortLabel ? '40px' : '240px')};
  margin-right: ${p => (p.marginRight ? p.marginRight : '0px')};
`

const Content = styled.div`
  flex: 1 1 auto;
  position: relative;
`

const BlockContainer = styled.div`
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
`
const BlockLabel = styled(FormLabel)`
  display: block;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
`

const BlockContent = styled.div`
  padding: 8px;
`

const CardSection = props => {
  const { children, label, margin, shortLabel, labelWidth, marginRight } = props

  const isMobile = useBorrowerIsMobile()
  const [{ width }, ref] = useRect()

  const compact = isMobile || width < 400

  return compact ? (
    <BlockContainer ref={ref} margin={margin}>
      {label && <BlockLabel shortLabel={shortLabel} text={label} />}
      <BlockContent>{children}</BlockContent>
    </BlockContainer>
  ) : (
    <Container ref={ref} margin={margin}>
      {label && (
        <Label
          shortLabel={shortLabel}
          text={label}
          labelWidth={labelWidth}
          marginRight={marginRight}
        />
      )}
      <Content>{children}</Content>
    </Container>
  )
}

CardSection.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  /** Only provide this if you need to override the
   * default margin of 24px for some special use case.
   */
  margin: PropTypes.string,
  shortLabel: PropTypes.bool,
  labelWidth: PropTypes.string,
  marginRight: PropTypes.string
}
CardSection.defaultProps = {
  margin: '24px'
}

export default CardSection
