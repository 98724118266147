import _ from 'lodash'

import { GET_TEAM, GET_TEAMS } from 'core/actions/constants'

const initialState = {
  byId: {}
}

const teams = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEAMS:
      return {
        ...state,
        byId: _.keyBy(action.payload, 'id')
      }
    case GET_TEAM:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: action.payload
        }
      }
    default:
      return state
  }
}

export default teams
