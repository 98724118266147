import { POSTwithToken } from 'core/api/legacyApi'
import { handleErrors } from 'core/helpers/handleErrors'
import { APIendpoint, LOADING, getStoredToken } from 'core/actions/constants'
import { getGlobalValue } from 'core/badGlobalDoNotUse'
import { refreshLoanAndRelatedData } from './loanAndRelatedData'

const addInterestRate = ({
  loanId,
  caseId,
  annualRate,
  startDate,
  endDate,
  key
}) => {
  return async dispatch => {
    const personId = getGlobalValue('borrowerPersonId')

    const loanEndpoint = `${APIendpoint}/people/${personId}/loans`

    dispatch({ type: LOADING, key, status: 'loading' })

    const body = {
      caseId,
      interestRate: { annualRate, startDate, endDate }
    }
    const endpoint = `${loanEndpoint}/${loanId}/rates`

    const resp = await fetch(endpoint, POSTwithToken(getStoredToken(), body))
    const { status } = await handleErrors(resp)

    if (status === 201) {
      dispatch({ type: LOADING, key, status: 'success' })
      dispatch(refreshLoanAndRelatedData(loanId))
    } else {
      dispatch({ type: LOADING, key, status: 'error' })
    }
    return resp
  }
}
export { addInterestRate }
