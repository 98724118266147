import {
  GET_VERIFICATIONS,
  SET_TOO_MANY_VERIFICATION_REQUESTS,
  SET_UNABLE_TO_VERIFY_BORROWER,
  START_VERIFICATION
} from 'core/actions/constants'

const initialState = {
  byId: {},
  allIds: [],
  tooManyVerificationAttempts: null,
  unableToVerify: false
}

const verifications = function(state = initialState, action) {
  switch (action.type) {
    case GET_VERIFICATIONS:
      return {
        ...state,
        // Reset this property back to null for future use cases
        // of new verification requests
        tooManyVerificationAttempts: null,
        byId: {
          // keep all existing verifications for other personIds
          ...Object.values(state.byId).reduce((acc, curr) => {
            if (curr.personId !== action.personId) {
              acc[curr.id] = curr
            }
            return acc
          }, {}),
          // insert the fresh verifications for this personId
          ...action.payload.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.id]: {
                ...curr,
                status: curr.status.toLowerCase()
              }
            }),
            {}
          )
        }
      }
    case START_VERIFICATION:
      return {
        ...state,
        unableToVerify: false,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...action.payload,
            status: action.payload.status.toLowerCase()
          }
        }
      }
    case SET_TOO_MANY_VERIFICATION_REQUESTS:
      return {
        ...state,
        tooManyVerificationAttempts: action.payload
      }
    case SET_UNABLE_TO_VERIFY_BORROWER:
      return {
        ...state,
        unableToVerify: action.value
      }
    default:
      return state
  }
}

export default verifications
