import _ from 'lodash'
/**
 *
 * @param {string[]} loanIds
 * @param {Object[]} documents
 *
 * @returns {Object}
 * ex: {
 *  'LN-1': [{ id: 'DD-1', ...rest }, { id: 'DD-2', ..rest }]
 * }
 *
 * Disclaimer: As long as a document has a non-null value
 * for loanId and isHumanBorrowerUpload is false, we are
 * considering it to be a loan document.
 */

const getLoanDocsByLoanIds = (loanIds, documents) => {
  const obj = {}
  const docsById = _.groupBy(documents, 'loanId')
  loanIds.forEach(loanId => {
    const loanDocs = _.filter(docsById[loanId], document => {
      const {
        contentType,
        isHumanBorrowerUpload,
        loanId: id,
        status
      } = document
      return (
        contentType === 'application/pdf' &&
        !isHumanBorrowerUpload &&
        id === loanId &&
        status !== 'draft'
      )
    })
    if (loanDocs.length > 0) obj[loanId] = loanDocs
  })
  return obj
}

export default getLoanDocsByLoanIds
