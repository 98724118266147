import { LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

const resendCommunication = ({ key, id }) => {
  return async dispatch => {
    if (key) {
      dispatch({ type: LOADING, key, status: 'loading' })
    }

    try {
      const response = await peachApi.post({
        url: `/communicator/resend`,
        body: { interactionId: id }
      })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
      return response.data
    } catch {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
    }
  }
}

export default resendCommunication
