// Third party --------------
import PropTypes from 'prop-types'
import React from 'react'

// Components ---------------
import {
  Amount,
  CustomMaskedInput,
  Container,
  Input,
  Label,
  SubLabel
} from './RadioStyles'

const Radio = props => {
  const {
    amount,
    bare,
    checked,
    children,
    customField,
    customFieldPlaceholder,
    customInputRef,
    disabled,
    getCustomAmount,
    id,
    label,
    name,
    onChange,
    subLabel,
    value,
    width
  } = props

  return (
    <Container data-cy={`radio-${value}`} className='radio'>
      <Input
        type='radio'
        name={name || label}
        id={id || label}
        value={value}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      {bare ? (
        <Label
          data-testid='label-bare'
          width={width}
          checked={checked}
          disabled={disabled}
          htmlFor={id || label}
        >
          {'\u00A0'}
        </Label>
      ) : children ? (
        <Label
          data-testid='label-with-children'
          width={width}
          checked={checked}
          disabled={disabled}
          htmlFor={id || label}
        >
          {children}
        </Label>
      ) : !customField ? (
        <>
          <Label
            data-testid='label-no-custom-field'
            checked={checked}
            disabled={disabled}
            htmlFor={id || label}
            width={width}
          >
            {label}
          </Label>
          {subLabel && <SubLabel>{subLabel}</SubLabel>}
          {amount && <Amount>{amount}</Amount>}
        </>
      ) : (
        <>
          <Label
            checked={checked}
            disabled={disabled}
            htmlFor={id || label}
            width={width}
          >
            {label}
          </Label>
          <CustomMaskedInput
            customInputRef={customInputRef}
            inputType='amount'
            onChange={e => getCustomAmount(e.target.value)}
            placeholder={customFieldPlaceholder}
          />
        </>
      )}
    </Container>
  )
}

Radio.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** If defined, children will be used to render the radio label
   * component instead of calling _renderRadioLabels.
   */
  bare: PropTypes.bool,
  checked: PropTypes.bool,
  children: PropTypes.node,
  customField: PropTypes.bool,
  customFieldPlaceholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  customInputRef: PropTypes.shape({
    current: PropTypes.object
  }),
  disabled: PropTypes.bool,
  getCustomAmount: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  subLabel: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** The full border-box width of the Radio, button and label included. */
  width: PropTypes.string
}

export default Radio
