import {
  GET_SUPERCASES,
  GET_MORE_SUPERCASES,
  LOADING
} from 'core/actions/constants'
import { peachApi } from 'core/api'

const getSupercases = ({ key, nextUrl }) => {
  return async dispatch => {
    if (key && !nextUrl) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    const endpoint = `/supercases?limit=100`

    try {
      const response = await peachApi.get({
        url: !nextUrl ? endpoint : null,
        fullUrl: nextUrl
      })
      dispatch({
        type: nextUrl ? GET_MORE_SUPERCASES : GET_SUPERCASES,
        payload: response.data
      })
      // The additional condition of response.nextUrl !== nextUrl
      // protects against infinite loops that could result from
      // mistakes in the backend code.
      if (response.nextUrl && response.nextUrl !== nextUrl) {
        dispatch(
          getSupercases({
            key,
            nextUrl: response.nextUrl
          })
        )
      } else {
        if (key) {
          dispatch({ type: LOADING, key, status: 'success' })
        }
      }
    } catch {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
    }
  }
}

export default getSupercases
