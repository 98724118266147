// Third Party ------------------------
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import styled from 'styled-components/macro'

// Proprietary ------------------------
import variables from 'core/styles/variables'
import { useRect } from 'core/hooks'

// Components -------------------------
import Icon from 'core/components/Icon'

// Styles -----------------------------
const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  overflow-y: auto;
  box-sizing: border-box;
  background: ${variables.overlayDark};
`
const InnerContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
`
const Content = styled.div`
  position: absolute;
  z-index: 3;
  border-radius: 8px;
  background: white;

  /**
   * This pseudo element is meant to include extra space
   * at the bottom of the modal when the modal is fixed to
   * the window.
   */
  &:after {
    display: block;
    position: absolute;
    top: 100%;
    content: '';
    width: 1px;
    height: ${p => (p.isFixed ? '80px' : '0')};
  }
`
const Close = styled.span`
  z-index: 3;
  position: absolute;
  color: white;
  font-size: 28px;
  cursor: pointer;
`
const ModalContent = styled.p`
  margin: 24px;
  font-size: 16px;
  color: ${variables.colorBlack70};
`
/**
 * This ModalActions styled component is used to style the
 * button actions at the bottom of a Modal. This only works
 * if you wrap our Button component or any element with the
 * .button class with this styled component.
 */
const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 32px 24px;
  font-size: 16px;
  color: ${variables.colorBlack90};

  .button:last-child {
    margin-left: 32px;
  }
`

const Modal = props => {
  const { canClose, onClose, children, width: propWidth } = props

  const $canClose = _.has(props, 'canClose') ? canClose : true

  const [
    { width: containerWidth, height: containerHeight },
    containerRef
  ] = useRect()
  const [{ width: contentWidth, height: contentHeight }, contentRef] = useRect()

  const isModalFixedToTop = useMemo(() => {
    return contentHeight >= containerHeight - 80
  }, [contentHeight, containerHeight])

  /**
   * This calculates where to position the close button
   * icon in relation to the modal.
   */
  const { closeTopPosition, closeRightPosition } = useMemo(() => {
    const uncenteredTop = 8
    const centeredTop = (containerHeight - contentHeight) / 2 - 32
    const top = isModalFixedToTop ? uncenteredTop : centeredTop
    const right = (containerWidth - contentWidth) / 2 - 32
    return { closeTopPosition: top, closeRightPosition: right }
  }, [
    contentWidth,
    contentHeight,
    containerWidth,
    containerHeight,
    isModalFixedToTop
  ])

  const width = Math.min(propWidth, containerWidth - 16)

  /**
   * This calculates the position of the modal, relative
   * to the browser window. If the modal has enough room
   * above and below it, it will be centered in the browser.
   * If it does not, then it will be fixed to the top of
   * the browser and the main container of this component
   * will handle scrolling to allow users to see any
   * overflowing modal content.
   */
  const modalPosition = useMemo(() => {
    const centeredStyle = { top: '50%', transform: 'translateY(-50%)' }
    const fixedToTopStyle = { top: '40px', transform: 'translateY(0)' }
    return isModalFixedToTop ? fixedToTopStyle : centeredStyle
  }, [isModalFixedToTop])

  const contentStyles = useMemo(() => {
    return {
      left: `calc(50% - ${width / 2}px)`,
      width: `${width}px`,
      ...modalPosition
    }
  }, [width, modalPosition])

  const handleClose = () => {
    if ($canClose && typeof onClose === 'function') {
      onClose()
    }
  }

  // TODO: add Icon prop
  return (
    <Container ref={containerRef}>
      <InnerContainer>
        <Overlay onClick={handleClose} />
        <Close
          data-testid='button-close'
          onClick={handleClose}
          style={{ top: closeTopPosition, right: closeRightPosition }}
        >
          <Icon name='clear' />
        </Close>
        <Content
          data-testid='modal-content'
          ref={contentRef}
          style={contentStyles}
          isFixed={isModalFixedToTop}
        >
          {children}
        </Content>
      </InnerContainer>
    </Container>
  )
}

Modal.propTypes = {
  canClose: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  width: PropTypes.number
}

export { Content, ModalContent, ModalActions }
export default Modal
