import {
  GET_TASK_AVAILABILITY,
  UPDATE_TASK_AVAILABILITY
} from 'core/actions/constants'

const initialState = {
  taskTypeAvailability: {}
}

const cases = function(state = initialState, action) {
  switch (action.type) {
    case GET_TASK_AVAILABILITY:
    case UPDATE_TASK_AVAILABILITY:
      return {
        ...state,
        taskTypeAvailability: action.payload
      }
    default:
      return state
  }
}

export default cases
