import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

const LoginInfoContext = createContext({})

const useLoginInfo = () => useContext(LoginInfoContext)

const LoginInfoProvider = props => {
  const {
    appDomainType,
    userType,
    authType,
    authValueType,
    companyId,
    children,
    ssoUrl
  } = props

  const value = useMemo(() => {
    return {
      appDomainType,
      userType,
      authType,
      authValueType: authValueType || 'email',
      companyId,
      ssoUrl
    }
  }, [appDomainType, userType, authType, authValueType, companyId, ssoUrl])

  return (
    <LoginInfoContext.Provider value={value}>
      {children}
    </LoginInfoContext.Provider>
  )
}

LoginInfoProvider.propTypes = {
  appDomainType: PropTypes.oneOf(['agent', 'borrower', 'admin']),
  userType: PropTypes.oneOf(['agent', 'borrower']),
  companyId: PropTypes.string,
  authType: PropTypes.oneOf([
    'basic',
    'google',
    'oneTimeCodeEmail',
    'oneTimeCodeText',
    'SAML'
  ]),
  ssoUrl: PropTypes.string,
  authValueType: PropTypes.oneOf([
    'email',
    'username',
    'phone',
    'samlSubjectNameId'
  ]),
  children: PropTypes.node
}

export { LoginInfoProvider, useLoginInfo }
