// Error import: keep at top
import 'core/system/ErrorLogging'
// -------------------------
import 'focus-visible'
import 'index.scss'
import 'core/hooks/useLog'
import Badge from 'core/components/Badge/Badge'
import BootstrapManager from 'core/SystemProvider/BootstrapManager'
import React from 'react'
import ReactDOM from 'react-dom'

ReactDOM.render(<Badge />, document.getElementById('badgeRoot'))

ReactDOM.render(<BootstrapManager />, document.getElementById('root'))
