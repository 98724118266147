const Iframe = ({ content, title }) => {
  const writeHtml = frame => {
    if (!frame) return

    const doc = frame.contentDocument
    doc.open()
    doc.write(content)
    doc.close()
    frame.style.width = '100%'
    frame.style.height = `100%`
  }

  const iframeTitle = title || ''

  return (
    <iframe
      src='about:blank'
      title={iframeTitle}
      frameBorder='0'
      ref={writeHtml}
      sandbox='allow-same-origin'
    />
  )
}

export default Iframe
