import {
  INITIATE_RENDER_DOCUMENT,
  RENDER_DOCUMENT,
  RENDER_TO_DOCUMENT,
  REQUEST_PROGRESS,
  SEND_VERIFICATION_CODE_MAX_ATTEMPT,
  SEND_VERIFICATION_CODE_SUCCESS,
  SEND_VERIFICATION_CODE_TIMEOUT,
  SEND_VERIFICATION_CODE_FAILED,
  CLEAR_COMMUNICATION_ERROR
} from 'core/actions/communicator'
import {
  GET_SUBJECTS,
  SEND_COMMUNICATION_FAILED,
  SEND_COMMUNICATION_INTERNAL_SERVER_ERROR
} from 'core/actions/constants'

const initialState = {
  codeVerificationError: '',
  codeVerificationStatus: '',
  currentRenderedHTML: null,
  documentRenderedToDoc: false,
  renderingDocument: false,
  requestProgress: {},
  subjects: []
}

const communicator = function(state = initialState, action) {
  switch (action.type) {
    case INITIATE_RENDER_DOCUMENT:
      return {
        ...state,
        currentRenderedHTML: null,
        renderingDocument: true
      }
    case RENDER_DOCUMENT:
      return {
        ...state,
        currentRenderedHTML: action.payload,
        renderingDocument: false
      }
    case RENDER_TO_DOCUMENT:
      return {
        ...state,
        documentData: action.payload,
        documentRenderedToDoc: true
      }
    case SEND_VERIFICATION_CODE_SUCCESS:
      return {
        codeVerificationError: '',
        codeVerificationStatus: ''
      }
    case SEND_VERIFICATION_CODE_MAX_ATTEMPT:
      return {
        ...state,
        codeVerificationError: action.payload.errorMessage,
        codeVerificationStatus: action.payload.errorStatus
      }
    case SEND_VERIFICATION_CODE_TIMEOUT:
      return {
        ...state,
        codeVerificationError: action.payload.errorMessage,
        codeVerificationStatus: action.payload.errorStatus
      }
    case SEND_VERIFICATION_CODE_FAILED:
      return {
        ...state,
        codeVerificationError: action.payload.errorMessage,
        codeVerificationStatus: action.payload.errorStatus
      }
    case REQUEST_PROGRESS:
      return {
        ...state,
        requestProgress: {
          ...state.requestProgress,
          [action.payload.file]: {
            loaded: action.payload.loaded,
            total: action.payload.total
          }
        }
      }
    case GET_SUBJECTS:
      return {
        ...state,
        subjects: action.payload,
        personId: action.personId
      }
    case SEND_COMMUNICATION_FAILED:
      return {
        ...state,
        communicationError: action.payload
      }
    case SEND_COMMUNICATION_INTERNAL_SERVER_ERROR:
      return {
        ...state,
        communicationError: action.payload
      }
    case CLEAR_COMMUNICATION_ERROR:
      return {
        ...state,
        communicationError: ''
      }
    default:
      return state
  }
}

// TODO: cache state values using redux selectors for scalability/performance boosts

export default communicator
