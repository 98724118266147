import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

const VisaIcon = () => (
  <SvgIcon fontSize='inherit' viewBox='0 0 40 40'>
    <path
      d='M40 31.4282C40 32.7341 38.9414 33.7928 37.6355 33.7928H2.36445C1.05859 33.7929 0 32.7341 0 31.4282V8.57162C0 7.26569 1.05859 6.20709 2.36445 6.20709H37.6355C38.9415 6.20709 40 7.26576 40 8.57162V31.4282Z'
      fill='white'
    />
    <path
      d='M8.87815 20.159L7.93893 15.6534C7.77839 15.0321 7.3112 14.8473 6.72745 14.8228H2.42135L2.383 15.0287C5.73682 15.8417 7.95596 17.8031 8.87815 20.159Z'
      fill='#F79F1A'
    />
    <path
      d='M18.8557 14.9135H16.0735L14.3358 25.1109H17.1135L18.8557 14.9135Z'
      fill='#0E4595'
    />
    <path
      d='M10.5739 25.1006L14.9497 14.9203H12.0051L9.28295 21.8756L8.9924 20.8255C8.45881 19.5621 6.93381 17.7438 5.14349 16.5991L7.63177 25.104L10.5739 25.1006Z'
      fill='#0E4595'
    />
    <path
      d='M26.7915 21.7773C26.8018 20.6196 26.0584 19.7365 24.4565 19.0072C23.483 18.5361 22.8897 18.2219 22.8968 17.7439C22.8968 17.3215 23.3993 16.8679 24.488 16.8679C25.399 16.8539 26.055 17.0528 26.568 17.2587L26.8194 17.374L27.1965 15.1648C26.645 14.9588 25.7829 14.739 24.7047 14.739C21.958 14.739 20.0246 16.1211 20.0071 18.1033C19.9896 19.5692 21.3893 20.3858 22.4432 20.8743C23.525 21.3723 23.8879 21.691 23.881 22.1377C23.874 22.8207 23.0189 23.1322 22.2197 23.1322C21.1064 23.1322 20.5166 22.9787 19.6018 22.5976L19.2464 22.4343L18.8559 24.7194C19.5046 25.0029 20.705 25.2507 21.9511 25.2611C24.8719 25.2611 26.7705 23.8965 26.7915 21.7773Z'
      fill='#0E4595'
    />
    <path
      d='M30.0963 23.5824C30.4139 23.5824 33.2512 23.5859 33.6562 23.5859C33.7399 23.941 33.9947 25.1144 33.9947 25.1144H36.5737L34.3227 14.924H32.1764C31.5098 14.924 31.0144 15.1054 30.7211 15.7684L26.5926 25.1144H29.5138C29.5136 25.1144 29.9881 23.8583 30.0963 23.5824ZM32.0017 18.6441C31.988 18.672 32.2322 18.0543 32.3718 17.6704L32.5602 18.5498C32.5602 18.5498 33.0908 20.9859 33.2024 21.4955H30.8955C31.1259 20.9092 32.0017 18.6441 32.0017 18.6441Z'
      fill='#0E4595'
    />
    <path
      d='M37.6355 6.20709H2.36445C1.05859 6.20709 0 7.26568 0 8.57162V11.0257H40V8.57162C40 7.26568 38.9415 6.20709 37.6355 6.20709Z'
      fill='#0E4595'
    />
    <path
      d='M37.6355 33.7929H2.36445C1.05859 33.7929 0 32.7342 0 31.4284V28.9743H40V31.4284C40 32.7342 38.9415 33.7929 37.6355 33.7929Z'
      fill='#F79F1A'
    />
  </SvgIcon>
)

export default VisaIcon
