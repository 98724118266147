// Third party --------------
import React from 'react'
import styled, { css } from 'styled-components/macro'

// Components ---------------
import FooterCompanyInfo from './FooterCompanyInfo'
import FooterConsents from './FooterConsents'
import FooterDisclosures from './FooterDisclosures'
import FooterLinks from './FooterLinks'
import { PoweredByPeach } from 'core/components'

// Rest ---------------------
import variables from 'core/styles/variables'
import { useRect, useCompanyInfo } from 'core/hooks'

const Wrapper = styled.div``

const MaxWidth = styled.div`
  max-width: 1024px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
`

const FooterTop = styled.div`
  background-color: ${variables.colorBlack20};
  text-align: center;
`

const threeColumnsStyles = css`
  display: flex;
  flex-direction: row;
  & > * {
    flex-basis: 10px;
    flex-grow: 1;
  }
`

const FooterTopContent = styled.div`
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  max-width: 1186px;

  ${p => !p.isMobile && threeColumnsStyles};
`

const Section = styled.div`
  padding: 24px;
`

const FooterBottom = styled.div`
  padding-top: 42px;
  text-align: center;
`

const FooterBottomContent = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`

const PoweredByArea = styled.div`
  text-align: center;
  padding: 8px 24px;
`

const Footer = () => {
  const [{ width }, ref] = useRect()

  const { showPoweredByPeach } = useCompanyInfo()

  const isMobile = width < 1024

  return (
    <Wrapper>
      <FooterTop ref={ref}>
        <MaxWidth>
          <FooterTopContent isMobile={isMobile}>
            <Section>
              <FooterLinks />
            </Section>
            <Section>
              <FooterCompanyInfo centerLogo={isMobile} />
            </Section>
            <Section>
              <FooterConsents />
            </Section>
          </FooterTopContent>
        </MaxWidth>
      </FooterTop>

      <FooterBottom>
        <MaxWidth>
          <FooterBottomContent>
            <FooterDisclosures />

            {showPoweredByPeach && (
              <PoweredByArea>
                <PoweredByPeach />
              </PoweredByArea>
            )}
          </FooterBottomContent>
        </MaxWidth>
      </FooterBottom>
    </Wrapper>
  )
}

export default Footer
