import './index.scss'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useFormDisabled } from 'loan/components/actionCards/shared/DisabledContext'
import variables from 'core/styles/variables'

import classNames from 'classnames'

export const StyledButton = styled.button`
  margin: ${p => p.margin};
  padding: ${p => p.padding};
  font-size: ${p => p.fontSize || '16px'};
  ${p => p.primary && `background-color: ${p.theme.primary}`}
  ${p => p.color && `color: ${p.color}`}
  ${p =>
    p.errorMessage &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-style: none;
  `}
`

export const ErrorMessage = styled.div`
  background-color: ${variables.colorRedLighten};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px 24px;
  color: ${variables.colorBlack80};
`

const maxErrorLength = 400

const Button = props => {
  const {
    onClick,
    children,
    focus,
    borderType,
    primary,
    primaryLight,
    primaryOutline,
    secondary,
    fullWidth,
    margin,
    padding,
    className,
    fontSize,
    readOnly: passedReadOnly,
    color,
    errorMessage
  } = props

  const croppedErrorMsg =
    errorMessage?.length > maxErrorLength
      ? `${errorMessage.substring(0, maxErrorLength)}...`
      : errorMessage

  const readOnly = useFormDisabled(passedReadOnly)

  // TODO: refactor this with style-component's pattern
  const componentClass = classNames(className, 'button', {
    focus: focus,
    [`border-${borderType}`]: [`border-${borderType}`],
    primary: primary,
    'primary-light': primaryLight,
    'primary-outline': primaryOutline,
    secondary: secondary,
    'full-width': fullWidth,
    disabled: readOnly
  })

  return (
    <>
      <StyledButton
        color={color}
        fontSize={fontSize}
        className={componentClass}
        onClick={onClick}
        disabled={readOnly}
        margin={margin}
        padding={padding}
        primary={primary}
        errorMessage={errorMessage}
        data-cy={props['data-cy']}
      >
        {children}
      </StyledButton>
      {errorMessage && <ErrorMessage>{croppedErrorMsg}</ErrorMessage>}
    </>
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  onClick: PropTypes.func,
  color: PropTypes.string,
  focus: PropTypes.bool,
  borderType: PropTypes.oneOf(['solid-light', 'dashed-light', 'none']),
  primary: PropTypes.bool,
  primaryOutline: PropTypes.bool,
  secondary: PropTypes.bool,
  primaryLight: PropTypes.bool,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  margin: PropTypes.string,
  padding: PropTypes.string,
  className: PropTypes.string,
  fontSize: PropTypes.string,
  errorMessage: PropTypes.string
}

Button.defaultProps = {
  children: null,
  focus: false,
  borderType: 'none',
  margin: '0'
}

export default Button
