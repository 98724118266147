// Third party --------------
import _ from 'lodash'
import styled from 'styled-components/macro'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useDispatch } from 'react-redux'

// Components ---------------
import Case from '../Case'
import Conversation from '../Conversation'
import MessageTextarea from '../MessageTextarea'
import To from '../To'
import TopBar from '../TopBar'
import InteractionModalContent, {
  DIALOG_LOCK,
  DIALOG_UNAUTHORIZED
} from '../InteractionModalContent'
import { Button as ButtonBase, Dialog, Modal, Spinner } from 'core/components'

// Rest ---------------------
import addAssociation from 'core/actions/cases/addAssociation'
import deleteAssociation from 'core/actions/cases/deleteAssociation'
import getFullName from 'core/helpers/getFullName'
import updateInteraction from 'core/actions/interactions/updateInteraction'
import { INTERACTION, RELATED } from 'core/actions/constants'
import { TwilioConversationContext } from 'contexts/TwilioConversationContext'
import { formatSelectOption } from '../helpers'
import {
  closeConversation,
  createAndSetupTwilioClient,
  setupConversation
} from './utils'
import {
  useCases,
  useContact,
  useHasPermission,
  useInteraction,
  useWrite
} from 'core/hooks'
import {
  useCaseAssociationsByInteractionId,
  useCasesOptions,
  useFormattedTextConversation,
  useFormattedToValue
} from '../hooks'
import { useTime } from 'core/time'
import { variables } from 'core/styles'

// Styled components --------
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${variables.colorWhite};
  height: calc(100% - 48px);
`
const ScrollableArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  background-color: white;
`
const ButtonControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px 16px 0;
`
const EndConversation = styled(ButtonBase)`
  margin-right: 16px;
`
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${variables.overlay};
`
const NoConversationFound = styled.div`
  padding: 24px;
  font-style: italic;
`

const ActiveTextConversation = ({
  borrower,
  id: interactionId,
  maximized,
  mode,
  onClose,
  setMode
}) => {
  const { useContext, useDispatch } = ActiveTextConversation.dependencies

  // Refs and dispatch
  const messagesEndRef = useRef(null)
  const dispatch = useDispatch()
  const { formatRelative } = useTime()

  // Twilio Conversation Context
  const { conversations, addConversation, updateConversation } = useContext(
    TwilioConversationContext
  )

  // Permissions
  const canReadSensitive = useHasPermission('interaction:read.sensitive')

  // Local state
  const [blinkTopBar, setBlinkTopBar] = useState(false)
  const [connectionState, setConnectionState] = useState(null)
  const [conversation, setConversation] = useState()
  const [dialogToShow, setDialogToShow] = useState(null)
  const [focusTextarea, setFocusTextarea] = useState(true)
  const [hasSetupConversation, setHasSetupConversation] = useState(false)
  const [hasCreatedAndSetupTwilio, setHasCreatedAndSetupTwilio] =
    useState(false)
  const [isConversationClosed, setIsConversationClosed] = useState(false)
  const [isEndingConversation, setIsEndingConversation] = useState(false)
  const [isInitializing, setIsInitilizing] = useState(false)
  const [isLoadingMessages, setIsLoadingMessages] = useState(false)
  const [isSendingMessage, setIsSendingMessage] = useState(false)
  const [messages, setMessages] = useState([])
  const [noConversationFound, setNoConversationFound] = useState(false)
  const [showNoConversationModal, setShowNoConversationModal] = useState(false)
  const [showEndConversationModal, setShowEndConversationModal] =
    useState(false)
  const [showLockModal, setShowLockModal] = useState(false)
  const [textareaContent, setTextareaContent] = useState('')

  // The conversation in our context
  const { sid: conversationId } = conversation || {}
  const thisConversation = conversations[conversationId]

  // Borrower
  const { id: borrowerId } = borrower || {}

  // Interaction
  const interaction = useInteraction(interactionId)
  const { contactId, sensitive, twilioConvoSid } = interaction

  // Contact
  const contact = useContact(contactId)
  const { affiliation } = contact || {}
  const isSelfAffiliation = affiliation === undefined || affiliation === 'self'
  const isContactLoading = !contact?.id
  const to = useFormattedToValue({ contact })

  // Top bar props
  const borrowerName = getFullName(borrower)
  const topBarCenterText = isSelfAffiliation
    ? borrowerName
    : `Conversation on behalf of ${borrowerName}`
  const gridTemplateColumns =
    mode === 'minimized'
      ? '32px 152px 104px'
      : mode === 'maximized'
      ? '320px auto 320px'
      : '200px auto 200px'
  const topBarLeftText = messages[0]?.state?.body || ''

  // Conversation
  const formattedTextConversation = useFormattedTextConversation({
    interaction,
    isTransactionalInteraction: false,
    messages
  })
  const startISOString = conversation?.dateCreated?.toISOString()
  const startedAt = !!startISOString ? formatRelative(startISOString) : ''
  const dateUpdatedISOString = conversation?.dateUpdated?.toISOString()
  const endedAt =
    !!dateUpdatedISOString && isConversationClosed
      ? formatRelative(dateUpdatedISOString)
      : ''

  // Case stuff
  const casesCollection = useCases({
    personId: interaction?.personId
  }).byId
  const cases = useMemo(() => {
    return _.orderBy(_.values(casesCollection), ['createdAt'], ['desc'])
  }, [casesCollection])
  const casesOptions = useCasesOptions({ cases })
  const associations = useCaseAssociationsByInteractionId({
    cases,
    interactionId
  })
  const associatedCases = _.map(associations, c => formatSelectOption(c))

  const onInitialSetup = useCallback(async () => {
    setIsInitilizing(true)
    await createAndSetupTwilioClient({
      setConnectionState,
      setConversation,
      setFocusTextarea,
      setIsLoadingMessages,
      setMessages,
      setNoConversationFound,
      setShowNoConversationModal,
      twilioConvoSid
    })
    setIsInitilizing(false)
    setHasCreatedAndSetupTwilio(true)
  }, [twilioConvoSid])

  // Lifecycle hooks --------
  useEffect(() => {
    if (
      !isInitializing &&
      twilioConvoSid &&
      hasCreatedAndSetupTwilio === false
    ) {
      onInitialSetup()
    }
  }, [hasCreatedAndSetupTwilio, isInitializing, onInitialSetup, twilioConvoSid])

  // Setup the conversation if it exists
  useEffect(() => {
    if (
      !!conversation &&
      hasSetupConversation === false &&
      hasCreatedAndSetupTwilio === true
    ) {
      setupConversation({ conversation, mode, setBlinkTopBar, setMessages })
      setHasSetupConversation(true)
    }
  }, [conversation, hasCreatedAndSetupTwilio, hasSetupConversation, mode])

  /**
   * Add current conversation to the conversation context
   * if the conversation is created and does not exist in
   * the context.
   */
  useEffect(() => {
    const hasAddedConversation = !!conversations[conversationId]
    if (conversationId && interactionId && !hasAddedConversation) {
      addConversation({
        conversationId,
        interactionId,
        status: 'connected'
      })
    }
  }, [addConversation, conversationId, conversations, interactionId])

  useEffect(() => {
    setTimeout(() => {
      /**
       * This setTimeout allows the UI to update fully
       * before scrolling the conversation to the bottom.
       * Otherwise the calculated bottom will be set to
       * the bottom that used to be before the most recent
       * message was added.
       */
      messagesEndRef.current?.scrollIntoView({ block: 'end' })
    }, 100)
  }, [messages])

  useEffect(() => {
    if ((mode === 'open' || mode === 'maximized') && blinkTopBar === true) {
      setBlinkTopBar(false)
    }
  }, [blinkTopBar, messages, mode, setBlinkTopBar])

  // Methods
  const [onUpdateInteraction, isUpdatingInteraction] = useWrite(body => {
    return dispatch(
      updateInteraction({
        body,
        id: interactionId,
        key: `UpdateInteraction-${interactionId}`,
        personId: borrowerId
      })
    )
  })

  const onClickLock = () => {
    if (sensitive && canReadSensitive) {
      onUpdateInteraction({ sensitive: false })
    } else if (sensitive) {
      setDialogToShow(DIALOG_UNAUTHORIZED)
      setShowLockModal(true)
    } else {
      setDialogToShow(DIALOG_LOCK)
      setShowLockModal(true)
    }
  }

  const onClickConfirm = () => {
    onUpdateInteraction({ sensitive: true })
    setShowLockModal(false)
  }

  const [onCaseSelect, isUpdatingCaseSelect] = useWrite(async option => {
    if (option?.length > associatedCases.length) {
      const caseId = option[option.length - 1].value
      return dispatch(
        addAssociation({
          key: `AddAssociation-${caseId}`,
          filters: {
            personId: borrowerId,
            caseId
          },
          body: {
            objectType: INTERACTION,
            objectId: interactionId,
            relation: RELATED
          }
        })
      )
    } else {
      let caseId
      const oldIds = associatedCases.map(_case => _case.value)
      const newIds = option?.map(o => o.value) || []
      oldIds.forEach(id => {
        if (!newIds.includes(id)) {
          caseId = id
        }
      })
      return dispatch(
        deleteAssociation({
          key: `DeleteAssociation-${caseId}`,
          personId: borrowerId,
          caseId,
          query: `objectType=${INTERACTION}&objectId=${interactionId}`,
          objectId: interactionId
        })
      )
    }
  })

  const onSendMessage = async () => {
    setIsSendingMessage(true)
    await conversation.sendMessage(textareaContent)
    // Reset textarea content, but keep brand prefix
    const index = textareaContent.indexOf(': ')
    const brandPrefixOnly = textareaContent.substring(0, index + 2)
    setTextareaContent(brandPrefixOnly)
    setIsSendingMessage(false)
    setFocusTextarea(true)
  }

  const onEndConversation = async () => {
    setShowEndConversationModal(false)
    setIsEndingConversation(true)
    await closeConversation({ interactionId })
    setIsEndingConversation(false)
    setIsConversationClosed(true)
    updateConversation({ ...thisConversation, status: 'closed' })
  }

  const onConfirmNoConversationModal = async () => {
    setShowNoConversationModal(false)
    await onUpdateInteraction({ status: 'canceled' })
  }

  // Loading state
  const isLoading =
    _.includes(['connecting', 'disconnecting'], connectionState) ||
    isInitializing ||
    isContactLoading ||
    isUpdatingCaseSelect ||
    isLoadingMessages ||
    isUpdatingInteraction ||
    isSendingMessage ||
    isEndingConversation

  return (
    <>
      <TopBar
        blinkTopBar={blinkTopBar}
        centerText={topBarCenterText}
        gridTemplateColumns={gridTemplateColumns}
        iconName='mobile_screen_share'
        id={interactionId}
        leftText={topBarLeftText}
        mode={mode}
        onClose={onClose}
        setMode={setMode}
        type='textConversation'
      />
      <MainContainer>
        <To
          borrowerId={borrowerId}
          contactInfo={to}
          interaction={interaction}
          isLockDisabled={noConversationFound}
          maximized={maximized}
          onClickLock={onClickLock}
          readOnly
          showLockIcon
        />
        <Case
          isControlled
          isDisabled={noConversationFound}
          maxMenuHeight={120}
          maximized={maximized}
          multiSelect
          onChange={onCaseSelect}
          options={casesOptions}
          value={associatedCases}
        />
        <ScrollableArea>
          {noConversationFound ? (
            <NoConversationFound>
              This conversation is no longer active.
            </NoConversationFound>
          ) : (
            <>
              <Conversation
                conversation={formattedTextConversation}
                startedAt={startedAt}
                endedAt={endedAt}
              />
              <div ref={messagesEndRef} />
            </>
          )}
        </ScrollableArea>
        {!isConversationClosed && (
          <>
            <MessageTextarea
              disabled={isLoading || noConversationFound}
              onChange={value => setTextareaContent(value)}
              onSendMessage={onSendMessage}
              setFocusTextarea={setFocusTextarea}
              focusTextarea={focusTextarea}
              value={textareaContent}
            />
            <ButtonControls>
              {noConversationFound ? (
                <ButtonBase onClick={onClose} secondary>
                  Close
                </ButtonBase>
              ) : (
                <>
                  <EndConversation
                    data-testid='button-end-conversation'
                    onClick={() => setShowEndConversationModal(true)}
                    secondary
                  >
                    End conversation
                  </EndConversation>
                  <ButtonBase
                    data-testid='button-send'
                    onClick={onSendMessage}
                    secondary
                  >
                    Send
                  </ButtonBase>
                </>
              )}
            </ButtonControls>
          </>
        )}
        {isLoading && mode !== 'minimized' && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
      </MainContainer>

      {showLockModal && (
        <Modal
          data-testid='lock-interaction-modal'
          onClose={() => setShowLockModal(false)}
          width={476}
        >
          <InteractionModalContent
            dialogToShow={dialogToShow}
            onLockInteraction={onClickConfirm}
            onCancel={() => setShowLockModal(false)}
          />
        </Modal>
      )}

      {showEndConversationModal && (
        <Modal onClose={() => setShowEndConversationModal(false)} width={476}>
          <Dialog
            data-testid='dialog-end-conversation'
            title='Are you sure you want to end the conversation?'
            confirmLabel='Confirm'
            onConfirm={onEndConversation}
            cancelLabel='Back'
            onCancel={() => setShowEndConversationModal(false)}
          />
        </Modal>
      )}

      {showNoConversationModal && (
        <Modal onClose={() => setShowNoConversationModal(false)} width={476}>
          <Dialog
            data-testid='dialog-no-conversation'
            confirmLabel='Ok'
            onConfirm={onConfirmNoConversationModal}
          >
            This conversation has timed out due to inactivity; you must close
            out the task.
          </Dialog>
        </Modal>
      )}
    </>
  )
}

ActiveTextConversation.dependencies = {
  useContext,
  useDispatch
}

export default ActiveTextConversation
