// Third party --------------
import _ from 'lodash'
import { useEffect, useState } from 'react'

const useCaseAssociationsByInteractionId = ({ cases, interactionId }) => {
  const [associations, setAssociations] = useState([])

  useEffect(() => {
    const $associations = _.filter(cases, c => {
      return _.some(c.associations, {
        objectId: interactionId,
        objectType: 'interaction'
      })
    })
    setAssociations(prev => {
      if (_.isEqual(prev, $associations)) return prev
      return $associations
    })
  }, [cases, interactionId])

  return associations
}

export default useCaseAssociationsByInteractionId
