import PropTypes from 'prop-types'
import React, { createContext, useContext } from 'react'

const DisabledContext = createContext(false)

const useFormDisabled = localDisabled => {
  const disabledAbove = useContext(DisabledContext)

  return localDisabled !== undefined ? localDisabled : disabledAbove
}

const FormContextProvider = ({ disabled, children }) => {
  const isDisabled = useFormDisabled(disabled)

  return (
    <DisabledContext.Provider value={isDisabled}>
      {children}
    </DisabledContext.Provider>
  )
}

FormContextProvider.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node
}

export { FormContextProvider, useFormDisabled }
