// Third party --------------
import PropTypes from 'prop-types'
import React, { createContext, useMemo, useState } from 'react'

// Rest ---------------------
import { PREDIAL, ACTIVE, POST_DIAL, CALL_COMPLETED } from './const'

const CallModeContext = createContext({})

const CallModeContextProvider = ({ children }) => {
  const [callMode, setCallMode] = useState(PREDIAL)

  const value = useMemo(() => {
    return {
      callMode,
      isActiveCall: callMode === ACTIVE,
      setCallModePredial: () => setCallMode(PREDIAL),
      setCallModeActive: () => setCallMode(ACTIVE),
      setCallModePostDial: () => setCallMode(POST_DIAL),
      setCallModeCallCompleted: () => setCallMode(CALL_COMPLETED)
    }
  }, [callMode])

  return (
    <CallModeContext.Provider value={value}>
      {children}
    </CallModeContext.Provider>
  )
}

CallModeContextProvider.propTypes = {
  children: PropTypes.node
}

export { CallModeContext }
export default CallModeContextProvider
