import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

const outboundInteractionReasonOptions = [
  { label: 'Servicing', value: 'opsServicing' },
  { label: 'Collections', value: 'opsCollDebt' },
  { label: 'Locate borrower for collections', value: 'opsCollLocateBorrower' },
  {
    label: 'Verify borrower employment for collections',
    value: 'opsCollVerifyEmployment'
  },
  { label: 'Debt validation notice', value: 'opsServicingDebtValidation' },
  {
    label: 'Intention to contact employer notice',
    value: 'opsCollContactEmployerNotice'
  }
]

const inboundInteractionReasonOptions = [
  { label: 'Missing feature', value: 'inbMissingFeature' },
  { label: 'Education', value: 'inbEducation' },
  { label: 'Bug', value: 'inbBug' },
  { label: 'Request', value: 'inbRequest' },
  { label: 'Human love', value: 'inbHumanLove' },
  { label: 'Unknown', value: 'inbUnknown' }
]

const dniReasonOptions = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Servicing',
    value: 'opsServicing'
  },
  {
    label: 'Collections',
    value: 'opsCollDebt'
  },
  {
    label: 'Locate borrower for collections',
    value: 'opsCollLocateBorrower'
  },
  {
    label: 'Verify borrower employment for collections',
    value: 'opsCollVerifyEmployment'
  },
  {
    label: 'Debt validation notice',
    value: 'opsServicingDebtValidation'
  },
  {
    label: 'Intention to contact employer notice',
    value: 'opsCollContactEmployerNotice'
  },
  {
    label: 'Time-barred debt notice',
    value: 'opsLoanTimeBarredNotice'
  },
  {
    label: 'Account credentials',
    value: 'opsAccountCredentials'
  },
  {
    label: 'Negative credit report',
    value: 'opsServicingNegativeCreditReportNotice'
  }
]

// Takes dniReasonOptions and morphs it into the format
// {
//   all: 'All',
//   opsServicing: 'Servicing',
//   ...
// }
const dniReasonLabels = mapValues(
  keyBy(dniReasonOptions, 'value'),
  item => item.label
)

export {
  dniReasonLabels,
  dniReasonOptions,
  inboundInteractionReasonOptions,
  outboundInteractionReasonOptions
}
