import _ from 'lodash'
import { formatUrl } from './formatUrl'
import parseErrorResponse from './parseErrorResponse'

/* this is wrapper around window.fetch (but that could be subbed out for something else later) that conforms to the signature of

# constraints
 - returns a promise
 - resolves to a value
 - rejects with an error
   - with at least `status` and `message`

# niceties (can be added to as long as they don't break existing calls)
 - parses a `params` option if passed in
 - adds default headers
 - JSON.stringifies body if an object is passed
 - gracefully handle 204
 - parses JSON response
 - parses JSON error
 - TODO:  eventually will want to add support for parsing resp.text() for HTML resp, etc


# notes
 - does not handle authorization or unauthorized handling.
   - that's one level up in peachApi.js
*/

const rawApiCall = async (options = {}) => {
  const {
    url,
    fullUrl,
    headers,
    body,
    params,
    method,
    ...restOptions
  } = options

  if (!_.isString(url) && !_.isString(fullUrl)) {
    throw new Error('url or fullUrl is required for an API call')
  }

  const requestUrl = fullUrl || formatUrl(url, params)

  const fetchOptions = {}
  fetchOptions.method = method
  fetchOptions.headers = {}
  fetchOptions.headers.Accept = 'application/json'
  if (!(body instanceof FormData)) {
    fetchOptions.headers['Content-Type'] = 'application/json'
  }
  Object.assign(fetchOptions.headers, headers)
  Object.assign(fetchOptions, restOptions)

  if (method !== 'GET' && method !== 'DELETE') {
    if (body instanceof FormData) {
      fetchOptions.body = body
    } else if (!_.isUndefined(body) || method === 'POST') {
      fetchOptions.body = _.isString(body) ? body : JSON.stringify(body ?? {})
    }
  }

  const resp = await window.fetch(requestUrl, fetchOptions)

  if (resp.ok) {
    const contentType = resp.headers.get('Content-Type')
    if (resp.status === 204) {
      return {}
    } else if (_.includes(contentType, 'json')) {
      const json = await resp.json()
      return json
    } else if (_.includes(contentType, 'text')) {
      const text = await resp.text()
      return text
    } else {
      return {}
    }
  } else {
    const error = await parseErrorResponse(resp)
    throw error
  }
}

export default rawApiCall
