// Third party ------------------------
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useDispatch } from 'react-redux'

// Proprietary ------------------------
import updateInteraction from 'core/actions/interactions/updateInteraction'
import variables from 'core/styles/variables'
import { useHasPermission } from 'core/hooks'
import { useTime } from 'core/time'

// Components -------------------------
import Icon from 'core/components/Icon'
import RowBase from './Row'
import InteractionModalContent, {
  DIALOG_LOCK,
  DIALOG_UNAUTHORIZED
} from './InteractionModalContent'
import { Button, Modal } from 'core/components'

// Styles -----------------------------
const Row = styled(RowBase)`
  justify-content: space-between;
  padding: 24px 16px;
`
const NameContainer = styled.span`
  position: relative;
`
const Name = styled.span`
  font-weight: 700;
`
const AssociateDisassociate = styled(Button)`
  position: absolute;
  bottom: -24px;
  left: 0;
`
const IconGroup = styled.div`
  display: flex;
  align-items: center;
  color: ${variables.colorBlack50};
  font-size: 24px;
`

const FromV2 = ({
  borrowerId,
  contactInfo,
  date,
  hideLock,
  interaction,
  maximized,
  name,
  onAssociateDisassociateBorrower
}) => {
  const dispatch = useDispatch()

  const { formatRelative } = useTime()

  // Permissions
  const canReadSensitive = useHasPermission('interaction:read.sensitive')

  // Local state
  const [showModal, setShowModal] = useState(false)
  const [dialogToShow, setDialogToShow] = useState(null)

  // Local variables
  const lockIconColor = interaction.sensitive
    ? variables.colorBlack90
    : variables.colorBlack40

  const canChangeAssociation = interaction.channel === 'email'

  // Methods
  const _updateInteraction = body => {
    dispatch(
      updateInteraction({
        body,
        id: interaction.id,
        key: `UpdateInteraction-${interaction.id}`,
        personId: borrowerId
      })
    )
  }

  const _onClickLock = () => {
    if (interaction.sensitiveBorrowerOnly) {
      setDialogToShow(DIALOG_UNAUTHORIZED)
      setShowModal(true)
    } else if (interaction.sensitive && canReadSensitive) {
      _updateInteraction({ sensitive: false })
    } else if (interaction.sensitive) {
      setDialogToShow(DIALOG_UNAUTHORIZED)
      setShowModal(true)
    } else {
      setDialogToShow(DIALOG_LOCK)
      setShowModal(true)
    }
  }

  const _onClickConfirm = () => {
    _updateInteraction({ sensitive: true })
    setShowModal(false)
  }

  return (
    <>
      <Row maximized={maximized}>
        <NameContainer>
          {name?.length > 0 && <Name>{name} </Name>}
          {canChangeAssociation && (
            <AssociateDisassociate
              fontSize='14px'
              onClick={() => onAssociateDisassociateBorrower()}
              secondary
            >
              {name === 'Unidentified borrower'
                ? 'Associate borrower'
                : 'Disassociate borrower'}
            </AssociateDisassociate>
          )}
          {/**
           * TODO: Write more robust logic to handle both inbound
           * and outbound text interaction display logic. For now
           * we only support outbound text interactions so it's
           * okay to not display the `contactInfo` since it's the
           * same as the `name` value.
           */}
          {contactInfo && `<${contactInfo}>, `}
          <span>{formatRelative(date)}</span>
        </NameContainer>
        <IconGroup>
          {!hideLock && (
            <Button onClick={_onClickLock}>
              <Icon
                color={lockIconColor}
                fontSize='24px'
                name={
                  interaction.sensitiveBorrowerOnly
                    ? 'lock'
                    : interaction.sensitive
                    ? 'lock'
                    : 'lock_open'
                }
              />
            </Button>
          )}
        </IconGroup>
      </Row>
      {showModal && (
        <Modal onClose={() => setShowModal(false)} width={476}>
          <InteractionModalContent
            dialogToShow={dialogToShow}
            onLockInteraction={_onClickConfirm}
            onCancel={() => setShowModal(false)}
          />
        </Modal>
      )}
    </>
  )
}

FromV2.propTypes = {
  borrowerId: PropTypes.string,
  contactInfo: PropTypes.string,
  date: PropTypes.string,
  hideLock: PropTypes.bool,
  interaction: PropTypes.shape({
    id: PropTypes.string,
    sensitive: PropTypes.bool,
    sensitiveBorrowerOnly: PropTypes.bool
  }),
  /** If the containing resource window is maximized */
  maximized: PropTypes.bool,
  name: PropTypes.string,
  onAssociateDisassociateBorrower: PropTypes.func
}

export default FromV2
