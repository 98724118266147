import { GET_DRAFT_POPULATION, LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

const getDraftPopulation = ({ supercaseId, key }) => {
  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    try {
      const response = await peachApi.get({
        url: `/supercases/${supercaseId}/populations/draft`
      })
      const membersResponse = await peachApi.get({
        url: `/supercases/${supercaseId}/populations/draft/members`
      })
      dispatch({
        type: GET_DRAFT_POPULATION,
        payload: {
          ...response.data,
          supercaseId,
          members: membersResponse.data
        }
      })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
    } catch {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
    }
  }
}

export default getDraftPopulation
