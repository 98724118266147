import React, { useState, useCallback } from 'react'
import styled, { css } from 'styled-components/macro'
import useOnClickOutside from './useOnClickOutside'
import BadgeMenu from './BadgeMenu'
import { overridesEnabled } from 'core/components/Badge/overrides'

const Wrap = styled.span`
  display: inline-block;
  padding: 0;
  margin: 0;
  line-height: 1;
  position: relative;
  ${!overridesEnabled &&
    css`
      pointer-events: none;
    `};
`

const Contents = styled.div`
  width: 250px;
  position: absolute;
  bottom: 32px;
  right: 4px;
`

const BadgeToggle = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onClose = useCallback(() => setIsOpen(false), [])

  const ref = useOnClickOutside(onClose)

  const onOpen = () => {
    if (overridesEnabled) setIsOpen(true)
  }

  return (
    <Wrap onDoubleClick={onOpen}>
      {children}
      {isOpen && (
        <Contents ref={ref}>
          <BadgeMenu onClose={onClose} />
        </Contents>
      )}
    </Wrap>
  )
}

export default BadgeToggle
