import { GET_LOCAL_NOTES, LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

const getLocalNotes = ({ key, filters }) => {
  const { supercaseId } = filters
  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    try {
      const response = await peachApi.get({
        url: `/supercases/${supercaseId}/notes`
      })
      dispatch({ type: GET_LOCAL_NOTES, payload: response.data })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
      return response.data
    } catch {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
    }
  }
}

export default getLocalNotes
