import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

import variables from 'core/styles/variables'

const Label = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: ${variables.colorBlack60};
  letter-spacing: 0.5px;
`

/** This component is how we want to style most form labels
 * and input field labels. The prop className makes this
 * component styleable, so you can use it like this, for
 * example (using styled-components):
 *
 * const StyledFormLabel = styled(FormLabel)`
 *   margin-right: 24px;
 *   width: 240px;
 * `
 *
 * Please use the styleable aspect responsibly. Do not
 * change css properties that are already defined in
 * FormLabel itself, like font-size and color. If you
 * feel you need to change any of these, then either you
 * need to just make an entirely new component, or the design
 * team needs to approve a consistent change to FormLabels
 * across the app.
 */
const FormLabel = ({ text, className }) => (
  <Label data-testid='label' className={className}>
    {text.toUpperCase()}
  </Label>
)

FormLabel.propTypes = {
  /** The text of the label. FormLabel changes to all-caps
   * for you.
   */
  text: PropTypes.string,
  /** Do not use this directly. It exists so that the
   * component is styleable with styled-components.
   */
  className: PropTypes.string
}

FormLabel.defaultProps = {
  text: ''
}

export default FormLabel
