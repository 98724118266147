// Third party
import { useMemo } from 'react'

// Rest
import getInfo from './getInfo'
import { usePopulations } from 'core/hooks'

const usePopulationsInfo = supercaseId => {
  const populationsCollection = usePopulations({ supercaseId })
  const populationsById = useMemo(() => {
    return populationsCollection?.byId || {}
  }, [populationsCollection])

  const {
    populationsList,
    activePopulations,
    activeCases,
    activeLoans,
    activeBorrowers
  } = useMemo(() => {
    return getInfo(populationsById)
  }, [populationsById])

  return {
    populationsById,
    populationsList,
    activePopulations,
    activeCases,
    activeLoans,
    activeBorrowers
  }
}

export default usePopulationsInfo
