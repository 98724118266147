// Third party ------------------------
import React from 'react'
import styled from 'styled-components/macro'
import { useParams } from 'react-router-dom'

// Proprietary ------------------------
import variables from 'core/styles/variables'
import { useFileUpload } from 'contexts/FileUploadContext'
import { useLoadingStatus } from 'core/hooks'

// Components -------------------------
import Icon from 'core/components/Icon'
import { Button, CompanyLogo, InfoCard, Spinner } from 'core/components'

// Styles -----------------------------
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 100%;
  max-width: 680px;
`
const LogoWrapper = styled.div`
  margin: 40px 0 48px;
`
const Description = styled.div`
  padding: 0 24px 24px;
  color: ${variables.colorBlack90};
`
const ButtonContainer = styled.div`
  position: relative;
  margin: 8px 0 0 0;
  width: 100%;
`
const UploadIconContainer = styled.span`
  display: inherit;
  position: relative;
  top: 8px;
  font-size: 24px;
`
const RetryAndCancel = styled.div`
  display: flex;
  position: absolute;
  top: 16px;
  right: 16px;
`
const NoticeBarContainer = styled.div`
  position: relative;
  margin-top: -12px;
  overflow: hidden;
`
const NoticeBar = styled.div`
  position: relative;
  left: -2px;
  bottom: -2px;
  box-sizing: border-box;
  overflow: hidden;
  width: calc(100% + 4px);
  height: 32px;
  border-radius: 0 0 8px 8px;
  padding: 4px 24px;
`
const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${p => p.progress}%;
  background: ${p => p.background};
`
const Message = styled.div`
  position: relative;
  color: ${p => p.color};
`
const SpinnerContainer = styled.span`
  display: inherit;
  position: relative;
  top: 4px;
  width: 24px;
  height: 19px;
`

const UPLOAD_SUCCESS = 'Document(s) successfully uploaded.'
const UPLOAD_FAIL =
  'Document upload failed, would you like to retry your upload?'
const UPLOAD_TAKING_LONG =
  'Document upload is taking longer than expected, would you like to retry your upload?'

const helpText =
  'Please note that we only accept the following uploaded file types: .jpg,.jpeg, .png, or .pdf, that the file size may not exceed 10 megabytes, and that the file name cannot be greater than 50 characters.'

const STATES = {
  loading: {
    progressTextColor: variables.colorGreen,
    progressBackgroundColor: variables.colorGreenLighten,
    progressMessage: null,
    buttonColor: variables.colorBlack50,
    showSpinner: true,
    showRetryAndCancel: true,
    showRetry: false
  },
  longLoading: {
    progressTextColor: variables.colorOrange,
    progressBackgroundColor: variables.colorOrangeLighten,
    progressMessage: UPLOAD_TAKING_LONG,
    buttonColor: variables.colorBlack50,
    showSpinner: true,
    showRetryAndCancel: true,
    showRetry: true
  },
  success: {
    progressTextColor: variables.colorGreen,
    progressBackgroundColor: variables.colorGreenLighten,
    progressMessage: UPLOAD_SUCCESS,
    buttonColor: variables.colorBluePrimary,
    showSpinner: false,
    showRetryAndCancel: false,
    showRetry: false
  },
  error: {
    progressTextColor: variables.colorRed,
    progressBackgroundColor: variables.colorRedLighten,
    progressMessage: UPLOAD_FAIL,
    buttonColor: variables.colorBluePrimary,
    showSpinner: false,
    showRetryAndCancel: true,
    showRetry: true
  },
  defaultState: {
    progressTextColor: null,
    progressBackgroundColor: null,
    progressMessage: null,
    buttonColor: null,
    showSpinner: false,
    showRetryAndCancel: false,
    showRetry: false
  }
}

const DocumentUploader = () => {
  // ----------------------------------
  // Loading status: Document descriptor & file upload
  const documentDescriptorsLoadingStatus = useLoadingStatus(
    'CreateDocumentDescriptors'
  )
  const uploadFileLoadingStatus = useLoadingStatus('UploadFiles')
  const hasUploadError =
    documentDescriptorsLoadingStatus === 'error' ||
    uploadFileLoadingStatus === 'error'

  // ----------------------------------
  // Local variables
  const elapsedTimeToRetry = 10 // in seconds
  const { uploadDocumentLinkKey } = useParams()

  // ----------------------------------
  // File upload context values
  const {
    onOpenFileSelector,
    error: requirementError,
    progress,
    onCancelUpload,
    onRetryUpload,
    elapsedTime
  } = useFileUpload()

  // ----------------------------------
  // State colors and messages
  const state =
    documentDescriptorsLoadingStatus === 'loading' ||
    uploadFileLoadingStatus === 'loading'
      ? elapsedTime > elapsedTimeToRetry
        ? 'longLoading'
        : 'loading'
      : documentDescriptorsLoadingStatus === 'error' ||
        uploadFileLoadingStatus === 'error'
      ? 'error'
      : documentDescriptorsLoadingStatus === 'success' ||
        uploadFileLoadingStatus === 'success'
      ? 'success'
      : 'defaultState'

  const {
    progressTextColor,
    progressBackgroundColor,
    progressMessage,
    buttonColor,
    showSpinner,
    showRetryAndCancel,
    showRetry
  } = STATES[state]

  const onFileUploadClick = () => {
    onOpenFileSelector({
      acceptedFileTypes: '.jpg, .jpeg, .png, .pdf',
      autoUpload: true,
      allowMultiFileUpload: false,
      magicLinkKey: uploadDocumentLinkKey
    })
  }
  return (
    <Container>
      <LogoWrapper>
        <CompanyLogo type='appLargeCentered' />
      </LogoWrapper>
      <InfoCard
        title='Upload documents'
        helpText={helpText}
        helpTextMargin='0 24px 24px'
        iconMargin='0 56px 0 0'
        removeHelpTextTitle
      >
        <Description>
          To keep your personal information safe, documents will not be
          accessible after you successfully upload them. Our customer care team
          will review them internally. Upload one .jpg, .jpeg, .pdf or .png
          document at a time with a maximum size of 10MB.
        </Description>
        <NoticeBarContainer>
          {(requirementError || uploadFileLoadingStatus !== undefined) && (
            <NoticeBar>
              <ProgressBar
                progress={
                  requirementError || hasUploadError
                    ? 100
                    : Math.floor((progress.loaded / progress.total) * 100)
                }
                background={progressBackgroundColor}
              />
              <Message color={progressTextColor}>{progressMessage}</Message>
            </NoticeBar>
          )}
        </NoticeBarContainer>
      </InfoCard>
      <ButtonContainer>
        <Button
          primaryLight
          fullWidth
          borderType='dashed-light'
          color={buttonColor}
          id='uploadbutton'
          onClick={() => onFileUploadClick()}
        >
          {showSpinner ? (
            <>
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>{' '}
              Uploading
            </>
          ) : (
            <>
              <UploadIconContainer>
                <Icon name='upload' fontSize='inherit' />
              </UploadIconContainer>{' '}
              Upload document(s)
            </>
          )}
        </Button>
        {showRetryAndCancel && (
          <RetryAndCancel>
            {showRetry && (
              <Button
                secondary
                margin='0 40px 0 0'
                onClick={onRetryUpload}
                className='button-retry'
              >
                Retry
              </Button>
            )}
            <Button
              secondary
              onClick={onCancelUpload}
              className='button-cancel'
            >
              Cancel upload
            </Button>
          </RetryAndCancel>
        )}
      </ButtonContainer>
    </Container>
  )
}

export { ProgressBar, Message }
export default DocumentUploader
