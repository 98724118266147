// Third party ------------------------
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

// Proprietary ------------------------
import variables from 'core/styles/variables'

// Components -------------------------
import { Button } from 'core/components'
import Icon from 'core/components/Icon'

// Styles -----------------------------
const NoteSection = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`
const NoteLabel = styled.div`
  flex: 0 0 104px;
  color: ${variables.colorBlack60};
  font-size: 14px;
  font-weight: 300;
`
const Note = styled.div`
  flex: 0 1 auto;
  color: ${variables.colorBlack70};
  font-size: 14px;
`
const StyledButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ButtomSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`
const BottomCallCompletedSection = ({
  caseNote,
  isMakeAnotherCallDisabled,
  onClickRedial,
  onClickClose,
  onClickNewCall,
  selectedCase
}) => {
  return (
    <>
      {selectedCase !== null && (
        <NoteSection data-testid='note-section'>
          <NoteLabel>
            <div>Case note</div>
            <div>(Optional)</div>
          </NoteLabel>
          <Note data-testid='note'>{caseNote}</Note>
        </NoteSection>
      )}
      <ButtomSection>
        <>
          <StyledButton
            data-testid='button-close'
            margin='0 0 0 40px'
            onClick={onClickClose}
            secondary
          >
            Close
          </StyledButton>

          <StyledButton
            data-testid='button-redial'
            margin='0 0 0 40px'
            onClick={onClickRedial}
            secondary
          >
            <Icon fontSize='24px' name='replay' />
            Redial
          </StyledButton>

          <StyledButton
            data-testid='button-make-another-call'
            margin='0 0 0 40px'
            onClick={onClickNewCall}
            readOnly={isMakeAnotherCallDisabled}
            secondary
          >
            <Icon fontSize='24px' name='phone' />
            Make another call
          </StyledButton>
        </>
      </ButtomSection>
    </>
  )
}

BottomCallCompletedSection.propTypes = {
  caseNote: PropTypes.string,
  isMakeAnotherCallDisabled: PropTypes.bool,
  onClickRedial: PropTypes.func,
  onClickClose: PropTypes.func,
  onClickNewCall: PropTypes.func,
  selectedCase: PropTypes.object
}

export default BottomCallCompletedSection
