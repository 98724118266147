import { css } from 'styled-components/macro'

const itemStyles = css`
  box-sizing: border-box;
  display: block;
  width: 100%;
  margin: 0;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 1px;
  font-size: 12px;
  padding: 2px 4px;
`

export default itemStyles
