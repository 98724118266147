// Third party --------------
import PropTypes from 'prop-types'
import React, { createContext, useCallback, useMemo, useState } from 'react'
import _ from 'lodash'

// Rest ---------------------
import { isLoggedIn } from 'core/actions/helpers/tokenHelpers'
import { reconcileResourceList, _setMode } from './helpers'
import { useOnMount } from 'core/hooks'
import { useStoredState } from 'admin/hooks'

const OpenResourceContext = createContext({})

const OpenResourceContextProvider = ({ children }) => {
  const [storedResourceList, setStoredResourceList] =
    useStoredState('storedResourceList')
  const [resourceList, setResourceList] = useState([])

  useOnMount(() => {
    if (
      !_.isEmpty(storedResourceList) &&
      resourceList?.length === 0 &&
      isLoggedIn()
    ) {
      setResourceList(storedResourceList)
    }
  }, [resourceList, storedResourceList, setResourceList])

  const setMode = useCallback(
    ({ id, mode, type }) => {
      const prevResourceList = resourceList
      const newList = _setMode({ prevResourceList, id, mode, type })
      setResourceList(newList)
      setStoredResourceList(newList)
    },
    [resourceList, setStoredResourceList]
  )
  const onClose = useCallback(
    (id, type) => {
      const newResourceList = _.filter(
        resourceList,
        item => item.id !== id || item.type !== type
      )
      const resource = _.find(
        resourceList,
        item => item.id === id && item.type === type
      )
      if (resource?.onClose) {
        resource.onClose()
      }
      const newList = reconcileResourceList(newResourceList)
      setResourceList(newList)
      setStoredResourceList(newList)
    },
    [resourceList, setResourceList, setStoredResourceList]
  )

  /** id should be the id of the resource unique to its type, e.g. the database id.
   * type should be "interaction", "document", or "draftInteraction".
   * _onClose gets stored on the resource as onClose and is an optional function to
   * run when closing out the resource, e.g. removing a draftInteraction from
   * localStorage.
   */
  const openResource = useCallback(
    ({ _onClose, borrowerId, id, options, supercaseId, type }) => {
      if (!id) return
      const _openResource = prevResourceList => {
        const index = resourceList.findIndex(item => item.id === id)
        const hasTelephony = resourceList.find(
          item => item.type === 'telephony'
        )
        const newResourceList = [...prevResourceList]
        if (index === -1 || type === 'telephony') {
          if (hasTelephony) {
            /**
             * We always want to keep the telephony widget at the end of the list.
             * If the widget already exists but the new resource we want to add is
             * not of type 'telephony' then we want to add that new resource just
             * before the telephony widget in the list.
             *
             * If the telephony widget already exists and the new resource is of
             * type 'telephony' then we want to replace the current widget with the
             * new one so the telephony widget reloads itself.
             */
            const replaceCount = type !== 'telephony' ? 0 : 1
            newResourceList.splice(newResourceList.length - 1, replaceCount, {
              borrowerId,
              id,
              mode: 'open',
              onClose: _onClose,
              options,
              supercaseId,
              type
            })
          } else {
            newResourceList.push({
              borrowerId,
              id,
              mode: 'open',
              onClose: _onClose,
              options,
              supercaseId,
              type
            })
          }
          return reconcileResourceList(
            newResourceList,
            newResourceList.length - 1
          )
        } else {
          return _setMode({ id, mode: 'open', type, prevResourceList })
        }
      }
      const prevResourceList = resourceList
      const reconciledList = _openResource(prevResourceList)
      setResourceList(reconciledList)
      setStoredResourceList(reconciledList)
    },
    [resourceList, setResourceList, setStoredResourceList]
  )

  /** channel must be one of "email", "text", or "mail" */
  const openDraftInteraction = useCallback(
    ({
      associatedCase,
      borrowerId,
      channel,
      companyId,
      id,
      previousInteractions,
      supercaseId
    }) => {
      openResource({
        id,
        type: 'draftInteraction',
        borrowerId,
        supercaseId,
        associatedCase,
        channel,
        companyId,
        previousInteractions
      })
    },
    [openResource]
  )

  const value = useMemo(() => {
    return {
      openResource,
      openDraftInteraction,
      resourceList,
      setMode,
      onClose
    }
  }, [openResource, openDraftInteraction, resourceList, setMode, onClose])

  return (
    <OpenResourceContext.Provider value={value}>
      {children}
    </OpenResourceContext.Provider>
  )
}

OpenResourceContextProvider.propTypes = {
  children: PropTypes.node
}

export { OpenResourceContext }
export default OpenResourceContextProvider
