import _ from 'lodash'
import capitalizeFirstLetter from './capitalizeFirstLetter'

const capitalizeAllWords = str => {
  if (!_.isString(str)) return ''

  const words = str.toLowerCase().split(' ')

  return _.map(words, word => capitalizeFirstLetter(word)).join(' ')
}

export default capitalizeAllWords
