import { useState, useEffect } from 'react'

const useFakeLoading = (wait = 300, key) => {
  const [isFakeLoading, setIsFakeLoading] = useState(!!wait)

  useEffect(() => {
    if (!isFakeLoading) setIsFakeLoading(true)

    const id = setTimeout(() => {
      setIsFakeLoading(false)
    }, wait)

    return () => clearTimeout(id)
  }, [key]) // eslint-disable-line

  return isFakeLoading
}
export default useFakeLoading
