import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

import Row from './Row'
import RowLabel from './RowLabel'

const Input = styled.input`
  flex: 1 1 auto;
  color: inherit;
  font-size: inherit;
`

const Span = styled.span`
  flex: 1 1 auto;
  padding: 0 0 0 8px;
  /** The 30px height/line-height are according to design */
  height: 30px;
  line-height: 30px;
`

const Subject = ({ subject, onChange, readOnly, maximized }) => {
  return (
    <Row maximized={maximized}>
      <RowLabel>Subject</RowLabel>
      {readOnly ? (
        <Span>{subject}</Span>
      ) : (
        <Input
          type='text'
          value={subject}
          onChange={e => onChange(e.target.value)}
        />
      )}
    </Row>
  )
}

Subject.propTypes = {
  subject: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  /** If the containing resource window is maximized */
  maximized: PropTypes.bool
}

export default Subject
