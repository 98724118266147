import { checkTimeordate, isTimestring } from '../types'
import mtz from '../utils/mtz'

const makeEndOf = (context = {}) => {
  const { timezone } = context

  const endOf = (timeordate, duration) => {
    checkTimeordate(timeordate)

    const { year, day, month, hour } = duration
    const mDate = mtz(timeordate, timezone)
    if (year) {
      mDate.endOf('year')
    }
    if (day) {
      mDate.endOf('day')
    }
    if (month) {
      mDate.endOf('month')
    }
    if (hour) {
      mDate.endOf('hour')
    }

    return isTimestring(timeordate)
      ? mDate.toISOString()
      : mDate.format('Y-MM-DD')
  }

  return endOf
}

export default makeEndOf
