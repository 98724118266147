import _ from 'lodash'

import {
  GET_LOANS,
  GET_NEXT_PAYMENT,
  GET_EXPECTED_PAYMENTS,
  GET_AUTOPAY_DATA,
  GET_AUTOPAY_PREVIEW,
  CLEAR_AUTOPAY_DATA,
  SET_AUTOPAY_SUCCESS,
  UPDATE_AUTOPAY_SUCCESS,
  UPDATE_AUTOPAY_INTERNAL_ERROR,
  SET_AUTOPAY_INTERNAL_ERROR,
  SET_AUTOPAY_ERROR,
  GET_EXPECTED_PAYMENTS_PREVIEW,
  SET_EXPECTED_PAYMENTS,
  RESET_FLAG,
  CHANGE_LOAN_NICKNAME,
  GET_OBLIGATIONS,
  GET_NEXT_AUTOPAY,
  GET_TRANSACTIONS,
  GET_BALANCES,
  GET_PERIODS,
  GET_RATES,
  REMOVE_INTEREST_RATE,
  GET_UNFREEZE_PREVIEW,
  GET_SETUP_PAYMENT_PLAN_PREVIEW,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_ERROR,
  CREATE_TRANSACTION_SERVER_ERROR,
  CANCEL_TRANSACTION,
  DEBIT_TRANSACTION_COMPLETED,
  DEBIT_TRANSACTION_INITIATED,
  DEBIT_TRANSACTION_TIMED_OUT,
  DEBIT_TRANSACTION_ERROR,
  GET_REFUNDS
} from 'core/actions/loans'
import {
  GET_AUTOPAY_PREVIEW_ERROR,
  GET_AUTOPAY_PREVIEW_INTERNAL_ERROR,
  GET_EXPECTED_PAYMENTS_PREVIEW_ERROR,
  GET_EXPECTED_PAYMENTS_PREVIEW_INTERNAL_ERROR,
  GET_LOAN_BY_ID
} from 'core/actions/constants'
import { CANCEL_LOAN } from 'core/actions/loans/cancelLoan'
import reject from 'lodash/reject'

const initialState = {
  byId: {},
  allIds: [],
  activeLoansLoaded: false,
  pastLoansLoaded: false
}

const loans = function (state = initialState, action) {
  const updateLoan = (loanId, properties) => {
    if (loanId) {
      const { byId } = state
      const loan = byId[loanId] || {}
      return {
        ...state,
        byId: { ...byId, [loanId]: { ...loan, ...properties } }
      }
    } else {
      return state
    }
  }

  const updateLoanProperty = (loanId, property, newValue) => {
    const oldLoan = state.byId[loanId] || {}
    const oldValue = oldLoan[property] || {}
    const newLoan = { ...oldLoan, [property]: { ...oldValue, ...newValue } }
    return updateLoan(loanId, newLoan)
  }

  switch (action.type) {
    case GET_LOANS: {
      const { personId, payload: loans, activeLoansLoaded, pastLoansLoaded } = action

      const newById = { ...state.byId }

      _.each(loans, loan => {
        const { id } = loan
        const existingLoan = state.byId[id]
        newById[id] = { ...existingLoan, personId, ...loan }
      })

      const allIds = _.keys(newById, 'id')

      return {
        ...state,
        byId: newById,
        allIds,
        activeLoansLoaded: state.activeLoansLoaded || activeLoansLoaded,
        pastLoansLoaded: state.pastLoansLoaded || pastLoansLoaded
      }
    }

    case GET_LOAN_BY_ID: {
      const { loanId, data } = action.payload
      return updateLoan(loanId, data)
    }

    case GET_NEXT_PAYMENT: {
      return updateLoan(action.payload.loanId, { nextPayment: action.payload })
    }

    case GET_EXPECTED_PAYMENTS: {
      const { loanId, data } = action.payload

      return updateLoanProperty(loanId, 'expectedPayments', {
        expectedPaymentsData: data
      })
    }

    case GET_AUTOPAY_DATA: {
      const { loanId, data } = action.payload

      return updateLoanProperty(loanId, 'autopay', { autopayData: data })
    }

    case GET_NEXT_AUTOPAY: {
      return updateLoan(action.payload.loanId, { nextAutopay: action.payload })
    }

    case GET_AUTOPAY_PREVIEW: {
      const { loanId, data } = action.payload

      return updateLoanProperty(loanId, 'autopay', { autopayPreviewData: data })
    }

    case GET_AUTOPAY_PREVIEW_ERROR: {
      const { loanId, data } = action.payload

      return updateLoanProperty(loanId, 'autopay', {
        autopayErrorMessage: data
      })
    }

    case GET_AUTOPAY_PREVIEW_INTERNAL_ERROR: {
      const { loanId, data } = action.payload

      return updateLoanProperty(loanId, 'autopay', {
        autopayErrorMessage: data
      })
    }

    case CLEAR_AUTOPAY_DATA: {
      const { loanId } = action
      const { autopay, current } = state.byId[loanId] || {}
      return updateLoan(loanId, {
        autopay: { ...autopay, autopayData: {} },
        current: { ...current, autopayEnabled: false }
      })
    }

    case SET_AUTOPAY_SUCCESS: {
      const { loanId, data } = action.payload
      const loan = state.byId[loanId] || {}

      return updateLoan(loanId, {
        autopay: { ...loan.autopay, autopayData: data, autopaySet: true },
        current: { ...loan.current, autopayEnabled: true }
      })
    }

    case UPDATE_AUTOPAY_SUCCESS: {
      const { loanId, data } = action.payload

      return updateLoanProperty(loanId, 'autopay', {
        autopayData: data,
        autopayUpdated: true
      })
      // TODO: update the state with new data?
      // autopayUpdated: true
    }

    case UPDATE_AUTOPAY_INTERNAL_ERROR: {
      const { loanId, data } = action.payload

      return updateLoanProperty(loanId, 'autopay', {
        autopayErrorMessage: data
      })
    }

    case SET_AUTOPAY_ERROR: {
      const { loanId, data } = action.payload

      return updateLoanProperty(loanId, 'autopay', {
        autopayErrorMessage: data
      })
    }

    case SET_AUTOPAY_INTERNAL_ERROR: {
      const { loanId, data } = action.payload

      return updateLoanProperty(loanId, 'autopay', {
        autopayErrorMessage: data
      })
    }

    case GET_EXPECTED_PAYMENTS_PREVIEW: {
      const { loanId, data } = action.payload

      return updateLoanProperty(loanId, 'expectedPayments', {
        expectedPaymentsPreviewData: data
      })
    }
    case GET_EXPECTED_PAYMENTS_PREVIEW_ERROR: {
      const { loanId, data } = action.payload

      return updateLoanProperty(loanId, 'expectedPayments', {
        expectedPaymentsErrorMessage: data
      })
    }
    case GET_EXPECTED_PAYMENTS_PREVIEW_INTERNAL_ERROR: {
      const { loanId, data } = action.payload

      return updateLoanProperty(loanId, 'expectedPayments', {
        expectedPaymentsErrorMessage: data
      })
    }

    case SET_EXPECTED_PAYMENTS: {
      const { loanId, data } = action.payload
      const loan = state.byId[loanId] || {}

      return updateLoan(loanId, {
        expectedPayments: {
          ...loan.expectedPayments,
          expectedPaymentsData: data,
          dueDateSet: true
        },
        autopay: { ...loan.autopay, autopayData: {} },
        current: { ...loan.current, autopayEnabled: false }
      })
    }

    case RESET_FLAG: {
      const { loanId } = action.payload
      const loan = state.byId[loanId] || {}

      return updateLoan(loanId, {
        expectedPayments: {
          ...loan.expectedPayments,
          dueDateSet: false,
          expectedPaymentsErrorMessage: ''
        },
        autopay: {
          ...loan.autopay,
          autopaySet: false,
          autopayUpdated: false,
          autopayErrorMessage: ''
        },
        transactions: {
          ...loan.transactions,
          transactionCreated: false,
          debitTransactionData: {},
          debitTransactionStep: '',
          hasTimedOut: false,
          transactionErrorMessage: ''
        }
      })
    }

    case CHANGE_LOAN_NICKNAME: {
      const { loanId, data } = action.payload

      return updateLoan(loanId, { nickname: data })

      // loanNicknameSet: true
    }
    case GET_OBLIGATIONS: {
      return updateLoan(action.payload.loanId, {
        obligationsData: action.payload
      })
    }

    case GET_TRANSACTIONS: {
      const { loanId, data } = action.payload
      return updateLoanProperty(loanId, 'transactions', {
        transactionData: data
      })
    }

    case GET_BALANCES: {
      return updateLoan(action.payload.loanId, {
        balances: action.payload.data
      })
    }

    case GET_PERIODS: {
      return updateLoan(action.payload.loanId, {
        periods: action.payload.data
      })
    }

    case GET_RATES: {
      return updateLoan(action.payload.loanId, { rates: action.payload.data })
    }

    case REMOVE_INTEREST_RATE: {
      const { loanId, rateId } = action.payload
      const rates = state.byId[loanId]?.rates
      const interestRates = reject(rates?.interestRates, { id: rateId })
      return updateLoan(loanId, { rates: { ...rates, interestRates } })
    }

    case GET_UNFREEZE_PREVIEW: {
      return updateLoan(action.payload.loanId, {
        unfreezePreview: action.payload.data
      })
    }

    case GET_SETUP_PAYMENT_PLAN_PREVIEW: {
      return updateLoanProperty(action.loanId, 'setupPaymentPlanPreview', {
        ...action.payload,
        agreementDoc: action.document
      })
    }

    case CANCEL_LOAN: {
      return updateLoan(action.loanId, { status: 'canceled' })
    }

    case CREATE_TRANSACTION: {
      const { loanId, data } = action.payload
      return updateLoanProperty(loanId, 'transactions', {
        transactionCreated: true,
        currentTransactionData: data
      })
    }

    case CREATE_TRANSACTION_ERROR: {
      const { loanId, data } = action.payload
      return updateLoanProperty(loanId, 'transactions', {
        transactionErrorMessage: data
      })
    }

    case CREATE_TRANSACTION_SERVER_ERROR: {
      const { loanId, data } = action.payload
      return updateLoanProperty(loanId, 'transactions', {
        transactionErrorMessage: data
      })
    }

    case CANCEL_TRANSACTION: {
      const { loanId } = action.payload
      return updateLoanProperty(loanId, 'transactions', {
        transactionCanceled: true
      })
    }

    case DEBIT_TRANSACTION_COMPLETED: {
      return updateLoanProperty(action.payload.loanId, 'transactions', {
        debitTransactionStep: action.payload.step,
        debitTransactionData: action.payload
      })
    }

    case DEBIT_TRANSACTION_INITIATED: {
      const { loanId, step } = action.payload
      return updateLoanProperty(loanId, 'transactions', {
        debitTransactionStep: step
      })
    }

    case DEBIT_TRANSACTION_TIMED_OUT: {
      const { loanId, step, hasTimedOut } = action.payload
      return updateLoanProperty(loanId, 'transactions', {
        debitTransactionStep: step,
        hasTimedOut: hasTimedOut
      })
    }

    case DEBIT_TRANSACTION_ERROR: {
      return updateLoanProperty(action.payload.loanId, 'transactions', {
        debitTransactionStep: action.payload.step,
        debitTransactionData: action.payload
      })
    }

    case GET_REFUNDS: {
      return updateLoan(action.payload.loanId, {
        refunds: action.payload.data
      })
    }

    default:
      return state
  }
}

export default loans
