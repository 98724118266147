// Third party --------------
import _ from 'lodash'

// Rest ---------------------
import { formatPhone } from 'core/helpers/phoneNumberFormatter'
import { outboundInteractionReasonOptions as reasonOptions } from 'core/helpers/getReasonOptions'
import { peachApi } from 'core/api'

const fetchToken = async () => {
  try {
    const response = await peachApi.post({
      url: '/twilio/auth/voice-token'
    })
    return response
  } catch {
    throw new Error('Failed to fetch token.')
  }
}

const getFormattedLabel = contact => {
  const { affiliation, label, name } = contact || {}
  const newLabel =
    affiliation === 'self' ? (label === 'personal' ? 'Mobile' : label) : name
  return newLabel || null
}

const getFormattedValue = contact => {
  const { value } = contact || {}
  if (!value) return null
  return value.includes('+1') ? value : `+1${value}`
}

const formattedContactOption = option => {
  const { isUserInputtedPhoneNumber } = option || {}

  // If option is falsy, that means we need to return null
  // so that the dropdown will show that a selected value
  // is empty and cleared.
  if (!option) return null

  // This will pass down any user inputted phone number
  // that is not a found contact object
  if (isUserInputtedPhoneNumber) return option

  const newValue = getFormattedValue(option)
  const displayLabel = getFormattedLabel(option)

  return {
    ...option,
    label: displayLabel,
    sublabel: null,
    value: newValue
  }
}

/**
 *
 * @param {Array} groupedContactList
 * @param {String} contactIdOrValue
 * @returns {Object|null}
 *
 * contactIdOrValue can either be a contactId or the phone
 * value. This will first try to find a matching contact
 * from the groupedContactList, assuming that contactIdOrValue
 * is a contactId. If it can't find a match and contactIdOrValue
 * exists, it will return a custom contact object that simply
 * contains the label and value of the unknown contact.
 */
// TODO: (Satoru) consider adding helper functions like these...
// https://github.com/peachfinance/peach-front/pull/1163#discussion_r608054902
const _getDefaultContact = (groupedContactList, contactIdOrValue) => {
  const combinedContacts = _.flatten(
    _.map(groupedContactList, group => group.options)
  )
  const defaultContact = _.find(
    combinedContacts,
    ({ id, value }) => id === contactIdOrValue || value === contactIdOrValue
  )

  if (defaultContact) return defaultContact
  if (contactIdOrValue)
    return {
      label: formatPhone(contactIdOrValue),
      value: contactIdOrValue
    }
  return null
}

const getDefaultOptions = ({ casesOptions, groupedContactList, options }) => {
  const { _case, contactIdOrValue, theme, groupedContactListFromOptions } =
    options || {}

  const defaultContact = _getDefaultContact(
    groupedContactListFromOptions || groupedContactList,
    contactIdOrValue
  )
  const defaultCase = _.find(casesOptions, c => c.value === _case)
  const defaultTheme = _.find(reasonOptions, r => r.value === theme)

  return {
    defaultCase,
    defaultContact,
    defaultTheme
  }
}

export {
  fetchToken,
  formattedContactOption,
  getDefaultOptions,
  getFormattedLabel,
  getFormattedValue,
  // exporting for testing purposes only
  _getDefaultContact
}
