// Third party --------------
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components/macro'
import { useContext } from 'react'

// Components ---------------
import Icon from 'core/components/Icon'

// Rest ---------------------
import variables from 'core/styles/variables'
import { DraftInteractionContext } from 'contexts/DraftInteractionContext'

// Styled components --------
const blinkingBackground = keyframes`
  0% {
    background-color: ${variables.colorBlack100};
  }
  4% {
    background-color: ${variables.colorBlueTertiary};
  }
  8% {
    background-color: ${variables.colorBlack100};
  }
  12% {
    background-color: ${variables.colorBlack100};
  }
  16% {
    background-color: ${variables.colorBlueTertiary};
  }
  20% {
    background-color: ${variables.colorBlack100};
  }
  100% {
    background-color: ${variables.colorBlack100};
  }
`
const animation = css`
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-name: ${blinkingBackground};
  animation-timing-function: ease-out;
`
const Container = styled.div`
  display: grid;
  grid-template-columns: ${p => p.gridTemplateColumns || 'auto 96px'};
  align-items: center;
  ${p => (p.blinkTopBar ? animation : '')}
  ${p => !p.blinkTopBar && `background-color: ${p.backgroundColor}`};
  color: white;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: ${p => (p.mode === 'maximized' ? '12px 32px' : '12px 16px')};
  box-sizing: border-box;
  font-size: 24px;
`
const LeftText = styled.span`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  line-height: 24px;
`
const CenterText = styled.span`
  flex: 0 1 auto;
  align-items: center;
  margin: ${p => (p.mode === 'minimized' ? '0' : 'auto')};
  ${p =>
    p.mode === 'open'
      ? 'max-width: 200px;'
      : p.mode === 'maximized'
      ? 'max-width: 280px;'
      : ''}
  width: 100%;
  height: 24px;
  text-align: ${p => (p.mode === 'minimized' ? 'left' : 'center')};
  line-height: 24px;
`
const Text = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  font-size: 16px;
`
const DisplayControls = styled.div`
  display: flex;
  margin-left: auto;
  color: white;
`
const Control = styled.div`
  margin-left: 8px;
  color: white;
  font-size: 24px;
  cursor: pointer;
`

const TopBar = ({
  backgroundColor,
  blinkTopBar,
  centerText,
  gridTemplateColumns,
  iconName,
  id,
  leftText,
  mode,
  onClose,
  setMode,
  showCloseControl = true,
  showExpandControl = true,
  showMinimizeControl = true,
  type
}) => {
  const { useContext } = TopBar.dependencies

  const { onDeleteDraft } = useContext(DraftInteractionContext)

  const onClickResizeButton = (event, mode) => {
    // we stop propagation here to more cleanly deal with the title bar
    // also being clickable
    event.stopPropagation()
    setMode({ id, mode, type })
  }

  const baseControl = ({ mode, iconName }) => (
    <Control
      data-testid={`button-${iconName}`}
      onClick={event => onClickResizeButton(event, mode)}
    >
      <Icon name={iconName} />
    </Control>
  )

  const open = baseControl({ mode: 'open', iconName: 'maximize' })
  const minimize = baseControl({ mode: 'minimized', iconName: 'minimize' })
  const maximize = baseControl({ mode: 'maximized', iconName: 'open_in_full' })
  const exitMaximized = baseControl({
    mode: 'open',
    iconName: 'close_fullscreen'
  })
  const isMinimized = mode === 'minimized'
  const isMaximized = mode === 'maximized'
  const minimizeButton = isMinimized ? open : minimize
  const expandButton = isMaximized ? exitMaximized : maximize

  const topBarBgColor = backgroundColor ?? variables.colorBlack100

  const onCloseResource = e => {
    e.stopPropagation()
    onClose(id, type)
    if (type === 'draftInteraction') onDeleteDraft(id)
  }

  const closeButton = (
    <Control data-testid='button-close' onClick={onCloseResource}>
      <Icon name='clear' />
    </Control>
  )

  const toggleMode = () => {
    const newMode = mode === 'open' ? 'minimized' : 'open'
    setMode({ id, mode: newMode, type })
  }

  return (
    <Container
      backgroundColor={topBarBgColor}
      blinkTopBar={blinkTopBar}
      data-testid='container'
      gridTemplateColumns={gridTemplateColumns}
      mode={mode}
      onClick={toggleMode}
      type={type}
    >
      <LeftText>
        {iconName && (
          <Icon fontSize='24px' margin='0 16px 0 0' name={iconName} />
        )}
        {leftText && !isMinimized && <Text>{leftText}</Text>}
      </LeftText>
      <CenterText mode={mode}>
        <Text data-testid='center-text-inner'>{centerText || ''}</Text>
      </CenterText>
      <DisplayControls>
        {showMinimizeControl && minimizeButton}
        {showExpandControl && expandButton}
        {showCloseControl && closeButton}
      </DisplayControls>
    </Container>
  )
}

TopBar.propTypes = {
  backgroundColor: PropTypes.string,
  blinkTopBar: PropTypes.bool,
  centerText: PropTypes.string,
  gridTemplateColumns: PropTypes.string,
  iconName: PropTypes.string,
  id: PropTypes.string,
  leftText: PropTypes.string,
  mode: PropTypes.string,
  onClose: PropTypes.func,
  setMode: PropTypes.func,
  showCloseControl: PropTypes.bool,
  showExpandControl: PropTypes.bool,
  showMinimizeControl: PropTypes.bool,
  type: PropTypes.string
}

TopBar.dependencies = {
  useContext
}

export { Container, CenterText }
export default TopBar
