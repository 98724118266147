const outcomeOptions = [
  {
    label: 'Succeeded - Spoke with first party',
    value: 'voiceSpokeWithFirstParty',
    status: 'succeeded'
  },
  {
    label: 'Succeeded - Spoke with third party',
    value: 'voiceSpokeWithThirdParty',
    status: 'succeeded'
  },
  {
    label: 'Attempted - Rang no pickup',
    value: 'voiceRangNoPickup',
    status: 'attempted'
  },
  {
    label: 'Attempted - Picked and hung up',
    value: 'voicePickedHungUp',
    status: 'attempted'
  },
  {
    label: 'Attempted - Line busy no ring',
    value: 'voiceLineBusyNoRing',
    status: 'attempted'
  },
  {
    label: 'Attempted - Voicemail no message left',
    value: 'voiceReachedVoicemailNoMessageLeft',
    status: 'attempted'
  },
  {
    label: 'Attempted - Left voicemail with first party',
    value: 'voiceLeftVoicemailFirstParty',
    status: 'attempted'
  },
  {
    label: 'Attempted - Left voicemail with third party',
    value: 'voiceLeftVoicemailThirdParty',
    status: 'attempted'
  },
  {
    label: 'Attempted - Call received outside of business hours',
    value: 'voiceReceivedOutsideOpenHours',
    status: 'failed'
  },
  {
    label: 'Attempted - Call unexpectedly disconnected',
    value: 'voiceUnexpectedDisconnect',
    status: 'failed'
  },
  {
    label: 'Attempted - Call disconnected',
    value: 'voiceDisconnectedWhileQueuedOnHold',
    status: 'failed'
  },
  {
    label: `Failed - Couldn't initiate`,
    value: 'voiceFailedToInitiate',
    status: 'failed'
  },
  {
    label: 'Failed - Number disconnected',
    value: 'voiceNumberDisconnected',
    status: 'failed'
  }
]

export default outcomeOptions
