import { useQuery } from 'react-query'
import defaultQueryConfig from './defaultQueryConfig'
import getIsEnabled from './getIsEnabled'

const useAdminQuery = options => {
  const { queryKey, queryFn, ...restConfig } = options

  const enabled = getIsEnabled(options)

  const query = useQuery({
    ...defaultQueryConfig,
    ...restConfig,
    queryKey: ['admin', ...queryKey],
    queryFn,
    enabled
  })

  const resp = query.data || {}

  const result = resp.data || resp

  return [result, query]
}

export default useAdminQuery
