import { GET_DRAFT_POPULATION } from 'core/actions/constants'

const initialState = {
  bySupercaseId: {}
}

const draftPopulations = (state = initialState, action) => {
  switch (action.type) {
    case GET_DRAFT_POPULATION:
      return {
        ...state,
        bySupercaseId: {
          ...state.bySupercaseId,
          [action.payload.supercaseId]: action.payload
        }
      }
    default:
      return state
  }
}

export default draftPopulations
