import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

const DiscoverIcon = () => (
  <SvgIcon fontSize='inherit' viewBox='0 0 40 40'>
    <path
      d='M40 31.4283C40 32.7342 38.9414 33.7929 37.6355 33.7929H2.36445C1.05859 33.7929 0 32.734 0 31.4283V8.57162C0 7.26569 1.05859 6.20709 2.36445 6.20709H37.6355C38.9414 6.20709 39.9999 7.26576 39.9999 8.57162L40 31.4283Z'
      fill='#34495E'
    />
    <path
      d='M24.171 19.9844C24.1742 19.2316 23.8919 18.4777 23.3239 17.9096C22.7546 17.3403 21.9988 17.0583 21.2444 17.0628C21.2408 17.0628 21.2373 17.0626 21.2336 17.0626C19.5955 17.0626 18.2961 18.3616 18.2961 20.0002C18.2961 21.6226 19.6115 22.9376 21.2336 22.9376C22.8722 22.9376 24.1714 21.6383 24.1714 20.0002C24.1714 19.9948 24.171 19.9897 24.171 19.9844Z'
      fill='#F26E21'
    />
    <path
      d='M17.7498 21.2429C17.3108 21.6824 16.9056 21.8542 16.4691 21.8635C15.4057 21.8539 14.6239 21.0664 14.6239 19.9437C14.6239 19.3785 14.8379 18.9153 15.1769 18.5196C15.4948 18.2178 15.9043 18.0228 16.3752 18.0228C16.8836 18.0228 17.2792 18.1923 17.7309 18.6442V17.3449C17.2793 17.1191 16.8835 17.006 16.3752 17.006C15.6571 17.0574 14.98 17.3475 14.4606 17.8032C14.3109 17.9378 14.1675 18.0865 14.0459 18.2567C13.6665 18.7484 13.4372 19.3505 13.4372 20.0004C13.4372 21.6385 14.737 22.8813 16.3752 22.8813C16.3831 22.8813 16.391 22.8806 16.3988 22.8805C16.4098 22.8807 16.4206 22.8813 16.4316 22.8813C16.8834 22.8813 17.2792 22.7685 17.7876 22.5425L17.7498 21.2429C17.7308 21.262 17.7688 21.2797 17.7498 21.2978V21.2429Z'
      fill='#E7E8E3'
    />
    <path
      d='M27.88 17.815L26.6006 20.9605L25.1317 17.2317H23.9456L26.2616 22.9375H26.8831L29.3123 17.2317H28.1261L27.88 17.815Z'
      fill='#E7E8E3'
    />
    <path
      d='M29.7643 19.7175V22.7683H32.8151H32.8714V21.8641H31.9111H30.8942V20.8475V20.3392H32.8151V19.3785H30.8942V18.1922H32.8714V17.2317H29.7643V19.7175Z'
      fill='#E7E8E3'
    />
    <path
      d='M4.22933 17.2317H2.64722V22.7683H4.22925C5.07652 22.7683 5.69784 22.5421 6.20628 22.1467C6.82761 21.6382 7.22324 20.8475 7.22324 20.0003C7.22331 18.3615 6.03675 17.2317 4.22933 17.2317ZM5.52855 21.4125C5.18956 21.6948 4.73761 21.8641 4.05964 21.8641H3.72066V18.1922H4.00308C4.68105 18.1922 5.133 18.3051 5.47206 18.644C5.86745 18.9827 6.09355 19.4915 6.09355 20C6.15003 20.5085 5.92409 21.0732 5.52855 21.4125Z'
      fill='#E7E8E3'
    />
    <path
      d='M8.80542 17.2312H7.73206V22.7677H8.80542V17.2312Z'
      fill='#E7E8E3'
    />
    <path
      d='M11.5168 19.3785C10.839 19.1526 10.6693 18.983 10.6693 18.7006C10.6693 18.3616 11.0083 18.0792 11.4603 18.0792C11.7992 18.0792 12.0817 18.1923 12.3642 18.5312L12.9292 17.7969C12.4771 17.4012 11.9124 17.1755 11.291 17.1755C10.3303 17.1755 9.5394 17.8535 9.5394 18.7571C9.5394 19.5482 9.87869 19.8871 10.8954 20.2826C11.3474 20.4518 11.5169 20.5085 11.6301 20.6215C11.8556 20.7343 11.9688 20.9605 11.9688 21.1862C11.9688 21.6382 11.6301 21.9773 11.1213 21.9773C10.6124 21.9773 10.1609 21.6948 9.93486 21.2428L9.2569 21.9208C9.76557 22.6552 10.387 22.9941 11.1779 22.9941C12.3077 22.9941 13.0982 22.2597 13.0982 21.1862C13.0984 20.226 12.7597 19.8305 11.5168 19.3785Z'
      fill='#E7E8E3'
    />
    <path
      d='M35.9222 20.4518C36.7694 20.2825 37.2215 19.7174 37.2215 18.8702C37.2215 17.8535 36.4869 17.2317 35.2443 17.2317H33.6058V22.7683H34.6792V20.5648H34.8489L36.3738 22.7683H37.6733L35.9222 20.4518ZM35.018 19.7738H34.6793V18.0792H35.018C35.696 18.0792 36.0916 18.3615 36.0916 18.9268C36.0916 19.4915 35.6961 19.7738 35.018 19.7738Z'
      fill='#E7E8E3'
    />
  </SvgIcon>
)

export default DiscoverIcon
