// Third party --------------
import _ from 'lodash'
import { useEffect, useState } from 'react'

// Rest ---------------------
import { useTime } from 'core/time'

const useFormattedTextConversation = ({
  interaction,
  isTransactionalInteraction,
  messages = []
}) => {
  const { formatRelative } = useTime()

  const [conversation, setConversation] = useState([])

  useEffect(() => {
    const { content, createdAt, createdBy } = interaction || {}
    const { body, participants } = content || {}
    const { name: createdByEmployee } = createdBy || {}
    if (isTransactionalInteraction) {
      const formattedMessage = {
        author: createdByEmployee,
        direction: 'outBound',
        content: body,
        timestamp: formatRelative(createdAt, {
          isCapitalized: false,
          showTime: true
        })
      }
      const formattedConversation = [_.pickBy(formattedMessage)]
      setConversation(prev => {
        if (_.isEqual(prev, formattedConversation)) return prev
        return formattedConversation
      })
    } else {
      const formattedMessages = _.map(messages, message => {
        const {
          author,
          body,
          date_created: dateCreated,
          participant_sid: participantSid
        } = message || {}
        const isSentBySystem = author === 'system'
        const isSentByEmployee = _.startsWith(author, 'uid')
        const direction =
          isSentBySystem || isSentByEmployee ? 'outbound' : 'inbound'
        const employeeName = isSentByEmployee
          ? participants[participantSid]?.attributes?.username || ''
          : ''
        const timestamp = formatRelative(dateCreated, {
          isCapitalized: true,
          showTime: true
        })
        return {
          author:
            direction === 'outbound'
              ? isSentBySystem
                ? 'System'
                : employeeName
              : null,
          direction,
          content: body,
          timestamp: timestamp
        }
      })
      setConversation(prev => {
        if (_.isEqual(prev, formattedMessages)) return prev
        return formattedMessages
      })
    }
  }, [
    conversation,
    formatRelative,
    interaction,
    isTransactionalInteraction,
    messages
  ])

  return conversation
}

export default useFormattedTextConversation
