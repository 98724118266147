import peachApiCall from './peachApiCall'
import peachGetAll from './peachGetAll'
import peachApiDownload from './peachApiDownload'

const peachApi = {
  fetch: peachApiCall,
  get: options => peachApiCall({ ...options, method: 'GET' }),
  getAll: options => peachGetAll({ apiOptions: options }),
  post: options => peachApiCall({ ...options, method: 'POST' }),
  put: options => peachApiCall({ ...options, method: 'PUT' }),
  patch: options => peachApiCall({ ...options, method: 'PATCH' }),
  delete: options => peachApiCall({ ...options, method: 'DELETE' }),
  /** Use this when needing to directly download a file that
   * requires authorization. Usage:
   * - peachApi.download({ url: /the-file.txt })
   */
  download: peachApiDownload
}

export default peachApi
