import PropTypes from 'prop-types'
import React, { useState } from 'react'

import OneTimeCodeOverlay from './OneTimeCodeOverlay'
import OneTimeCodeForm from './OneTimeCodeForm'

import { useWrite } from 'core/hooks'
import { formatOtcError } from './otcHelpers'
import { minDelay } from '../loginHelpers'

const OneTimeCodeModal = props => {
  const {
    onOneTimeCode,
    onRequestCodeToChannel,
    onClose,
    userType,
    initialChannel
  } = props

  const [channel, setChannel] = useState(initialChannel || 'email')

  const [loginWithCode, loggingIn, , loginError, clearLoginRequest] = useWrite(
    async code => {
      return onOneTimeCode(code)
    }
  )

  const [
    sendRequestCode,
    requestingCode,
    codeResp,
    codeError,
    clearCodeRequest
  ] = useWrite(async channel => {
    const promise = onRequestCodeToChannel(channel)
    return minDelay(promise, 750)
  })

  const error = loginError || codeError

  const errorMessage = formatOtcError(error)

  const handleSwitchChannel = channel => {
    setChannel(channel)
    sendRequestCode(channel)
    clearLoginRequest()
    clearCodeRequest()
  }

  const sending = loggingIn || requestingCode

  const formContents = (() => {
    const sentMessage =
      !requestingCode &&
      codeResp &&
      (channel === 'voice' ? 'Call made' : 'Code Sent')

    const formProps = {
      key: channel,
      onOneTimeCode: loginWithCode,
      sending,
      sentMessage
    }

    if (channel === 'email') {
      const onTryAnotherWay =
        userType === 'borrower' ? () => handleSwitchChannel('text') : null
      return (
        <OneTimeCodeForm
          {...formProps}
          subtitle='Check your inbox for an email with the verification code and enter it here'
          onEmailMe={() => handleSwitchChannel('email')}
          tryAnotherWayLabel='Try another way to login, text me'
          onTryAnotherWay={onTryAnotherWay}
        />
      )
    }

    if (channel === 'text' || channel === 'voice') {
      return (
        <OneTimeCodeForm
          {...formProps}
          subtitle='Check your phone for the verification code and enter it here'
          onTextMe={() => handleSwitchChannel('text')}
          onCallMe={() => handleSwitchChannel('voice')}
        />
      )
    }
  })()

  return (
    <OneTimeCodeOverlay errorMessage={errorMessage} onClose={onClose}>
      {formContents}
    </OneTimeCodeOverlay>
  )
}

OneTimeCodeModal.propTypes = {
  onOneTimeCode: PropTypes.func,
  userType: PropTypes.oneOf(['agent', 'borrower']),
  initialChannel: PropTypes.oneOf(['email', 'text', 'voice']),
  onRequestCodeToChannel: PropTypes.func,
  onClose: PropTypes.func
}

export default OneTimeCodeModal
