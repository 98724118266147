const defaultEndpoint = process.env.REACT_APP_API_ENDPOINT

let APIendpoint = defaultEndpoint

// localStorage.setItem('PEACH_API_ENDPOINT', 'https://devapi.peach.finance/api')

// localhost:3001/domain=peach-agent.peach-dev.finance

const KEY = 'PEACH_API_ENDPOINT'

if (process.env.REACT_APP_ENV_LABEL !== 'prod') {
  try {
    const customEndpoint = localStorage.getItem(KEY)
    if (customEndpoint) {
      APIendpoint = customEndpoint
    }
  } catch (e) {
    APIendpoint = defaultEndpoint
  }
}

const isCustomEndpoint = APIendpoint !== defaultEndpoint

const setApiEndpoint = endpoint => {
  if (endpoint) {
    localStorage.setItem(KEY, endpoint)
  } else {
    localStorage.removeItem(KEY)
  }
}
export {
  isCustomEndpoint,
  defaultEndpoint,
  setApiEndpoint as PRIVATE_setApiEndpoint
}

export default APIendpoint
