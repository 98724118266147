import { createSelector } from 'reselect'

/** You must supply the `key` arg to this selector factory
 * so that the resulting selector knows which loading status
 * to look up. The loading object in the redux store
 * consists of keys defined by the actions dispatched to it.
 * A common pattern we are using is for a component to define
 * its own key when dispatching an action with something like
 * the name of the component itself as the key. If the
 * component needs to keep track of multiple loading statuses,
 * it can make an even more specific name for the key.
 * The component can then make a selector from this factory
 * for each loading status it needs to track, supplying the
 * same key(s) it used when dispatching. Don't forget in
 * your component to call resetLoading from actions/loading.js
 * on your key(s) when mounting or in a one-time `useEffect()`.
 * That way your loading status will be null if the component
 * is unmounted then remounted later.
 */
export const makeGetLoadingStatus = key =>
  createSelector(
    state => state.loading[key],
    loadingStatus => loadingStatus
  )
