import styled from 'styled-components/macro'

const Wrapper = styled.div`
  padding-top: 4px;
`

const Label = styled.label`
  display: block;
  padding-top: 4px;
  padding-bottom: 6px;
  padding-left: 4px;
  color: #444;
  font-weight: 400;
  font-size: 12px;
`

const withLabel = Control => {
  const Inner = ({ label, placeholder, ...rest }) => {
    const $placeholder = placeholder || (label ? `Enter ${label}...` : '')

    return (
      <Wrapper>
        {label && <Label htmlFor={label}>{label}</Label>}
        <Control
          name={label}
          placeholder={$placeholder}
          label={label}
          {...rest}
        />
      </Wrapper>
    )
  }

  return Inner
}

export default withLabel
