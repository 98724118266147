import _ from 'lodash'
import { useEffect, useRef } from 'react'

const MINUTE = 60 * 1000

const useOnActivity = (onActivity, options = {}) => {
  const throttleDelay = (options.delayInMinutes || 1) * MINUTE

  const onActivityRef = useRef(onActivity)

  useEffect(() => {
    onActivityRef.current = onActivity
  }, [onActivity])

  useEffect(() => {
    const throttledOnActivity = _.throttle(
      () => {
        if (onActivityRef.current) onActivityRef.current()
      },
      throttleDelay,
      { leading: false, trailing: true }
    )

    document.addEventListener('mousemove', throttledOnActivity)
    document.addEventListener('keypress', throttledOnActivity)

    return () => {
      throttledOnActivity.cancel()
      document.removeEventListener('mousemove', throttledOnActivity)
      document.removeEventListener('keypress', throttledOnActivity)
    }
  }, [throttleDelay])
}

export default useOnActivity
