// https://www.twilio.com/docs/taskrouter/api/reservations#resource-properties
export const RESERVATION_ACCEPTED = 'accepted'
export const RESERVATION_REJECTED = 'rejected'
export const RESERVATION_PENDING = 'pending'
export const RESERVATION_RESCINDED = 'rescinded'
export const RESERVATION_TIMEOUT = 'timeout'

// https://www.twilio.com/docs/taskrouter/api/task#task-lifecycle
export const TASK_COMPLETED = 'completed'
export const TASK_CANCELED = 'canceled'
export const TASK_ASSIGNED = 'assigned'
export const TASK_PENDING = 'pending'
export const TASK_RESERVED = 'reserved'

export const WORKER_ACTIVITY_NAME_UNKNOWN = 'Unknown' // Doesn't actually exist in twilio
export const WORKER_ACTIVITY_NAME_OFFLINE = 'Offline'
export const WORKER_ACTIVITY_NAME_AVAILABLE = 'Available'
export const WORKER_ACTIVITY_NAME_UNAVAILABLE = 'Unavailable'
export const WORKER_ACTIVITY_NAME_BUSY_ON_VOICE_CALL = 'Busy on voice call'
export const WORKER_ACTIVITY_NAME_AVAILABLE_FOR_VOICE_CALL =
  'Available for voice call'

// Possible values of taskType
export const ANSWER_INBOUND_VOICE_CALL = 'answerInboundVoiceCall'
export const AUTO_CASE = 'reviewAutoCreatedCase'
export const DOCUMENT = 'reviewDocumentUpload'
export const ESCALATED_CASE = 'reviewEscalatedCase'
export const INBOUND = 'handleInboundInteraction'
export const REPLY_TO_NEW_CONVO = 'replyToNewConvo'
export const REVIEW_CASE_INSTRUCTIONS = 'reviewCaseInstructions'
export const REVIEW_ESCALATED_CASE_AGENT = 'reviewEscalatedCaseAgent'
export const REVIEW_ESCALATED_CASE_AGENT_URGENT =
  'reviewEscalatedCaseAgentUrgent'
export const REVIEW_ESCALATED_CASE_TEAM = 'reviewEscalatedCaseTeam'
export const REVIEW_ESCALATED_CASE_TEAM_URGENT = 'reviewEscalatedCaseTeamUrgent'
export const REVIEW_UPDATED_CASE = 'reviewAutoUpdatedCase'
export const SCHEDULED_CALL = 'makeScheduledCall'
export const SNOOZED = 'reviewSnoozedCase'
export const UNBOUND = 'handleUnboundInboundInteraction'

/**
 * This KNOWN_TASK_TYPES variable is used to mimic a
 * possible response the FE might receive from the BE when
 * requesting what task types are known for a given company.
 * This should be used as a temporary solution until such
 * endpoint is available.
 */
export const KNOWN_TASK_TYPES = [
  ANSWER_INBOUND_VOICE_CALL,
  AUTO_CASE,
  DOCUMENT,
  ESCALATED_CASE,
  INBOUND,
  REVIEW_CASE_INSTRUCTIONS,
  REVIEW_ESCALATED_CASE_AGENT,
  REVIEW_ESCALATED_CASE_AGENT_URGENT,
  REVIEW_ESCALATED_CASE_TEAM,
  REVIEW_ESCALATED_CASE_TEAM_URGENT,
  REVIEW_UPDATED_CASE,
  REPLY_TO_NEW_CONVO,
  SCHEDULED_CALL,
  SNOOZED,
  UNBOUND
]
