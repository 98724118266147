import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

import CardSectionListRow from './CardSectionListRow'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const CardSectionList = ({ children, leftWidth }) => {
  const kiddos = React.Children.toArray(children).filter(child => child)

  const list = kiddos.map((child, index) => {
    const props = { isFirst: index === 0, leftWidth }

    return child.type === CardSectionListRow ? (
      React.cloneElement(child, props)
    ) : (
      <CardSectionListRow key={`wrapped-${index}`} {...props}>
        {child}
      </CardSectionListRow>
    )
  })

  return <ContentContainer>{list}</ContentContainer>
}

CardSectionList.propTypes = {
  children: PropTypes.node,
  leftWidth: PropTypes.string
}

CardSectionList.Row = CardSectionListRow

export default CardSectionList
