import PropTypes from 'prop-types'
import React, { useState } from 'react'
import LoginSubmitButton from '../LoginSubmitButton'
import { useOnMount, useWrite } from 'core/hooks'
import { getQueryParams, clearQueryParams } from 'core/helpers/queryParams'
import { Spinner } from 'core/components'
import LoginFormCard from '../LoginFormCard'
import { useLoginInfo } from '../LoginWrapper'

const DEFAULT_ERROR_TEXT = `Your login session has expired or there was a problem with your login information. Please try again or contact support.`

// known error types `SAML_NO_LOGIN_FOUND`, `SAML_CONFIG_ERROR`

const SamlLogin = ({ onLogin }) => {
  const { ssoUrl } = useLoginInfo()

  const [queryParams] = useState(() => getQueryParams() || {})
  const {
    samlUsername,
    samlPassword,
    errorType,
    errorMessage,
    redirect
  } = queryParams

  const immediatelyRedirect = redirect === 'immediate'

  const hasSamlCreds = samlUsername && samlPassword

  const hasSamlError = errorType || errorMessage

  const [onSubmitSamlCreds, sending, resp, error, clear] = useWrite(
    async () => {
      return onLogin({ username: samlUsername, password: samlPassword })
    }
  )

  const onLoginWithSSO = () => {
    clear()
    window.location.href = ssoUrl
  }

  useOnMount(() => {
    clearQueryParams()

    if (hasSamlCreds) {
      onSubmitSamlCreds()
    } else if (!hasSamlError) {
      // automatically redirect
      if (immediatelyRedirect) onLoginWithSSO()
    }
  })

  const hasError = errorType || errorMessage || error

  const errorMsg = hasError ? DEFAULT_ERROR_TEXT : undefined

  const disabled = !!(
    !ssoUrl ||
    resp ||
    sending ||
    hasSamlError ||
    error ||
    immediatelyRedirect
  )

  const isWorkingAutomatically =
    !hasError && (hasSamlCreds || immediatelyRedirect)

  if (isWorkingAutomatically) {
    return <Spinner height='120px' />
  }

  return (
    <LoginFormCard>
      <LoginSubmitButton
        submitLabel='Login via SSO'
        onSubmit={onLoginWithSSO}
        disabled={disabled}
        sending={sending || immediatelyRedirect}
        errorMessage={errorMsg}
      />
    </LoginFormCard>
  )
}

SamlLogin.propTypes = {
  onLogin: PropTypes.func
}

export default SamlLogin
