import { CREATE_NOTE, LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

const createNote = ({ key, personId, caseId, body }) => {
  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    try {
      const response = await peachApi.post({
        url: `/people/${personId}/cases/${caseId}/notes`,
        body
      })
      dispatch({ type: CREATE_NOTE, payload: response.data, personId })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
    } catch {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
    }
  }
}

export default createNote
