import _ from 'lodash'

import useEntity from 'core/hooks/useEntity'

const useFirstLoanIdOfCase = ({ borrowerId, caseId }) => {
  // Get the loanId of the first associated loan to the case
  const thisCase = useEntity('cases', caseId, { personId: borrowerId })
  const loanId =
    thisCase.loadingStatus === 'success'
      ? _.find(thisCase.associations, x => x.objectType === 'loan')?.objectId
      : undefined

  return loanId
}

export default useFirstLoanIdOfCase
