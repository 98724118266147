const retrieve = key => {
  if (!key) return undefined
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (e) {
    return undefined
  }
}

const store = (key, value) => {
  if (!key) return
  try {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.removeItem(key)
    }
  } catch (error) {}
}

export { store, retrieve }
