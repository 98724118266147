import _ from 'lodash'
import { useMemo } from 'react'
import { peachApi } from 'core/api'

import { useAdminCompanyId } from './adminCompanyId'

/* 

adminApi provides an API surface similar to peachApi, 
but with the company ID applied automatically  


when company X is active

example:  

adminApi.get({ url: "/users" })

will call

peachApi.get({url: "/companies/CP-X/users" })

and

adminApi.get({ url: "/communicator/templates" })

will call

peachApi.get({ url: "/communicator/templates", params: { companyId: "CP-X"}})

*/

const isCompanyIdViaParam = options => {
  return _.startsWith(options.url, '/communicator')
}

const useAdminApi = () => {
  const companyId = useAdminCompanyId()

  return useMemo(() => {
    const adminApi = _.mapValues(peachApi, callFn => {
      return options => {
        if (isCompanyIdViaParam(options)) {
          // this is an endpoint that passes companyId via query param
          const { params, ...rest } = options

          const fullParams = { ...params, companyId }

          return callFn({ params: fullParams, ...rest })
        } else {
          // this is an endpoint that use companyId in the path
          const { url, ...rest } = options

          const nestedUrl = `/companies/${companyId}${url}`

          return callFn({ url: nestedUrl, ...rest })
        }
      }
    })

    return adminApi
  }, [companyId])
}

export default useAdminApi
