import { APIendpoint, LOADING, getStoredToken } from 'core/actions/constants'
import requestProgress from 'core/actions/documents/requestProgress'

import { getGlobalValue } from 'core/badGlobalDoNotUse'

/**
 * This action has a slightly different pattern compared to our other actions.
 * This action will batch multiple API calls in a single Promise so you will
 * have to provide an array of files as the POST body.
 */
const uploadFiles = ({
  key,
  files,
  documentDescriptors,
  documentUploadLinkKey
}) => {
  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    let isSuccess = true
    const makeRequest = ({ data, url, method }) => {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== 4) return
          if (xhr.status < 200 || xhr.status > 299) {
            isSuccess = false
            resolve(xhr)
          }
          resolve(xhr)
        }

        xhr.open(method, url, true)
        xhr.setRequestHeader('Authorization', `Bearer ${getStoredToken()}`)
        xhr.upload.onprogress = event => {
          /**
           * Since this onprogress event fires pretty often, whenever the
           * cancelUpload property is set to 'true' in localStorage, this
           * event should almost instantly catch that and abort any uploads
           * that are still in progress.
           */
          const shouldCancelUpload =
            localStorage.getItem('cancelUpload') === 'true'
          if (shouldCancelUpload) {
            xhr.abort()
          }

          let file
          for (const value of data.values()) {
            file = value.name
          }
          dispatch(
            requestProgress({ file, loaded: event.loaded, total: event.total })
          )
        }
        xhr.send(data)
      })
    }

    await Promise.all([
      ...files.map(file => {
        const fd = new FormData()
        fd.append('file', file, file.name)
        let id
        Object.values(documentDescriptors).forEach(doc => {
          if (doc.fileName === file.name) {
            id = doc.id
          }
        })

        const personId = getGlobalValue('borrowerPersonId')
        const defaultUrl = `${APIendpoint}/people/${personId}/documents/${id}/content`
        const uploadLinkUrl = `${APIendpoint}/document-uploads/${documentUploadLinkKey}/documents/${id}/content`

        return makeRequest({
          data: fd,
          url: documentUploadLinkKey ? uploadLinkUrl : defaultUrl,
          method: 'POST'
        })
      })
    ])

    /**
     * If the cancelUpload value in localStorage is set to true then we
     * want to reset the loading status of this action to undefined.
     * Since both success and error status is used to determine a specific
     * state in the document upload feature, especially in the borrower portal,
     * we use the undefined status as the default state of components that
     * deal with file uploads.
     */
    const uploadHasBeenCanceled =
      localStorage.getItem('cancelUpload') === 'true'

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: uploadHasBeenCanceled
          ? undefined
          : isSuccess
          ? 'success'
          : 'error'
      })
    }
  }
}

export default uploadFiles
