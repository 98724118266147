// Third party ------------------------
import PropTypes from 'prop-types'
import React, { useContext, useMemo, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'

// Components -------------------------
import ActiveTextConversation from 'components/ResourceOverlay/ActiveTextConversation'
import Document from 'components/ResourceOverlay/Document'
import DraftInteraction from './DraftInteraction'
import { ErrorBoundary } from 'core/components'
import Icon from 'core/components/Icon'
import Interaction from './Interaction'
import PastTextInteraction from './PastTextInteraction'
import ResourceModal from './ResourceModal'
import Telephony from 'components/ResourceOverlay/Telephony'
import VoiceInteraction from './VoiceInteraction'

// Rest ---------------------
import parseInteractionInfo from 'core/helpers/parseInteractionInfo'
import variables from 'core/styles/variables'
import {
  CallModeContext,
  PREDIAL,
  POST_DIAL,
  CALL_COMPLETED
} from 'contexts/CallModeContext'
import { DraftInteractionContext } from 'contexts/DraftInteractionContext'
import { useEntity } from 'core/hooks'

// Styles
const DisplayControls = styled.div`
  display: flex;
  margin-left: auto;
  color: white;
`
const Control = styled.div`
  margin-left: 8px;
  color: white;
  fontsize: 24px;
  cursor: pointer;
`

const EverythingBelowTitle = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${p => (p.showTopBar ? 'white' : 'none')};
  flex: 1 1 auto;
  height: ${p =>
    p.mode === 'minimized'
      ? p.showTopBar
        ? '0'
        : '48px'
      : p.type === 'telephony' ||
        p.type === 'voiceInteraction' ||
        p.isDraftTextInteraction
      ? 'auto'
      : p.mode === 'open'
      ? '600px'
      : '100%'};
  width: 100%;
  box-sizing: border-box;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${p => (p.mode === 'maximized' ? 'height: 100%;' : '')}
  width: ${p =>
    p.mode === 'minimized' ? '320px' : p.mode === 'open' ? '680px' : ''};
  margin: ${p => (p.mode === 'maximized' ? '0' : '0 16px')};
  box-shadow: ${p =>
    p.mode === 'open' ? `${variables.peachyShadow}` : 'none'};
`

const TitleBar = styled.div`
  display: grid;
  grid-template-columns: ${p =>
    p.type === 'draftInteraction'
      ? '104px auto 104px'
      : p.type === 'interaction'
      ? '30% auto 30%'
      : p.type === 'telephony' &&
        (p.callMode === PREDIAL.state || p.callMode === POST_DIAL.state)
      ? '64px auto 64px'
      : p.type === 'telephony'
      ? '32px auto 32px'
      : p.type === 'voiceInteraction'
      ? '72px auto 72px'
      : 'auto 96px'};
  align-items: center;
  background-color: ${p =>
    p.type === 'telephony'
      ? `${variables.colorBlueTertiary}`
      : `${variables.colorBlack100}`};
  color: white;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: ${p => (p.mode === 'maximized' ? '12px 32px' : '12px 16px')};
  box-sizing: border-box;
  font-size: 24px;
`

const Title = styled.span`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  font-size: 16px;
`
const TitleText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const BorrowerName = styled.span`
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: auto;
  ${p => (p.mode === 'minimized' ? 'max-width: 224px;' : '')}
  white-space: nowrap;
  width: 100%;
  font-size: 16px;
  text-align: center;
`

const phoneIcon = name => <Icon name={name} />
const ICONS = {
  email: 'email',
  mail: 'markunread_mailbox',
  text: 'mobile_screen_share'
}

const Resource = ({
  id,
  mode,
  type,
  setMode,
  onClose,
  borrowerId: passedInBorrowerId,
  options
}) => {
  const { callMode } = useContext(CallModeContext)
  const { draftInteractionsById, onDeleteDraft } = useContext(
    DraftInteractionContext
  )
  // Local state
  const [borrowerId, setBorrowerId] = useState(passedInBorrowerId)
  const [borrowerName, setBorrowerName] = useState(null)

  const interaction = useEntity(
    'interactions',
    type === 'interaction' ? id : null
  )
  const document = useEntity('documents', type === 'document' ? id : null, {
    personId: borrowerId
  })
  const draftInteraction = useMemo(
    () => (type === 'draftInteraction' ? draftInteractionsById[id] : {}),
    [id, type, draftInteractionsById]
  )
  const borrower = useEntity('people', borrowerId)

  let title
  if (type === 'interaction') {
    title = parseInteractionInfo(interaction).title
  } else if (type === 'document') {
    title = document?.description || document?.fileName
  } else if (type === 'draftInteraction') {
    title = `New ${draftInteraction?.channel || 'draft'}`
  }

  const isDraftTextInteraction = draftInteraction?.channel === 'text'

  if (mode === 'hidden') {
    return null
  }

  const width =
    mode === 'minimized' ? '320px' : mode === 'open' ? '680px' : 'auto'

  const onClickResizeButton = (event, mode) => {
    // we stop propagation here to more cleanly deal with the title bar
    // also being clickable
    event.stopPropagation()
    setMode({ id, mode, type })
  }

  const open = (
    <Control onClick={event => onClickResizeButton(event, 'open')}>
      <Icon name='maximize' />
    </Control>
  )
  const minimize = (
    <Control onClick={event => onClickResizeButton(event, 'minimized')}>
      <Icon name='minimize' />
    </Control>
  )
  const maximize = (
    <Control onClick={event => onClickResizeButton(event, 'maximized')}>
      <Icon name='open_in_full' />
    </Control>
  )
  const exitMaximized = (
    <Control onClick={event => onClickResizeButton(event, 'open')}>
      <Icon name='close_fullscreen' />
    </Control>
  )
  const minimizeButton = mode === 'minimized' ? open : minimize
  const expandButton =
    mode === 'open' || mode === 'minimized' ? maximize : exitMaximized

  const onCloseResource = e => {
    e.stopPropagation()
    onClose(id, type)
    if (type === 'draftInteraction') onDeleteDraft(id)
  }
  const closeButton = (
    <Control onClick={onCloseResource}>
      <Icon name='clear' />
    </Control>
  )

  const toggleMode = () => {
    const newMode =
      mode === 'open'
        ? 'minimized'
        : mode === 'minimized'
        ? 'open'
        : mode === 'maximized'
        ? 'open'
        : 'open'
    setMode({ id, mode: newMode, type })
  }

  /**
   * Because of the growing complexity of the <TitleBar />
   * UI portion of this component, it's probably best to
   * separate that portion out into its own component.
   * The <TitleBar /> styled component is now available via
   * the <TopBar /> component and can be used for any
   * resource type. The <PastTextInteraction /> component
   * is the first component to utilize the <TopBar />
   * component within itself.
   */
  const topBarExcluded = ['pastTextInteraction', 'textConversation']
  const showTopBar = !_.includes(topBarExcluded, type)

  const mainJsx = (
    <Container width={width} mode={mode}>
      {showTopBar && (
        <TitleBar
          onClick={toggleMode}
          mode={mode}
          type={type}
          callMode={callMode.state}
        >
          {(type === 'telephony' || type === 'voiceInteraction') &&
            phoneIcon(callMode.iconName)}
          {type !== 'telephony' && type !== 'voiceInteraction' && (
            <Title>
              {(type === 'draftInteraction' || type === 'interaction') && (
                <Icon
                  name={
                    ICONS[draftInteraction?.channel || interaction?.channel]
                  }
                  margin='0 8px 0 0'
                />
              )}
              <TitleText>{title}</TitleText>
            </Title>
          )}
          {type !== 'document' && (
            <BorrowerName type={type} mode={mode}>
              {borrowerName || ''}
            </BorrowerName>
          )}
          <DisplayControls>
            {callMode.state !== CALL_COMPLETED.state && minimizeButton}
            {type !== 'telephony' &&
              type !== 'voiceInteraction' &&
              expandButton}
            {(callMode.state === PREDIAL.state ||
              callMode.state === CALL_COMPLETED.state ||
              type !== 'telephony') &&
              closeButton}
          </DisplayControls>
        </TitleBar>
      )}

      <EverythingBelowTitle
        showTopBar={showTopBar}
        mode={mode}
        type={type}
        isDraftTextInteraction={isDraftTextInteraction}
      >
        <ErrorBoundary>
          {type === 'interaction' ? (
            <Interaction
              borrower={borrower}
              borrowerName={borrowerName}
              id={id}
              maximized={mode === 'maximized'}
              onClose={() => onClose(id, type)}
              options={options}
              setBorrowerId={setBorrowerId}
              setBorrowerName={setBorrowerName}
            />
          ) : type === 'voiceInteraction' ? (
            <VoiceInteraction id={id} setBorrowerName={setBorrowerName} />
          ) : type === 'document' ? (
            <Document id={id} mode={mode} borrowerId={borrowerId} />
          ) : type === 'draftInteraction' ? (
            <>
              {borrower !== undefined && (
                <DraftInteraction
                  borrower={borrower}
                  borrowerName={borrowerName}
                  id={id}
                  maximized={mode === 'maximized'}
                  onClose={() => onClose(id, type)}
                  setBorrowerName={setBorrowerName}
                />
              )}
            </>
          ) : type === 'telephony' ? (
            <Telephony
              borrowerId={borrowerId}
              onClose={() => onClose(id, type)}
              id={id}
              maximized={mode === 'maximized'}
              options={options}
              setTitle={setBorrowerName}
            />
          ) : type === 'pastTextInteraction' ? (
            <PastTextInteraction
              borrower={borrower}
              id={id}
              maximize={mode === 'maximized'}
              mode={mode}
              onClose={() => onClose(id, type)}
              setMode={setMode}
            />
          ) : type === 'textConversation' ? (
            <ActiveTextConversation
              borrower={borrower}
              id={id}
              maximize={mode === 'maximized'}
              mode={mode}
              onClose={() => onClose(id, type)}
              setMode={setMode}
            />
          ) : null}
        </ErrorBoundary>
      </EverythingBelowTitle>
    </Container>
  )

  return mode === 'maximized' ? (
    <ResourceModal
      onClickOutside={() => setMode({ id, mode: 'open', type })}
      isDraftTextInteraction={isDraftTextInteraction}
    >
      {mainJsx}
    </ResourceModal>
  ) : (
    mainJsx
  )
}

Resource.propTypes = {
  borrowerId: PropTypes.string,
  id: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['hidden', 'minimized', 'open', 'maximized'])
    .isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.object,
  setMode: PropTypes.func.isRequired,
  type: PropTypes.oneOf([
    'document',
    'draftInteraction',
    'interaction',
    'pastTextInteraction',
    'telephony',
    'textConversation',
    'voiceInteraction'
  ])
}

export default Resource
