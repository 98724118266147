import { useEffect, useState } from 'react'

const useIsContentEmpty = (channel, plainText, htmlContent) => {
  const [isEmpty, setIsEmpty] = useState(false)

  useEffect(() => {
    const value =
      channel === 'text'
        ? plainText.length === 0
        : htmlContent.length === 0 || htmlContent === '<p><br></p>'
    setIsEmpty(value)
  }, [channel, plainText, htmlContent])

  return isEmpty
}

export default useIsContentEmpty
