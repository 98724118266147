// Third party ------------------------
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css, keyframes } from 'styled-components/macro'

// Styles -----------------------------
const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`
const animation = css`
  ${rotate} 1s infinite linear;
`
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => p.width || 'inherit'};
  height: ${p => p.height || '100%'};
`
const StyledSpinner = styled.div.attrs(p => ({
  'data-testid': p.testId
}))`
  position: absolute;
  padding: 6px;
  border: 3px solid #ccc;
  border-right-color: #888;
  border-radius: 50%;
  animation: ${animation};
`

const Spinner = ({ height, width }) => {
  return (
    <SpinnerContainer
      data-testid='spinner-container'
      height={height}
      width={width}
    >
      <StyledSpinner className='spinner' />
    </SpinnerContainer>
  )
}

Spinner.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
}

export default Spinner
