// Third party --------------
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

// Components ---------------
import { Button } from 'core/components'
import Icon from 'core/components/Icon'

// Rest ---------------------
import capitalizeAllWords from 'core/helpers/capitalizeAllWords'
import deCamelize from 'core/helpers/deCamelize'
import variables from 'core/styles/variables'
import { formatPhone } from 'core/helpers/phoneNumberFormatter'
import { getFormattedLabel } from './helpers'

// Styles -------------------
const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  padding-bottom: 8px;
`
const Left = styled.div`
  color: ${variables.colorBlack60};
  font-size: 14px;
  font-weight: 300;
`
const AssociateDisassociate = styled(Button)`
  margin-top: 16px;
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
`
const SelectedContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${variables.colorBlack70};
`
const IconLabel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`
const NameOrContactType = styled.div`
  font-weight: 500;
  text-transform: capitalize;
`
const LabelValue = styled.div`
  display: flex;
  margin: ${p => (p.margin ? p.margin : '')};
`
const Label = styled.div`
  width: ${p => p.width};
  color: ${variables.colorBlack60};
  font-size: 14px;
  font-weight: 300;
`
const Value = styled.div`
  color: ${variables.colorBlack70};
  font-size: 14px;
`
const RecordedLabelContainer = styled.div`
  display: flex;
`

const RecordedLabelValue = styled.div`
  display: flex;
  justify-content: space-between;
`

const TopPostDialSection = ({
  borrowerId,
  callDuration,
  callerPhoneNumber,
  canChangeBorrowerAssociation,
  contact,
  isInbound,
  onChangeBorrowerAssociation,
  onLockClick,
  recorded,
  sensitiveData
}) => {
  const { affiliation, value } = contact || {}
  const displayLabel = getFormattedLabel(contact)
  const displayValue = formatPhone(value || callerPhoneNumber)
  const isSelf = affiliation === 'self' || affiliation === undefined
  const toFrom = isInbound ? 'From:' : 'To:'
  const formattedAffiliation =
    affiliation === 'legal'
      ? 'Legal Representation'
      : !isSelf
      ? capitalizeAllWords(deCamelize(affiliation))
      : ''

  return (
    <Section>
      <Left data-testid='to-from'>
        <div>
          {toFrom} {formattedAffiliation}
        </div>
        {canChangeBorrowerAssociation && (
          <AssociateDisassociate
            data-testid='button-change-borrower-association'
            fontSize='14px'
            onClick={() => onChangeBorrowerAssociation()}
            secondary
          >
            {!borrowerId ? 'Associate borrower' : 'Disassociate borrower'}
          </AssociateDisassociate>
        )}
      </Left>
      <SelectedContactDetails>
        <IconLabel>
          <NameOrContactType data-testid='caller-name-or-contact-type'>
            {!borrowerId ? 'Unidentified caller' : displayLabel}
          </NameOrContactType>
        </IconLabel>
        <div data-testid='caller-number'>{displayValue}</div>
      </SelectedContactDetails>
      <Right>
        <LabelValue margin='0 0 16px'>
          <Label width={recorded ? '72px' : '104px'}>Duration</Label>
          <Value data-testid='call-duration'>{callDuration}</Value>
        </LabelValue>
        <RecordedLabelValue>
          <RecordedLabelContainer>
            <Label
              data-testid='recorded-status-text'
              width={recorded ? '72px' : '104px'}
            >
              {recorded ? 'Recorded' : 'Not recorded'}
            </Label>
            <Icon
              data-testid='recorded-status-icon'
              color={recorded ? variables.colorBlack70 : variables.colorBlack60}
              fontSize='24px'
              name={recorded ? 'voicemail' : 'block'}
            />
          </RecordedLabelContainer>
          {recorded && (
            <>
              {sensitiveData ? (
                <Button
                  data-testid='button-lock'
                  color={variables.colorBlack90}
                  fontSize='24px'
                  onClick={onLockClick}
                >
                  <Icon name='lock' />
                </Button>
              ) : (
                <Button
                  data-testid='button-unlock'
                  color={variables.colorBlack50}
                  fontSize='24px'
                  onClick={onLockClick}
                >
                  <Icon name='lock_open' />
                </Button>
              )}
            </>
          )}
        </RecordedLabelValue>
      </Right>
    </Section>
  )
}

TopPostDialSection.propTypes = {
  borrowerId: PropTypes.string,
  callDuration: PropTypes.string,
  callerPhoneNumber: PropTypes.string,
  canChangeBorrowerAssociation: PropTypes.bool,
  contact: PropTypes.object,
  isInbound: PropTypes.bool,
  onChangeBorrowerAssociation: PropTypes.func,
  onLockClick: PropTypes.func,
  recorded: PropTypes.bool,
  sensitiveData: PropTypes.bool
}

export { LabelValue, Label }
export default TopPostDialSection
