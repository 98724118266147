// Third party ------------------------
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

// Components -------------------------
import { Button } from 'core/components'
import Icon from 'core/components/Icon'

// Styles -----------------------------
const Section = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`
const StyledButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BottomActiveSection = ({ onClickEndCall, micOn, setMicOn }) => {
  return (
    <Section>
      <StyledButton
        data-testid='button-mic-toggle'
        margin='0 0 0 40px'
        onClick={setMicOn}
        secondary={micOn}
      >
        <Icon
          data-testid='icon-mic'
          fontSize='24px'
          name={micOn ? 'mic' : 'mic_off'}
        />
        {micOn ? 'Turn off microphone' : 'Turn on microphone'}
      </StyledButton>
      <StyledButton
        data-testid='button-end-call'
        margin='0 0 0 40px'
        onClick={onClickEndCall}
        secondary
      >
        <Icon fontSize='24px' name='call_end' />
        End call
      </StyledButton>
    </Section>
  )
}

BottomActiveSection.propTypes = {
  onClickEndCall: PropTypes.func.isRequired,
  micOn: PropTypes.bool.isRequired,
  setMicOn: PropTypes.func.isRequired
}

export default BottomActiveSection
