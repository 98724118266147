// Third party --------------
import PropTypes from 'prop-types'
import React, { createContext, useCallback, useContext, useMemo } from 'react'
import _ from 'lodash'
import { v4 as uuid4 } from 'uuid'

// Rest ---------------------
import { OpenResourceContext } from 'contexts/OpenResourceContext'
import { useStoredState } from 'admin/hooks'

const DraftInteractionContext = createContext({})

const DraftInteractionContextProvider = ({ children }) => {
  const { openDraftInteraction } = useContext(OpenResourceContext)
  const [draftInteractionsById, setDraftInteractionsById] = useStoredState(
    'draftInteractionsById'
  )

  const onOpenNewDraftInteraction = useCallback(
    ({
      associatedCase,
      borrowerId,
      channel,
      companyId,
      previousInteractions,
      supercaseId
    }) => {
      const id = uuid4()
      const props = _.pickBy({
        associatedCase,
        borrowerId,
        channel,
        companyId,
        previousInteractions,
        supercaseId
      })
      openDraftInteraction({ id, ...props })
      setDraftInteractionsById({
        ...draftInteractionsById,
        [id]: { id, ...props }
      })
    },
    [openDraftInteraction, draftInteractionsById, setDraftInteractionsById]
  )

  const onUpdateDraftInteraction = useCallback(
    ({ id, newState: newDraftInteraction }) => {
      setDraftInteractionsById(prevState => {
        return { ...prevState, [id]: newDraftInteraction }
      })
    },
    [setDraftInteractionsById]
  )

  const onDeleteDraft = useCallback(
    id => {
      setDraftInteractionsById(prevState => {
        const { [id]: removedItem, ...rest } = prevState
        return rest
      })
    },
    [setDraftInteractionsById]
  )

  const value = useMemo(() => {
    return {
      draftInteractionsById,
      setDraftInteractionsById,
      onOpenNewDraftInteraction,
      onUpdateDraftInteraction,
      onDeleteDraft
    }
  }, [
    draftInteractionsById,
    setDraftInteractionsById,
    onOpenNewDraftInteraction,
    onUpdateDraftInteraction,
    onDeleteDraft
  ])

  return (
    <DraftInteractionContext.Provider value={value}>
      {children}
    </DraftInteractionContext.Provider>
  )
}

DraftInteractionContextProvider.propTypes = {
  children: PropTypes.node
}

export { DraftInteractionContext }
export default DraftInteractionContextProvider
