// Third party ------------------------
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'

// Proprietary ------------------------
import { useTime } from 'core/time'

// Components -------------------------
import InlineDropdownBase from 'core/components/InlineDropdown'
import RowBase from '../Row'
import RowLabel from '../RowLabel'

// Styles -----------------------------
export const InlineDropdown = styled(InlineDropdownBase)`
  width: calc(100% - 64px);
  max-width: 400px;
`
const Row = styled(RowBase)`
  justify-content: space-between;
`
const RowLeft = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
export const Span = styled.span`
  padding: 0 0 0 8px;
  /** The 30px height/line-height are according to design */
  height: 30px;
  line-height: 30px;
`

const ToPopulations = ({
  maximized,
  onChange,
  readOnly,
  activePopulations,
  activeBorrowers,
  value
}) => {
  const { useTime } = ToPopulations.dependencies

  const { format } = useTime()

  const sortedPopulations = _.sortBy(activePopulations, 'updatedAt')

  const options = [
    {
      value: 'all',
      label: `All impacted borrowers (${activeBorrowers.length})`
    },
    ..._.map(sortedPopulations, p => {
      const { members, updatedAt } = p
      const borrowerIds = _.uniqBy(members, ({ borrowerId }) => borrowerId)
      const numberOfBorrowers = borrowerIds.length
      const date = format(updatedAt, 'MMM D, YYYY')
      const plural = numberOfBorrowers === 1 ? '' : 's'
      return {
        value: p.id,
        label: `${numberOfBorrowers} borrower${plural} added ${date}`
      }
    })
  ]

  const selectedOption = _.isNil(value)
    ? null
    : _.find(options, o => o.value === value)

  return (
    <Row maximized={maximized}>
      <RowLeft>
        <RowLabel>To</RowLabel>
        {readOnly ? (
          <Span>{selectedOption.label}</Span>
        ) : (
          <InlineDropdown
            isControlled
            onChange={item => onChange(item ? item.value : null)}
            options={options}
            value={selectedOption}
          />
        )}
      </RowLeft>
    </Row>
  )
}

ToPopulations.propTypes = {
  readOnly: PropTypes.bool,
  /** If the containing resource window is maximized */
  maximized: PropTypes.bool,
  channel: PropTypes.oneOf(['email', 'mail', 'text']),
  onChange: PropTypes.func,
  activePopulations: PropTypes.array,
  activeBorrowers: PropTypes.array,
  value: PropTypes.string
}

ToPopulations.defaultProps = {
  onChange: _.noop
}

ToPopulations.dependencies = {
  useTime
}

export default ToPopulations
