import { redirectToLogin } from 'app/loginRedirect/loginRedirectHelpers'
import { setSessionExpiredIfRecent } from 'core/actions/helpers/tokenHelpers'
/**
 *
 * @param {Object} response
 * @param {Array} parseJson
 *
 * Note regarding parseJson param:
 *
 * Use this param to include an array of reponse status codes that
 * you want this handler to parse the response, even if the
 * response is an error. For example, if a particular 4XX response
 * contains critical information within the parsed JSON, add that
 * status code to bypass the other, more generic error handling.
 */

export function handleErrors(response, parseJson = []) {
  if (response.status === 401) {
    setSessionExpiredIfRecent()
    redirectToLogin()
    return response
  }

  if (parseJson.includes(response.status)) {
    return response.json()
  }

  if (!response.ok) {
    return response
  }

  if (response.ok && response.status === 204) {
    return response
  }

  return response.json()
}
