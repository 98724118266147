// Third party --------------
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

// Components ---------------
import Row from '../Row'
import RowLabel from '../RowLabel'

// Styled components --------
const Span = styled.span`
  padding: 0 0 0 8px;
  /** The 30px height/line-height are according to design */
  height: 30px;
  line-height: 30px;
`

const SupercaseId = ({ maximized, supercaseId }) => {
  return (
    <Row maximized={maximized}>
      <RowLabel>SupercaseId</RowLabel>
      <Span data-testid='reason-string'>{supercaseId}</Span>
    </Row>
  )
}

SupercaseId.propTypes = {
  /** If the containing resource window is maximized */
  maximized: PropTypes.bool,
  supercaseId: PropTypes.string
}

export default SupercaseId
