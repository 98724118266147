import _ from 'lodash'
import htmlToText from 'html-to-text'
import { useEffect, useState } from 'react'

const useFreeFormContext = (body, channel, company, subjectLine) => {
  const [context, setContext] = useState({})

  useEffect(() => {
    const isEmail = channel === 'email'
    const isMail = channel === 'mail'
    const isText = channel === 'text'

    const removeBrandPrefixFromBody = $body => {
      const { brandName } = company
      const prefixLength = brandName?.length || 0
      /**
       * Adding 2 to index to include the colon and the
       * space after the colon.
       */
      return $body.substring(prefixLength + 2)
    }
    const newContext = isEmail
      ? {
          subjectLine,
          contentHtml: body,
          contentText: htmlToText.fromString(body)
        }
      : isMail
      ? {
          subjectLine,
          contentHtml: body,
          contentText: htmlToText.fromString(body)
        }
      : isText
      ? {
          company: {
            brandName: company.brandName
          },
          textMessage: removeBrandPrefixFromBody(body)
        }
      : {}
    setContext(prev => {
      if (_.isEqual(prev, newContext)) return prev
      return newContext
    })
  }, [body, channel, company, subjectLine, setContext])

  return context
}

export default useFreeFormContext
