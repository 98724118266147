import { useContext } from 'react'
import { VoiceTaskContext } from 'contexts/VoiceTaskContext'

const useVoiceTaskContext = () => {
  const { voiceTask, setHasSavedCallOutcome } = useContext(VoiceTaskContext)

  return { voiceTask, setHasSavedCallOutcome }
}

export default useVoiceTaskContext
