import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
import variables from 'core/styles/variables'
import { Spinner } from 'core/components'

const RADIUS = '8px'

const Wrapper = styled.div``

const SubmitButton = styled.button`
  justify-content: center;
  border: none;
  background-color: ${p => p.theme.primary};
  border-radius: ${RADIUS};
  border-bottom-left-radius: ${p => (p.hasError ? '0' : RADIUS)};
  border-bottom-right-radius: ${p => (p.hasError ? '0' : RADIUS)};
  width: 100%;
  color: ${variables.colorWhite};
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  height: 64px;
  transition: opacity 0.2s;

  &:disabled {
    background-color: ${variables.colorBlack40};
  }

  &:disabled {
    cursor: default;
  }

  &:hover,
  &:focus {
    opacity: 0.95;
  }
`

const Subtitle = styled.div`
  padding: 32px;
  text-align: center;
  color: ${variables.colorBlack70};
`

const ErrorMessage = styled.div`
  background-color: ${variables.colorRedLighten};
  border-bottom-left-radius: ${RADIUS};
  border-bottom-right-radius: ${RADIUS};
  padding: 8px 24px;
  color: ${variables.colorBlack80};
`

const LoginSubmitButton = props => {
  const {
    onSubmit,
    errorMessage,
    submitLabel,
    subtitle,
    sending,
    disabled,
    ...rest
  } = props

  const displayLabel = sending ? <Spinner /> : submitLabel || 'Continue'

  const isDisabled = errorMessage || disabled

  return (
    <Wrapper>
      <SubmitButton
        onClick={onSubmit}
        disabled={isDisabled}
        hasError={!!errorMessage}
        data-cy='submit'
        {...rest}
      >
        {displayLabel}
      </SubmitButton>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Wrapper>
  )
}

LoginSubmitButton.propTypes = {
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  sending: PropTypes.bool,
  subtitle: PropTypes.node,
  errorMessage: PropTypes.node,
  onSuccess: PropTypes.func,
  disabled: PropTypes.bool
}

export default LoginSubmitButton
