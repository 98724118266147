// Third party --------------
import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

// Components ---------------
import Icon from 'core/components/Icon'
import { Button } from 'core/components'

// Rest ---------------------
import {
  UPLOAD_FAIL,
  UPLOAD_SUCCESS,
  UPLOAD_TAKING_LONG
} from 'contexts/FileUploadContext'
import { useFileUploadContext, useLoadingStatus } from 'core/hooks'
import { variables } from 'core/styles'

const Container = styled.div`
  position: fixed;
  top: 16px;
  right: 16px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 1px ${variables.colorBlack20};
  background: white;
  width: 476px;
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 3px;
  right: 20px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.56);
`

const TitleAndActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

const Title = styled.h2`
  padding: 0 16px;
  font-size: 22px;
  color: ${variables.colorBlack90};
`

const ButtonActions = styled.div`
  display: flex;
  padding-right: 24px;
`

const ProgressBarContainer = styled.div`
  position: relative;
  height: 32px;
  padding: 0 16px;
`

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${p => p.progress}%;
  background: ${p => p.background};
`

const Message = styled.div`
  position: relative;
  top: 5px;
  font-size: 14px;
  color: ${p => p.color};
`

const FileUploadModal = () => {
  const [message, setMessage] = useState('')
  const [progressTextColor, setProgressTextColor] = useState(null)
  const [progressBackgroundColor, setProgressBackgroundColor] = useState(null)

  const {
    elapsedTime,
    error: requirementError,
    onCancelUpload,
    onOpenFileSelector,
    onRetryUpload,
    progress,
    setShowUploadStatusModal
  } = useFileUploadContext()

  const elapsedTimeToRetry = 10 // in seconds

  // ----------------------------------
  // Loading status: Document descriptor & file upload
  const documentDescriptorsLoadingStatus = useLoadingStatus(
    'CreateDocumentDescriptors'
  )
  const uploadFileLoadingStatus = useLoadingStatus('UploadFiles')
  const error =
    documentDescriptorsLoadingStatus === 'error' ||
    uploadFileLoadingStatus === 'error'

  // Loading state
  useEffect(() => {
    if (uploadFileLoadingStatus === 'loading') {
      setMessage(null)
      if (elapsedTime > elapsedTimeToRetry) {
        setMessage(UPLOAD_TAKING_LONG)
        setProgressTextColor(variables.colorOrange)
        setProgressBackgroundColor(variables.colorOrangeLighten)
      } else {
        setProgressTextColor(null)
        setProgressBackgroundColor(variables.colorGreenLighten)
      }
    }
  }, [uploadFileLoadingStatus, elapsedTime, elapsedTimeToRetry])

  // Success state
  useEffect(() => {
    if (uploadFileLoadingStatus === 'success') {
      setMessage(UPLOAD_SUCCESS)
      setProgressTextColor(variables.colorGreen)
      setProgressBackgroundColor(variables.colorGreenLighten)
    }
  }, [uploadFileLoadingStatus])

  // Error state
  useEffect(() => {
    if (requirementError) {
      setMessage(requirementError)
      setProgressTextColor(variables.colorRed)
      setProgressBackgroundColor(variables.colorRedLighten)
    } else if (error) {
      setMessage(UPLOAD_FAIL)
      setProgressTextColor(variables.colorRed)
      setProgressBackgroundColor(variables.colorRedLighten)
    }
  }, [
    error,
    requirementError,
    uploadFileLoadingStatus,
    documentDescriptorsLoadingStatus
  ])

  return (
    <Container>
      <TitleAndActions>
        <Title>Uploading document</Title>
        <ButtonActions>
          {uploadFileLoadingStatus !== 'success' &&
          (error || elapsedTime > elapsedTimeToRetry) ? (
            <>
              <Button secondary margin={'0 40px 0 0'} onClick={onRetryUpload}>
                Retry
              </Button>
              <Button secondary onClick={onCancelUpload}>
                Cancel upload
              </Button>
            </>
          ) : uploadFileLoadingStatus === 'success' ? (
            <Button
              data-testid='button-upload'
              secondary
              onClick={() =>
                onOpenFileSelector({
                  acceptedFileTypes: '.jpg, .doc, .docx, .pdf, .txt, .png',
                  autoUpload: true,
                  allowMultiFileUpload: false
                })
              }
            >
              Upload another document
            </Button>
          ) : (
            <Button secondary onClick={onCancelUpload}>
              Cancel upload
            </Button>
          )}
        </ButtonActions>
      </TitleAndActions>
      <ProgressBarContainer>
        <ProgressBar
          progress={
            error ? 100 : Math.floor((progress.loaded / progress.total) * 100)
          }
          background={progressBackgroundColor}
        />
        <Message color={progressTextColor}>{message}</Message>
        <CloseButton
          data-testid='button-close'
          onClick={() => setShowUploadStatusModal(false)}
        >
          <Icon name='clear' fontSize='inherit' />
        </CloseButton>
      </ProgressBarContainer>
    </Container>
  )
}

export { Message, ProgressBar }
export default FileUploadModal
