import { GET_LEGAL_REPRESENTATIVES } from 'core/actions/constants'

const initialState = {
  byId: {}
}

const legalRepresentatives = function(state = initialState, action) {
  switch (action.type) {
    case GET_LEGAL_REPRESENTATIVES:
      return {
        ...state,
        byId: {
          // keep all existing legal representatives for other personIds
          ...Object.values(state.byId).reduce((acc, curr) => {
            if (curr.personId !== action.personId) {
              acc[curr.id] = curr
            }
            return acc
          }, {}),
          // insert the fresh legal representatives for this personId
          ...action.payload.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.id]: {
                ...curr,
                personId: action.personId
              }
            }),
            {}
          )
        }
      }
    default:
      return state
  }
}

export default legalRepresentatives
