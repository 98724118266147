// Third party --------------
import PropTypes from 'prop-types'
import React, { forwardRef, useEffect, useRef } from 'react'
import styled from 'styled-components/macro'

// Rest ---------------------
import variables from 'core/styles/variables'
import { useFormDisabled } from 'loan/components/actionCards/shared/DisabledContext'

const StyledTextarea = styled.textarea`
  box-sizing: border-box;
  border-radius: 4px; /* according to design */
  padding: ${p => p.padding || '8px'};
  width: 100%;
  height: ${p => p.height};
  font-size: 16px;
  border: ${p => (p.noBorder ? 'none' : `1px solid ${variables.colorBlack20}`)};
  resize: none;
  &:focus-visible {
    outline: none;
  }
`

const Textarea = forwardRef((props, passedRef) => {
  const {
    disabled,
    height,
    noBorder,
    onChange,
    padding,
    setFocusTextarea,
    focusTextarea,
    value,
    ...rest
  } = props
  const isDisabled = useFormDisabled(disabled)
  const localRef = useRef()
  const ref = passedRef || localRef

  useEffect(() => {
    if (focusTextarea) {
      !!ref?.current && ref.current.focus()
      setFocusTextarea(false)
    }
  }, [ref, setFocusTextarea, focusTextarea])

  return (
    <StyledTextarea
      disabled={isDisabled}
      height={height}
      noBorder={noBorder}
      onChange={evt => onChange(evt.target.value)}
      padding={padding}
      ref={ref}
      value={value || ''}
      {...rest}
    />
  )
})

Textarea.propTypes = {
  disabled: PropTypes.bool,
  height: PropTypes.string,
  noBorder: PropTypes.bool,
  onChange: PropTypes.func,
  padding: PropTypes.string,
  value: PropTypes.string
}

Textarea.defaultProps = {
  height: 'auto'
}

export { StyledTextarea }
export default Textarea
