import { APIendpoint, LOADING, getStoredToken } from 'core/actions/constants'
import { POSTwithToken } from 'core/api/legacyApi'
import { handleErrors } from 'core/helpers/handleErrors'

const updateVoiceCall = ({ key, interactionId, options }) => {
  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    const endpoint = `${APIendpoint}/twilio/update-voice-call?interactionId=${interactionId}`
    const response = await fetch(
      endpoint,
      POSTwithToken(getStoredToken(), { ...options })
    )
    await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error'
      })
    }
  }
}

export default updateVoiceCall
