import _ from 'lodash'
import { useMemo } from 'react'

import useCaseTypes from './useCaseTypes'

/** This piggy backs off of useCaseTypes and
 * additionally formats the caseTypes into options
 * for the Select component. The useCaseTypes hook already
 * filters to just this company and only active caseTypes.
 */
const useCaseTypesOptions = () => {
  const { useCaseTypes } = useCaseTypesOptions.dependencies

  const caseTypes = useCaseTypes()

  const options = useMemo(() => {
    if (caseTypes.loadingStatus !== 'success') {
      return []
    } else {
      return Object.keys(caseTypes.byId)
        .filter(id => caseTypes.byId[id].caseType !== 'disputeOfPurchase')
        .map(id => ({
          value: id,
          label:
            caseTypes.byId[id].name || _.startCase(caseTypes.byId[id].caseType)
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    }
  }, [caseTypes])

  return options
}

useCaseTypesOptions.dependencies = {
  useCaseTypes
}

export default useCaseTypesOptions
