import _ from 'lodash'
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import APIendpoint, {
  isCustomEndpoint,
  PRIVATE_setApiEndpoint as setApiEndpoint
} from 'core/actions/helpers/APIendpoint'
import {
  Select,
  BooleanSelect,
  Input,
  BadgeButton,
  Section,
  Divider
} from './components'

import {
  PRIVATE_getLocalStorageParams as getLocalStorageParams,
  PRIVATE_setLocalStorageParams as setLocalStorageParams
} from 'core/SystemProvider/fetchBootstrapData'
import { setOverrides, clearOverrides, useOverrides } from './overrides'

const Contents = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
`

const Submit = styled.div`
  padding: 8px;
`

const DEFAULT_ENDPOINT = 'https://devapi.peach.finance/api'

const DEFAULT_COMPANY_ID = 'CP-L9BN-5J52'

const DEFAULT_DOMAIN = 'service.globex.capital'

const BadgeMenu = ({ onClose }) => {
  // api endpoint configuration

  const existingApiMode = isCustomEndpoint ? 'custom' : 'default'

  const [apiMode, setApiMode] = useState(existingApiMode)

  const [newEndpoint, setNewEndpoint] = useState(
    isCustomEndpoint ? APIendpoint : ''
  )

  const endpointHasChanged =
    existingApiMode !== apiMode ||
    (apiMode === 'custom' && newEndpoint !== APIendpoint)

  const newCustomEndpoint = apiMode === 'custom' ? newEndpoint : null

  // default app type and company

  const [params] = useState(getLocalStorageParams)
  const { appDomainType, companyId, domain } = params

  const [mode, setMode] = useState(() => {
    return appDomainType && companyId ? 'manual' : domain ? 'domain' : 'default'
  })

  const [newType, setNewType] = useState(appDomainType)
  const [newCompanyId, setNewCompanyId] = useState(companyId)
  const [newDomain, setNewDomain] = useState(domain)

  const newParams =
    mode === 'manual'
      ? { appDomainType: newType, companyId: newCompanyId }
      : mode === 'domain'
      ? { domain: newDomain }
      : {}

  const paramsHaveChanged = !_.isEqual(params, newParams)

  // overrides

  const overrides = useOverrides()

  const [newOverrides, setNewOverrides] = useState(overrides || {})

  const overridesHaveChanged = !_.isEqual(newOverrides, overrides)

  const onOverrideChange = (key, val) => {
    setNewOverrides(state => ({ ...state, [key]: val }))
  }

  const renderBooleanOverride = (label, key) => {
    return (
      <BooleanSelect
        label={label}
        value={newOverrides[key]}
        onChange={val => onOverrideChange(key, val)}
        nullable
      />
    )
  }

  const onCommit = reload => {
    if (reload) {
      localStorage.removeItem('secretToken')
      window.location.reload()
    } else {
      _.delay(() => onClose(), 200)
    }
  }

  // submit logic

  const hasChanged =
    endpointHasChanged || paramsHaveChanged || overridesHaveChanged

  const needsReload = endpointHasChanged || paramsHaveChanged

  const onSubmit = () => {
    setApiEndpoint(newCustomEndpoint)
    setLocalStorageParams(newParams)
    if (overridesHaveChanged) setOverrides(newOverrides)
    onCommit(needsReload)
  }

  // reset logic

  const reloadOnReset = isCustomEndpoint || !_.isEmpty(params)

  const canReset =
    isCustomEndpoint || !_.isEmpty(params) || !_.isEmpty(overrides)

  const onReset = () => {
    setApiEndpoint('')
    setLocalStorageParams({})
    clearOverrides()
    onCommit(reloadOnReset)
  }

  return (
    <Contents>
      <Section title='API Endpoint'>
        <Select
          value={apiMode}
          onChange={setApiMode}
          options={['default', 'custom']}
        />

        {apiMode === 'custom' && (
          <Input
            label='Custom Endpoint'
            value={newEndpoint}
            onChange={setNewEndpoint}
            placeholder={DEFAULT_ENDPOINT}
            onDoubleClick={() => setNewEndpoint(DEFAULT_ENDPOINT)}
          />
        )}
      </Section>
      <Divider />
      <Section title='App Type & Company'>
        <Select
          value={mode}
          onChange={setMode}
          options={['default', 'manual', 'domain']}
        />

        {mode === 'manual' && (
          <>
            <Select
              label='App Domain Type'
              value={newType}
              onChange={setNewType}
              prompt='App Domain Type...'
              options={['borrower', 'agent', 'admin', '']}
            />

            <Input
              label='Company ID'
              value={newCompanyId}
              onChange={setNewCompanyId}
              placeholder='Company Id'
              onDoubleClick={() => setNewCompanyId(DEFAULT_COMPANY_ID)}
            />
          </>
        )}

        {mode === 'domain' && (
          <Input
            label='Domain'
            value={newDomain}
            onChange={setNewDomain}
            placeholder='Domain'
            onDoubleClick={() => setNewDomain(DEFAULT_DOMAIN)}
          />
        )}
      </Section>
      <Divider />
      <Section title='Local Overrides'>
        <Select
          label='User Type'
          value={newOverrides.userType}
          onChange={val => onOverrideChange('userType', val)}
          options={['borrower', 'agent', '']}
        />
        {renderBooleanOverride('Show Case ID', 'showCaseId')}

        {renderBooleanOverride('Plaid Balance Check', 'plaidBalanceCheck')}

        {renderBooleanOverride('Plaid Verification', 'plaidVerification')}

        {renderBooleanOverride(
          'Microdeposit Verifcation',
          'microdepositVerification'
        )}
      </Section>

      <Divider />
      <Submit>
        <BadgeButton onClick={onSubmit} disabled={!hasChanged}>
          {needsReload ? 'Save & Reload' : 'Save'}
        </BadgeButton>
      </Submit>

      <Submit>
        <BadgeButton onClick={onReset} disabled={!canReset}>
          Reset
        </BadgeButton>
      </Submit>
    </Contents>
  )
}

export default BadgeMenu
