import _ from 'lodash'
import { LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

const sendFreeFormEmail = ({
  contentHtml,
  contentPlain,
  key,
  language = 'en',
  personId,
  previousInteractionId,
  subjectLine,
  theme,
  to
}) => {
  return async dispatch => {
    if (key) {
      dispatch({ type: LOADING, key, status: 'loading' })
    }

    /**
     * Remove any properties that are undefined or null
     */
    const body = _.pickBy({
      contentHtml,
      contentPlain,
      language,
      personId,
      personalizations: [{ to }],
      previousInteractionId,
      subjectLine,
      theme
    })

    try {
      await peachApi.post({ url: `/communicator/send-free-form-email`, body })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
    } catch (e) {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
      throw e
    }
  }
}

export default sendFreeFormEmail
