import styled from 'styled-components/macro'

const TemplateStyles = styled.div`
  ${props => props.templateStyles};
  ${props => (props.addPadding ? 'padding: 16px;' : '')}
  box-sizing: border-box;
  width: 100%;
  height: ${p => (p.setHeightAuto ? 'auto' : '100%')};

  .page,
  .content {
    padding-bottom: ${p =>
      p.numberOfAttachments > 0 ? `${p.numberOfAttachments * 48}px` : '24px'};
  }

  .ql-editor {
    padding: 16px;
    padding-bottom: ${p =>
      p.numberOfAttachments > 0 ? `${p.numberOfAttachments * 48}px` : '16px'};
  }

  .page-content {
    padding: 32px;
  }

  img {
    width: auto;
  }
`

export default TemplateStyles
