// Third party --------------
import PropTypes from 'prop-types'
import React from 'react'

// Components ---------------
import StyledAttachment from 'components/ResourceOverlay/StyledAttachment'

const Attachment = ({ attachment, openAttachment }) => {
  const { content, filename } = attachment
  return (
    <StyledAttachment
      isClickable
      noFlex
      onClick={() => openAttachment(content)}
    >
      {filename}
    </StyledAttachment>
  )
}

Attachment.propTypes = {
  attachment: PropTypes.object,
  openAttachment: PropTypes.func
}

export default Attachment
