import _ from 'lodash'

const getInfo = populationsById => {
  const populationsList = _.values(populationsById)
  const activePopulations = _.filter(populationsList, p => !p.isDraft)
  const activeCases = _.compact(
    _.union(..._.map(activePopulations, p => _.map(p.members, m => m.caseId)))
  )
  const activeLoans = _.compact(
    _.union(..._.map(activePopulations, p => _.map(p.members, m => m.loanId)))
  )
  const activeBorrowers = _.compact(
    _.union(
      ..._.map(activePopulations, p => _.map(p.members, m => m.borrowerId))
    )
  )
  return {
    populationsById,
    populationsList,
    activePopulations,
    activeCases,
    activeLoans,
    activeBorrowers
  }
}

export default getInfo
