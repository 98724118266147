import makeAdd from './helpers/makeAdd'
import makeAddDays from './helpers/makeAddDays'
import makeClosestTo from './helpers/makeClosestTo'
import makeDifference from './helpers/makeDifference'
import makeDifferenceInCalendarDays from './helpers/makeDifferenceInCalendarDays'
import makeDifferenceInHours from './helpers/makeDifferenceInHours'
import makeFirstWeekdayAfter from './helpers/makeFirstWeekdayAfter'
import makeFormat from './helpers/makeFormat'
import makeFormatRelative from './helpers/makeFormatRelative'
import makeGetHours from './helpers/makeGetHours'
import makeGetISODay from './helpers/makeGetISODay'
import makeGetNow from './helpers/makeGetNow'
import makeGetToday from './helpers/makeGetToday'
import makeIsAfter from './helpers/makeIsAfter'
import makeIsInTimeRange from './helpers/makeIsInTimeRange'
import makeIsSameDay from './helpers/makeIsSameDay'
import makeIsToday from './helpers/makeIsToday'
import makeParse from './helpers/makeParse'
import makeStartOfDay from './helpers/makeStartOfDay'
import makeStartOf from './helpers/makeStartOf'
import makeSubtract from './helpers/makeSubtract'
import makeTimestringToDatestring from './helpers/makeTimestringToDatestring'
import makeEndOf from './helpers/makeEndOf'

/** Returns all helpers in the time library with the provided
 * context attached to each entity. `context` is an
 * object with the following params:
 * - timezone: IANA string of timezone, defaults to local
 *
 * More params may be added to context in the future, but
 * always with a default behavior when not specified.
 *
 * Example usage:
 * const { format, formatRelative } = makeTime({ timezone })
 *
 * Note that if your context is established in your React tree
 * with TimeProvider, you can use the hook useTime() which
 * conveniently calls makeTime() with the context provided by
 * TimeProvider.
 */
const makeTime = context => ({
  add: makeAdd(context),
  addDays: makeAddDays(context),
  closestTo: makeClosestTo(context),
  difference: makeDifference(context),
  differenceInCalendarDays: makeDifferenceInCalendarDays(context),
  differenceInHours: makeDifferenceInHours(context),
  endOf: makeEndOf(context),
  firstWeekdayAfter: makeFirstWeekdayAfter(context),
  format: makeFormat(context),
  formatRelative: makeFormatRelative(context),
  getHours: makeGetHours(context),
  getISODay: makeGetISODay(context),
  getNow: makeGetNow(context),
  getToday: makeGetToday(context),
  isAfter: makeIsAfter(context),
  isInTimeRange: makeIsInTimeRange(context),
  isSameDay: makeIsSameDay(context),
  isToday: makeIsToday(context),
  parse: makeParse(context),
  startOfDay: makeStartOfDay(context),
  startOf: makeStartOf(context),
  subtract: makeSubtract(context),
  timestringToDatestring: makeTimestringToDatestring(context)
})

export default makeTime
