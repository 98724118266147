import _ from 'lodash'
import { interactionThemes } from 'config/constants'
import formatAddress from 'core/helpers/formatAddress'
import { formatPhone } from 'core/helpers/phoneNumberFormatter'

const interactionDirectionStr = {
  inbound: 'Inbound',
  outbound: 'Outbound'
}

const writtenInteractionStatuses = {
  failed: 'Failed',
  attempted: 'Attempted',
  succeeded: 'Succeeded',
  inProgress: 'In progress'
}

const voiceInteractionStatuses = {
  failed: 'Failed',
  attempted: 'Attempted',
  succeeded: 'Succeeded'
}

// yes, I called it "detailses"
const interactionStatusDetailses = {
  emailSent: 'Sent',
  emailDelivered: 'Delivered',
  emailBounced: 'Bounced',
  emailOpened: 'Opened',
  emailDropped: 'Dropped',
  emailReportedAsSpam: 'Reported as spam',
  voiceSpokeWithFirstParty: 'Spoke with first party',
  voiceSpokeWithThirdParty: 'Spoke with third party',
  voiceLeftVoicemailFirstParty: 'Left voicemail with first party',
  voiceLeftVoicemailThirdParty: 'Left voicemail with third party',
  voiceRangNoPickup: 'Rang no pickup',
  voicePickedHungUp: 'Picked and hung up',
  voiceFailedToInitiate: `Couldn't initiate`,
  voiceLineBusyNoRing: 'Line busy no ring',
  voiceReachedVoicemailNoMessageLeft: 'Voicemail no message left',
  voiceNumberDisconnected: 'Number disconnected',
  mailSent: 'Sent',
  mailReturnedToSender: 'Returned to sender',
  mailFailedToInitiate: `Couldn't initiate`
}

/** Takes an interaction object (as it appears in redux) and
 * parses it to return several useful values used for things
 * like displaying status or a title on an interaction card.
 */
const parseInteractionInfo = interaction => {
  if (!interaction) {
    return {}
  }

  const {
    content,
    channel,
    startedAt,
    theme,
    status,
    statusDetails,
    direction
  } = interaction

  // Basically, if it's an empty object passed in, still short circuit.
  if (!content) {
    return {}
  }

  const directionStr = interactionDirectionStr[direction] || '' // should never, ever get ''

  let body, from, htmlBody, name, plainBody, title, to

  switch (channel) {
    case 'email':
      to = content.personalizations?.[0].to?.[0].email ?? ''
      from = content.from?.email ?? ''
      name = content.from?.name ?? ''
      title = content.subject ?? 'No Subject'
      htmlBody = content.content?.find(item => item.type === 'text/html')?.value
      plainBody = content.content?.find(item => item.type === 'text/plain')
        ?.value
      body = htmlBody ?? plainBody ?? ''
      break
    case 'voice':
      to = content.to ? formatPhone(content.to) : ''
      from = content.from ? formatPhone(content.from) : ''
      title = interactionThemes[theme] ?? `${directionStr} call`
      body = content.body ?? ''
      break
    case 'mail':
      const isSingleLine = true
      to = content.to_address
        ? formatAddress(content.to_address, { singleLine: isSingleLine })
        : ''
      from = content.from_address
        ? formatAddress(content.from_address, { singleLine: isSingleLine })
        : ''
      name = content.from_name ?? ''
      title = content.template_name ?? `${directionStr} mail`
      htmlBody = Array.isArray(content?.content)
        ? content.content.find(item => item.type === 'text/html')?.value
        : typeof content?.content === 'string'
        ? content.content
        : null
      plainBody = Array.isArray(content?.content)
        ? content.content.find(item => item.type === 'text/plain')?.value
        : typeof content?.content === 'string'
        ? content.content
        : null
      body = htmlBody ?? plainBody ?? ''
      break
    case 'text':
      to = content.to ? formatPhone(content.to) : ''
      from = content.from ? formatPhone(content.from) : ''
      name = content.from_name ?? ''
      title = content.body?.slice(0, 40) ?? `${directionStr} text message`
      body = content.body ?? ''

      /**
       * This section is meant to determine the from info
       * if the text interaction is created using the Twilio
       * conversation API.
       */
      const { messages } = content
      const firstInboundMessage = _.find(messages, message => {
        const { author, participant_sid: sid } = message
        const isBorrower = !_.includes(author, 'uid') && author !== 'system'
        return sid !== null && isBorrower
      })
      const { author } = firstInboundMessage || {}
      if (author) {
        from = formatPhone(author)
      }
      break
    default:
      to = content.to ?? ''
      from = content.from ?? ''
      title = content.template_name ?? `${directionStr} message`
      body = content.body ?? ''
  }
  const toFrom =
    direction === 'inbound'
      ? `${from ? 'From: ' : ''}${from}`
      : `${to ? 'To: ' : ''}${to}`

  const maybeMainStatus =
    channel === 'voice'
      ? voiceInteractionStatuses[status]
      : writtenInteractionStatuses[status]
  const mainStatus = maybeMainStatus ?? status
  const deets = interactionStatusDetailses[statusDetails]
  const statusText =
    mainStatus && deets
      ? `${mainStatus} - ${deets}`
      : mainStatus
      ? `${mainStatus}`
      : deets
      ? `${deets}`
      : ''

  return {
    body,
    createdAt: startedAt,
    from,
    name,
    startedAt,
    statusText,
    title,
    to,
    toFrom
  }
}

export default parseInteractionInfo
