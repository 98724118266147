import { APIendpoint, getStoredToken } from 'core/actions/helpers/tokenHelpers'
import rawApiCall from './rawApiCall'
import onUnauthorized from './onUnauthorized'

const peachApiCall = async options => {
  const { url, fullUrl, headers, ...restOptions } = options

  const callOptions = {
    url: APIendpoint + url,
    fullUrl,
    headers: {
      Authorization: `Bearer ${getStoredToken()}`,
      ...headers
    },
    ...restOptions
  }

  try {
    const resp = await rawApiCall(callOptions)
    return resp
  } catch (error) {
    if (error.status === 401) {
      onUnauthorized()
    } else {
      throw error
    }
  }
}

export default peachApiCall
