import variables from 'core/styles/variables'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import Icon from 'core/components/Icon'
import { Button } from 'core/components'

export const StyledToast = styled.div`
  flex-direction: column;
  display: flex;
  margin: ${p => p.margin};
  padding: 16px;
  border-radius: 8px;
  background-color: ${p => p.bgColor};

  svg {
    align-self: flex-start;
    min-width: 24px;
  }

  .button {
    align-self: flex-end;
    margin-top: 16px;
  }
`

const ToastMessageContainer = styled.div`
  display: flex;
`

const ToastHeader = styled.span`
  font-weight: 500;
  margin-left: 8px;
`

const Span = styled.span`
  display: block;
  font-size: 1.6rem;
  margin-left: 8px;
  line-height: 24px;
`

const Toast = ({
  icon,
  iconFill,
  header,
  description,
  bgColor,
  margin,
  actionLabel,
  action
}) => {
  return (
    <StyledToast bgColor={bgColor} margin={margin}>
      <ToastMessageContainer>
        <Icon name={icon} color={iconFill} fontSize='24px' />
        <div>
          <ToastHeader>{header}</ToastHeader>
          <Span className='toast-description'>{description}</Span>
        </div>
      </ToastMessageContainer>
      {actionLabel && (
        <Button secondary onClick={action} data-cy='toast-action'>
          {actionLabel}
        </Button>
      )}
    </StyledToast>
  )
}

Toast.propTypes = {
  icon: PropTypes.string,
  iconFill: PropTypes.string,
  header: PropTypes.string,
  description: PropTypes.string,
  bgColor: PropTypes.string,
  margin: PropTypes.string,
  actionLabel: PropTypes.string,
  action: PropTypes.func
}

Toast.defaultProps = {
  icon: 'warning',
  iconFill: variables.colorBlack80,
  header: 'Please include a header for this toast',
  description: 'Please include a description for this toast',
  bgColor: 'transparent'
}

export default Toast
