import _ from 'lodash'
import { LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

/**
 * Sends a communication (i.e. creates an interaction) to the person denoted
 * by `personId`, following the backend template denoted by `subject`.
 */
const sendCommunication = ({
  attachments,
  caseId,
  channel,
  contactId,
  context,
  key,
  loanId,
  personId,
  previousInteractionId,
  subject,
  theme
}) => {
  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    /**
     * Remove any properties that are undefined or null
     */
    const body = _.pickBy({
      attachments,
      caseId,
      channel,
      contactId,
      context,
      loanId,
      personId,
      previousInteractionId,
      subject,
      theme
    })

    try {
      const response = await peachApi.post({
        url: '/communicator/send',
        body
      })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
      return response
    } catch (e) {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
      throw e
    }
  }
}

export default sendCommunication
