import _ from 'lodash'

import { GET_POPULATIONS } from 'core/actions/constants'

const initialState = {
  byId: {}
}

const populations = (state = initialState, action) => {
  switch (action.type) {
    case GET_POPULATIONS:
      return {
        ...state,
        byId: {
          ...state.byId,
          ..._.keyBy(action.payload, 'id')
        }
      }
    default:
      return state
  }
}

export default populations
