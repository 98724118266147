const makeContext = (template, context) => {
  switch (template) {
    case 'freeForm':
      return context
    case 'freeFormBranded':
      return context
    case 'identityTheftSubmitDocumentation':
      return {
        daysTillCaseCanceled: 30
      }
    default:
      return {}
  }
}

export default makeContext
