import styled from 'styled-components/macro'
import React from 'react'
import PropTypes from 'prop-types'

import { useNow, useLongTimezone } from 'core/hooks'
import { useTime } from 'core/time'

const StyledTimeStamp = styled.span`
  color: var(--colorBlack50);
  font-weight: var(--fontWeight500);
  font-size: 14px;
  display: block;
  line-height: 24px;
  text-align: ${p => (p.align ? p.align : undefined)};
  margin: ${p => (p.margin ? p.margin : undefined)};
`

const CurrentTimestamp = ({ align, margin }) => {
  const { format } = useTime()

  const now = useNow()
  const prettyTimezone = useLongTimezone()

  const currentDateTimeTimezone = `${format(
    now,
    'MMM D, h:mm A'
  )} ${prettyTimezone}`

  return (
    <StyledTimeStamp align={align} margin={margin}>
      {currentDateTimeTimezone}
    </StyledTimeStamp>
  )
}

CurrentTimestamp.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  margin: PropTypes.string
}

export default CurrentTimestamp
