import { useEffect, useState } from 'react'

const useCanSendInteraction = ({
  channel,
  hasContact,
  hasMadeMinimumEdits,
  hasNoTemplateFoundError,
  hasTheme,
  isContentEmpty,
  isSending,
  isSupercase,
  isUnbound,
  subjectLine,
  template
}) => {
  const [canSend, setCanSend] = useState(false)

  useEffect(() => {
    const isText = channel === 'text'
    const isEmail = channel === 'email'
    const isMail = channel === 'mail'

    const isBound = !isUnbound

    const hasBaseRequirments =
      !isSending &&
      !isContentEmpty &&
      hasMadeMinimumEdits &&
      !hasNoTemplateFoundError &&
      hasTheme

    const canSendBoundInteraction = hasBaseRequirments && isBound && hasContact

    const canSendEmailInteraction =
      canSendBoundInteraction && isEmail && !!subjectLine
    const canSendTextInteraction = canSendBoundInteraction && isText
    const canSendMailInteraction = canSendBoundInteraction && isMail
    const canSendUnboundEmail =
      hasBaseRequirments && isEmail && isUnbound && !!subjectLine

    const canSendSupercaseEmailInteraction =
      hasBaseRequirments &&
      isSupercase &&
      isEmail &&
      !!template &&
      !!subjectLine

    setCanSend(
      canSendEmailInteraction ||
        canSendMailInteraction ||
        canSendTextInteraction ||
        canSendUnboundEmail ||
        canSendSupercaseEmailInteraction
    )
  }, [
    channel,
    hasContact,
    hasMadeMinimumEdits,
    hasNoTemplateFoundError,
    hasTheme,
    isContentEmpty,
    isSending,
    isSupercase,
    isUnbound,
    subjectLine,
    template,
    setCanSend
  ])

  return canSend
}

export default useCanSendInteraction
