import _ from 'lodash'
import { LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

const bulkSend = ({
  key,
  attachments,
  channel,
  context,
  subject,
  supercaseId,
  theme,
  populationId
}) => {
  return async dispatch => {
    if (key) {
      dispatch({ type: LOADING, key, status: 'loading' })
    }

    const body = _.pickBy({ attachments, channel, context, subject, theme })

    const url =
      populationId === 'all'
        ? `/supercases/${supercaseId}/bulk-send`
        : `/supercases/${supercaseId}/populations/${populationId}/bulk-send`

    try {
      await peachApi.post({ url, body })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
    } catch (e) {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
      throw e
    }
  }
}

export default bulkSend
