import PropTypes from 'prop-types'
import React from 'react'

import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import variables from 'core/styles/variables'
import { useBorrowerBaseUrl } from 'core/hooks'

const colors = {
  success: {
    color: variables.colorGreen,
    backgroundColor: variables.colorGreenLighten
  },
  error: {
    color: variables.colorRed,
    backgroundColor: variables.colorRedLighten
  },
  default: {
    color: variables.colorBlack70,
    backgroundColor: variables.colorBlack10
  }
}

const BottomCap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: ${p => p.color};
  background-color: ${p => p.backgroundColor};
  font-size: 14px;
  min-height: 16px;
`

const doneStyles = css`
  font-weight: 700;
`

const DoneLink = styled(Link)`
  ${doneStyles};
`

const DoneButton = styled.button`
  ${doneStyles};
`

const StatusMessage = styled.span``

const InfoCardBottomCap = props => {
  const {
    status,
    successMessage,
    errorMessage,
    completeUrl,
    onComplete
  } = props

  const baseUrl = useBorrowerBaseUrl()

  const url = completeUrl ? `${baseUrl}${completeUrl}` : undefined

  const { color, backgroundColor } = colors[status] || colors['default']

  const displayMessage =
    status === 'success'
      ? successMessage || 'Action complete'
      : status === 'loading'
      ? 'Processing...'
      : status === 'error'
      ? errorMessage || 'Something went wrong. Please refresh and try again.'
      : '???'

  return (
    <BottomCap color={color} backgroundColor={backgroundColor}>
      <StatusMessage>{displayMessage}</StatusMessage>

      {status === 'success' &&
        (url ? (
          <DoneLink to={url} onClick={onComplete}>
            Got it
          </DoneLink>
        ) : onComplete ? (
          <DoneButton onClick={onComplete}>Got it</DoneButton>
        ) : null)}
    </BottomCap>
  )
}

InfoCardBottomCap.propTypes = {
  status: PropTypes.oneOf(['success', 'loading', 'error']),
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  completeUrl: PropTypes.string,
  onComplete: PropTypes.func
}

export default InfoCardBottomCap
