import _ from 'lodash'
import React from 'react'
import { usePermissions } from 'core/hooks'

const Permissions = () => {
  const permissions = usePermissions()

  const list = _.keys(permissions)

  const grouped = _.groupBy(list, perm => perm.split(':')[0])

  return (
    <div style={{ whiteSpace: 'pre-wrap' }}>
      {_.map(grouped, (group, label) => {
        return (
          <div key={label}>
            <h3>{label}</h3>
            <ul>
              {_.map(group, key => (
                <li key={key}>{key}</li>
              ))}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default Permissions
