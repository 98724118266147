import React from 'react'
import styled from 'styled-components/macro'

const SectionElement = styled.section`
  padding-top: 4px;
  padding-bottom: 4px;
`

const Title = styled.div`
  text-align: center;
  padding-top: 4px;
  padding-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
  color: #888;
  line-height: 1;
`

const Contents = styled.div`
  padding: 4px;
`

const Section = ({ title, children }) => {
  return (
    <SectionElement>
      {title && <Title>{title}</Title>}
      <Contents>{children}</Contents>
    </SectionElement>
  )
}

export default Section
