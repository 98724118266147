import { useQuery } from 'react-query'
import { useAdminCompanyId } from '../adminCompanyId'
import defaultQueryConfig from './defaultQueryConfig'
import getIsEnabled from './getIsEnabled'

const useAdminCompanyQuery = options => {
  const adminCompanyId = useAdminCompanyId()

  const { queryKey, queryFn, ...restConfig } = options

  const enabled = !!(adminCompanyId && getIsEnabled(options))

  const query = useQuery({
    ...defaultQueryConfig,
    ...restConfig,
    queryKey: ['admin', 'companies', adminCompanyId, ...queryKey],
    queryFn,
    enabled
  })

  const resp = query.data || {}

  const result = resp.data || resp

  return [result, query]
}

export default useAdminCompanyQuery
