import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

const withBorrowerLoans = WrappedComponent => {
  class WrappedComponentWithBorrowerLoans extends React.Component {
    getBorrowerLoans = async ({ borrowerId, closestTo, getToday, status }) => {
      const {
        getBorrowerLoans,
        getNextPayment,
        getNextAutopay,
        getObligations,
        getBalances
      } = this.props

      const loans = await getBorrowerLoans({ personId: borrowerId, status })
      const promises = []

      if (_.size(loans) > 0) {
        _.each(loans, ({ id, current }) => {
          promises.push(getNextPayment(id, { closestTo, getToday }))

          promises.push(getObligations(id))

          promises.push(getBalances(id))

          // only fetch autopay if the loans endpoint says we should
          if (current?.autopayEnabled) {
            promises.push(getNextAutopay(id, { closestTo, getToday }))
          }
        })
      }

      return await Promise.all(promises)
    }

    getInitialBorrowerLoans = async ({
      borrowerId,
      closestTo,
      getToday,
      setLoansError,
      setLoansLoading,
      setLoansSuccess
    }) => {
      setLoansLoading()

      try {
        await this.getBorrowerLoans({
          borrowerId,
          closestTo,
          getToday,
          status: 'active,originated'
        })
        setLoansSuccess()
      } catch (error) {
        setLoansError()
      }
    }

    getPastBorrowerLoans = async ({
      borrowerId,
      closestTo,
      getToday,
      setLoansError,
      setLoansLoading,
      setLoansSuccess
    }) => {
      setLoansLoading()

      try {
        await this.getBorrowerLoans({
          borrowerId,
          closestTo,
          getToday,
          status: 'chargedOff,paidOff,declined'
        })
        setLoansSuccess()
      } catch (error) {
        setLoansError()
      }
    }

    render() {
      return (
        <WrappedComponent
          getInitialBorrowerLoans={this.getInitialBorrowerLoans}
          getPastBorrowerLoans={this.getPastBorrowerLoans}
          {...this.props}
        />
      )
    }
  }

  WrappedComponentWithBorrowerLoans.propTypes = {
    getBorrowerLoans: PropTypes.func.isRequired,
    getNextPayment: PropTypes.func.isRequired,
    getNextAutopay: PropTypes.func.isRequired,
    getObligations: PropTypes.func.isRequired,
    getBalances: PropTypes.func.isRequired
  }

  return WrappedComponentWithBorrowerLoans
}

export default withBorrowerLoans
