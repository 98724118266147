const MINUTE = 60 * 1000

const defaultQueryConfig = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: true,
  retry: 0,
  staleTime: 2 * MINUTE,
  cacheTime: 2 * MINUTE
}

export default defaultQueryConfig
