import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import {
  getStoredToken,
  renewSession,
  decodeToken
} from 'core/actions/helpers/tokenHelpers'

const AuthWrapper = styled.div`
  font-family: monospace;
  white-space: pre-wrap;
`

const Table = styled.div`
  display: table;
  border: 1px solid #ccc;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
`

const Row = styled.div`
  display: table-row;
`

const Cell = styled.div`
  display: table-cell;
  padding: 8px;
  border: 1px solid #ccc;
  font-family: monospace;
  white-space: pre-wrap;
`

const Button = styled.button`
  font-family: monospace;
  padding: 4px 8px;
  cursor: pointer;
`

const getNow = () => Date.now()

const formatMilliseconds = duration => {
  if (!_.isFinite(duration)) return '--'

  const sign = duration < 0 ? '-' : ''

  const val = Math.abs(duration)

  const seconds = Math.floor((val / 1000) % 60)
  const minutes = Math.floor((val / (1000 * 60)) % 60)
  const hours = Math.floor((val / (1000 * 60 * 60)) % 24)
  const days = Math.floor(val / (1000 * 60 * 60 * 24))

  const $hours = hours < 10 ? '0' + hours : hours
  const $minutes = minutes < 10 ? '0' + minutes : minutes
  const $seconds = seconds < 10 ? '0' + seconds : seconds

  if (days) {
    return `${sign}${days} days ${$hours} hours ${$minutes} minutes ${$seconds} seconds`
  }

  if (hours) {
    return `${sign}${$hours} hours ${$minutes} minutes ${$seconds} seconds`
  }

  if (minutes) {
    return `${sign}${$minutes} minutes ${$seconds} seconds`
  }

  return `${sign}${$seconds} seconds`
}

const formatToken = token => {
  return token ? `${token.slice(0, 12)}...${token.slice(-12)}` : '--'
}

const formatTimestamp = ts => {
  return ts ? new Date(ts).toLocaleString('en-US') : '--'
}

const Auth = () => {
  const token = getStoredToken()

  const decodedToken = decodeToken(token)

  const [now, setNow] = useState(getNow)

  useEffect(() => {
    const id = setInterval(() => setNow(getNow), 400)
    return () => clearInterval(id)
  }, [])

  const row = (label, val1 = '', val2 = '') => {
    return (
      <Row>
        <Cell>{label}</Cell>
        <Cell>{val1}</Cell>
        <Cell>{val2}</Cell>
      </Row>
    )
  }

  const { iat, exp } = decodedToken

  const issuedAt = iat * 1000

  const expiresAt = (exp - 5) * 1000

  return (
    <AuthWrapper>
      <Table>
        {row('Token', formatToken(token))}

        {row(
          'Issued At',
          formatTimestamp(issuedAt),
          formatMilliseconds(now - issuedAt) + ' ago'
        )}

        {row(
          'Expires At',
          formatTimestamp(expiresAt),
          formatMilliseconds(expiresAt - now)
        )}

        {row('Decoded Token', JSON.stringify(decodedToken, null, 2))}

        {row('Renew', <Button onClick={renewSession}>Renew Session</Button>)}
      </Table>
    </AuthWrapper>
  )
}

export default Auth
