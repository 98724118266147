import { APIendpoint } from 'core/actions/constants'
import { rawApiCall } from 'core/api'
import qs from 'qs'
import { store, retrieve } from 'admin/hooks/useStoredState/storage'

const key = 'LOCAL_BOOSTRAP_PARAMS'

const isTrue = value => value === true || value === 'true'

const CUSTOM_BOOTSTRAP_ENABLED = isTrue(
  process.env.REACT_APP_CUSTOM_BOOTSTRAP_ENABLED
)

const envDomain = process.env.REACT_APP_CUSTOM_DOMAIN
const envCompanyId = process.env.REACT_APP_CUSTOM_COMPANY_ID
const envAppDomainType = process.env.REACT_APP_CUSTOM_APP_DOMAIN_TYPE

const realDomain = window.location.hostname

const getUrlParams = () => {
  try {
    return qs.parse(window.location.search.substring(1)) || {}
  } catch {
    return {}
  }
}

const getLocalStorageParams = () => retrieve(key) || {}

const setLocalStorageParams = params => {
  store(key, params)
  return params
}

const getBootstrapParams = () => {
  if (!CUSTOM_BOOTSTRAP_ENABLED) {
    return { domain: realDomain }
  }

  const {
    domain: urlDomain,
    companyId: urlCompanyId,
    appDomainType: urlAppDomainType
  } = getUrlParams()

  if (urlDomain) {
    return setLocalStorageParams({
      domain: urlDomain
    })
  }

  if (urlCompanyId && urlAppDomainType) {
    return setLocalStorageParams({
      companyId: urlCompanyId,
      appDomainType: urlAppDomainType
    })
  }

  const {
    domain: localDomain,
    companyId: localCompanyId,
    appDomainType: localAppDomainType
  } = getLocalStorageParams()

  if (localDomain) {
    return { domain: localDomain }
  }

  if (localCompanyId && localAppDomainType) {
    return { companyId: localCompanyId, appDomainType: localAppDomainType }
  }

  if (envDomain) {
    return { domain: envDomain }
  }

  if (envCompanyId && envAppDomainType) {
    return { companyId: envCompanyId, appDomainType: envAppDomainType }
  }

  return { domain: realDomain }
}

const fetchBootstrapData = async () => {
  try {
    const resp = await rawApiCall({
      method: 'GET',
      url: `${APIendpoint}/bootstrap`,
      params: getBootstrapParams()
    })

    return resp
  } catch (e) {
    if (e.status === 503) throw e
  }
}

export {
  CUSTOM_BOOTSTRAP_ENABLED,
  getLocalStorageParams as PRIVATE_getLocalStorageParams,
  setLocalStorageParams as PRIVATE_setLocalStorageParams
}

export default fetchBootstrapData
