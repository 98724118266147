import { useContext } from 'react'
import { CallModeContext } from 'contexts/CallModeContext'

const useCallModeContext = () => {
  const { callMode, setCallModeCallCompleted } = useContext(CallModeContext)

  return { callMode, setCallModeCallCompleted }
}

export default useCallModeContext
