// Third party --------------
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import styled from 'styled-components/macro'
import { useDispatch } from 'react-redux'

// Components ---------------
import Icon from 'core/components/Icon'
import { Button, Checkbox, Dialog, Modal, Spinner } from 'core/components'

// Rest ---------------------
import updateInteraction from 'core/actions/interactions/updateInteraction'
import updateUnboundInteraction from 'core/actions/interactions/updateUnboundInteraction'
import variables from 'core/styles/variables'
import { SearchedBorrowerContext } from 'contexts/SearchedBorrowerContext'
import { formatPhone } from 'core/helpers/phoneNumberFormatter'
import { getFormattedLabel } from './helpers'
import {
  useBorrowerIdFromPathname,
  useCompanyId,
  useInterval,
  useWrite
} from 'core/hooks'

// Styles -----------------------------
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Recording = styled.div`
  display: flex;
  align-items: center;
  color: ${variables.colorRed};
`
const NotRecording = styled.div`
  display: flex;
  align-items: center;
  color: ${variables.colorOrange};
`
const RecordCheckbox = styled.div`
  display: flex;
  align-items: center;
  color: ${variables.colorBlack60};
  font-size: 14px;
  font-weight: 300;
`
const BottomSection = styled.div`
  display: flex;
  align-item: flex-start;
  justify-content: space-between;
  padding: 8px 0 0 0;
`
const SelectedContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const PhoneLabel = styled.div`
  display: flex;
  align-items: center;
`
const Label = styled.div`
  margin-bottom: 8px;
  color: ${variables.colorBlack90};
  font-weight: 500;
`
const PhoneNumber = styled.div`
  color: ${variables.colorBlack90};
`
const RecordingLoading = styled.div`
  display: flex;
  position: relative;
  right: -2px; // slight nudge to the right for alignment
  align-items: center;
  justify-content: space-between;
  width: 112px;

  span {
    white-space: nowrap;
    margin-right: 8px;
  }
`
const ChangeAssociation = styled.div`
  flex: 0 1 180px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`
const Filler = styled.div`
  flex: 0 1 180px;
`

const TopActiveSection = ({
  callerPhoneNumber,
  contact,
  interaction,
  isInboundCall,
  recording,
  setInteraction,
  onUpdateRecordingStatus
}) => {
  const {
    useCompanyId,
    useContext,
    useDispatch
  } = TopActiveSection.dependencies

  const { value } = contact || {}
  const displayLabel = getFormattedLabel(contact)
  const displayValue = formatPhone(value || callerPhoneNumber)
  const { id, personId } = interaction || {}

  const companyId = useCompanyId()
  const dispatch = useDispatch()
  const { searchedBorrower } = useContext(SearchedBorrowerContext)
  const borrowerIdFromPathname = useBorrowerIdFromPathname()

  const [isRecordingDisabled, setIsRecordingDisabled] = useState(true)
  const [showDisassociateModal, setShowDisassociateModal] = useState(false)
  const [
    showAssociateRequirementModal,
    setShowAssociateRequirementModal
  ] = useState(false)

  const canChangeAssociation =
    (searchedBorrower || borrowerIdFromPathname) && isInboundCall
  const canAssociateBorrower = canChangeAssociation && !personId
  const showAssociateButton = isInboundCall && !personId
  const showDisassociateButton = isInboundCall && personId

  useInterval(
    () => {
      if (sessionStorage.getItem('isVoiceRecordingDisabled') === 'false') {
        setIsRecordingDisabled(false)
      }
    },
    { delay: 300 }
  )

  const [associateBorrower, isAssociatingBorrower] = useWrite(async () => {
    const resp = await dispatch(
      updateUnboundInteraction({
        body: {
          personId: searchedBorrower?.id || borrowerIdFromPathname
        },
        companyId,
        id,
        key: `Interactions.byId(id=${id})`
      })
    )
    setInteraction(resp.data)
  })

  const [disassociateBorrower, isDisassociatingBorrower] = useWrite(
    async () => {
      const resp = await dispatch(
        updateInteraction({
          body: {
            personId: null
          },
          personId,
          id,
          personIdIsNull: true,
          key: `Interactions.byId(id=${id})`
        })
      )
      setInteraction(resp.data)
    }
  )

  const onAssociateBorrower = () => {
    if (!canAssociateBorrower) {
      setShowAssociateRequirementModal(true)
      return
    }
    associateBorrower()
  }

  const onConfirmDisassociateBorrower = async () => {
    await disassociateBorrower()
    setShowDisassociateModal(false)
  }

  const canCloseModal = !isAssociatingBorrower && !isDisassociatingBorrower

  return (
    <>
      <Top>
        {recording ? (
          <Recording data-testid='status-recording'>
            <Icon margin='0 16px 0 0' name='fiber_manual_record' />
            Recording
          </Recording>
        ) : (
          <NotRecording data-testid='status-not-recording'>
            <Icon fontSize='24px' margin='0 16px 0 0' name='block' />
            Not recording
          </NotRecording>
        )}
        <RecordCheckbox>
          {/* We're using the disabled prop here to indicate loading state.
           ** Somewhat misleading but using it for now since it's a prop
           ** that was already created and don't want to address it fo now. */}
          {isRecordingDisabled ? (
            <RecordingLoading data-testid='recording-loading'>
              <span>Record call</span> <Spinner />
            </RecordingLoading>
          ) : (
            <Checkbox
              data-testid='checkbox-record'
              checked={recording}
              color={variables.colorBlack60}
              flexReverse
              fontSize='14px'
              label='Record call'
              onChange={() => onUpdateRecordingStatus(!recording)}
              value='record-call'
            />
          )}
        </RecordCheckbox>
      </Top>
      <BottomSection>
        <Filler />
        <SelectedContactDetails>
          <PhoneLabel>
            <Label data-testid='label-caller-name'>
              {displayLabel || 'Unidentified caller'}
            </Label>
          </PhoneLabel>
          <PhoneNumber data-testid='displayed-phone-number'>
            {displayValue}
          </PhoneNumber>
        </SelectedContactDetails>
        <ChangeAssociation>
          {showAssociateButton && (
            <Button
              data-testid='button-associate-borrower'
              fontSize='14px'
              onClick={onAssociateBorrower}
              secondary
            >
              Associate borrower
            </Button>
          )}
          {showDisassociateButton && (
            <Button
              data-testid='button-disassociate-borrower'
              fontSize='14px'
              onClick={() => setShowDisassociateModal(true)}
              secondary
            >
              Disassociate borrower
            </Button>
          )}
        </ChangeAssociation>
      </BottomSection>
      {showDisassociateModal && (
        <Modal
          data-testid='modal-disassociate-borrower'
          onClose={() => setShowDisassociateModal(false)}
          width={476}
          canClose={canCloseModal}
        >
          <Dialog
            data-testid='dialog-associate-borrower'
            title='Disassociate'
            confirmLabel='Yes'
            onConfirm={onConfirmDisassociateBorrower}
            cancelLabel='No'
            onCancel={() => setShowDisassociateModal(false)}
          >
            Are you sure you want to remove borrower association whilch will
            leave the interaction without a borrower ID?
          </Dialog>
        </Modal>
      )}
      {showAssociateRequirementModal && (
        <Modal
          data-testid='modal-associate-borrower-requirment'
          onClose={() => setShowAssociateRequirementModal(false)}
          width={476}
          canClose={canCloseModal}
        >
          <Dialog
            data-testid='dialog-associate-borrower-requirement'
            title='Associate with borrower'
            confirmLabel='Ok'
            onConfirm={() => setShowAssociateRequirementModal(false)}
          >
            To associate with a borrower please search for the borrower in "Find
            a borrower". Select the borrower to confirm their contact details,
            then you can "Associate with borrower" in the interaction.
          </Dialog>
        </Modal>
      )}
    </>
  )
}

TopActiveSection.propTypes = {
  callerPhoneNumber: PropTypes.string,
  contact: PropTypes.object,
  interaction: PropTypes.object,
  isInboundCall: PropTypes.bool,
  recording: PropTypes.bool,
  setInteraction: PropTypes.func,
  onUpdateRecordingStatus: PropTypes.func
}

TopActiveSection.dependencies = {
  useCompanyId,
  useContext,
  useDispatch
}

export default TopActiveSection
