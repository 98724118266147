// Third party ------------------------
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

// Proprietary ------------------------
import variables from 'core/styles/variables'

// Components -------------------------
import { Button } from 'core/components'
import Icon from 'core/components/Icon'

const Toolbar = styled.div`
  &.ql-toolbar.ql-snow {
    display: inline-flex;
    position: relative;
    align-items: center;
    flex: 0 0 42px;
    border: none;
    margin-right: 8px;
    padding: 4px 0 4px 4px;
    border-radius: 8px;
    background: white;
    height: 36px;
  }

  .ql-picker-options {
    // This moves the select drop down menu above the
    // select element plus the number of pixels of the
    // height of the select element. In this casse the
    // height is 24px.
    transform: translateY(-100%) translateY(-24px);
  }
`
const ButtonGroup = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${variables.colorBlack40};
  border-radius: 4px;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  button {
    width: 40px;
    height: 32px;
  }

  button.disabled {
    // Adding !important to keep Quill styles from
    // overriding our default button disabled styles
    // on hover.
    cursor: default !important;
    color: ${variables.colorBlack40} !important;
  }
`
const ButtonDivider = styled.span`
  background: ${variables.colorBlack40};
  width: 1px;
  height: 20px;
`

const EditorToolbar = ({ id = 'toolbar', forceShowToolbar }) => (
  <Toolbar id={id} forceShowToolbar={forceShowToolbar}>
    <ButtonGroup>
      <Button className='ql-bold'> </Button>
      <ButtonDivider />
      <Button className='ql-italic'> </Button>
      <ButtonDivider />
      <Button className='ql-underline'> </Button>
    </ButtonGroup>
    <ButtonGroup>
      <button className='ql-list' value='bullet' />
      <ButtonDivider />
      <button className='ql-list' value='ordered' />
    </ButtonGroup>
    <ButtonGroup>
      <select
        className='ql-header'
        defaultValue={''}
        onChange={e => e.persist()}
      >
        <option value='1' />
        <option value='2' />
      </select>
    </ButtonGroup>
    <ButtonGroup>
      <Button className='ql-undo'>
        <Icon name='undo' />
      </Button>
      <ButtonDivider />
      <Button className='ql-redo'>
        <Icon name='redo' />
      </Button>
    </ButtonGroup>
  </Toolbar>
)

EditorToolbar.propTypes = {
  id: PropTypes.string,
  forceShowToolbar: PropTypes.bool
}

export default EditorToolbar
