// Third party ------------------------
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'

// Proprietary ------------------------
import outcomeOptions from 'core/helpers/getOutcomeOptions'

// Components -------------------------
import InlineDropdownBase from 'core/components/InlineDropdown'
import Row from './Row'
import RowLabel from './RowLabel'

// Styles -----------------------------
const InlineDropdown = styled(InlineDropdownBase)`
  width: 360px;
`

const Outcome = ({
  maximized,
  maxMenuHeight,
  menuPlacement,
  onChange,
  outcome: passedOutcome,
  readOnly
}) => {
  const outcome = _.find(
    outcomeOptions,
    options => options.value === passedOutcome
  )
  const reasonString =
    typeof passedOutcome === 'string'
      ? outcome?.label ?? ''
      : passedOutcome?.label ?? ''
  const value =
    typeof passedOutcome === 'string'
      ? _.find(outcomeOptions, options => options.value === passedOutcome)
      : passedOutcome
  return (
    <Row maximized={maximized}>
      <RowLabel>Outcome</RowLabel>
      {readOnly ? (
        <span>{reasonString}</span>
      ) : (
        <InlineDropdown
          isControlled
          maxMenuHeight={maxMenuHeight}
          menuPlacement={menuPlacement || 'bottom'}
          onChange={onChange}
          options={outcomeOptions}
          placeholder='Select an outcome'
          value={value}
        />
      )}
    </Row>
  )
}

Outcome.propTypes = {
  maximized: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  menuPlacement: PropTypes.string,
  onChange: PropTypes.func,
  outcome: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  readOnly: PropTypes.bool
}

export default Outcome
