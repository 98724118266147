// Call modes ---------------
const PREDIAL = {
  state: 'PREDIAL',
  iconName: 'phone'
}
const ACTIVE = {
  state: 'ACTIVE',
  iconName: 'phone_in_talk'
}
const POST_DIAL = {
  state: 'POST_DIAL',
  iconName: 'phone_paused'
}
const CALL_COMPLETED = {
  state: 'CALL_COMPLETED',
  iconName: 'phone'
}

export { PREDIAL, ACTIVE, POST_DIAL, CALL_COMPLETED }
