// Third party ------------------------
import PropTypes from 'prop-types'
import React, { createContext, useContext, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// Proprietary ------------------------
import getLoanTypes from 'core/actions/loanTypes/getLoanTypes'
import getPermissions from 'core/actions/permissions/getPermissions'
import { useSetGlobalValue } from 'core/badGlobalDoNotUse'

// non-userType specific routes
import AppRoutes from 'app/AppRoutes'

// login components
import { LoginLayout, LoginInfoProvider } from 'noauth/pages/Login/LoginWrapper'

import LoginManager from 'noauth/pages/Login/LoginManager'

import DocumentUploader from 'noauth/pages/DocumentUploader'
import {
  getStoredToken,
  decodeToken,
  isTokenValid
} from 'core/actions/helpers/tokenHelpers'
import AppRootErrorBoundary from './AppRootErrorBoundary'

const SessionPersonIdContext = createContext('')

const App = props => {
  const {
    appDomainType,
    userType,
    companyId,
    authType,
    authValueType,
    ssoUrl,
    companyTheme
  } = props

  const token = getStoredToken()
  const { personId: sessionPersonId } = decodeToken(token)

  const { useDispatch, useEffect, isTokenValid } = App.dependencies

  const dispatch = useDispatch()
  const tokenIsValid = isTokenValid(token)

  // this is a hack to allow reducers and other functions access to
  // current user's personId
  useSetGlobalValue('sessionPersonId', sessionPersonId)

  /**
   * Fetching all loan types here since it only needs to loaded once.
   */
  useEffect(() => {
    if (companyId && tokenIsValid) {
      dispatch(getLoanTypes({ companyId, key: 'GetLoanTypes' }))
      dispatch(getPermissions({ key: 'GetPermissions' }))
    }
  }, [companyId, dispatch, tokenIsValid])

  const appRoutesOrRedirect = tokenIsValid ? (
    <AppRoutes
      appDomainType={appDomainType}
      userType={userType}
      sessionPersonId={sessionPersonId}
      companyTheme={companyTheme}
      authType={authType}
    />
  ) : (
    <Redirect to='/login' />
  )

  return (
    <AppRootErrorBoundary>
      <Switch>
        {/* routes where it doesn't matter if you're logged in */}
        <Route path='/login'>
          <LoginInfoProvider
            appDomainType={appDomainType}
            userType={userType}
            companyId={companyId}
            authType={authType}
            authValueType={authValueType}
            ssoUrl={ssoUrl}
          >
            <LoginLayout companyTheme={companyTheme}>
              <LoginManager />
            </LoginLayout>
          </LoginInfoProvider>
        </Route>

        {/* 
      this route enables customers to link to our app and opt-into sending the user directly into the SSO flow.  
      we don't do this for all /login routes to avoid getting the user into a redirect loop of 
      in-app -> click sign-out -> redirect to /login -> redirect to SSO -> redirected back into app -> oops we're signed in again 
      */}

        <Route path='/sso-login'>
          <Redirect to='/login?redirect=immediate' />
        </Route>

        <Route path='/document-uploader/:uploadDocumentLinkKey'>
          <DocumentUploader />
        </Route>

        <Route>{appRoutesOrRedirect}</Route>
      </Switch>
    </AppRootErrorBoundary>
  )
}

App.propTypes = {
  appDomainType: PropTypes.oneOf(['borrower', 'agent', 'admin']),
  userType: PropTypes.oneOf(['borrower', 'agent']),
  companyId: PropTypes.string,
  authValueType: PropTypes.oneOf([
    'email',
    'username',
    'phone',
    'samlSubjectNameId'
  ]),
  authType: PropTypes.oneOf([
    'basic',
    'google',
    'oneTimeCodeEmail',
    'oneTimeCodeText',
    'SAML'
  ]),
  ssoUrl: PropTypes.string,
  companyTheme: PropTypes.object
}

App.dependencies = {
  isTokenValid,
  useDispatch,
  useEffect
}

const _useSessionPersonId = () => useContext(SessionPersonIdContext)

export { _useSessionPersonId }

export default App
