import { useEffect } from 'react'
import { redirectToLogin } from './loginRedirectHelpers'

// this component sends the user to the login page,
// but stores the current path to localStorage so that we can pick that back up

const LoginRedirect = () => {
  useEffect(() => {
    redirectToLogin()
  }, [])

  return null
}

export default LoginRedirect
