import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const ErrorDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 24px;
`

const LoginErrorMessage = ({ children }) => {
  return (
    <ErrorDiv>
      {children || 'Something went wrong. Please try refreshing the page.'}
    </ErrorDiv>
  )
}

LoginErrorMessage.propTypes = {
  children: PropTypes.node
}

export default LoginErrorMessage
