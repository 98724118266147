import { peachApi } from 'core/api'

const fetchTwilioAccessToken = async () => {
  try {
    const response = await peachApi.post({
      url: '/twilio/auth/access-tokens',
      body: {}
    })
    return response
  } catch {
    throw new Error('Failed to fetch token.')
  }
}

export default fetchTwilioAccessToken
