// Third party --------------
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'

// Components ---------------
import InlineDropdown from 'core/components/InlineDropdown'
import Row from '../Row'
import RowLabel from '../RowLabel'

// Rest ---------------------
import { interactionThemes } from 'config/constants'
import {
  inboundInteractionReasonOptions,
  outboundInteractionReasonOptions
} from 'core/helpers/getReasonOptions'

// Styled components --------
const InlineDropdownContainer = styled.div`
  width: 360px;
`
const Span = styled.span`
  padding: 0 0 0 8px;
  /** The 30px height/line-height are according to design */
  height: 30px;
  line-height: 30px;
`

const Reason = ({
  isControlled,
  isInboundInteraction,
  maxMenuHeight,
  maximized,
  menuPlacement,
  onChange,
  readOnly,
  theme
}) => {
  const options = isInboundInteraction
    ? inboundInteractionReasonOptions
    : outboundInteractionReasonOptions

  const isThemeObject = _.isObject(theme)
  const reasonString = isThemeObject
    ? theme.label
    : theme !== null
    ? interactionThemes[theme]
    : ''
  const value =
    typeof theme === 'object'
      ? theme
      : _.find(options, option => option.value === theme)
  return (
    <Row maximized={maximized}>
      <RowLabel>Reason</RowLabel>
      {readOnly ? (
        <Span data-testid='reason-string'>{reasonString}</Span>
      ) : (
        <InlineDropdownContainer>
          <InlineDropdown
            isControlled={isControlled}
            options={options}
            value={value}
            onChange={onChange}
            placeholder='Select a reason'
            maxMenuHeight={maxMenuHeight}
            menuPlacement={menuPlacement}
          />
        </InlineDropdownContainer>
      )}
    </Row>
  )
}

Reason.propTypes = {
  isControlled: PropTypes.bool,
  isInboundInteraction: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  /** If the containing resource window is maximized */
  maximized: PropTypes.bool,
  menuPlacement: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  theme: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export { InlineDropdown }
export default Reason
