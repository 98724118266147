import { POSTwithToken } from 'core/api/legacyApi'
import { handleErrors } from 'core/helpers/handleErrors'

import {
  LOADING,
  SEND_COMMUNICATION,
  SEND_COMMUNICATION_FAILED,
  SEND_COMMUNICATION_INTERNAL_SERVER_ERROR,
  APIendpoint,
  getStoredToken
} from 'core/actions/constants'

import { getGlobalValue } from 'core/badGlobalDoNotUse'

export const RENDER_TO_DOCUMENT = 'RENDER_TO_DOCUMENT'
export const RENDER_DOCUMENT = 'RENDER_DOCUMENT'
export const INITIATE_RENDER_DOCUMENT = 'INITIATE_RENDER_DOCUMENT'
export const SEND_VERIFICATION_CODE_SUCCESS = 'SEND_VERIFICATION_CODE_SUCCESS'
export const SEND_VERIFICATION_CODE_MAX_ATTEMPT =
  'SEND_VERIFICATION_CODE_MAX_ATTEMPT'
export const SEND_VERIFICATION_CODE_TIMEOUT = 'SEND_VERIFICATION_CODE_TIMEOUT'
export const SEND_VERIFICATION_CODE_FAILED = 'SEND_VERIFICATION_CODE_FAILED'
export const REQUEST_PROGRESS = 'REQUEST_PROGRESS'
export const CLEAR_COMMUNICATION_ERROR = 'CLEAR_COMMUNICATION_ERROR'

const communicatorEndpoint = `${APIendpoint}/communicator`

/**
 * Sends a communication (i.e. creates an interaction) to the person denoted
 * by `personId`, following the backend template denoted by `subject`.
 */
export const sendCommunication = ({
  attachments,
  caseId,
  channel,
  companyId,
  contactId,
  context,
  key,
  loanId,
  personId,
  subject,
  theme
}) => {
  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    const body = {
      attachments,
      caseId,
      channel,
      contactId,
      context,
      loanId,
      personId,
      subject,
      theme
    }

    const parseJson = [400]

    const endpoint = `${communicatorEndpoint}/send`
    const response = await fetch(
      endpoint,
      POSTwithToken(getStoredToken(), body)
    )
    const responseBody = await handleErrors(response, parseJson)
    const isSuccess = response.status >= 200 && response.status <= 299
    const badRequest = response.status === 400
    const serverError = response.status === 500

    if (badRequest) {
      dispatch({
        type: SEND_COMMUNICATION_FAILED,
        payload: 'Email was not sent. Please contact Customer Support for help.'
      })
    }

    if (isSuccess) {
      dispatch({
        type: SEND_COMMUNICATION,
        payload: body,
        companyId
      })
    }

    if (serverError) {
      dispatch({
        type: SEND_COMMUNICATION_INTERNAL_SERVER_ERROR,
        payload: `Your request couldn't be processed. Please try again later or contact customer support.`
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error'
      })
    }
    return responseBody
  }
}

export const clearCommunicationError = () => {
  return async dispatch => {
    dispatch({
      type: CLEAR_COMMUNICATION_ERROR
    })
  }
}

/**
 * Like sendCommunication, but returns a preview. Currently does
 * not dispatch an action object or put anything in the redux
 * store (other than loading status).
 */
export const renderTemplate = ({
  key,
  subject,
  personId,
  channel,
  contactId,
  caseId,
  context,
  companyId
}) => {
  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    const body = {
      subject,
      personId,
      channel,
      contactId,
      caseId,
      context
    }

    const endpoint = `${communicatorEndpoint}/render?companyId=${companyId}`
    const response = await fetch(
      endpoint,
      POSTwithToken(getStoredToken(), body)
    )
    const isSuccess = response.status >= 200 && response.status <= 299

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error'
      })
    }

    return response.text()
  }
}

/**
 * Renders data without saving it anywhere for a faster way of showcasing data
 */
export const renderDocument = ({ subject, channel = 'gui', loanId, data }) => {
  return dispatch => {
    const endpoint = `${communicatorEndpoint}/render`

    const body = {
      subject,
      channel,
      personId: getGlobalValue('borrowerPersonId'),
      context: data,
      loanId
    }

    dispatch({
      type: INITIATE_RENDER_DOCUMENT
    })

    return fetch(endpoint, POSTwithToken(getStoredToken(), body))
      .then(response => {
        return response.text()
      })
      .then(html => {
        dispatch({
          type: RENDER_DOCUMENT,
          payload: html
        })
      })
  }
}

/**
 * Renders data to the previous initial document descriptor that is in draft mode
 */
export const renderToDocument = ({ subject, documentId, data, loanId }) => {
  return dispatch => {
    const endpoint = `${communicatorEndpoint}/render-to-document`

    const body = {
      subject,
      channel: 'document',
      personId: getGlobalValue('borrowerPersonId'),
      documentId: documentId,
      context: data,
      loanId
    }

    return fetch(endpoint, POSTwithToken(getStoredToken(), body))
      .then(response => {
        return response.text()
      })
      .then(html => {
        dispatch({
          type: RENDER_TO_DOCUMENT,
          payload: html
        })
      })
  }
}

export const sendVerificationCode = ({ key, dataType, channel, data }) => {
  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    const body = {
      personId: getGlobalValue('borrowerPersonId'),
      [dataType]: data,
      channel: channel
    }

    const endpoint = `${communicatorEndpoint}/send-confirmation-code`
    const response = await fetch(
      endpoint,
      POSTwithToken(getStoredToken(), body)
    )
    const isSuccess = response.status >= 200 && response.status <= 299
    const badRequest = response.status === 400
    const maxRequest = response.status === 429
    const timeoutRequest = response.status === 408

    if (isSuccess) {
      dispatch({
        type: SEND_VERIFICATION_CODE_SUCCESS
      })
    }

    if (badRequest) {
      dispatch({
        type: SEND_VERIFICATION_CODE_FAILED,
        payload: {
          errorMessage: `Verification code couldn't be sent due to a technical issue. Please try again or contact Customer Support for help.`,
          errorStatus: 'generalFailed'
        }
      })
    }

    if (maxRequest) {
      dispatch({
        type: SEND_VERIFICATION_CODE_MAX_ATTEMPT,
        payload: {
          errorMessage: `Verification failed as you've exceeded the maximum number of attempts.`,
          errorStatus: 'maxRequest'
        }
      })
    }

    if (timeoutRequest) {
      dispatch({
        type: SEND_VERIFICATION_CODE_TIMEOUT,
        payload: {
          errorMessage:
            'The verification code we’ve sent expired. Try to send a new code.',
          errorStatus: 'timeoutRequest'
        }
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error'
      })
    }
  }
}
