import { Component } from 'react'
import { Error } from 'core/SystemProvider/SystemProviderStyles'
import ErrorLogging from 'core/system/ErrorLogging'

class AppRootErrorBoundary extends Component {
  state = { error: null }

  promiseRejectionHandler = event => {
    this.setState({
      error: event.reason
    })
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    const { consoleError, captureException } = AppRootErrorBoundary.dependencies
    if (error.status !== 503) {
      consoleError({
        error,
        errorInfo
      })
      captureException(error)
    }
  }

  componentDidMount() {
    const { addEventListener } = AppRootErrorBoundary.dependencies
    addEventListener('unhandledrejection', this.promiseRejectionHandler)
  }

  componentWillUnmount() {
    const { removeEventListener } = AppRootErrorBoundary.dependencies
    removeEventListener(
      'unhandledrejection',
      this.promiseRejectionHandler
    )
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    if (error) {
      if (error.status === 503) {
        return (
          <Error>
            This site is briefly undergoing maintenance. We appreciate your
            patience.
          </Error>
        )
      } else {
        return (
          <Error>
            An unexpected error has occurred. We apologize for the inconvenience.
          </Error>
        )
      }
    } else {
      return children
    }
  }
}

AppRootErrorBoundary.dependencies = {
  addEventListener: window.addEventListener,
  removeEventListener: window.removeEventListener,
  consoleError: console.error, // eslint-disable-line no-console
  captureException: ErrorLogging.captureException
}

export default AppRootErrorBoundary
