import {
  APIendpoint,
  GET_DOCUMENT_WITH_CONTENT,
  LOADING,
  getStoredToken
} from 'core/actions/constants'
import { GET } from 'core/api/legacyApi'
import { handleErrors } from 'core/helpers/handleErrors'

const getDocumentWithContent = ({ key, id, personId }) => {
  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    const endpoint = `${APIendpoint}/people/${personId}/documents/${id}`
    const response = await fetch(endpoint, GET(getStoredToken()))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    const contentEndpoint = `${APIendpoint}/people/${personId}/documents/${id}/content?disposition=inline&returnUrl=true`
    const contentResponse = await fetch(contentEndpoint, GET(getStoredToken()))
    const contentResponseBody = await handleErrors(contentResponse)
    const contentIsSuccess =
      contentResponse.status >= 200 && contentResponse.status <= 299

    if (isSuccess && contentIsSuccess) {
      dispatch({
        type: GET_DOCUMENT_WITH_CONTENT,
        content: contentResponseBody.url,
        id,
        payload: responseBody
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess && contentIsSuccess ? 'success' : 'error'
      })
    }
  }
}

export default getDocumentWithContent
