import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import APIendpoint, { isCustomEndpoint } from 'core/actions/helpers/APIendpoint'
import variables from 'core/styles/variables'
import BadgeToggle from './BadgeToggle'

const envLabelHide = process.env.REACT_APP_ENV_LABEL_HIDE === 'true'
const envLabel = isCustomEndpoint ? 'unknown' : process.env.REACT_APP_ENV_LABEL

const COLORS = {
  local: {
    backgroundColor: variables.colorGreenLighten,
    color: variables.colorGreen
  },
  dev: {
    backgroundColor: variables.colorBlueLighten,
    color: variables.colorBluePrimary
  },
  sandbox: {
    backgroundColor: variables.colorOrangeLighten,
    color: variables.colorOrange
  },
  unknown: {
    backgroundColor: variables.colorRedLighten,
    color: variables.colorRed
  }
}

const { color, backgroundColor } = COLORS[envLabel] || COLORS['unknown']

const BadgeItem = styled.span`
  display: inline-block;
  padding: 4px 8px;
  line-height: 1;
  border-radius: 3px;
  position: absolute;
  bottom: 6px;
  right: 6px;
  background-color: ${backgroundColor};
  color: ${color};
  border: 1px solid ${color};
  opacity: 80%;
  font-size: 12px;
  cursor: default;
  white-space: nowrap;
`

const Label = styled.span`
  line-height: 1;
`

const Badge = () => {
  useEffect(() => {
    // eslint-disable-next-line
    if (document.title === '${TITLE}') {
      document.title = envLabelHide ? 'Peach' : `Peach [${envLabel}]`
    }
  }, [])

  if (envLabelHide) {
    return null
  }

  const title = isCustomEndpoint
    ? `Custom API Base: ${APIendpoint}`
    : `API Base: ${APIendpoint}`
  const label = envLabel && !isCustomEndpoint ? envLabel : title
  return (
    <BadgeToggle>
      <BadgeItem title={title}>
        <Label>{label}</Label>
      </BadgeItem>
    </BadgeToggle>
  )
}

export default Badge
