import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { useCompanyLogos } from 'core/hooks'

/*

Branding logos should be used in-app in exactly the dimensions present 
in the config below.  this is to ensure that the uploaded assets 
will behave as expected.  i.e. do not override width and height, className, or constrain them into
fixed width or fixed height containers smaller than their explicit dimensions.  

(Even if they are aesthetically bad, they will be predictably bad 
and the solution will be fixing the uploaded asset)

We should reuse these asset types when it is sensible (ex: appLargeCentered was designed for login, but works for 
<DocumentUpload> because they are similar single-item screens)

But if a new use-case comes up, it's quite possible we need to add an additional key 
to the logos object in the company config and to extend the LOGO_TYPE_CONFIG here appropriately.  

*/

const LOGO_TYPE_CONFIG = {
  // Login, Login OTC, DocumentUploadPage
  appLargeCentered: {
    width: 400,
    height: 64,
    backgroundPosition: 'center'
  },

  // Desktop layout, Navbar (when in CRM Main page)
  appRegularNavigation: {
    width: 304,
    height: 48,
    backgroundPosition: 'left'
  },
  // Mobile Layout
  appCompactNavigation: {
    width: 200,
    height: 32,
    backgroundPosition: 'left'
  },
  fallback: {
    width: 0,
    height: 0
  }
}

const Logo = styled.span`
  display: inline-block;
  width: ${p => (p.width ? `${p.width}px` : '0')};
  height: ${p => (p.height ? `${p.height}px` : '0')};
  background-image: ${p => (p.url ? `url(${p.url})` : '#967bb6')};
  background-repeat: no-repeat;
  background-position: ${p => p.backgroundPosition || 'center'};
  background-size: contain;
  line-height: 0;
`

const CompanyLogo = ({ type }) => {
  const logos = useCompanyLogos() || {}

  const url = logos[type] || logos.primary || logos.small

  const { width, height, backgroundPosition } =
    LOGO_TYPE_CONFIG[type] || LOGO_TYPE_CONFIG.fallback

  return (
    <Logo
      data-testid='logo'
      width={width}
      height={height}
      url={url}
      backgroundPosition={backgroundPosition}
    />
  )
}

CompanyLogo.propTypes = {
  type: PropTypes.oneOf([
    'appLargeCentered', // used for "single use pages" like login on no-auth upload
    'appRegularNavigation', // used for desktop navigation
    'appCompactNavigation' // used for NavBar and mobile navigation
  ]).isRequired
}

export { Logo }
export default CompanyLogo
