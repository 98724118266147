// Third party ------------------------
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

// Components -------------------------
import InlineDropdownBase from 'core/components/InlineDropdown'
import Row from './Row'
import RowLabel from './RowLabel'

const InlineDropdown = styled(InlineDropdownBase)`
  width: 360px;
`

const Case = ({
  isDisabled,
  isControlled,
  maxMenuHeight,
  maximized,
  menuPlacement,
  multiSelect,
  onChange,
  onClick,
  options,
  value
}) => {
  const onClickWhileDisabled = () => {
    if (isDisabled && typeof onClick === 'function') {
      onClick()
    }
  }
  return (
    <Row maximized={maximized}>
      <RowLabel>Case (Optional)</RowLabel>
      <span onClick={onClickWhileDisabled}>
        <InlineDropdown
          isDisabled={isDisabled}
          isControlled={isControlled}
          maxMenuHeight={maxMenuHeight}
          menuPlacement={menuPlacement}
          multiSelect={multiSelect}
          onChange={onChange}
          options={options}
          placeholder='Select a case'
          value={value}
        />
      </span>
    </Row>
  )
}

Case.propTypes = {
  isDisabled: PropTypes.bool,
  isControlled: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  /** If the containing resource window is maximized */
  maximized: PropTypes.bool,
  menuPlacement: PropTypes.string,
  multiSelect: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ])
}

Case.defaultProps = {
  onChange: () => {}
}

export default Case
