import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
import variables from 'core/styles/variables'
import { Spinner } from 'core/components'
import Icon from 'core/components/Icon'

const Status = styled.div`
  text-align: center;
  margin-top: ${p => p.marginTop};
  margin-bottom: ${p => p.marginBottom};
  font-size: 12px;
  font-weight: 400;
`

const Label = styled.span`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`

const LabelText = styled.span`
  display: inline-block;
  color: ${variables.colorBlack50};
  margin-right: 8px;
`

const CodeSentStatus = props => {
  const { sending, label, marginTop, marginBottom } = props

  const contents = sending ? (
    <Spinner />
  ) : (
    <Label>
      <LabelText>{label || 'Code Sent'}</LabelText>
      <Icon name='check' color={variables.colorGreen} inline />
    </Label>
  )

  return sending || label ? (
    <Status marginTop={marginTop} marginBottom={marginBottom}>
      {contents}
    </Status>
  ) : null
}

CodeSentStatus.propTypes = {
  sending: PropTypes.bool,
  label: PropTypes.node,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string
}

export default CodeSentStatus
