import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from 'core/reducers'
import { /* saveState */ loadState } from './localStorage'

const persistedState = loadState()
const middleware = [thunk]
const enhancers = []
const logger = createLogger({
  collapsed: true
})

if (
  process.env.NODE_ENV === 'development' ||
  process.env.enviroment === 'development'
) {
  const devToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

  if (devToolsExtension) {
    enhancers.push(devToolsExtension)
    middleware.push(logger)
  }
}

export const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(rootReducer, persistedState, composedEnhancers)

// TODO: determine how the approach on persisting data
// and how often we should check data freshness

// store.subscribe(() => {
//   saveState({
//     ...store.getState()
//   })
// })
export default store
