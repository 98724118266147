// Third party --------------
import PropTypes from 'prop-types'
import React from 'react'

// Rest ---------------------
import TaskAvailabilityContextProvider from 'contexts/TaskAvailabilityContext'

const EmployeeSettingsProvider = ({ children }) => {
  return (
    <TaskAvailabilityContextProvider>
      {children}
    </TaskAvailabilityContextProvider>
  )
}

EmployeeSettingsProvider.propTypes = {
  children: PropTypes.node
}

export default EmployeeSettingsProvider
