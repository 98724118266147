// Third party --------------
import PropTypes from 'prop-types'
import React from 'react'

// Components ---------------
import { Spinner } from 'core/components'

// Rest ---------------------
import { useFormDisabled } from 'loan/components/actionCards/shared/DisabledContext'

// Styles -------------------
import { InnerContainer, Input, Label, OuterContainer } from './CheckboxStyles'

const Checkbox = props => {
  const {
    checked,
    children,
    color,
    disabled,
    flexReverse,
    fontSize,
    label,
    loading,
    margin,
    onChange,
    onClick,
    padding,
    value
  } = props

  const isDisabled = useFormDisabled(disabled)

  return (
    <OuterContainer margin={margin}>
      <InnerContainer className='checkbox' flexReverse={flexReverse}>
        <>
          {loading ? (
            <Spinner />
          ) : (
            <Input
              checked={checked}
              disabled={isDisabled}
              id={value}
              name={value}
              onChange={onChange}
              onClick={onClick}
              type='checkbox'
              value={value}
            />
          )}
        </>
        <Label
          color={color}
          flexReverse={flexReverse}
          fontSize={fontSize}
          htmlFor={value}
          padding={padding}
        >
          {label}
        </Label>
        {children}
      </InnerContainer>
    </OuterContainer>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  flexReverse: PropTypes.bool,
  fontSize: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  margin: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  padding: PropTypes.string,
  value: PropTypes.string.isRequired
}

Checkbox.defaultProps = {
  margin: '0'
}

export default Checkbox
