import { GET_TASK_AVAILABILITY, LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

const getTaskAvailability = ({ key }) => {
  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    try {
      const response = await peachApi.get({
        url: `/twilio/tasktype-availability`
      })
      dispatch({ type: GET_TASK_AVAILABILITY, payload: response.data })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
      return response.data
    } catch {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
    }
  }
}

export default getTaskAvailability
