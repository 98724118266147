import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

const Span = styled.span`
  user-select: none;
  font-size: inherit;
`

const MaterialIconRounded = ({ name }) => {
  return <Span className='material-icons-round'>{name}</Span>
}

MaterialIconRounded.propTypes = {
  name: PropTypes.string.isRequired
}

export default MaterialIconRounded
