import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useOverride } from 'core/components/Badge/overrides'

const UserTypeContext = createContext('')

const UserTypeProvider = ({ userType, children }) => {
  const $userType = useOverride('userType', userType)

  return (
    <UserTypeContext.Provider value={$userType}>
      {children}
    </UserTypeContext.Provider>
  )
}

UserTypeProvider.propTypes = {
  userType: PropTypes.string,
  children: PropTypes.node
}

const _useUserType = () => {
  const userType = useContext(UserTypeContext)
  return userType
}

// if you need this hook, use import { useUserType } from 'core/hooks'
export { UserTypeProvider, _useUserType }
