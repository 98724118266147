import { GET_POPULATIONS, LOADING } from 'core/actions/constants'
import { peachApi } from 'core/api'

const getPopulations = ({ filters, key }) => {
  const { supercaseId } = filters

  return async dispatch => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading'
      })
    }

    try {
      const response = await peachApi.get({
        url: `/supercases/${supercaseId}/populations`
      })
      const membersArray = await Promise.all(
        response.data.map(p =>
          peachApi.get({
            url: `/supercases/${supercaseId}/populations/${p.id}/members`
          })
        )
      )
      dispatch({
        type: GET_POPULATIONS,
        payload: response.data.map((p, i) => ({
          ...p,
          supercaseId,
          members: membersArray[i].data
        }))
      })
      if (key) {
        dispatch({ type: LOADING, key, status: 'success' })
      }
    } catch {
      if (key) {
        dispatch({ type: LOADING, key, status: 'error' })
      }
    }
  }
}

export default getPopulations
