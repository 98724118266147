import _ from 'lodash'

const getTimeFromSeconds = (totalSeconds, options) => {
  const { addPadding } = options || {}
  const days = _.floor(totalSeconds / 60 / 60 / 24)
  const totalHours = _.floor(totalSeconds / 60 / 60)
  const hours = _.floor(totalSeconds / 60 / 60) - days * 24
  const minutes = _.floor(totalSeconds / 60) - totalHours * 60
  const seconds = _.floor(totalSeconds % 60)

  const paddedDays = String(days).padStart(2, '0')
  const paddedHours = String(hours).padStart(2, '0')
  const paddedMinutes = String(minutes).padStart(2, '0')
  const paddedSeconds = String(seconds).padStart(2, '0')

  if (addPadding) {
    return {
      days: paddedDays,
      hours: paddedHours,
      minutes: paddedMinutes,
      seconds: paddedSeconds
    }
  }

  return { days, hours, minutes, seconds }
}

export default getTimeFromSeconds
