import { CLEAR_DOCUMENT_DESCRIPTORS } from 'core/actions/constants'

const clearDocumentDescriptors = () => {
  return async dispatch => {
    dispatch({
      type: CLEAR_DOCUMENT_DESCRIPTORS
    })
  }
}

export default clearDocumentDescriptors
