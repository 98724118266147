// Third party ------------------------
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { startCase, includes, filter } from 'lodash'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'

// Proprietary ------------------------
import useCollection from 'core/hooks/useCollection'

// Components -------------------------
import InlineDropdownBase from 'core/components/InlineDropdown'
import Row from './Row'
import RowLabel from './RowLabel'

const Container = styled.div`
  background-color: white;
`
const InlineDropdown = styled(InlineDropdownBase)`
  width: 360px;
`
const Span = styled.span`
  padding: 0 0 0 8px;
  /** The 30px height/line-height are according to design */
  height: 30px;
  line-height: 30px;
`

const Header = ({
  borrowerId,
  caseId,
  contact,
  isMulti,
  maximized,
  onChangeCaseId,
  onChangeTemplate,
  readOnly,
  template
}) => {
  const { useMemo, useCollection, useSelector } = Header.dependencies
  // Selectors & Collections
  const allSubjects = useSelector(state => state.communicator.subjects) ?? []
  const subjects = filter(allSubjects, 'agentCanSend')
  const cases = useCollection('cases', { personId: borrowerId })

  // Drop down options ----------------
  const caseOptions = useMemo(
    () =>
      Object.values(cases.byId)
        .filter(c => c.status !== 'completed' && c.status !== 'canceled')
        .map(c => ({
          value: c.id,
          label: `${c.id} - ${c.name || startCase(c.caseType)}`,
          caseType: c.caseType
        })),
    [cases]
  )

  const selectedCaseOption = useMemo(
    () => caseOptions.find(o => o.value === caseId),
    [caseOptions, caseId]
  )

  const templateOptions = useMemo(
    () =>
      subjects
        .filter(s => includes(s?.caseTypes, selectedCaseOption?.caseType))
        .map(s => ({
          label: s.title,
          value: s.subject
        })),
    [selectedCaseOption, subjects]
  )
  const selectedTemplateOption = useMemo(
    () => templateOptions.find(o => o?.value === template),
    [template, templateOptions]
  )

  return (
    <>
      <Container maximized={maximized}>
        <Row maximized={maximized}>
          <RowLabel>Case</RowLabel>
          {readOnly ? (
            <Span>{selectedCaseOption?.label || ''}</Span>
          ) : (
            <InlineDropdown
              getSelectionValue={onChangeCaseId}
              isControlled
              isDisabled={!contact?.id}
              multiSelect={isMulti}
              options={caseOptions}
              placeholder='Select a case'
              value={selectedCaseOption}
            />
          )}
        </Row>
        <Row maximized={maximized}>
          <RowLabel>Template</RowLabel>
          {readOnly ? (
            <Span>
              {template === 'freeFormBranded'
                ? ''
                : selectedTemplateOption?.label || ''}
            </Span>
          ) : (
            <InlineDropdown
              getSelectionValue={onChangeTemplate}
              isControlled
              isDisabled={!selectedCaseOption || !contact?.id}
              options={templateOptions}
              placeholder='Select a template'
              value={
                template === 'freeFormBranded' ? null : selectedTemplateOption
              }
            />
          )}
        </Row>
      </Container>
    </>
  )
}

Header.propTypes = {
  borrowerId: PropTypes.string,
  caseId: PropTypes.string,
  contact: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  interaction: PropTypes.shape({
    id: PropTypes.string,
    sensitive: PropTypes.bool
  }),
  isMulti: PropTypes.bool,
  /** If the containing resource window is maximized */
  maximized: PropTypes.bool,
  onChangeCaseId: PropTypes.func,
  onChangeTemplate: PropTypes.func,
  readOnly: PropTypes.bool,
  template: PropTypes.string
}

Header.dependencies = {
  useMemo,
  useCollection,
  useSelector
}

export default Header
