import { useContext } from 'react'
import { TelephonyContext } from 'contexts/TelephonyContext'

const useTelephonyContext = () => {
  const {
    callDuration,
    contactId,
    setContactId,
    hasBeenRecorded,
    setHasBeenRecorded,
    micOn,
    setMicOn,
    personId,
    setPersonId,
    recording,
    setRecording,
    onEndCall,
    setupActiveCallState,
    removeActiveCallDraft,
    draftVoiceInteraction,
    setDraftVoiceInteraction,
    onRedial,
    onNewCall
  } = useContext(TelephonyContext)

  return {
    callDuration,
    contactId,
    setContactId,
    hasBeenRecorded,
    setHasBeenRecorded,
    micOn,
    setMicOn,
    personId,
    setPersonId,
    recording,
    setRecording,
    onEndCall,
    setupActiveCallState,
    removeActiveCallDraft,
    draftVoiceInteraction,
    setDraftVoiceInteraction,
    onRedial,
    onNewCall
  }
}

export default useTelephonyContext
