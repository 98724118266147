import PropTypes from 'prop-types'
import React from 'react'
import { Redirect, useParams } from 'react-router'
import ActionDisabledMessage from './ActionDisabledMessage'
import { useBorrowerUrl, useUserType } from 'core/hooks'

const agentText = `You cannot perform this action now. Some actions are limited due to the current loan's status. Please contact your supervisor for help.`

const LoanStatusMessageOrRedirect = ({ backUrl }) => {
  const userType = useUserType()
  const { loanId } = useParams()

  const url = backUrl || (loanId ? `/loans/${loanId}/activity` : '/loans')

  const borrowerUrl = useBorrowerUrl(url)

  if (userType === 'borrower') {
    return <Redirect to={borrowerUrl} />
  } else {
    return <ActionDisabledMessage message={agentText} backUrl={url} />
  }
}

LoanStatusMessageOrRedirect.propTypes = {
  backUrl: PropTypes.string
}

export default LoanStatusMessageOrRedirect
