import { useEffect, useRef } from 'react'

function useInterval(callback, options) {
  const { delay = 1000, disabled = false } = options || {}
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = () => {
      if (!disabled) callback()
    }
  }, [callback, disabled])

  useEffect(() => {
    const id = setInterval(() => callbackRef.current(), delay)
    return () => clearInterval(id)
  }, [delay])
}

export default useInterval
