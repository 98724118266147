import { useEntity, useSupercase } from 'core/hooks'

/** Takes in a caseId and personId and returns an object
 * with the current escalation information
 * for that case. It only includes info found
 * on the case object itself, so that means it
 * does not include `note`. If you need `note`
 * or something else about the escalation that is
 * not available through the case object, you'll
 * have to somehow get it from the escalation objects
 * themselves, not using this hook.
 */
const useCurrentEscalation = ({ caseId, personId, isSupercase }) => {
  const thisRegularCase = useEntity('cases', caseId, { personId })
  const thisSupercase = useSupercase(caseId)
  const thisCase = isSupercase ? thisSupercase : thisRegularCase
  return {
    escalatedTeamId: thisCase.escalatedTeamId,
    escalatedEmployeeId: thisCase.escalatedEmployeeId,
    isUrgent: thisCase.escalationIsUrgent
  }
}

export default useCurrentEscalation
