import keyBy from 'lodash/keyBy'
import pickBy from 'lodash/pickBy'

import {
  CREATE_DO_NOT_INTERACT,
  GET_DO_NOT_INTERACTS,
  GET_MORE_DO_NOT_INTERACTS,
  UPDATE_DO_NOT_INTERACT,
  DELETE_DO_NOT_INTERACT
} from 'core/actions/constants'

const initialState = {
  byId: {}
}

const doNotInteracts = function(state = initialState, action) {
  switch (action.type) {
    case GET_DO_NOT_INTERACTS:
      return {
        ...state,
        byId: {
          // keep all existing do-not-interacts for other personIds
          ...pickBy(state.byId, item => item.personId !== action.personId),
          // insert the fresh do-not-interacts for this personId
          ...keyBy(action.payload, 'id')
        }
      }
    case GET_MORE_DO_NOT_INTERACTS:
      // This differs from GET_DO_NOT_INTERACTS by not deleting any
      // existing ones. It's used by getDoNotInteracts when it calls
      // itself with nextUrl to get follow-up batches.
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(action.payload, 'id')
        }
      }
    case CREATE_DO_NOT_INTERACT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        }
      }
    case UPDATE_DO_NOT_INTERACT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        }
      }
    case DELETE_DO_NOT_INTERACT:
      return {
        ...state,
        byId: pickBy(state.byId, item => item.id !== action.id)
      }
    default:
      return state
  }
}

export default doNotInteracts
