import styled, { css } from 'styled-components/macro'

import MaskedInput from 'core/components/MaskedInput'

import { variables } from 'core/styles'

const sharedStyles = css`
  color: ${variables.colorBlack60};
  font-size: 16px;
`
const Amount = styled.span`
  display: block;
  ${sharedStyles}
`
const CustomMaskedInput = styled(MaskedInput)`
  display: inline-block;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${variables.colorBlack30};
  ${sharedStyles}
`
const Container = styled.div`
  &:not(:first-child) {
    margin-top: 24px;
  }
  & > .amount-input-wrapper,
  & > ${Amount} {
    margin-left: 32px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
// TODO: figure out why we need `!important` here - should avoid this is possible
const Input = styled.input`
  display: none !important;
`
const Label = styled.label`
  box-sizing: border-box;
  ${p => (p.width ? `width: ${p.width};` : '')}
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: ${variables.colorBlack90};
  font-size: '16px';

  cursor: ${p => (p.disabled ? 'default' : 'pointer')};

  &:before {
    box-sizing: border-box;
    padding: 0;
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 2px solid
      ${p =>
        p.disabled
          ? variables.colorBlack30
          : p.checked
          ? p.theme.primary
          : variables.colorBlack70};
    background-color: ${p =>
      p.disabled
        ? p.checked
          ? variables.colorBlack20
          : variables.colorBlack10
        : p.checked
        ? p.theme.secondary
        : variables.colorWhite};
  }

  &:after {
    box-sizing: border-box;
    padding: 0;
    border: none;
    content: '';
    width: 6px;
    height: 6px;
    background-color: ${variables.colorWhite};
    position: absolute;
    top: calc(50% - 3.5px);
    left: 7px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

    opacity: ${p => (p.checked ? 1 : 0)};
    transform: scale(${p => (p.checked ? 1 : 0)});
  }
`
const SubLabel = styled.span`
  display: block;
  color: ${variables.colorBlack50};
  font-size: 12px;
  font-weight: 500;
  margin-left: 32px;
`

export { Amount, CustomMaskedInput, Container, Input, Label, SubLabel }
