import { DELETE } from 'core/api/legacyApi'

import { APIendpoint, LOADING, getStoredToken } from 'core/actions/constants'

import { REMOVE_INTEREST_RATE } from './loans'
import { refreshLoanAndRelatedData } from './loanAndRelatedData'

import { getGlobalValue } from 'core/badGlobalDoNotUse'

const removeInterestRate = options => {
  const personId = getGlobalValue('borrowerPersonId')

  const loanEndpoint = `${APIendpoint}/people/${personId}/loans`

  const { loanId, caseId, rateId, key, force = false } = options

  return async dispatch => {
    dispatch({ type: LOADING, key, status: 'loading' })

    const queryParams = caseId ? `?caseId=${caseId}&force=${force}` : ''

    const endpoint = `${loanEndpoint}/${loanId}/rates/${rateId}${queryParams}`

    const resp = await fetch(endpoint, DELETE(getStoredToken()))

    if (resp.ok) {
      dispatch({ type: REMOVE_INTEREST_RATE, payload: { loanId, rateId } })
      dispatch({ type: LOADING, key, status: 'success' })
      dispatch(refreshLoanAndRelatedData(loanId))
    } else {
      dispatch({ type: LOADING, key, status: 'error' })
    }
    return resp
  }
}
export { removeInterestRate }
