import _ from 'lodash'

import {
  CREATE_GLOBAL_NOTE,
  GET_GLOBAL_NOTES,
  UPDATE_GLOBAL_NOTE
} from 'core/actions/constants'

const initialState = {
  byId: {}
}

const localNotes = function(state = initialState, action) {
  switch (action.type) {
    case GET_GLOBAL_NOTES:
      const newGlobalNotes = _.keyBy(action.payload, 'id')
      return {
        ...state,
        byId: {
          ...state.byId,
          ...newGlobalNotes
        }
      }
    case UPDATE_GLOBAL_NOTE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...action.payload
          }
        }
      }
    case CREATE_GLOBAL_NOTE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        }
      }
    default:
      return state
  }
}

export default localNotes
